import { ComponentOverlay } from "../../../../global/components/ComponentOverlay.tsx";
import useCurrency, { CurrencyLocation } from "../../../../global/utils/useCurrency.tsx";
import { ButtonBase, Paper, Typography } from "@mui/material";
import { selectAppLanguage } from "../../LanguageSelector/useLanguage.ts";
import store, { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import { useDialog } from "../../../../global/utils/dialog/DialogProvider.tsx";
import Article, { getArticleName, getArticlePrice } from "../../../../global/utils/models/menu/Article.ts";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz.tsx";
import isKiosk from "../../../../global/utils/helpers/isKiosk.tsx";
import SlideUpTransition from "../../../../global/components/SlideUpTransition.tsx";
import OrderArticleSelectorPage from "../../../pages/OrderArticleSelectorPage/OrderArticleSelectorPage.tsx";
import Image from "../../../../global/components/Image";
import { voucherOrderArticleConfirmed } from "../../../../global/utils/vouchersV2/vouchersV2Slice.tsx";
import { OrderArticleOrigin } from "../../../../global/utils/models/order/OrderArticle.ts";
import { selectSalesAreaPriceLineId } from "../../../../global/utils/useSalesAreaPriceLineId.ts";

interface Props {
  article: Article;

  useAsDialog: boolean;
}

export default function DiscountedProductItem(props: Props) {
  const { openDialog, closeDialog } = useDialog();
  const lang = useAppSelector(selectAppLanguage);
  const toCurrency = useCurrency({ location: CurrencyLocation.Menu });
  const priceLineId = useAppSelector(selectSalesAreaPriceLineId);
  const price = getArticlePrice(props.article, { salesAreaPriceLineId: priceLineId });
  return (
    <ComponentOverlay
      disabled={!props.article.isNotAvailable}
      overlayContent={
        props.article.isOutOfStock ? (
          <Typography variant={"h6"} sx={{ fontWeight: 1000 }} className={"JS-ArticleSuggestionItem-OutOfStock-Text"}>
            <FormattedMessageJamezz id={"OUT OF STOCK"} />
          </Typography>
        ) : (
          <Typography variant={"h6"} sx={{ fontWeight: 1000 }} className={"JS-ArticleSuggestionItem-NotAvailable-Text"}>
            <FormattedMessageJamezz id={"NOT AVAILABLE"} />
          </Typography>
        )
      }
    >
      <ButtonBase
        className={"JS-ArticleSuggestionItem-Root"}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          margin: 2,
          flexBasis: "calc(50% - 32px)",
          boxShadow: "0 1px 10px 0px rgba(0,0,0,0.2)",
        }}
        onClick={() => {
          if (isKiosk()) {
            openDialog({
              children: (
                <OrderArticleSelectorPage
                  article={props.article}
                  isUpselled={true}
                  fixedCount={1}
                  onOrderArticleOk={(orderArticle) => {
                    orderArticle.added_origin = OrderArticleOrigin.SYSTEM;
                    store.dispatch(voucherOrderArticleConfirmed(orderArticle));
                    closeDialog();
                    closeDialog();
                  }}
                />
              ),
              fullScreen: "almostFullScreen",
              TransitionComponent: SlideUpTransition,
            });
          }
        }}
      >
        <Paper sx={{ width: 1, height: 1, padding: 3 }} className={"JS-ArticleSuggestionItem-Paper"}>
          {props.article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset ? (
            <Image
              srcSet={props.article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset}
              style={{ width: 220, height: 220, objectFit: "contain" }}
            />
          ) : null}
          <Typography sx={{ marginY: 1, fontWeight: 800 }}>{getArticleName(props.article, lang)}</Typography>
          {price > 0 || props.article.vaprice > 0 ? (
            <Typography sx={{ fontWeight: 800, marginTop: 1 }} className={"JS-ArticleSuggestionItem-Price"}>
              {toCurrency(props.article.vaprice > 0 ? props.article.vaprice : price, {
                name: "ArticleContent-price",
              })}
            </Typography>
          ) : null}
        </Paper>
      </ButtonBase>
    </ComponentOverlay>
  );
}
