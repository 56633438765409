import { Divider, Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz.tsx";
import { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import { useListVouchersQuery } from "../../../../global/utils/redux/api/piggyApi.ts";
import PiggyVoucherItem from "./PiggyVoucherItem.tsx";

export default function PiggyVouchers() {
  const piggyVouchersEnabled = useAppSelector((state) => state.global.salesarea.piggy.vouchers.enabled);
  const contactIdentifierScan = useAppSelector((state) => state.piggy.contactIdentifierScan);
  const { data: vouchersList, isFetching } = useListVouchersQuery(
    { contactIdentifier: contactIdentifierScan?.code ?? "", page: 1, status: "ACTIVE" },
    {
      skip: !piggyVouchersEnabled || !contactIdentifierScan,
    }
  );

  if ((vouchersList?.data.length == 0 && !isFetching) || !piggyVouchersEnabled) {
    return null;
  }
  return (
    <>
      <Divider
        sx={{
          "&::before": {
            borderTopWidth: "4px",
          },
          "&::after": {
            borderTopWidth: "4px",
          },
        }}
      >
        <FormattedMessageJamezz id="Piggy.order-page.vouchers-list.header" />
      </Divider>
      <Box
        data-cy="piggy-rewards-container"
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          overflowX: "scroll",
          pt: 2,
          pb: 1.5,
        }}
      >
        {isFetching ? (
          <>
            <Box sx={{ flex: "0 0 auto", display: "flex", justifyContent: "center", px: 2 }}>
              <Skeleton variant="rectangular">
                <Box sx={{ height: "160px", width: "320px" }} />
              </Skeleton>
            </Box>
            <Box sx={{ flex: "0 0 auto", display: "flex", justifyContent: "center", px: 2 }}>
              <Skeleton variant="rectangular">
                <Box sx={{ height: "160px", width: "320px" }} />
              </Skeleton>
            </Box>
            <Box sx={{ flex: "0 0 auto", display: "flex", justifyContent: "center", px: 2 }}>
              <Skeleton variant="rectangular">
                <Box sx={{ height: "160px", width: "320px" }} />
              </Skeleton>
            </Box>
          </>
        ) : vouchersList != null ? (
          <>
            {vouchersList.data.map((piggyVoucher) => (
              <Box
                key={piggyVoucher.uuid}
                sx={{
                  flex: "0 0 auto",
                  display: "flex",
                  justifyContent: "center",
                  px: 2,
                }}
              >
                <PiggyVoucherItem item={piggyVoucher} />
              </Box>
            ))}
          </>
        ) : (
          <FormattedMessageJamezz id="Piggy.order-page.vouchers.loading-error" />
        )}
      </Box>
    </>
  );
}
