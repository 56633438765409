import OptionItemRadio from "../OptionItems/OptionItemRadio";
import { useMemo } from "react";
import OrderOptionGroup from "../../../../global/utils/models/order/OrderOptionGroup";
import OrderArticle from "../../../../global/utils/models/order/OrderArticle";
import _ from "lodash";
import Box from "@mui/material/Box";
import { optionGroupGridStyles } from "./OptionGroupCountable";

export default function OptionGroupRadio(props: {
  orderOptionGroup: OrderOptionGroup;
  onChange: (orderOptionGroup: OrderOptionGroup) => void;
}) {
  const value = useMemo(() => {
    return props.orderOptionGroup.orderArticles.find((orderArticle) => orderArticle.count === 1)?.article.id ?? "";
  }, [props.orderOptionGroup]);

  const numberOfItemsPerRowOfOrderArticles = 4;

  return (
    <>
      {_.chunk(props.orderOptionGroup.orderArticles, numberOfItemsPerRowOfOrderArticles).map((orderArticles) => {
        return (
          <Box
            key={orderArticles.map((o) => o.article.id).join(",")}
            sx={optionGroupGridStyles(orderArticles.length, 4, numberOfItemsPerRowOfOrderArticles)}
          >
            {orderArticles.map((orderArticle, columnIndex) => (
              <OptionItemRadio
                key={orderArticle.article.id}
                columnIndex={columnIndex}
                orderArticle={orderArticle}
                orderOptionGroup={props.orderOptionGroup}
                value={value}
                onChange={(selectedOrderArticle: OrderArticle) => {
                  const copy = _.cloneDeep(props.orderOptionGroup);

                  copy.orderArticles.forEach((orderArticle: OrderArticle) => {
                    if (orderArticle.article.id === selectedOrderArticle.article.id) {
                      orderArticle.count = 1;
                      orderArticle.orderOptionGroups = selectedOrderArticle.orderOptionGroups;
                    } else {
                      orderArticle.count = 0;
                      // TODO reset orderOptionGroups
                    }
                  });
                  props.onChange(copy);
                }}
              />
            ))}
          </Box>
        );
      })}
    </>
  );
}
