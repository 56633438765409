import { useFetchPackagingsSettingsQuery } from "../redux/api/packagingsApi";
import { useEffect } from "react";
import store, { useAppSelector } from "../redux/store";
import { packagingAdded, packagingTypeAdded } from "../redux/packagingsSlice";

export default function useLoadPackagings() {
  const usePackagings = useAppSelector((state) => state.global.salesarea.use_packagings);
  const { data } = useFetchPackagingsSettingsQuery(undefined, { skip: !usePackagings, pollingInterval: 120_000 });

  useEffect(() => {
    if (data?.packagings) {
      store.dispatch(packagingAdded(data.packagings));
      store.dispatch(packagingTypeAdded(data.packaging_types));
    }
  }, [data]);
}
