import { useMemo } from "react";
import Box from "@mui/material/Box";
import { useScroll } from "../../../../global/utils/ScrollProvider";
import { useAppSelector } from "../../../../global/utils/redux/store";
import SearchContent from "../SearchContent";
import WideShoppingCartButton from "../ShoppingCartButtons/WideShoppingCartButton";
import SmallShoppingCartButton from "../ShoppingCartButtons/SmallShoppingCartButton";
import MultipleLayersBottomTabBar from "./MultipleLayersBottomTabBar";
import KioskTopBar from "../../../components/KioskTopBar/KioskTopBar";
import ArticlegroupsShortcutPage from "../../../components/ArticlegroupsShortcutPage/ArticlegroupsShortcutPage";
import MultipleLayersMenuContent from "./MultipleLayersMenuContent";
import QuickTraitFilterDialog from "./QuickTraits/QuickTraitFilterDialog";
import { selectArticlesSum } from "../../../../global/utils/redux/shoppingCartSlice";
import BackgroundMediaUrlFromSalesarea from "../../../hooks/useBackgroundMediaUrl.tsx";

export default function MultipleLayersMenuPage() {
  const isArticleSearchTextEmpty = useAppSelector((state) => state.global.isArticleSearchTextEmpty);
  const selectedArticlegroup = useAppSelector((state) => state.menu.selectedArticlegroup);
  const articlegroupsShortcutPage = useAppSelector((state) => state.global.salesarea?.articlegroups_shortcut_page);
  const typeOfShoppingCartButton = useAppSelector((state) => state.global.salesarea?.type_of_shopping_cart_button);

  const count = useAppSelector(selectArticlesSum);
  const { scrollContent } = useScroll();
  const stateOfArticleTraits = useAppSelector((state) => state.traitFilter.stateOfArticleTraits);
  const numberOfItems = useMemo(() => {
    return Object.keys(stateOfArticleTraits).reduce((sum, arrKey) => {
      return sum + stateOfArticleTraits[arrKey].length;
    }, 0);
  }, [stateOfArticleTraits]);
  const pixelHeightCartButton = "200px";

  return (
    <>
      <Box className={"JS-MultipleLayersMenuPage-Top"} sx={{ width: 1, textAlign: "center", paddingY: 4 }}>
        <KioskTopBar />
      </Box>
      {isArticleSearchTextEmpty &&
        (selectedArticlegroup !== null || !articlegroupsShortcutPage || numberOfItems !== 0) && (
          <MultipleLayersBottomTabBar />
        )}
      <QuickTraitFilterDialog />
      {/*<FilterPage />*/}
      <BackgroundMediaUrlFromSalesarea
        keyName={"extra_settings__kioskv5_background_of_menu_page_content"}
        className={"JS-MultipleLayersMenuPage-Root"}
        data-cy="menu"
        sx={{
          height: "100%",
          width: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
        sxWrapper={{ height: 0, flex: "1 1 0%", overflowY: "auto" }}
        innerRef={scrollContent}
      >
        {isArticleSearchTextEmpty &&
          (selectedArticlegroup === null && articlegroupsShortcutPage && numberOfItems === 0 ? (
            <ArticlegroupsShortcutPage />
          ) : (
            <MultipleLayersMenuContent pixelHeightCartButton={pixelHeightCartButton} />
          ))}
        {!isArticleSearchTextEmpty && <SearchContent pixelHeightCartButton={pixelHeightCartButton} />}

        {typeOfShoppingCartButton === "WIDE" && count > 0 && (
          <WideShoppingCartButton pixelHeightCartButton={pixelHeightCartButton} />
        )}
        {typeOfShoppingCartButton === "SMALL" && count > 0 && (
          <SmallShoppingCartButton pixelHeightCartButton={pixelHeightCartButton} />
        )}
      </BackgroundMediaUrlFromSalesarea>
      {/*<Button*/}
      {/*  className={"JS-Filters-Button"}*/}
      {/*  sx={{ position: "absolute", bottom: 20, right: 20 }}*/}
      {/*  onClick={() => {*/}
      {/*    props.setOpenTraitMenu(true);*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <FormattedMessageJamezz id={"Filters"} />*/}
      {/*</Button>*/}
    </>
  );
}
