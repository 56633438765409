import { useMemo } from "react";
import OrderArticle from "../models/order/OrderArticle";
import OrderOptionGroup from "../models/order/OrderOptionGroup";

export default function useOrderArticlesFromShoppingCart(orderArticle: OrderArticle) {
  return useMemo(() => {
    const selected: OrderArticle[] = [];

    function merge(orderOptionGroups: OrderOptionGroup[]) {
      orderOptionGroups.forEach((orderOptionGroup: OrderOptionGroup) => {
        orderOptionGroup.orderArticles.forEach((orderArticle) => {
          if (orderArticle.count > 0) {
            selected.push(orderArticle);
          }
          merge(orderArticle.orderOptionGroups);
        });
      });
    }

    if (!orderArticle) {
      return [];
    }

    merge(orderArticle.orderOptionGroups);
    return selected;
  }, [orderArticle]);
}
