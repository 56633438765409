import _ from "lodash";
import store from "../redux/store.tsx";
import { stockUpdated } from "../redux/menuDataSlice.ts";
import { toast } from "react-toastify";

export function processStock(data: any) {
  if (data?.checkerNames && data.checkerNames.includes("CheckProductsAreInStock")) {
    /// update stock of products
    if (data?.newProductStocks) {
      store.dispatch(
        stockUpdated(
          _.map(data?.newProductStocks, (obj) => ({
            articleId: Object.keys(obj)[0],
            newStock: obj[Object.keys(obj)[0]].stock,
          }))
        )
      );
      toast.warn("Some items are out of stock, check your order please.");
    }
  }
}
