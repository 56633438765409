import { ReactElement, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { unstable_next } from "scheduler";

export function DeferredChunkRenderer({
  children,
  placeholder,
  prerenderSize,
  chunkSize = 2,
}: {
  children: ReactElement[];
  placeholder: ReactElement;
  prerenderSize: number;
  chunkSize?: number;
}) {
  const [renderUptoChunk, setRenderUptoChunk] = useState(prerenderSize);
  const chunks = useMemo(() => _.chunk(children, chunkSize), [children, chunkSize]);
  const isRendering = renderUptoChunk * chunkSize < children.length;

  useEffect(() => {
    let id: number | undefined;
    if (isRendering) {
      id = _.defer(() => {
        unstable_next(function () {
          setRenderUptoChunk((i) => i + 1);
        });
      });
    }

    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [renderUptoChunk, isRendering]);

  return chunks
    .filter((_, idx) => idx < renderUptoChunk)
    .flat()
    .concat(isRendering ? placeholder : []);
}
