import { ReactNode } from "react";
import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import Box from "@mui/material/Box";

export function OrderPageInfo({
  itemCount,
  total,
  children,
}: {
  itemCount: ReactNode;
  total: ReactNode;
  children: ReactNode;
}) {
  const wheelchairFriendlyEnabled = useAppSelector((state) => state.kiosk.wheelchairFriendly);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: wheelchairFriendlyEnabled ? 1 : 4,
        width: 1,
        marginBottom: wheelchairFriendlyEnabled ? 1 : 10,
        color: "primary.contrastText",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
        {itemCount} {total}
        {children}
      </Box>
    </Box>
  );
}
