import _ from "lodash";

interface LoDashMixins extends _.LoDashStatic {
  matchPattern: (pattern: any) => (input: any) => boolean;
}

_.mixin({
  matchPattern: function (pattern) {
    return function (input: any) {
      return _.isMatchWith(input, pattern, function (objValue, srcValue, key, obj) {
        if (!_.has(obj, key)) {
          return true;
        }
        if (_.isFunction(srcValue)) {
          return srcValue(objValue);
        }
        return _.isEqual(objValue, srcValue);
      });
    };
  },
});

export default <LoDashMixins>_;
