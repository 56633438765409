import { useCallback, useEffect } from "react";
import { httpGet } from "../../global/utils/api/http";
import { setPriceKey } from "../../global/utils/redux/menuSlice";
import { setDiscountCardNr } from "../../global/utils/redux/shoppingCartSlice";
import store, { useAppDispatch, useAppSelector } from "../../global/utils/redux/store";
import usePriceKeysWithOnlyCodes from "./usePriceKeysWithOnlyCodes";

export default function usePriceKeyChecker() {
  const account_to_pricekey = useAppSelector((state) => state.global.salesarea?.account_to_pricekey);
  const priceKeysWithOnlyCodes = usePriceKeysWithOnlyCodes();
  const useAccountServer = useAppSelector((state) => state.global.salesarea?.useAccountServer);
  const dispatch = useAppDispatch();

  const setPriceKeyChecker = useCallback(
    (identifier: string, name?: string, hash?: string) => {
      return new Promise((resolve, reject) => {
        if (priceKeysWithOnlyCodes) {
          for (const priceKeyEntry of priceKeysWithOnlyCodes) {
            if (priceKeyEntry?.code == identifier && priceKeyEntry.priceKey != null) {
              dispatch(setPriceKey(priceKeyEntry.priceKey));
              resolve("OK");
              return;
            }
          }
          reject("Error");
          return;
        }

        if (useAccountServer) {
          const searchParams = new URLSearchParams();
          searchParams.set("i", identifier);
          if (name) {
            searchParams.set("n", name);
          } else {
            if (hash !== undefined && hash) {
              searchParams.set("h", hash);
            } else {
              searchParams.set("h", "kioskscan");
            }
          }

          httpGet("/v5_2/sessions/getAccountData?" + searchParams.toString())
            .then((res: any) => {
              if (res.data.data) {
                let pricekeys: any = [];
                if (account_to_pricekey && typeof account_to_pricekey === "string") {
                  pricekeys = JSON.parse(account_to_pricekey);
                }

                if (pricekeys && Array.isArray(pricekeys)) {
                  if (
                    pricekeys.some((priceKeySet: any) => {
                      if (priceKeySet.accountType && priceKeySet.accountType == res.data.data.AccountType) {
                        dispatch(setPriceKey(priceKeySet.id));
                        dispatch(setDiscountCardNr(identifier));
                        return true;
                      } else {
                        return false;
                      }
                    })
                  ) {
                    resolve("OK");
                    return;
                  }
                }
              }
              reject("ERROR");
            })
            .catch(() => {
              reject("ERROR");
            });
        } else {
          reject("No price keys");
        }
      });
    },
    [priceKeysWithOnlyCodes, useAccountServer, dispatch, account_to_pricekey]
  );

  useEffect(() => {
    if (useAccountServer) {
      /**
       * HVP Discounts
       */
      if (
        store.getState().global.sessionState?.rawData?.hash &&
        store.getState().global.sessionState?.rawData?.cardnr
      ) {
        setPriceKeyChecker(
          store.getState().global.sessionState?.rawData?.cardnr,
          undefined,
          store.getState().global.sessionState?.rawData?.hash
        );
      }
    }
  }, [useAccountServer]);

  window.setPriceKeyChecker = setPriceKeyChecker;
  return setPriceKeyChecker;
}
