import { useEffect, useMemo } from "react";
import { Button, DialogActions, DialogContent, Typography } from "@mui/material";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import MenuFilterDialog from "../../../qr/pages/MenuTagFilterPage/MenuFilterDialog";
import { useAppSelector } from "../../../global/utils/redux/store";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import Box from "@mui/material/Box";
import MenuFilterDialogV2 from "../../pages/MenuTagFilterPage/MenuFilterDialogV2.tsx";

export default function AllergensMenu() {
  const { openDialog, closeDialog } = useDialog();

  const salesarea = useAppSelector((state) => state.global.salesarea);
  const allergensOrderArticles = useAppSelector((state) => state.shoppingCart.allergenOrderArticles);

  const allergensProductMap = useMemo(() => {
    const allergenProductMap = { ...(salesarea?.custom_data?.allergen_product_map ?? {}) };
    Object.keys(allergenProductMap).forEach((key) => {
      if (!allergenProductMap[key]) {
        delete allergenProductMap[key];
      }
    });

    return allergenProductMap;
  }, [salesarea]);

  const hasOneOrMoreProducts = useMemo(() => {
    return Object.keys(allergensProductMap).length;
  }, [allergensProductMap]);

  useEffect(() => {
    if (hasOneOrMoreProducts && Object.keys(allergensOrderArticles).length === 0) {
      openDialog({ children: <AllergenDialog /> });
    }
    return () => {
      if (hasOneOrMoreProducts && Object.keys(allergensOrderArticles).length === 0) {
        closeDialog();
      }
    };
  }, []);

  return hasOneOrMoreProducts ? (
    <Box display={"inline-flex"}>
      <Typography>
        <FormattedMessageJamezz id={"Do you have any allergens we need to know about?"} />
      </Typography>
      <Button
        sx={{ padding: "20px 48px" }}
        variant={"contained"}
        onClick={() => {
          openDialog({ children: <MenuFilterDialog />, fullScreen: "almostFullScreen" });
        }}
      >
        <FormattedMessageJamezz id="Yes" />
      </Button>
    </Box>
  ) : null;
}

function AllergenDialog() {
  const { closeDialog, openDialog } = useDialog();

  return (
    <>
      <DialogContent>
        <Typography>
          <FormattedMessageJamezz id={"Do you have any allergens we need to know about?"} />
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          sx={{ padding: "20px 48px" }}
          variant={"contained"}
          onClick={() => {
            closeDialog();
          }}
        >
          <FormattedMessageJamezz id={"No"} />
        </Button>
        <Button
          sx={{ padding: "20px 48px" }}
          variant={"contained"}
          onClick={() => {
            closeDialog();
            openDialog({ children: <MenuFilterDialogV2 />, fullScreen: "almostFullScreen" });
          }}
        >
          <FormattedMessageJamezz id={"Yes"} />
        </Button>
      </DialogActions>
    </>
  );
}
