import OrderArticle, { recurOrderArticleOptions } from "../models/order/OrderArticle";
import Article from "../models/menu/Article";

/**
 * there can be multiple products that need to be checked for age.
 *  For instance, consider:
 * - Bitterballen (age=0):
 *   - Options:
 *     - Beer (age=18)
 * this construct retrieves the product with the highest age requirement, considering the main item itself
 * as well as all of its options.
 */
export function findProductWithHighestAgeRequirement(...items: OrderArticle[]): Article {
  const products = items.concat(...items.map((item) => [...recurOrderArticleOptions(item)]));
  return products.reduce((targetProduct, currentProduct) => {
    if (
      (currentProduct?.article?.requireAge ?? 0) > (targetProduct?.article?.requireAge ?? 0) &&
      currentProduct.count
    ) {
      return currentProduct;
    }
    return targetProduct;
  }, items[0]).article;
}

export function countProductsWithAgeRequirement(parentCount: number, ...items: OrderArticle[]): number {
  return items.reduce((count, currentProduct) => {
    if (currentProduct?.article?.requireAge ?? 0) {
      count += currentProduct.count * parentCount;
    }

    currentProduct.orderOptionGroups.forEach((orderOptionGroup) => {
      count += countProductsWithAgeRequirement(currentProduct.count, ...orderOptionGroup.orderArticles);
    });
    return count;
  }, 0);
}

/**
 * Postponed happens when the dialog is closed without inputting anything
 */
export enum AgeCheckResult {
  Accepted,
  Denied,
  Postponed,
}
