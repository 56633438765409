import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import RightRip from "../../../../assets/images/rip/right_rip_stroked.svg?react";
import LeftRip from "../../../../assets/images/rip/left_rip_stroked.svg?react";

export default function PaperRip({ isUsed = false, isExpired = false }: { isUsed?: boolean; isExpired?: boolean }) {
  return (
    <>
      <Box sx={{ position: "absolute", width: 1, height: 1, background: "#00000050" }}></Box>
      <LeftRip
        style={{
          position: "absolute",
          left: "50%",
          transform: "translate(-10px,-1px)",

          height: "calc(100% + 2px)",
        }}
      />
      <RightRip
        style={{
          position: "absolute",
          left: "50%",
          transform: "translate(-60px,-1px)",

          height: "calc(100% + 2px)",
        }}
      />
      <Typography
        sx={{
          transform: "rotate(10deg) translate(-50%,-50%)",
          fontWeight: 800,
          fontSize: "74px",
          position: "absolute",
          left: "50%",
          top: isExpired ? "70%" : "50%",
          color: "red",
          border: "red 1px solid",
        }}
      >
        {isUsed ? "USED" : null}
        {isExpired ? "EXPIRED" : null}
      </Typography>
    </>
  );
}
