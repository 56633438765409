import { useCallback } from "react";
import { httpPost } from "./api/http";
import { apiRoutes, createReturnPath } from "./config";
import OrderArticle, { initOrderArticle } from "./models/order/OrderArticle";
import { selectAppLanguage } from "../../kiosk/components/LanguageSelector/useLanguage";
import store from "./redux/store";
import { selectArticlesMap } from "./redux/selectors/selectArticlesMap";

export default function useSendArticles(
  { ignoreSharedShoppingCart = true }: { ignoreSharedShoppingCart: boolean } = { ignoreSharedShoppingCart: true }
) {
  return useCallback(
    (articleIds: string[]) => {
      const articlesMap = selectArticlesMap(store.getState());
      const orderArticles: OrderArticle[] = [];
      articleIds.forEach((articleId) => {
        const article = articlesMap[articleId];
        if (article) {
          orderArticles.push(initOrderArticle(articlesMap, article, 1));
        }
      });

      const selectedLanguage = selectAppLanguage(store.getState());

      httpPost(apiRoutes.kiosk.checkout, {
        items: orderArticles,
        voucherOrderItems: [],
        orderCustomFields: [],
        returnUrl: createReturnPath(),
        discountCardNr: null,
        selectedLanguage: selectedLanguage,
        ignoreSharedShoppingCart,
      });
    },
    [ignoreSharedShoppingCart]
  );
}
