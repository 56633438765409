import { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import useSalesareaIsOpen from "../../../../global/utils/useSalesareaIsOpen.ts";
import JTooltip from "../../../../kiosk/components/JamezzMUI/JTooltip.tsx";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Card } from "@mui/material";

export default function WaitingTimeView() {
  const waitTime = useAppSelector((state) => state.global.salesarea.waitTime);
  const isOpen = useSalesareaIsOpen();
  const intl = useIntl();
  const boxRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<number>();

  useEffect(() => {
    if (boxRef.current) {
      setSize(Math.max(boxRef.current.getBoundingClientRect().height, boxRef.current.getBoundingClientRect().width));
    }
  }, [waitTime]);

  if (waitTime && isOpen) {
    return (
      <JTooltip title={intl.formatMessage({ id: "Average waiting time" })}>
        <Card
          ref={boxRef}
          component={"div"}
          className={"JS-WaitingTimeView-Root"}
          sx={{
            backgroundColor: "#ffffffd0",
            borderRadius: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 1,
            width: size,
            height: size,
          }}
        >
          <span className={"JS-WaitingTimeView-time"} style={{ lineHeight: 1 }}>
            {waitTime}
          </span>
          <span className={"JS-WaitingTimeView-min"} style={{ lineHeight: 1 }}>
            min
          </span>
        </Card>
      </JTooltip>
    );
  } else {
    return null;
  }
}
