import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import {
  ActiveArrangement,
  ActivePreDiningArrangement,
} from "../../../global/utils/redux/arrangements/arrangementsSlice.tsx";
import { useDialog } from "../../../global/utils/dialog/DialogProvider.tsx";
import { useEffect, useRef } from "react";
import { selectActiveArrangement } from "../../../global/utils/redux/arrangements/selectActiveArrangement.tsx";

/**
 * Cleans up all unwanted arrangement-related state whenever the application state goes from ARRANGEMENT_LOADED to ARRANGEMENT_UNLOADED
 */
export default function AyceCleanupService() {
  const activeArrangement = useAppSelector(selectActiveArrangement);
  const { closeAllDialogs } = useDialog();
  const previousArrangementState = useRef<ActiveArrangement | ActivePreDiningArrangement>();

  useEffect(() => {
    if (previousArrangementState != null && activeArrangement == null) {
      closeAllDialogs();
    }
    previousArrangementState.current = activeArrangement;
  }, [activeArrangement, closeAllDialogs]);

  return <></>;
}
