import { MouseEventHandler } from "react";
import { ButtonBase, SxProps, Theme } from "@mui/material";
import FormattedMessageJamezz, { JamezzTranslation } from "../../global/components/FormattedMessageJamezz";

export default function NextButton(props: {
  sx?: SxProps<Theme>;
  disabled?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  translationId?: JamezzTranslation;
  isLastOptionGroup: boolean;
}) {
  const sx = props.sx ?? {};
  return (
    <ButtonBase
      className={"JS-NextButton"}
      data-cy={props.isLastOptionGroup ? "page-order-content-btn-add-to-order" : null}
      sx={{
        minWidth: 375,
        padding: 3,
        borderRadius: 16,
        fontSize: 42,
        color: "primary.contrastText",
        backgroundColor: "primary.light",
        textTransform: "uppercase",
        fontWeight: 800,
        boxShadow: "0 1px 10px 0px rgba(0,0,0,0.2)",
        ...sx,
      }}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      <FormattedMessageJamezz id={props.translationId ?? "Next"} />
    </ButtonBase>
  );
}
