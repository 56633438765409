import { Box, Collapse, Typography } from "@mui/material";

import { useAppSelector } from "../../../../global/utils/redux/store";
import useCurrency, { CurrencyLocation } from "../../../../global/utils/useCurrency";
import { useCustomTexts } from "../../../../global/utils/useCustomTexts.tsx";
import { selectPiggyGiftcardAmount } from "../../../../global/utils/redux/selectors/selectPiggyGiftcardAmount.tsx";

export default function PiggyGiftcardCheckoutAmounts() {
  const piggyGiftcardAmount = useAppSelector(selectPiggyGiftcardAmount);
  const toCurrency = useCurrency({ location: CurrencyLocation.Checkout });
  const ct = useCustomTexts(["loyalty gift card - title"]);
  return (
    <Collapse in={piggyGiftcardAmount > 0}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ fontStyle: "italic", opacity: 0.6 }}>
          {ct["loyalty gift card - title"] ?? "Piggy Giftcards"}
        </Typography>

        <Typography align={"right"} sx={{ fontStyle: "italic", opacity: 0.6, whiteSpace: "nowrap" }}>
          -{toCurrency(Number(piggyGiftcardAmount))}
        </Typography>
      </Box>
    </Collapse>
  );
}
