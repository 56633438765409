import Box from "@mui/material/Box";
import { Stack, SwipeableDrawer } from "@mui/material";

import { useFetchLoyaltyProgramQuery } from "../../../global/utils/redux/api/piggyApi";
import store, { useAppSelector } from "../../../global/utils/redux/store.tsx";
import {
  piggyContactIdentifierDialogClosed,
  piggyContactIdentifierDialogOpened,
} from "../../../global/utils/redux/piggySlice.tsx";
import { LoyaltyCard } from "./Loyalty/LoyaltyCard/LoyaltyCard.tsx";
import PiggyRewards from "./Loyalty/PiggyRewards.tsx";
import PiggyVouchers from "./Vouchers/PiggyVouchers.tsx";
import { useEffect } from "react";
import { useDialog } from "../../../global/utils/dialog/DialogProvider.tsx";

export default function PiggyDialog() {
  const isLoggedIn = useAppSelector((state) => state.piggy.contactIdentifierLoggedIn);
  const isOpen = !useAppSelector((state) => state.piggy.piggyContactIdentifierDialogMinimized);
  const { data: loyaltyProgram, isSuccess: loyaltySuccess } = useFetchLoyaltyProgramQuery(undefined, { skip: !isOpen });
  const { getDialogsCount } = useDialog();

  useEffect(() => {
    if (isLoggedIn && getDialogsCount() > 0) {
      store.dispatch(piggyContactIdentifierDialogOpened());
    }
  }, [getDialogsCount, isLoggedIn]);

  return (
    <SwipeableDrawer
      ModalProps={{
        keepMounted: false,
      }}
      anchor={"bottom"}
      open={isOpen}
      sx={{ zIndex: 1300, "> .MuiPaper-root": { borderTopLeftRadius: 48, borderTopRightRadius: 48 } }}
      onClose={() => store.dispatch(piggyContactIdentifierDialogClosed())}
      onOpen={() => store.dispatch(piggyContactIdentifierDialogOpened())}
    >
      {isOpen && (
        <Box
          className="JS-Piggy-RewardsPage"
          data-cy="piggy-dialog-rewards"
          sx={{
            width: 1,
            maxHeight: 1400,
            py: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              alignSelf: "center",
              width: 1,
              mt: 2,
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/*<Typography variant="h5" sx={{ fontWeight: 800, mb: 12, textTransform: "uppercase" }}>*/}
            {/*  {loyaltySuccess && loyaltyProgram ? loyaltyProgram.data.custom_credit_name : <Skeleton />}*/}
            {/*</Typography>*/}
            {/*<PiggyPage />*/}
            <Stack sx={{ width: 1, my: 4 }} alignItems={"center"} direction={"column"} gap={6}>
              <Box sx={{ width: 600 }}>
                <LoyaltyCard />
              </Box>
              <Box sx={{ maxWidth: 1 }}>
                <PiggyRewards showBalance={false} />
              </Box>
              <Box sx={{ maxWidth: 1 }}>
                <PiggyVouchers />
              </Box>
            </Stack>
          </Box>
        </Box>
      )}
    </SwipeableDrawer>
  );
}
