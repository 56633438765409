import { memo, useMemo, useState } from "react";
import OptionItemOptional from "../OptionItems/OptionItemOptional";
import OrderOptionGroup from "../../../../global/utils/models/order/OrderOptionGroup";
import { Button, Collapse, Paper } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz";
import useMenuFilters from "../../../../global/utils/menu/filters/useMenuFilters.ts";

interface Props {
  orderOptionGroup: OrderOptionGroup;
  onChange: (orderOptionGroup: OrderOptionGroup) => void;
}

function _OptionGroupOptional(props: Props) {
  const showMaxNumberOfItems = useMemo(() => {
    if (props.orderOptionGroup.optionGroup.showMaxNumberOfItems == null) {
      return props.orderOptionGroup.orderArticles.length;
    }
    return props.orderOptionGroup.optionGroup.showMaxNumberOfItems;
  }, [props.orderOptionGroup]);

  const [expand, setExpand] = useState(showMaxNumberOfItems === 0);

  const articleFilter = useMenuFilters({ useArrangementsFilter: false, useArticlegroupsFilter: false });
  return (
    <>
      {props.orderOptionGroup.orderArticles
        .filter((orderArticle) => articleFilter(orderArticle.article))
        .slice(0, showMaxNumberOfItems)
        .map((orderArticle, index) => (
          <OptionItemOptional
            isLastOptionInGroup={!expand && showMaxNumberOfItems === index + 1}
            isFirstOptionInGroup={index === 0}
            orderArticle={orderArticle}
            orderOptionGroup={props.orderOptionGroup}
            key={orderArticle.article.id}
            onChange={(orderArticle) => {
              const copy = JSON.parse(JSON.stringify(props.orderOptionGroup)) as OrderOptionGroup;
              copy.orderArticles[index] = orderArticle;
              props.onChange(copy);
            }}
          />
        ))}

      <Collapse in={expand} unmountOnExit sx={{ width: 1 }}>
        {props.orderOptionGroup.orderArticles
          .filter((orderArticle) => articleFilter(orderArticle.article))
          .slice(showMaxNumberOfItems, props.orderOptionGroup.orderArticles.length)
          .map((orderArticle, index) => (
            <OptionItemOptional
              isLastOptionInGroup={props.orderOptionGroup.orderArticles.length - showMaxNumberOfItems === index + 1}
              isFirstOptionInGroup={showMaxNumberOfItems === 0 && index === 0}
              orderArticle={orderArticle}
              orderOptionGroup={props.orderOptionGroup}
              key={orderArticle.article.id}
              onChange={(orderArticle) => {
                const copy = JSON.parse(JSON.stringify(props.orderOptionGroup)) as OrderOptionGroup;
                copy.orderArticles[index + showMaxNumberOfItems] = orderArticle;
                props.onChange(copy);
              }}
            />
          ))}
      </Collapse>

      {showMaxNumberOfItems < props.orderOptionGroup.orderArticles.length ? (
        <Collapse
          in={!expand}
          unmountOnExit
          sx={{ "& .MuiCollapse-wrapperInner": { width: 1, display: "flex", justifyContent: "center" } }}
        >
          <Button
            component={Paper}
            startIcon={<Refresh />}
            sx={{
              marginX: 1,
              marginY: 1,
              padding: 1,
              paddingX: 3,
              whiteSpace: "nowrap",
              backgroundColor: "background.paper",
              borderRadius: 3,
            }}
            color={"inherit"}
            onClick={() => {
              setExpand(true);
            }}
          >
            <FormattedMessageJamezz id={"Load more..."} />
          </Button>
        </Collapse>
      ) : null}
    </>
  );
}

export default memo(_OptionGroupOptional);
