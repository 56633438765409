import { Box, Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { useIntl } from "react-intl";
import { secondsPerMinute, useOrderTimer } from "../../utils/arrangements/useOrderTimer.ts";
import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { selectCurrentOrderRound } from "../../../global/utils/redux/arrangements/arrangementsSlice.tsx";
import { selectActiveArrangement } from "../../../global/utils/redux/arrangements/selectActiveArrangement.tsx";

export function AyceOrderTimer() {
  const arrangement = useAppSelector(selectActiveArrangement);
  const enabled = arrangement?.variant === "regular" && arrangement.minutes_between_every_round > 0;

  const currentOrderRound = useAppSelector(selectCurrentOrderRound);
  const timeUntilNextOrder = useOrderTimer();
  const intl = useIntl();
  const timeUnit =
    timeUntilNextOrder > 60 ? intl.formatMessage({ id: "minute(s)" }) : intl.formatMessage({ id: "second(s)" });
  const time = timeUntilNextOrder > 60 ? Math.ceil(timeUntilNextOrder / secondsPerMinute) : timeUntilNextOrder;

  if (!enabled || currentOrderRound === 1) {
    return null;
  }

  let content;

  if (timeUntilNextOrder > 0) {
    content = (
      <Typography data-cy="ayce-checkoutpage-ordertimer">
        <FormattedMessageJamezz id="AYCE.order-timer.next-round-starts-in" values={{ time: `${time} ${timeUnit}` }} />
      </Typography>
    );
  } else {
    content = (
      <Box data-cy="ayce-checkoutpage-ordertimer">
        <FormattedMessageJamezz id="AYCE.order-timer.checkout-page.ready" />
      </Box>
    );
  }

  return (
    <Box sx={{ textAlign: "center", alignSelf: "center" }}>
      <Box sx={{ mt: 2 }} />
      {content}
    </Box>
  );
}
