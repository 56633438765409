import store, { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import { useEffect } from "react";
import { useDialog } from "../../../../global/utils/dialog/DialogProvider.tsx";
import { selectArticlesMap } from "../../../../global/utils/redux/selectors/selectArticlesMap.ts";
import { getArticlesById } from "../../../../global/utils/models/menu/Menu.ts";
import AddDiscountedProductDialog from "./AddDiscountedProductDialog.tsx";
import { initOrderArticle, OrderArticleOrigin } from "../../../../global/utils/models/order/OrderArticle.ts";
import { voucherOrderArticleConfirmed } from "../../../../global/utils/vouchersV2/vouchersV2Slice.tsx";
import OrderArticleSelectorPage from "../../../pages/OrderArticleSelectorPage/OrderArticleSelectorPage.tsx";
import SlideUpTransition from "../../../../global/components/SlideUpTransition.tsx";

export default function VouchersAddDiscountedProducts() {
  const discountedProducts = useAppSelector((state) => state.vouchersV2.addDiscountedProductVouchers);
  const { openDialog } = useDialog();
  useEffect(() => {
    if (discountedProducts.length > 0) {
      const voucherV2 = discountedProducts[0];
      if (voucherV2.voucherdef.discountType == "ADD_DISCOUNTED_PRODUCT") {
        const articleIds = voucherV2.voucherdef.freeProducts_JSON;
        const articlesMap = selectArticlesMap(store.getState());
        const articles = getArticlesById(articlesMap, articleIds);
        console.log(articles);
        if (articles.length > 1) {
          openDialog({
            children: <AddDiscountedProductDialog articles={articles} voucher={voucherV2} />,
            fullScreen: "almostFullScreen",
            disabledBackdropClick: true,
          });
        } else if (articles[0]?.optionGroupIds.length > 0) {
          openDialog({
            children: (
              <OrderArticleSelectorPage
                article={articles[0]}
                isUpselled={true}
                fixedCount={1}
                onOrderArticleOk={(orderArticle) => {
                  orderArticle.added_origin = OrderArticleOrigin.SYSTEM;
                  store.dispatch(voucherOrderArticleConfirmed(orderArticle));
                }}
              />
            ),
            fullScreen: "almostFullScreen",
            TransitionComponent: SlideUpTransition,
          });
        } else if (articles.length === 1 && articles[0]?.optionGroupIds.length === 0) {
          const orderArticle = initOrderArticle(
            articlesMap,
            articles[0],
            1,
            "",
            false,
            false,
            false,
            OrderArticleOrigin.SYSTEM,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined
          );
          orderArticle.added_origin = OrderArticleOrigin.SYSTEM;
          store.dispatch(voucherOrderArticleConfirmed(orderArticle));
        }
      }
    }
  }, [discountedProducts, openDialog]);
  return null;
}
