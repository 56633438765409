import { Language } from "../../../types/shared/language.ts";
import { ButtonBase, Typography } from "@mui/material";
import { languageTranslations } from "../../../global/utils/languages.ts";
import { languageToCountry } from "./LanguageSelector.tsx";
import OrderMode from "../../utils/models/OrderMode.ts";
import clsx from "clsx";
import { setLanguage } from "./useLanguage.ts";

export default function TextLanguageButton({
  language,
  orderModes,
  onClickOrderMode,
  selectedLanguage,
}: {
  language: Language;
  orderModes: OrderMode[];
  onClickOrderMode: (orderMode?: OrderMode | undefined) => void;
  selectedLanguage: Language;
}) {
  const obj = languageToCountry(language);
  return (
    <ButtonBase
      data-language-code={language}
      data-country-code={obj.code}
      className={clsx(
        "JS-LanguageSelector-ButtonBase",
        selectedLanguage === language && "JS-LanguageSelector-Selected"
      )}
      key={language}
      onClick={(e) => {
        e.stopPropagation();
        setLanguage(language as Language);
        if (!orderModes || orderModes.length <= 1) {
          onClickOrderMode(orderModes?.[0]);
        }
      }}
      sx={{
        width: "25%",
        backgroundColor: selectedLanguage === language ? "#FAC708" : "white",
        borderRadius: "35px",
        border: "1px solid black",
      }}
    >
      <>
        <Typography
          typography={"span"}
          className={clsx(
            "JS-LanguageSelector-Text",
            selectedLanguage === language && "JS-LanguageSelector-Text-Selected"
          )}
          sx={{
            width: 1,
            paddingY: 1,
            fontSize: "3em",
            color: "black",

            fontWeight: selectedLanguage === language ? "bold" : null,
            textDecoration: selectedLanguage === language ? "underline" : null,
          }}
        >
          {languageTranslations[language] ?? language}
        </Typography>
      </>
    </ButtonBase>
  );
}
