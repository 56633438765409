import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { selectCurrentOrderRound } from "../../../global/utils/redux/arrangements/arrangementsSlice.tsx";
import { useAyceTicketTimerUntilInvalid } from "../../../global/components/ArrangementLimitations/useAyceTicketTimer.tsx";
import { selectActiveArrangement } from "../../../global/utils/redux/arrangements/selectActiveArrangement.tsx";

export enum AyceRuleResult {
  EATING_TIME_OVER = "eating time over",
  ROUND_COUNTER_EXCEEDED = "round counter exceededd",

  /* these only matter for server-side checks */
  // CREDIT_LIMIT_EXCEEDED,
  // ROUND_TIMER_STILL_RUNNING,

  NO_RULE_FAILURE = "no rule failure",
  RULES_NOT_AVAILABLE = "rules not available",
  PRE_DINING = "pre dining",
}

export function useCheckAyceRules(): AyceRuleResult {
  const round = useAppSelector(selectCurrentOrderRound);

  const timeLeft = useAyceTicketTimerUntilInvalid();
  const activeArrangement = useAppSelector(selectActiveArrangement);

  if (!activeArrangement) {
    return AyceRuleResult.RULES_NOT_AVAILABLE;
  }

  if (activeArrangement.variant === "pre-dining") {
    return AyceRuleResult.PRE_DINING;
  }

  const maxRound = activeArrangement?.max_rounds ?? null;

  if (maxRound !== null && round > maxRound && maxRound !== 0) {
    return AyceRuleResult.ROUND_COUNTER_EXCEEDED;
  }

  if (activeArrangement.visit_length === "duration" && activeArrangement.max_time_in_minutes && !timeLeft) {
    return AyceRuleResult.EATING_TIME_OVER;
  }
  if (activeArrangement.visit_length === "schedule") {
    // TODO arrangements implement schedule rule
  }

  return AyceRuleResult.NO_RULE_FAILURE;
}
