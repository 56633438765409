import { ReactNode } from "react";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import FormattedMessageJamezz from "../../global/components/FormattedMessageJamezz";
import { useDialog } from "../../global/utils/dialog/DialogProvider";
import { postAnalyticsEvent } from "../../global/utils/analytics/useAnalytics.ts";

interface Props {
  message: ReactNode;
  subtitle?: ReactNode;
  onOk: () => void;
}

function AreYouSureDialog(props: Props) {
  const { closeDialog } = useDialog();
  return (
    <Box sx={{ padding: 2 }}>
      <Typography sx={{ textAlign: "center" }}>{props.message}</Typography>
      {props.subtitle}
      <Box sx={{ display: "flex", justifyContent: "space-around", marginTop: 8, marginBottom: 2 }}>
        <Button
          sx={{ width: 0.4, color: "white" }}
          color={"primary"}
          variant={"contained"}
          onClick={() => {
            postAnalyticsEvent({
              category: "AreYouSureDialog (" + props.subtitle + ")",
              action: "Cancel",
            });
            closeDialog();
          }}
        >
          <FormattedMessageJamezz id={"Cancel"} />
        </Button>

        <Button
          sx={{ width: 0.4 }}
          color={"error"}
          variant={"outlined"}
          onClick={() => {
            postAnalyticsEvent({
              category: "AreYouSureDialog (" + props.subtitle + ")",
              action: "Ok",
            });
            closeDialog();
            props.onOk();
          }}
        >
          <FormattedMessageJamezz id={"Ok"} />
        </Button>
      </Box>
    </Box>
  );
}

export default AreYouSureDialog;
