import { useAppSelector } from "../../redux/store";
import { selectMenuTagFilters } from "../../redux/selectors/selectMenuTagFilters";
import { useIntl } from "react-intl";

export default function useMenuTagFilters() {
  const intl = useIntl();
  const menuTags = useAppSelector((state) => selectMenuTagFilters(state, intl));

  return Object.values(menuTags);
}
