import { ReactNode, useEffect, useMemo, useState } from "react";
import useSalesareaIsOpen from "../../global/utils/useSalesareaIsOpen";
import { Alert, Dialog, DialogContent, DialogTitle, Snackbar } from "@mui/material";
import { FormattedMessage } from "react-intl";

import CloseDialogButton from "./Dialog/CloseDialogButton.tsx";
import FormattedMessageJamezz from "../../global/components/FormattedMessageJamezz";
import { useAppSelector } from "../../global/utils/redux/store";
import { postAnalyticsEvent } from "../../global/utils/analytics/useAnalytics";

export default function SalesareaStatus() {
  const isOpen = useSalesareaIsOpen();
  const systemOfflineReason = useAppSelector((state) => state.global.salesarea?.systemOfflineReason);
  const generatedSystemOfflineReason_translationId = useAppSelector(
    (state) => state.global.sessionState?.generatedSystemOfflineReason
  );
  const [openPopup, setOpenPopup] = useState(false);
  const popupText = useMemo(() => {
    const popupText: { title: string; body: string; bodyComponent: ReactNode | null } = {
      title: "",
      body: "",
      bodyComponent: null,
    };

    popupText.title = "";

    if (systemOfflineReason) {
      popupText.body = systemOfflineReason;
      popupText.bodyComponent = <>{popupText.bodyComponent}</>;
    } else if (generatedSystemOfflineReason_translationId) {
      popupText.bodyComponent = (
        <FormattedMessageJamezz
          id={"Unfortunately, ordering is currently not possible. {generatedSystemOfflineReason_translationId}"}
          values={{
            generatedSystemOfflineReason_translationId: (
              <FormattedMessageJamezz id={generatedSystemOfflineReason_translationId} />
            ),
          }}
        />
      );
    } else {
      popupText.bodyComponent = <FormattedMessageJamezz id={"Unfortunately, ordering is not possible right now."} />;
    }

    return popupText;
  }, [systemOfflineReason, generatedSystemOfflineReason_translationId]);

  useEffect(() => {
    if (isOpen) {
      setOpenPopup(false);
    } else {
      setOpenPopup(true);
    }
  }, [isOpen]);

  return (
    <>
      <Snackbar open={!isOpen} autoHideDuration={null}>
        <Alert severity={"error"} sx={{ width: "100%" }}>
          <FormattedMessage id={"Ordering is disabled!"} />
        </Alert>
      </Snackbar>
      <Dialog
        open={openPopup}
        keepMounted={false}
        onClose={() => {
          postAnalyticsEvent({
            category: "SalesareaStatus",
            action: "closeDialog",
          });
          setOpenPopup(false);
        }}
      >
        <CloseDialogButton
          onClose={() => {
            postAnalyticsEvent({
              category: "SalesareaStatus",
              action: "closeDialog",
            });
            setOpenPopup(false);
          }}
        />
        <DialogTitle sx={{ marginRight: 5 }}>{popupText.title}</DialogTitle>
        <DialogContent>
          <div
            style={{ marginTop: "25px" }}
            dangerouslySetInnerHTML={{
              __html: popupText.body,
            }}
          ></div>
          {popupText.bodyComponent}
        </DialogContent>
      </Dialog>
    </>
  );
}
