import { useEffect } from "react";
import { useAppSelector } from "../../../global/utils/redux/store";
import { Alert, Dialog, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import usePrinterCheck from "../../utils/usePrinterCheck";

export default function PrinterError() {
  const printerErrorState = useAppSelector((state) => state.global.printerErrorState);
  const { checkPrinter } = usePrinterCheck();

  useEffect(() => {
    let waitForRequest = false;
    let interval: ReturnType<typeof setInterval> | null = null;
    if (printerErrorState) {
      interval = setInterval(() => {
        if (!waitForRequest) {
          waitForRequest = true;
          checkPrinter().then(() => {
            waitForRequest = false;
          });
        }
      }, 10000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [checkPrinter, printerErrorState]);

  if (printerErrorState === 0) {
    return null;
  }

  return (
    <Dialog
      open={printerErrorState !== 0}
      sx={{
        width: 1,
        marginTop: 8,
        zIndex: 3000,
      }}
    >
      <Alert
        severity={"error"}
        sx={{
          "& .MuiAlert-icon": { fontSize: 80 },
        }}
      >
        <Typography sx={{ fontSize: "2em" }}>
          <FormattedMessageJamezz id={"Printer.messages.error"} />
        </Typography>
        <Typography sx={{ fontSize: "1.5em" }}>
          <FormattedMessageJamezz id={"Printer.messages.ask-for-help"} />
        </Typography>
      </Alert>
      <Box
        sx={{
          display: "flex",
          width: 1,
          justifyContent: "center",
          paddingY: 8,
          backgroundColor: "rgb(253, 237, 237)",
        }}
      ></Box>
    </Dialog>
  );
}
