import { createSelector } from "@reduxjs/toolkit";
import Article from "../../models/menu/Article";
import _ from "lodash";
import { selectArticlesMap } from "./selectArticlesMap.ts";

export const selectArticleHashNamesMap = createSelector([selectArticlesMap], (articlesMap) => {
  const articleHashNamesMap: Record<string, Article> = {};

  _.values(articlesMap).forEach((article) => {
    if (article.hashedName != "") articleHashNamesMap[article.hashedName] = article;
  });

  return articleHashNamesMap;
});
