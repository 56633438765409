import { useEffect, useMemo, useState } from "react";

import LetterJ from "../../../assets/images/LetterJ.svg?react";
import Circle from "../../../assets/images/Circle.svg?react";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import "./JamezzLoading.css";
import clsx from "clsx";

interface Props {
  isLoading: boolean;
  isSuccessful: boolean;
  color: string;
  padding?: number;
}

export default function JamezzLoading(props: Props) {
  const [isLoading, setIsLoading] = useState(props.isLoading);

  useEffect(() => {
    setIsLoading(props.isLoading);
  }, [props.isLoading]);

  const theme = useTheme();

  const color = useMemo(() => {
    const values = props.color.split(".");

    function recursive(obj: any, values: Array<any>): any {
      const child = obj[values.splice(0, 1) as any];
      if (child) {
        if (values.length > 0) {
          return recursive(child, values);
        } else {
          return child;
        }
      } else {
        return null;
      }
    }

    const color = recursive(theme.palette, values);

    return color ?? props.color;
  }, [props.color, theme?.palette]);

  const logo = useMemo(() => {
    return (
      <LetterJ
        fill={color}
        style={{ position: "absolute", top: 0, left: 0, padding: props.padding ?? 70, width: "100%", height: "100%" }}
      />
    );
  }, [color, props.padding]);

  return (
    <Box sx={{ width: 1, height: 1, position: "relative" }}>
      {isLoading && (
        <Circle
          className={clsx("JamezzLoading", "JamezzLoadingPending")}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      )}
      {!isLoading && props.isSuccessful && (
        <Circle
          className={clsx("JamezzLoading", "JamezzLoadingSuccess")}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      )}
      {!isLoading && !props.isSuccessful && (
        <Circle
          className={clsx("JamezzLoading", "JamezzLoadingFailed")}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      )}

      {logo}
    </Box>
  );
}
