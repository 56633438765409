import { useDialog } from "../../global/utils/dialog/DialogProvider.tsx";
import { useAppSelector } from "../../global/utils/redux/store.tsx";
import BoardingPassPage from "../pages/BoardingPassPage/BoardingPassPage.tsx";
import SlideUpTransition from "../../global/components/SlideUpTransition.tsx";

enum BoardingPassScanResult {
  SCANNED,
  POSTPONED,
  NOT_SHOWN,
}

export function useScanBoardingPass() {
  const { openDialog } = useDialog();
  const hasBoardingPassScanner = useAppSelector(
    (state) => state.global.salesarea?.schiphol_boarding_pass_scanner?.enabled
  );

  return () => {
    if (!hasBoardingPassScanner) {
      return new Promise((resolve) => resolve(BoardingPassScanResult.NOT_SHOWN));
    }

    return new Promise((resolve) => {
      openDialog({
        children: (
          <BoardingPassPage
            onClose={() => {
              resolve(BoardingPassScanResult.POSTPONED);
            }}
            onFinish={() => {
              resolve(BoardingPassScanResult.SCANNED);
            }}
          />
        ),
        fullScreen: "almostFullScreen",
        TransitionComponent: SlideUpTransition,
      });
    });
  };
}
