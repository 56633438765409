import { MWiseReward } from "../../../types/mwise";

export const fakeApiData: MWiseReward[] = [
  {
    PositionId: 1,
    VisualStatus: 3,
    Label: "EXTRA 1",
    VisitExtraNo: 20001,
    ValidFrom: "2023-02-13",
    ValidTo: "2023-03-15",
    Title: "Heerlijk een kopje koffie of thee gratis",
    Description:
      "Deze Extra geeft je eenmalig recht op één gratis koffie, espresso, normale cappuccino, biologische thee, frisdrank van de tap of huisgemaakte limonade medium \nLaat bij de kassa jouw kaart scannen en het wordt verwerkt. ",
    ImageUrl:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20190327/134459-LPE-app-T01-gratis-drankje.png",
    BigImageUrl:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20190327/134459-LPE-app-T01-gratis-drankje.png",
    BackgroundImageURL:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20171110/LaPlace_Image_extraatjes_1370x1036.png",
    Conditions:
      "Niet geldig in combinatie met andere acties en/of promoties. \nDeze Extra is niet geldig bij La Place op Schiphol, Eindhoven Airport, ZiggoDome, alle treinstations. ",
    ShortTitle: "T20001",
    IsNewExtras: false,
    IsBirthdayExtras: false,
    IsStampExtras: false,
  },
  {
    PositionId: 2,
    VisualStatus: 3,
    Label: "EXTRA 2",
    VisitExtraNo: 20002,
    ValidFrom: "2023-02-22",
    ValidTo: "2023-03-24",
    Title: "Hoera er wacht 20% korting op jou! ",
    Description:
      "Deze Extra geeft je eenmalig recht op 20% korting op het bonbedrag. Laat bij de kassa jouw La Place Extra kaart of de kaart via de app scannen en de Extra wordt verwerkt. ",
    ImageUrl:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20180117/Extraatje_IceTea_700x700.png",
    BigImageUrl:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20180117/Extraatje_IceTea_700x700.png",
    BackgroundImageURL:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20171110/LaPlace_Image_extraatjes_1370x1036.png",
    Conditions:
      "Niet geldig in combinatie met andere acties en/of promoties.   De Extra's zijn geldig in alle La Place locaties in Nederland met uitzondering van Schiphol, Duinrell, ZiggoDome, Eindhoven Airport en alle treinstations en evenementen.",
    ShortTitle: "T20002",
    IsNewExtras: false,
    IsBirthdayExtras: false,
    IsStampExtras: false,
  },
  {
    PositionId: 3,
    VisualStatus: 3,
    Label: "EXTRA 3",
    VisitExtraNo: 20003,
    ValidFrom: "2023-02-22",
    ValidTo: "2023-03-24",
    Title: "Speciaal voor jou: lekkere chocolade",
    Description:
      "Deze Extra geeft je eenmalig recht op één verwenmomentje. Laat bij de kassa jouw La Place Extra kaart of de kaart via de app scannen en de Extra wordt verwerkt. ",
    ImageUrl:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20160906/6060.00.095_LPE3.0_NB_level1_T3.png",
    BigImageUrl:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20160906/6060.00.095_LPE3.0_NB_level1_T3.png",
    BackgroundImageURL:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20171110/LaPlace_Image_extraatjes_1370x1036.png",
    Conditions:
      "Niet geldig in combinatie met andere acties en/of promoties.   De Extra's zijn geldig in alle La Place locaties in Nederland met uitzondering van Schiphol, Duinrell, ZiggoDome, Eindhoven Airport en alle treinstations en evenementen.",
    ShortTitle: "T20003",
    IsNewExtras: false,
    IsBirthdayExtras: false,
    IsStampExtras: false,
  },
  {
    PositionId: 4,
    VisualStatus: 2,
    Label: "EXTRA 4",
    VisitExtraNo: 20004,
    ValidFrom: null,
    ValidTo: null,
    Title: "Bakkie doen? ",
    Description:
      "Deze Extra geeft je eenmalig recht op een 2e gratis warme drank (m.u.v. de alcoholische koffie's), frisdrank van de tap of huisgemaakte limonade. Laat bij de kassa jouw La Place Extra kaart of de kaart via de app scannen en de Extra wordt verwerkt. ",
    ImageUrl:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20160906/6060.00.095_LPE3.0_NB_level1_T4.png",
    BigImageUrl:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20160906/6060.00.095_LPE3.0_NB_level1_T4.png",
    BackgroundImageURL:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20171110/LaPlace_Image_extraatjes_1370x1036.png",
    Conditions:
      "Niet geldig in combinatie met andere acties en/of promoties.   De Extra's zijn geldig in alle La Place locaties in Nederland met uitzondering van Schiphol, Duinrell, ZiggoDome, Eindhoven Airport en alle treinstations en evenementen.",
    ShortTitle: "T20004",
    IsNewExtras: false,
    IsBirthdayExtras: false,
    IsStampExtras: false,
  },
  {
    PositionId: 5,
    VisualStatus: 2,
    Label: "EXTRA 5",
    VisitExtraNo: 21001,
    ValidFrom: null,
    ValidTo: null,
    Title: "Een gratis sandwich!",
    Description:
      "Deze Extra geeft je eenmalig recht op één gratis sandwich naar keuze. Laat bij de kassa jouw kaart scannen en het wordt verwerkt.",
    ImageUrl:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20190327/134459-LPE-app-T05-gratis-sandwich.png",
    BigImageUrl:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20190327/134459-LPE-app-T05-gratis-sandwich.png",
    BackgroundImageURL:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20171110/LaPlace_Image_extraatjes_1370x1036.png",
    Conditions:
      "Niet geldig in combinatie met andere acties en/of promoties. \nDeze Extra is niet geldig bij La Place op Schiphol, Eindhoven Airport, ZiggoDome, alle treinstations. ",
    ShortTitle: "T21001",
    IsNewExtras: false,
    IsBirthdayExtras: false,
    IsStampExtras: false,
  },
  {
    PositionId: 6,
    VisualStatus: 2,
    Label: "EXTRA 6",
    VisitExtraNo: 20006,
    ValidFrom: null,
    ValidTo: null,
    Title: "Lekker een huisgemaakte Extra voor jou. ",
    Description:
      "Deze Extra geeft je eenmalig recht op één soep medium voor 2 euro. Laat bij de kassa jouw La Place Extra kaart of de kaart via de app scannen en de Extra wordt verwerkt. ",
    ImageUrl:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20160906/6060.00.095_LPE3.0_NB_level2_T6.png",
    BigImageUrl:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20160906/6060.00.095_LPE3.0_NB_level2_T6.png",
    BackgroundImageURL:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20171110/LaPlace_Image_extraatjes_1370x1036.png",
    Conditions:
      "Niet geldig in combinatie met andere acties en/of promoties.   De Extra's zijn geldig in alle La Place locaties in Nederland met uitzondering van Schiphol, Duinrell, ZiggoDome, Eindhoven Airport en alle treinstations en evenementen.",
    ShortTitle: "T20006",
    IsNewExtras: false,
    IsBirthdayExtras: false,
    IsStampExtras: false,
  },
  {
    PositionId: 7,
    VisualStatus: 2,
    Label: "EXTRA 7",
    VisitExtraNo: 20007,
    ValidFrom: null,
    ValidTo: null,
    Title: "Hoera er wacht 20% korting op jou!",
    Description:
      "Deze Extra geeft je eenmalig recht op 20% korting op het bonbedrag. Laat bij de kassa jouw kaart scannen en het wordt verwerkt.",
    ImageUrl:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20190327/134459-LPE-app-T07-20pct-korting.png",
    BigImageUrl:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20190327/134459-LPE-app-T07-20pct-korting.png",
    BackgroundImageURL:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20171110/LaPlace_Image_extraatjes_1370x1036.png",
    Conditions:
      "Niet geldig in combinatie met andere acties en/of promoties. \nDeze Extra is niet geldig bij La Place op Schiphol, Eindhoven Airport, ZiggoDome, alle treinstations. ",
    ShortTitle: "T20007",
    IsNewExtras: false,
    IsBirthdayExtras: false,
    IsStampExtras: false,
  },
  {
    PositionId: 8,
    VisualStatus: 2,
    Label: "EXTRA 8",
    VisitExtraNo: 20008,
    ValidFrom: null,
    ValidTo: null,
    Title: "Ook zin in een ontbijtje?",
    Description:
      "Deze Extra geeft je eenmalig recht op één La Place ontbijt voor 2,50 euro. Het La Place ontbijt bestaat uit: koffie of thee, een glas vers geperste sinaasappelsap (small) en 5 items van het ontbijtbuffet.Laat bij de kassa jouw La Place Extra kaart of de kaart via de app scannen en de Extra wordt verwerkt. ",
    ImageUrl:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20160906/6060.00.095_LPE3.0_NB_level2_T8.png",
    BigImageUrl:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20160906/6060.00.095_LPE3.0_NB_level2_T8.png",
    BackgroundImageURL:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20171110/LaPlace_Image_extraatjes_1370x1036.png",
    Conditions:
      "Niet geldig in combinatie met andere acties en/of promoties.   De Extra's zijn geldig in alle La Place locaties in Nederland met uitzondering van Schiphol, Duinrell, ZiggoDome, Eindhoven Airport en alle treinstations en evenementen.",
    ShortTitle: "T20008",
    IsNewExtras: false,
    IsBirthdayExtras: false,
    IsStampExtras: false,
  },
  {
    PositionId: 9,
    VisualStatus: 2,
    Label: "EXTRA 9",
    VisitExtraNo: 20009,
    ValidFrom: null,
    ValidTo: null,
    Title: "Vers van de pers",
    Description:
      "Deze Extra geeft je eenmalig recht op één vers geperst sinaasappelsap (small) voor 1 euro. Laat bij de kassa jouw La Place Extra kaart of de kaart via de app scannen en de Extra wordt verwerkt. ",
    ImageUrl:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20160906/6060.00.095_LPE3.0_NB_level2_T9.png",
    BigImageUrl:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20160906/6060.00.095_LPE3.0_NB_level2_T9.png",
    BackgroundImageURL:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20171110/LaPlace_Image_extraatjes_1370x1036.png",
    Conditions:
      "Niet geldig in combinatie met andere acties en/of promoties.   De Extra's zijn geldig in alle La Place locaties in Nederland met uitzondering van Schiphol, Duinrell, ZiggoDome, Eindhoven Airport en alle treinstations en evenementen.",
    ShortTitle: "T20009",
    IsNewExtras: false,
    IsBirthdayExtras: false,
    IsStampExtras: false,
  },
  {
    PositionId: 10,
    VisualStatus: 2,
    Label: "EXTRA 10",
    VisitExtraNo: 21002,
    ValidFrom: null,
    ValidTo: null,
    Title: "Wat een feest: een gratis taartje",
    Description:
      "Deze Extra geeft je eenmalig recht op één taartje naar keuze. Laat bij de kassa jouw La Place Extra kaart of de kaart via de app scannen en de Extra wordt verwerkt.",
    ImageUrl:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20190327/134459-LPE-app-T10-gratis-taartje.png",
    BigImageUrl:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20190327/134459-LPE-app-T10-gratis-taartje.png",
    BackgroundImageURL:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20171110/LaPlace_Image_extraatjes_1370x1036.png",
    Conditions:
      "Niet geldig in combinatie met andere acties en/of promoties. \nDeze Extra is niet geldig bij La Place op Schiphol, Eindhoven Airport, ZiggoDome, alle treinstations. ",
    ShortTitle: "T21002",
    IsNewExtras: false,
    IsBirthdayExtras: false,
    IsStampExtras: false,
  },
  {
    PositionId: 14,
    VisualStatus: 2,
    Label: "EXTRA 14",
    VisitExtraNo: 0,
    ValidFrom: null,
    ValidTo: null,
    Title: "Suprise",
    Description: "Suprise description",
    ImageUrl: null,
    BigImageUrl: null,
    BackgroundImageURL:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20171110/LaPlace_Image_extraatjes_1370x1036.png",
    Conditions:
      "Niet geldig in combinatie met andere acties en/of promoties.   De Extra's zijn geldig in alle La Place locaties in Nederland met uitzondering van Schiphol, Duinrell, ZiggoDome, Eindhoven Airport en alle treinstations en evenementen.",
    ShortTitle: "T0",
    IsNewExtras: false,
    IsBirthdayExtras: false,
    IsStampExtras: false,
  },
  {
    PositionId: 15,
    VisualStatus: 2,
    Label: "EXTRA 15",
    VisitExtraNo: 21003,
    ValidFrom: null,
    ValidTo: null,
    Title: "Hmm, verse jus!",
    Description:
      "Deze Extra geeft je eenmalig recht op één versgeperst sinaasappelsap (small) gratis. Laat bij de kassa jouw La Place Extra kaart of de kaart via de app scannen en de Extra wordt verwerkt.",
    ImageUrl:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20190327/134459-LPE-app-T15-gratis-sinaasappelsap.png",
    BigImageUrl:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20190327/134459-LPE-app-T15-gratis-sinaasappelsap.png",
    BackgroundImageURL:
      "https://f6e5ae90faf54f608a0ff6aab9ed6c05.objectstore.eu/assets/Extras30/20171110/LaPlace_Image_extraatjes_1370x1036.png",
    Conditions:
      "Niet geldig in combinatie met andere acties en/of promoties. \nDeze Extra is niet geldig bij La Place op Schiphol, Eindhoven Airport, ZiggoDome, alle treinstations. ",
    ShortTitle: "T21003",
    IsNewExtras: false,
    IsBirthdayExtras: false,
    IsStampExtras: false,
  },
];
