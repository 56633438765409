import store from "../redux/store";
import { useEffect } from "react";
import { setWebcode } from "../redux/globalSlice";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router";

export function useWebcodes() {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const webcodeFromStore = store.getState().global.webcode;
    const qr = searchParams.get("qr");

    const matchesKiosk = location.pathname.match(/\/v5\/kiosk\/[a-zA-Z0-9]*/);
    const matchesQr = location.pathname.match(/\/v5\/qr\/[a-zA-Z0-9]*/);
    let matchedWebcode = null;

    if (matchesKiosk?.[0]) {
      matchedWebcode = matchesKiosk[0].substring(10);
    } else if (matchesQr?.[0]) {
      matchedWebcode = matchesQr[0].substring(7);
    }

    if (matchedWebcode && webcodeFromStore !== matchedWebcode) {
      store.dispatch(setWebcode(matchedWebcode));
    } else if (qr && webcodeFromStore !== qr) {
      store.dispatch(setWebcode(qr));
    }
  }, [location.pathname, searchParams]);
}
