import { postAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics.ts";
import React, { useMemo } from "react";
import SlideUpTransition from "../../../global/components/SlideUpTransition.tsx";
import { SavingsRounded } from "@mui/icons-material";
import { useDialog } from "../../../global/utils/dialog/DialogProvider.tsx";
import PiggyRewardsDialog from "../Piggy/PiggyRewardsDialog.tsx";
import isQr from "../../../global/utils/helpers/isQr.tsx";
import useIsWidescreen from "../../../global/utils/useIsWidescreen.ts";
import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useIntl } from "react-intl";
import clsx from "clsx";

export default function usePiggyLoyaltyListItem(): React.JSX.Element | null {
  const isWidescreen = useIsWidescreen();
  const { openDialog } = useDialog();
  const piggyLoyaltyEnabled = useAppSelector((state) => state.global.salesarea.piggy.loyalty.enabled);
  const intl = useIntl();

  return useMemo(
    () =>
      piggyLoyaltyEnabled ? (
        <ListItem
          className={clsx("JS-SideMenuDrawer-ListItem", "JS-SideMenuDrawer-Piggy-Loyalty")}
          key={"Piggy"}
          disablePadding
        >
          <ListItemButton
            onClick={() => {
              postAnalyticsEvent({
                category: "JamezzShowOrderButton",
                action: "PiggyDialog",
              });
              openDialog({
                children: <PiggyRewardsDialog />,
                fullScreen: !(isQr() && isWidescreen),
                TransitionComponent: SlideUpTransition,
              });
            }}
          >
            <ListItemIcon className={"JS-SideDrawerMenu-ItemIcon"}>
              <SavingsRounded />
            </ListItemIcon>
            <ListItemText primary={intl.formatMessage({ id: "Loyalty" })} />
          </ListItemButton>
        </ListItem>
      ) : null,
    [piggyLoyaltyEnabled, intl, openDialog, isWidescreen]
  );
}
