import { ReactElement } from "react";
import { DataType } from "csstype";
import { Box, BoxProps } from "@mui/material";

/**
 * Utility component to overlay something on an element
 * You can pass an overlayContent to show something on top of the overlay
 * @property ReactElement overlayContent This will be shown on top of the overlay
 * @property boolean disabled Disable the overlay, acts the same as if this component didn't exist
 * @property DataType.Color backdropColor Customize the backdrop color, transparency is recommended
 * @property boolean allowInteraction Determines whether you can or cannot click on items behind the overlay
 */
export function ComponentOverlay({
  overlayContent,
  disabled = false,
  children,
  backdropColor = "rgba(255, 255, 255, 0.7)",
  allowInteraction = undefined,
  ...props
}: {
  overlayContent?: ReactElement;
  disabled?: boolean;
  children: ReactElement;
  backdropColor?: DataType.Color;
  allowInteraction?: true;
} & BoxProps) {
  if (disabled) return children;

  return (
    <Box sx={{ position: "relative", height: "100%" }}>
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundColor: backdropColor,
          // TODO: Make zIndex parentRef.current.style.zIndex + 1
          zIndex: 2,
          ...(allowInteraction ? { pointerEvents: "none" } : {}),
        }}
        {...props}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            textAlign: "center",
          }}
        >
          {overlayContent}
        </Box>
      </Box>
      {children}
    </Box>
  );
}
