import { ReactNode } from "react";
import Box from "@mui/material/Box";

export function OrderPageBottom({
  back,
  forward,
  voucherAction,
  info,
}: {
  back: ReactNode;
  forward: ReactNode;
  voucherAction: ReactNode;
  info: ReactNode;
}) {
  return (
    <Box sx={{ width: "100%", mt: 4 }}>
      <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 4 }}>
        {back} {forward}
      </Box>
      <Box sx={{ width: "100%", display: "grid", gridTemplateColumns: "1fr", mt: 4 }}>{voucherAction}</Box>
      {info}
    </Box>
  );
}
