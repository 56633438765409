import { createContext, useContext } from "react";
import { OptionTranslations } from "../models/menu/Article";
import md5 from "md5";
import { selectAppLanguage } from "../../../kiosk/components/LanguageSelector/useLanguage";
import { useAppSelector } from "../redux/store.tsx";

export enum OptionTranslationType {
  OptionGroup = "mainOption",
  Option = "option",
}

function translateOption(
  originalName: string,
  language: string,
  optionType: OptionTranslationType,
  translations: OptionTranslations
) {
  if (translations) {
    const t = translations[language];
    if (t) {
      const titleMd5 = md5(`${optionType}${originalName}`);
      if (titleMd5 in t) {
        return t[titleMd5];
      }
    }
  }

  if (translations?.[language]?.name) {
    return translations?.[language].name;
  }

  return originalName;
}

export const OptionTranslationsContext = createContext<OptionTranslations>({});
export const useOptionTranslations = () => {
  const translations = useContext(OptionTranslationsContext);
  const selectedLanguage = useAppSelector(selectAppLanguage);

  return {
    translate: (originalName: string, optionType: OptionTranslationType = OptionTranslationType.Option) => {
      return translateOption(originalName, selectedLanguage, optionType, translations);
    },
  };
};
