import { createSelector } from "@reduxjs/toolkit";
import { selectPiggyGiftcardsWithUsingAmounts } from "./selectPiggyGiftcardsWithUsingAmounts.tsx";
import { selectAccountTotalValue } from "../accountSlice.tsx";

export const selectPiggyGiftcardAmount = createSelector(
  [selectPiggyGiftcardsWithUsingAmounts, selectAccountTotalValue],
  (usingGiftcards, totalAmount) => {
    return Math.min(
      totalAmount,
      usingGiftcards.reduce((amount, giftcard) => {
        return amount + giftcard.using_amount_in_cents;
      }, 0) / 100
    );
  }
);
