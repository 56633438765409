import { useMemo, useState } from "react";
import { Button, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import CloseDialogButton from "../../../qr/components/Dialog/CloseDialogButton.tsx";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import { onFocusKeyboard } from "../../../global/utils/redux/globalSlice";
import Box from "@mui/material/Box";
import Article from "../../../global/utils/models/menu/Article";
import useAddOrderArticleToShoppingCart from "../../../global/utils/order/useAddOrderArticleToShoppingCart";
import { initOrderArticle } from "../../../global/utils/models/order/OrderArticle";
import roundTwoDecimals from "../../../global/utils/helpers/roundTwoDecimals";
import _ from "lodash";
import isKiosk from "../../../global/utils/helpers/isKiosk";
import { selectArticlesMap } from "../../../global/utils/redux/selectors/selectArticlesMap";

export default function ManuallyAddArticleDialog() {
  const { closeDialog } = useDialog();
  const dispatch = useAppDispatch();
  const addOrderArticleToShoppingCart = useAddOrderArticleToShoppingCart();

  const customData = useAppSelector((state) => state.global.salesarea.custom_data);

  const articlesMap = useAppSelector(selectArticlesMap);

  const articleTypes: Article[] = useMemo(() => {
    const articleIds = customData.customizable_articles ? JSON.parse(customData.customizable_articles) : [];

    return articleIds.reduce((articles: Article[], articleId: number) => {
      if (articleId) {
        const article = articlesMap[String(articleId)];
        if (article) {
          articles.push(article);
        }
      }
      return articles;
    }, []);
  }, [customData.customizable_articles, articlesMap]);

  const [articleDescription, setArticleDescription] = useState("");
  const [articlePrice, setArticlePrice] = useState("");
  const [selectedArticleTypeId, setSelectedArticleTypeId] = useState<string | null>(null);

  return (
    <>
      <DialogContent
        sx={{
          paddingY: 8,
          paddingX: 8,
        }}
      >
        <CloseDialogButton
          onClose={() => {
            closeDialog();
          }}
        />
        <Typography sx={{ fontSize: "1.5em" }}>
          <FormattedMessageJamezz id="AddArticleDialog.messages.select-article-type" />
        </Typography>
        <FormControl fullWidth sx={{ marginY: 1 }}>
          <InputLabel>
            <FormattedMessageJamezz id="AddArticleDialog.messages.select-one" />
          </InputLabel>
          <Select
            label={<FormattedMessageJamezz id="AddArticleDialog.messages.select-one" />}
            fullWidth
            value={selectedArticleTypeId}
            onChange={(e) => {
              setSelectedArticleTypeId(e.target.value);
            }}
          >
            {articleTypes.map((article) => {
              return (
                <MenuItem key={article.id} value={article.id}>
                  {article.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <Typography sx={{ fontSize: "1.5em", opacity: selectedArticleTypeId == null ? 0.4 : 1 }}>
          <FormattedMessageJamezz id="AddArticleDialog.messages.add-manually" />
        </Typography>
        <Box display={"flex"} alignItems={"center"} sx={{ opacity: selectedArticleTypeId == null ? 0.4 : 1 }}>
          <Box>
            <TextField
              sx={{ margin: 1 }}
              label={"Article description"}
              value={articleDescription}
              disabled={selectedArticleTypeId == null}
              onChange={(e) => {
                setArticleDescription(e.target.value);
              }}
              inputProps={{
                inputMode: isKiosk() ? "none" : undefined,
              }}
              onFocus={() => {
                if (isKiosk()) {
                  dispatch(onFocusKeyboard({ value: articleDescription, layout: "default" }));
                }
              }}
            />
            <TextField
              sx={{ margin: 1 }}
              label={"Sales price"}
              value={articlePrice}
              disabled={selectedArticleTypeId == null}
              inputProps={{
                inputMode: isKiosk() ? "none" : undefined,
              }}
              onChange={(e) => {
                setArticlePrice(e.target.value);
              }}
              onFocus={() => {
                if (isKiosk()) {
                  dispatch(onFocusKeyboard({ value: articlePrice, layout: "numpad" }));
                }
              }}
            />
          </Box>
          <Button
            sx={{ marginLeft: 4 }}
            variant={"contained"}
            disabled={!articlePrice || !articleDescription}
            onClick={() => {
              const articleType = articleTypes.find((articleType) => articleType.id == selectedArticleTypeId);

              if (articleType) {
                const newArticle = _.cloneDeep(articleType);
                newArticle.name = articleDescription;
                const price = articlePrice.replace(",", ".");

                newArticle.price = roundTwoDecimals(Number(price));
                // newArticle.originalPrice = newArticle.price;
                newArticle.hasCustomizedPriceAndName = true;
                const orderArticle = initOrderArticle(articlesMap, newArticle, 1);

                closeDialog();
                addOrderArticleToShoppingCart(orderArticle).catch((err) => {
                  console.log(err);
                });
              }
            }}
          >
            <FormattedMessageJamezz id="AddArticleDialog.messages.add-to-shopping-cart" />
          </Button>
        </Box>
      </DialogContent>
    </>
  );
}
