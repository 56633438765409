import { createSelector } from "@reduxjs/toolkit";
import MenuTag, { MenuTagType } from "../../models/menu/MenuTag";
import store, { RootState } from "../store";
import { allergensDictionary } from "../../api/parseApis/useParseApiAllergen";
import { ProductResponse } from "../api/menuDataApi";
import { IntlShape } from "react-intl";
import { globalIntl } from "../../../../qr/App";

export const selectMenuTagFilters = createSelector(
  [
    (state: RootState) => state.menuData.products,
    (state: RootState) => state.menuData.tags,
    (state: RootState, intl: IntlShape) => intl,
  ],

  (products, tags, intl) => {
    const menuTags: Record<string, MenuTag> = {};

    const parseApiArticle = (apiArticle: ProductResponse) => {
      if (apiArticle.allergenen) {
        JSON.parse(apiArticle.allergenen)
          .sort()
          .forEach((allergen: keyof typeof allergensDictionary) => {
            const translatedName =
              intl.formatMessage({
                id: "Allergens." + ((allergensDictionary[allergen] as any)?.label ?? allergen),
              }) ?? "???";

            menuTags[allergen] = {
              id: allergen,
              name: translatedName,
              type: MenuTagType.Exclude,
              image: allergen,
              updatedAt: "",
              showTag: true,
            };
          });
      }
    };

    products.forEach(parseApiArticle);

    tags
      .filter((apiTag) => apiTag.tag_type != "BRAND")
      .forEach((apiTag) => {
        const menuTagId = String(apiTag.id);
        menuTags[menuTagId] = {
          id: menuTagId,
          name: String(apiTag.tagName),
          type: apiTag.tag_type == "ALLERGEN" ? MenuTagType.Exclude : MenuTagType.Include,
          mediaUrl: apiTag.media_urls.imgid?.[0],
          translations: apiTag.translations,
          updatedAt: "",
          showTag: apiTag.show_tag,
        };
      });

    return menuTags;
  }
);

if (window.selectors) {
  window.selectors.selectMenuTagFilters = () => selectMenuTagFilters(store.getState(), globalIntl.object!);
}
