import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { selectArticlesSum } from "../../../global/utils/redux/shoppingCartSlice.tsx";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";

export function InfoItemCount() {
  const count = useAppSelector(selectArticlesSum);

  return count > 0 ? (
    <Box>
      <Typography className="JS-OrderPage-TotalCount" align="left" sx={{ fontWeight: 800, fontSize: 48 }}>
        {count}&nbsp;
        {count === 1 ? <FormattedMessageJamezz id="item" /> : <FormattedMessageJamezz id="items" />}
      </Typography>
      <Typography sx={{ fontSize: 32, textAlign: "center", width: 1 }}></Typography>
    </Box>
  ) : null;
}
