import React, { MutableRefObject, useCallback, useEffect, useMemo, useState } from "react";
import _, { throttle } from "lodash";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import Article from "../utils/models/menu/Article.ts";
import Articlegroup from "../utils/models/menu/Articlegroup.ts";

// Separate ArticleContent props interface
export interface ArticleContentProps {
  article: Article;
  articlegroup?: Articlegroup;
  isColumnOrientation?: boolean;
}

const throttleRender = throttle((scrollContent, setRenderUpToItemIndex, numberOfItemsToAddEachLoad, items) => {
  if (scrollContent.current) {
    const scrollTop = scrollContent.current.scrollTop;
    const scrollHeight = scrollContent.current.scrollHeight;
    const clientHeight = scrollContent.current.clientHeight;
    if (scrollHeight - scrollTop - 3 * clientHeight < 0) {
      setRenderUpToItemIndex((index: number) => {
        return Math.min(index + numberOfItemsToAddEachLoad, items.length - 1);
      });
    }
  }
}, 1000);

export default function LazyLoadItems({
  items,
  numberOfItemsToRenderInitially,
  numberOfItemsToAddEachLoad,
  scrollContent,
  setRenderUpToArticleIndexRef,
  width,
  pixelHeightCartButton,
  articleContent,
  articlegroup,
  isColumnOrientation,
  sizeOfCircularProgress,
}: {
  items: Article[];
  numberOfItemsToRenderInitially: number;
  numberOfItemsToAddEachLoad: number;
  scrollContent: MutableRefObject<any>;
  setRenderUpToArticleIndexRef?: MutableRefObject<any>;
  width?: number;
  pixelHeightCartButton?: string;
  articleContent: React.ComponentType<ArticleContentProps>;
  // Make these props match ArticleContentProps exactly
  articlegroup?: Articlegroup;
  isColumnOrientation?: boolean;
  sizeOfCircularProgress?: number;
}) {
  const [renderUpToItemIndex, setRenderUpToItemIndex] = useState(numberOfItemsToRenderInitially);

  useEffect(() => {
    if (setRenderUpToArticleIndexRef) {
      setRenderUpToArticleIndexRef.current = setRenderUpToItemIndex;
    }
  }, [setRenderUpToArticleIndexRef]);

  const lastItemRendered = useMemo(() => {
    return renderUpToItemIndex >= items.length - 1;
  }, [renderUpToItemIndex, items]);

  const onScroll = useCallback(() => {
    throttleRender(scrollContent, setRenderUpToItemIndex, numberOfItemsToAddEachLoad, items);
  }, [items, numberOfItemsToAddEachLoad, scrollContent]);

  useEffect(() => {
    const element = scrollContent.current;
    if (element) {
      element.addEventListener("scroll", onScroll);
      return () => {
        element.removeEventListener("scroll", onScroll);
      };
    }
  }, [onScroll, scrollContent]);

  useEffect(() => {
    setTimeout(() => {
      throttleRender(scrollContent, setRenderUpToItemIndex, numberOfItemsToAddEachLoad, items);
    }, 500);
  }, [items, numberOfItemsToAddEachLoad, scrollContent]);

  const ArticleContent = articleContent;
  const minWidth = width === undefined ? 300 : undefined;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: 1,
        }}
      >
        {items.slice(0, renderUpToItemIndex + 1).map((article) => {
          const articleContentProps: ArticleContentProps = {
            article,
            articlegroup,
            isColumnOrientation,
          };

          return (
            <Box
              key={article.id}
              sx={{ width, minWidth, flex: width === undefined ? "1 1 0%" : undefined }}
              data-cy="search-result"
            >
              <ArticleContent {...articleContentProps} />
            </Box>
          );
        })}

        {minWidth !== undefined
          ? _.times(8).map(() => (
              // HACKY we should calculate the number of articles next to each other (X) and take items.length % X = instead of 8
              <Box sx={{ minWidth, flex: "1 1 0%" }}></Box>
            ))
          : null}
      </Box>

      {!lastItemRendered ? (
        <Box sx={{ width: 1, textAlign: "center", paddingBottom: pixelHeightCartButton ?? undefined }}>
          <CircularProgress size={sizeOfCircularProgress ?? 96} />
        </Box>
      ) : null}
    </>
  );
}
