import Article from "../../global/utils/models/menu/Article";
import Articlegroup from "../../global/utils/models/menu/Articlegroup";

export function JSClassAnnotatedWithIds(className: string, article?: Article, articlegroup?: Articlegroup): string {
  // note, classes that appear later in the string have higher css specificity.
  // so if both JS-Item-Title--ApiId123 and JS-Item-Title have a css "color" property set,
  // the last one applies. So precedence here is product.ApiId > product.id > articlegroup.id > articlegroup.apiid > regular
  let annotedClassName = `${className}`;
  if (articlegroup) {
    if (articlegroup.id) {
      annotedClassName += ` ${className}--ArticleGroupId${articlegroup.id}`;
    }
    if (articlegroup.apiId1) {
      annotedClassName += ` ${className}--ArticleGroupApiId${articlegroup.apiId1}`;
    }
  }
  if (article) {
    if (article.id) {
      annotedClassName += ` ${className}--${article.id}`;
    }
    if (article.apiId1) {
      annotedClassName += ` ${className}--ApiId${article.apiId1}`;
    }
  }
  return annotedClassName;
}
