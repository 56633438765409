import { alpha, IconButton, InputBase, Stack, styled, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import isKiosk from "../../../../global/utils/helpers/isKiosk.tsx";
import { onFocusKeyboard, setArticleSearchText } from "../../../../global/utils/redux/globalSlice.tsx";
import { Close } from "@mui/icons-material";
import React from "react";
import { useIntl } from "react-intl";
import store, { useAppSelector } from "../../../../global/utils/redux/store.tsx";

const Search = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  position: "relative",
  borderRadius: "16px",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  border: "1px solid rgba(255, 255, 255, 0.1)",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.2),
  },
  width: "calc(100% - 20px)",
  height: "32px",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "0 8px",
  height: "24px",
  pointerEvents: "none",
  color: theme.palette.text.secondary,

  "& svg": {
    fontSize: "20px",
    [theme.breakpoints.up("md")]: {
      fontSize: "24px",
    },
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  flex: 1,
  height: "100%",
  color: theme.palette.text.primary,

  "& .MuiInputBase-input": {
    height: "100%",
    width: "100%",
    fontSize: "16px",
    padding: "0",
    paddingRight: "16px",
    transition: theme.transitions.create("width"),

    [theme.breakpoints.up("md")]: {
      fontSize: "17px",
    },

    "&::placeholder": {
      opacity: 0.7,
      color: theme.palette.text.secondary,
    },
  },
}));

export default function SearchField() {
  const theme = useTheme();
  const intl = useIntl();
  const searchValue = useAppSelector((state) => state.global.articleSearchText);

  return (
    <Stack alignItems={"center"} sx={{ pt: 2, pb: 1, px: 1 }}>
      <Search
        className={"JS-Search-Root"}
        style={{
          maxWidth: 300,
          backgroundColor: alpha(theme.palette.primary.light, 1),
          color: theme.palette.primary.contrastText,
          boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.2)",
        }}
      >
        <SearchIconWrapper>
          <SearchIcon sx={{ width: 1, height: 1 }} />
        </SearchIconWrapper>
        <StyledInputBase
          data-cy={"search-input"}
          placeholder={intl.formatMessage({
            id: "Search",
            defaultMessage: "Search",
            description: "placeholder text",
          })}
          inputProps={{
            "aria-label": "search",
            className: "JS-Search-Input",
            inputMode: isKiosk() ? "none" : undefined,
          }}
          className={"JS-Search-InputBase"}
          style={{
            maxWidth: 400,
            width: "100%",
            color: theme.palette.primary.contrastText,
          }}
          value={searchValue}
          onChange={(e) => store.dispatch(setArticleSearchText(e.target.value))}
          onFocus={() => {
            if (isKiosk()) {
              store.dispatch(onFocusKeyboard({ value: searchValue, layout: "default" }));
            }
          }}
        />
        <IconButton
          className={"JS-Search-IconButton"}
          sx={{
            color: theme.palette.primary.contrastText,
            transition: theme.transitions.create("opacity"),
            visibility: searchValue === "" ? "hidden" : "unset",
          }}
          onClick={(e) => {
            e.stopPropagation();
            store.dispatch(setArticleSearchText(""));
          }}
        >
          <Close className={"JS-Search-Icon"} sx={{ width: "0.8em", height: "0.8em" }} />
        </IconButton>
      </Search>
    </Stack>
  );
}
