import { Typography } from "@mui/material";
import Bouncer from "../../../../qr/components/Bouncer";
import Box from "@mui/material/Box";

interface Props {
  count: number;
}

export default function ArticleCounter(props: Props) {
  return (
    <Box sx={{ position: "absolute", top: 0, left: "5%" }} className={"JS-ArticleCounter-Root"}>
      <Bouncer key={props.count} repeat={"1"}>
        <Typography
          sx={{
            padding: 2,
            overflow: "hidden",
            width: 50,
            height: 50,
            borderRadius: 10,
            backgroundColor: "primary.main",
            color: "primary.contrastText",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1.7em",
          }}
          className={"JS-ArticleCounter-Count"}
        >
          {props.count}
        </Typography>
      </Bouncer>
    </Box>
  );
}
