import { useDialog } from "../../../global/utils/dialog/DialogProvider.tsx";
import { OrderPageAction } from "./OrderPageAction.tsx";
import { ReactNode } from "react";
import { ButtonProps } from "@mui/material";

export function OrderMore({ children, ...props }: { children: ReactNode } & ButtonProps) {
  const { closeDialog } = useDialog();

  return (
    <OrderPageAction emphasize className="JS-OrderPage-ContinueShopping" onClick={() => closeDialog()} {...props}>
      {children}
    </OrderPageAction>
  );
}
