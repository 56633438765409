import { TerminalState } from "./SixPaymentsMain.tsx";

export default function checkConnection(ecrPayment: any) {
  try {
    if (ecrPayment?.terminal) {
      let status = ecrPayment.terminal.getTerminalStatus();

      // @ts-ignore
      if (status?.connectionStatus === timapi.constants.ConnectionStatus.loggedIn) {
        return TerminalState.loggedIn;
        // @ts-ignore
      } else if (status?.connectionStatus === timapi.constants.ConnectionStatus.loggedOut) {
        return TerminalState.loggedOut;
        // @ts-ignore
      } else if (status?.connectionStatus === timapi.constants.ConnectionStatus.disconnected) {
        return TerminalState.disconnected;
      } else {
        return undefined;
      }
    }
  } catch (err: any) {
    return err;
  }
}
