import { useEffect } from "react";
import { useDialog } from "../dialog/DialogProvider";
import { ShoppingCartConfirmationDenied } from "./ShoppingCartConfirmationDenied";
import useAgeCheckResponse from "./useAgeCheckResponse.ts";

export default function useWatcherDenied() {
  const response = useAgeCheckResponse();
  const { openDialog } = useDialog();
  useEffect(() => {
    if (response === "DENIED") {
      openDialog({ children: <ShoppingCartConfirmationDenied />, disabledBackdropClick: true });
    }
  }, [response, openDialog]);
}
