import { KioskSendOrderFunction, OrderResponse } from "../../../types/kiosk/sendCheckout";
import { ISendCheckPaymentRequest } from "../../../types/shared/checkPayment";

import { IQRSendCheckoutRequest } from "../../../types/qr/sendCheckout";

const mockCheckoutResponse: OrderResponse = {
  data: {
    data: {
      paymentReady: 1,
      orderHash: "abc",
      paymentError: 0,
      paymentErrorMsg: null,
      orderId: Math.floor(Math.random() * 10000),
      requestPayment: 0,
      orderStatus: 1,
      // TODO: Calculate based on orderArticles
      orderValue: 5,
      orderMsg: "Je hebt besteld",
      timeSlotError: 0,
      paymentData: {
        request: {
          result: 1,
        },
        transaction: {},
      },
      shared_shopping_cart_ordered: 0,
    },
    release: "",
    status: "ok",
    timestamp: +new Date(),
  },
};

export const mockSendCheckout: IQRSendCheckoutRequest = function mockSendCheckout() {
  return new Promise((resolve) => {
    resolve(mockCheckoutResponse);
  });
};

/**
 * For the live interface
 */
export const mockPostCheckout: KioskSendOrderFunction = function fakedPostCheckoutRequest(
  kioskSendCheckoutParams: Record<string, any>
) {
  return new Promise<OrderResponse>((resolve) => {
    console.log("faking order response in dev mode");
    console.log(kioskSendCheckoutParams);
    resolve(mockCheckoutResponse);
  });
};

export const mockSendCheckPaymentRequest: ISendCheckPaymentRequest = function (oid) {
  return new Promise((resolve) => {
    resolve({
      data: {
        data: {
          hasPrinters: 0,
          payStatus: 1,
          printed: 1,
          ordered_at: new Date(),
          payDirect: 1,
        },
      },
    });
  });
};
