import { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import { useIntl } from "react-intl";
import _ from "lodash";
import { Checkbox, FormControlLabel, Link } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { changedTermsAndConditionsAgreement } from "../../../global/utils/redux/userInputSlice";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";

import { Salesarea } from "../../../types/shared/Salesarea";
import { postAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics.ts";

export function TermsAndConditions() {
  const av_pdf = useAppSelector((state) => state.global.salesarea.terms_and_conditions.algemene_voorwaarden_url);
  const pv_pdf = useAppSelector((state) => state.global.salesarea.terms_and_conditions.privacy_statement_url);
  const dispatch = useAppDispatch();
  const checked = useAppSelector((state) => state.userInput.hasAcceptedTermsAndAgreements);
  const intl = useIntl();

  const retrieveMatchingTranslationId: ({
    av,
    pv,
  }: {
    av: Salesarea["terms_and_conditions"]["algemene_voorwaarden_url"];
    pv: Salesarea["terms_and_conditions"]["privacy_statement_url"];
  }) => "Terms_and_conditions.show_av" | "Terms_and_conditions.show_pv" | "Terms_and_conditions.show_both" = _.cond([
    [_.conforms({ av: _.isString, pv: _.isNull }), _.constant("Terms_and_conditions.show_av")],
    [_.conforms({ av: _.isNull, pv: _.isString }), _.constant("Terms_and_conditions.show_pv")],
    [_.conforms({ av: _.isString, pv: _.isString }), _.constant("Terms_and_conditions.show_both")],
  ]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          className="JS-CheckoutPage-TermsAndConditions-Checkbox"
          required
          icon={<CheckBoxOutlineBlankIcon className="JS-CheckoutPage-TermsAndConditions-Checkbox" />}
          checkedIcon={<CheckBoxIcon className="JS-CheckoutPage-TermsAndConditions-Checkbox" />}
          onChange={(evt) => {
            postAnalyticsEvent({
              category: "TermsAndConditions",
              action: "changedTermsAndConditionsAgreement(" + evt.target.checked + ")",
            });
            dispatch(changedTermsAndConditionsAgreement(evt.target.checked));
          }}
          checked={checked}
        />
      }
      label={
        <span className="JS-CheckoutPage-TermsAndConditions-Text">
          <FormattedMessageJamezz
            id={retrieveMatchingTranslationId({ av: av_pdf, pv: pv_pdf })}
            values={{
              terms: (
                <Link href={av_pdf as string} target="_blank" className="JS-CheckoutPage-TermsAndConditions">
                  {intl.formatMessage({ id: "Terms_and_conditions.terms_and_conditions" })}
                </Link>
              ),
              privacy: (
                <Link href={pv_pdf as string} target="_blank" className="JS-CheckoutPage-TermsAndConditions">
                  {intl.formatMessage({ id: "Terms_and_conditions.privacy_statement" })}
                </Link>
              ),
            }}
          />
        </span>
      }
    />
  );
}
