import useCurrency, { CurrencyLocation } from "../../../global/utils/useCurrency.tsx";
import { useAccountTotalValue } from "../../../global/utils/redux/accountSlice.tsx";
import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { useCustomTexts } from "../../../global/utils/useCustomTexts.tsx";
import { Alert, Box, Typography } from "@mui/material";
import PackagingKiosk from "../../components/Packagings/PackagingKiosk.tsx";
import SendOrderButton from "../../components/SendOrderButton.tsx";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";
import { selectPiggyGiftcardAmount } from "../../../global/utils/redux/selectors/selectPiggyGiftcardAmount.tsx";

export function ReviewOrderPage({ isValid }: { isValid?: boolean }) {
  const toCurrency = useCurrency({ location: CurrencyLocation.Checkout });
  const totalAmount = useAccountTotalValue();
  const payDirect = useAppSelector((state) => state.global.salesarea.payDirect);
  const piggyGiftcardAmount = useAppSelector(selectPiggyGiftcardAmount);
  const usePackagings = useAppSelector((state) => state.global.salesarea.use_packagings);
  const ct = useCustomTexts(["checkout_message"]);

  return (
    <Box
      sx={{
        display: "grid",
        marginY: 8,
        gridTemplate: `"packaging packaging" "p p" "b b" "m m" / 1fr 1fr`,
        gap: 2,
        justifyContent: "center",
      }}
    >
      <Box sx={{ gridArea: "packaging" }}>{usePackagings ? <PackagingKiosk /> : null}</Box>
      <Typography
        className={"JS-CheckoutPage-TotalAmount"}
        sx={{ fontWeight: 800, fontSize: 48, gridArea: "p", textAlign: "center" }}
      >
        {toCurrency(totalAmount - piggyGiftcardAmount)}
      </Typography>
      {ct["checkout_message"] ? (
        <Alert
          severity="info"
          icon={false}
          sx={{ width: "100%", gridArea: "m" }}
          className="JS-CheckoutPage-CheckoutMessage"
        >
          {ct["checkout_message"]}
        </Alert>
      ) : null}
      <SendOrderButton isValid={Boolean(isValid)} sx={{ gridArea: "b" }}>
        {payDirect ? <FormattedMessageJamezz id={"Pay"} /> : <FormattedMessageJamezz id={"Order"} />}
      </SendOrderButton>
    </Box>
  );
}
