import { useMemo } from "react";
import MenuTag, { getMenuTagTranslatedName, MenuTagType } from "../../global/utils/models/menu/MenuTag";
import { Chip, ChipProps } from "@mui/material";
import FormattedMessageJamezz from "../../global/components/FormattedMessageJamezz";
import { jamezzApplicationType } from "../App";
import { useAppSelector } from "../../global/utils/redux/store.tsx";
import { selectAppLanguage } from "../../kiosk/components/LanguageSelector/useLanguage.ts";

interface Props {
  menuTag: MenuTag;
  size?: ChipProps["size"];
}

export default function ActiveMenuTag(props: Props) {
  const size = useMemo(() => {
    return props.size ?? (jamezzApplicationType.type === "KIOSK" ? "medium" : "small");
  }, [props.size]);

  const selectedLanguage = useAppSelector(selectAppLanguage);

  if (props.menuTag.type === MenuTagType.Exclude) {
    return (
      <Chip
        label={
          <FormattedMessageJamezz
            id={"This product contains {allergen}!"}
            values={{ allergen: getMenuTagTranslatedName(props.menuTag, selectedLanguage) }}
          />
        }
        color={"error"}
        size={size}
      />
    );
  } else {
    return null;
  }
}
