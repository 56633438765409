import { useGoToHomeKiosk } from "../../../kiosk/pages/ReturnPage/ReturnPage";
import { useDialog } from "../dialog/DialogProvider";
import store, { useAppSelector } from "../redux/store";
import { useEffect, useState } from "react";
import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Box from "@mui/material/Box";
import { Warning } from "@mui/icons-material";
import { trayDeletedFromShoppingCart } from "../redux/shoppingCartSlice";

export function ShoppingCartConfirmationDenied() {
  const goToHome = useGoToHomeKiosk();
  const { closeDialog } = useDialog();
  const items = useAppSelector((state) => state.shoppingCart.items);

  const [timer, setTimer] = useState(20);

  useEffect(() => {
    let timeout: number | null = null;
    if (timer > 0) {
      timeout = window.setTimeout(() => {
        setTimer((timer) => timer - 1);
      }, 1000);
    } else {
      closeDialog();
      goToHome();
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [closeDialog, goToHome, timer]);
  return (
    <>
      <DialogTitle>Purchasing alcoholic items is denied by an employee.</DialogTitle>
      <DialogContent>
        <Box sx={{ width: 1, padding: 2, display: "flex", justifyContent: "center" }}>
          <Warning />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          onClick={() => {
            closeDialog();
            goToHome();
          }}
        >
          Cancel order ({timer}s)
        </Button>
        <Button
          variant={"contained"}
          onClick={() => {
            const tray = items
              .filter((item) => item.article.requireAge && item.article.requireAge > 0)
              .map((item) => item.uuid);

            store.dispatch(trayDeletedFromShoppingCart(tray));
            closeDialog();
          }}
        >
          Remove alcoholic items from order
        </Button>
      </DialogActions>
    </>
  );
}
