import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store.tsx";
import _ from "lodash";

export const selectCustomFieldBackgrounds = createSelector(
  [(state: RootState) => state.global.salesarea.media_urls],
  (mediaUrls) => {
    return _.chain(mediaUrls?.custom_fields_backgrounds ?? [])
      .groupBy("id")
      .value();
  }
);
