import { PropsWithChildren } from "react";
import { useAppSelector } from "../utils/redux/store.tsx";
import { Language } from "../../types/shared/language.ts";
import { FormattedMessage, IntlProvider } from "react-intl";
import { messagesForLanguages } from "../../kiosk/components/LanguageSelector/useLanguage.ts";
import { Typography } from "@mui/material";

export default function JamezzMultipleIntlProvider(props: PropsWithChildren & { messageId: string }) {
  const languagesString = useAppSelector((state) => state.global.sessionState?.showLangselLanguages);
  const languages = (languagesString ? (JSON.parse(languagesString) as Language[]) : null) ?? [];

  return (
    <>
      {languages.map((language) => (
        <IntlProvider
          key={language}
          locale={language}
          messages={messagesForLanguages[language] || messagesForLanguages["en"]}
          onError={() => {}}
        >
          <Typography sx={{ fontSize: 72, textAlign: "center", width: 1, marginBottom: 2 }}>
            <FormattedMessage id={props.messageId} />
          </Typography>
        </IntlProvider>
      ))}
    </>
  );
}
