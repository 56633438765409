import { useCallback, useEffect, useRef } from "react";
import { useAppSelector } from "./redux/store";
import useSalesareaData from "./api/useSalesareaData";
import { getNow } from "./models/JamezzHours";

export default function useRestartKiosk() {
  const restartTime = useAppSelector<string | undefined>((state) => state.global.salesarea?.kioskRestartTime);
  const timezone = useAppSelector((state) => state.global.salesarea?.timezone);
  const mid = useAppSelector((state) => state.global?.webcode) ?? null;
  const { getSalesareaData } = useSalesareaData();
  const runTime = useRef(getNow());

  const checkTime = useCallback(() => {
    const dt = getNow();
    const runningSeconds = Math.round((dt.getTime() - runTime.current.getTime()) / 1000);
    const timeZoneHour = parseInt(dt.toLocaleTimeString("nl-NL", { timeZone: timezone, hour: "2-digit" }));
    const timeZoneMinute = parseInt(dt.toLocaleTimeString("nl-NL", { timeZone: timezone, minute: "2-digit" }));
    if (restartTime) {
      const restartHour = parseInt(restartTime.split(":")[0]);
      const restartMinute = parseInt(restartTime.split(":")[1]);
      if (timeZoneHour == restartHour && timeZoneMinute == restartMinute && runningSeconds > 70) {
        if (mid) {
          (window as Window).location = "https://jamezz.app/dl/" + mid;
        } else {
          (window as Window).location = window.location;
        }
      } else if (timeZoneHour == restartHour && timeZoneMinute == restartMinute) {
        console.log("Runtime to short (" + runningSeconds + "), skipping reboot.");
      }
    }
  }, [restartTime, runTime, timezone, mid]);

  useEffect(() => {
    let interval: number | null = null;
    interval = window.setInterval(checkTime, 5000);
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [checkTime, getSalesareaData]);
}
