import { ItemOrderHours } from "../../menu/filters/MenuArticleOrderHours";
import { getNow, setDateToJamezzTime } from "../JamezzHours";
import { differenceInSeconds } from "date-fns";

export default interface OrderHours {
  [key: string]: ItemOrderHours | boolean;
}

export function getSecondsLeftForOrderHours(orderHours: OrderHours, id: string): number | null {
  if (orderHours[id] !== undefined) {
    if (!orderHours[id]) {
      return -1;
    }
    const orderHour = orderHours[id] as ItemOrderHours;
    const endDate = setDateToJamezzTime(orderHour.jamezzHours.endTime);
    return differenceInSeconds(endDate, getNow());
  }
  return null;
}
