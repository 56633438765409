import { memo, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "../global/utils/config";
import HomePage from "./pages/HomePage/HomePage";
import MenuPage from "./pages/MenuPage/MenuPage";
import useSessionState from "../global/utils/useSessionState";
import KioskInputListener from "./utils/KioskInputListener";
import useWebsockets2 from "../global/utils/useWebsockets2";
import PrinterErrorState from "./components/PrinterError/PrinterError";
import ShoppingCartChecker from "../global/utils/order/ShoppingCartChecker";
import MenuArticleOrderHours from "../global/utils/menu/filters/MenuArticleOrderHours";
import { useAppSelector } from "../global/utils/redux/store";
import KeyboardWrapper, { useCheckActiveElement } from "../global/components/Keyboard/KeyboardWrapper";
import Blinkstick from "./components/Blinkstick/Blinkstick";
import ProductAddedByBarcodeDialog from "./components/ProductAddedByBarcodeDialog";
import useRestartKiosk from "../global/utils/useRestartKiosk";
import { PropposPage } from "./pages/PropposPage/Proppos";
import { VoucherChecker } from "../global/utils/order/VoucherChecker";
import useAnalytics from "../global/utils/analytics/useAnalytics";
import SixPaymentsMain from "../global/components/SixPaymentsTim/SixPaymentsMain";
// @ts-ignore
import JamezzLogo from "../assets/images/Logo.svg";
// @ts-ignore
import LoadingDots from "../assets/images/LoadingDots.svg";
import { NavigateToWebcode } from "../qr/QrRouter";
import useServerSideShoppingCart from "../global/utils/hooks/useServerSideShoppingCart";
import { ReturnPageWrapper } from "./pages/ReturnPage/ReturnPage";
import PageNotFound from "../global/components/PageNotFound";
import useLoadPackagings from "../global/utils/hooks/useLoadPackagings";
import { useZoomFactor } from "./utils/ipadProViewportWorkaround";
import useLoadMenuData from "../global/utils/hooks/useLoadMenuData";
import { useIntl } from "react-intl";
import { globalIntl } from "../qr/App";
import WheelchairFriendlyButton from "./components/WheelchairFriendly/WheelchairFriendlyButton";
import RequireAgeShoppingCartCheck from "../global/utils/ageCheck/RequireAgeShoppingCartCheck";
import usePiggyLogin from "./utils/piggy/usePiggyLogin";

import { useMenuUpdatedListener } from "../global/utils/ageCheck/useWebsocketChannel";
import useAlcoholConfirmationEnabled from "../global/utils/ageCheck/useAlcoholConfirmationEnabled";
import { useWebcodes } from "../global/utils/hooks/useWebcode.ts";
import { useOpenStateAndDuration } from "../global/utils/useSalesareaIsOpen.ts";
import useLanguage from "./components/LanguageSelector/useLanguage.ts";
import useDefaultVoucherCode from "../global/utils/hooks/useDefaultVoucherCode.ts";

// adjustViewport();

export default function KioskRouter() {
  useWebsockets2();
  useAnalytics();
  useCheckActiveElement();
  globalIntl.object = useIntl();
  const enabledAlcoholConfirmation = useAlcoholConfirmationEnabled();
  const piggyVouchersEnabled = useAppSelector<boolean>(
    (state) => state.global.salesarea?.piggy?.vouchers?.enabled ?? false
  );
  const payProvider = useAppSelector((state) => state.global.salesarea?.payProvider);
  const salesareaId = useAppSelector((state) => state.global.salesarea?.id);
  const salesareaPrinterCheckstate = useAppSelector((state) => state.global.salesarea?.use_printer_state_check);
  const scanner_hw_available =
    useAppSelector((state) => state.global.salesarea.custom_data?.scanner_hw_available) ?? true;

  const sixPaymentsMain = useMemo(() => {
    if (payProvider === "SIXPAYMENTSTIM") {
      return <SixPaymentsMain />;
    } else {
      return null;
    }
  }, [payProvider]);

  if (!salesareaId) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          height: "95vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "100%", maxWidth: 600 }}>
          <img src={JamezzLogo} />
        </div>
        <div style={{ marginLeft: "auto", marginRight: "auto", width: 200 }}>
          <img src={LoadingDots} />
        </div>
      </div>
    );
  }

  return (
    <>
      {salesareaPrinterCheckstate ? <PrinterErrorState /> : null}
      {scanner_hw_available ? <KioskInputListener /> : null}
      <ProductAddedByBarcodeDialog />
      <ShoppingCartChecker />
      {/*lb: not sure if this is necessary in the KioskRouter*/}
      <VoucherChecker />
      <MenuArticleOrderHours />
      <HooksAfterLoading />
      {enabledAlcoholConfirmation ? <RequireAgeShoppingCartCheck /> : null}
      {/*{piggyVouchersEnabled ? <GroupedPiggyVouchersDialogController /> : null}*/}
      <Blinkstick />

      {sixPaymentsMain}
      <Routes>
        <Route path={routes.kiosk.redirectPath} element={<NavigateToWebcode />}></Route>
        <Route path={routes.kiosk.rootPath} element={<HomePage />} />
        <Route path={routes.kiosk.menuPath} element={<MenuPage />} />
        <Route path={routes.kiosk.returnPath} element={<ReturnPageWrapper />} />
        <Route path={routes.kiosk.propposPath} element={<PropposPage />} />
        <Route path="*" element={<PageNotFound redirectToHome={true} />} />
      </Routes>
      <WheelchairFriendlyButton />
      <KeyboardWrapper />
    </>
  );
}

export const SessionState = memo(SessionStateComp);
function SessionStateComp() {
  useWebcodes();
  useSessionState();
  return null;
}

export function RestartKiosk() {
  useRestartKiosk();
  return null;
}

function HooksAfterLoading() {
  useServerSideShoppingCart();
  useLoadPackagings();
  useLoadMenuData();
  useZoomFactor();
  usePiggyLogin();
  useMenuUpdatedListener();
  useOpenStateAndDuration();
  useLanguage();
  useDefaultVoucherCode();
  return null;
}
