import { useCallback } from "react";
import { useDialog } from "../../../../global/utils/dialog/DialogProvider.tsx";
import { Box, Button, DialogActions, Stack, Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz.tsx";
import isKiosk from "../../../../global/utils/helpers/isKiosk.tsx";
import { FloatingCloseDialogButton } from "../../../components/Dialog/CloseDialogButton.tsx";
import OrderArticle from "../../../../global/utils/models/order/OrderArticle.ts";

type Answer = "NEW_ARTICLE" | "EXISTING_ARTICLE" | "CLOSE";

export default function useAskNewArticleOrExistingArticle() {
  const { openDialog, closeDialog } = useDialog();
  return useCallback(
    (orderArticles: OrderArticle[]) => {
      return new Promise<Answer>((resolve) => {
        openDialog({
          children: (
            <Dialog
              orderArticles={orderArticles}
              onConfirm={(answer: Answer) => {
                closeDialog();
                resolve(answer);
              }}
            />
          ),
        });
      });
    },
    [openDialog, closeDialog]
  );
}

function Dialog({ orderArticles, onConfirm }: { orderArticles: OrderArticle[]; onConfirm: (answer: Answer) => void }) {
  return (
    <Box>
      <FloatingCloseDialogButton
        onClose={() => {
          onConfirm("CLOSE");
        }}
      />
      <Box sx={{ m: 2 }}>
        <Typography variant="body1" sx={{ mb: 2, wordBreak: "break-word" }}>
          <FormattedMessageJamezz
            id="Popup.askNewArticleOrExistingArticle.title"
            values={{ articleName: "'" + orderArticles[0]?.article.name + "'" }}
          />
        </Typography>

        <DialogActions>
          <Stack direction={"row"} gap={isKiosk() ? 6 : 2}>
            <Button
              type={"submit"}
              onClick={() => {
                onConfirm("NEW_ARTICLE");
              }}
              variant="contained"
            >
              <FormattedMessageJamezz id="No" />
            </Button>
            <Button
              type={"submit"}
              onClick={() => {
                onConfirm("EXISTING_ARTICLE");
              }}
              variant="contained"
            >
              <FormattedMessageJamezz id="Yes" />
            </Button>
          </Stack>
        </DialogActions>
      </Box>
    </Box>
  );
}
