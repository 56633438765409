import { useAppSelector } from "../../utils/redux/store.tsx";
import { selectCreatedAtTicket } from "../../utils/redux/arrangements/arrangementsSlice.tsx";
import { useEffect, useState } from "react";
import { differenceInSeconds } from "date-fns";
import { getNow } from "../../utils/models/JamezzHours.ts";
import { selectArrangementMaxTime } from "../../utils/redux/arrangements/arrangementRuleSelectors.ts";

export default function useAyceTicketTimer() {
  const maxTimeInMinutes = useAppSelector(selectArrangementMaxTime);
  const createdAtTicket = useAppSelector(selectCreatedAtTicket) ?? "";

  const [timeLeftInSeconds, setTimeLeftInSeconds] = useState(
    Math.max(0, (maxTimeInMinutes ?? 0) * 60 - (differenceInSeconds(getNow(), new Date(createdAtTicket)) ?? 0))
  );

  useEffect(() => {
    const timeout = (timeLeftInSeconds > 600 ? (timeLeftInSeconds % 60) + 1 : 1) * 1000;

    const timer = window.setTimeout(() => {
      setTimeLeftInSeconds(
        Math.max(0, (maxTimeInMinutes ?? 0) * 60 - (differenceInSeconds(getNow(), new Date(createdAtTicket)) ?? 0))
      );
    }, timeout);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [createdAtTicket, maxTimeInMinutes, timeLeftInSeconds]);

  return timeLeftInSeconds;
}

export function useAyceTicketTimerUntilInvalid() {
  const maxTimeInMinutes = useAppSelector(selectArrangementMaxTime);
  const createdAtTicket = useAppSelector(selectCreatedAtTicket) ?? "";

  const [timeLeft, setTimeLeft] = useState(
    (maxTimeInMinutes ?? 0) * 60 - (differenceInSeconds(getNow(), new Date(createdAtTicket)) ?? 0) > 0
  );

  useEffect(() => {
    if (maxTimeInMinutes && createdAtTicket) {
      setTimeLeft(maxTimeInMinutes * 60 - (differenceInSeconds(getNow(), new Date(createdAtTicket)) ?? 0) > 0);
    }
  }, [maxTimeInMinutes, createdAtTicket]);

  useEffect(() => {
    const timeout =
      ((maxTimeInMinutes ?? 0) * 60 - (differenceInSeconds(getNow(), new Date(createdAtTicket)) ?? 0)) * 1000 + 1000;

    let timer: number = 0;
    if (timeout > 0) {
      timer = window.setTimeout(() => {
        setTimeLeft(false);
      }, timeout);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [createdAtTicket, maxTimeInMinutes]);

  return timeLeft;
}
