import { useCallback } from "react";
import { useDialog } from "../../../global/utils/dialog/DialogProvider.tsx";
import { Button, DialogActions, DialogContent } from "@mui/material";
import FormattedMessageJamezz, { JamezzTranslation } from "../../../global/components/FormattedMessageJamezz.tsx";

type DialogAction = {
  id: JamezzTranslation;
  action?: () => void;
};

export default function useConfirmationDialog() {
  const { openDialog } = useDialog();
  return useCallback(
    ({ actions, body }: { actions: DialogAction[]; body: JamezzTranslation }) => {
      openDialog({ children: <ConfirmationDialog actions={actions} body={body} /> });
    },
    [openDialog]
  );
}

function ConfirmationDialog({ actions, body }: { actions: DialogAction[]; body: JamezzTranslation }) {
  const { closeDialog } = useDialog();
  return (
    <>
      <DialogContent>
        <FormattedMessageJamezz id={body} />
      </DialogContent>
      <DialogActions sx={{ gap: 3 }}>
        {actions.map((action) => (
          <Button
            variant={"contained"}
            onClick={() => {
              closeDialog();
              action.action?.();
            }}
          >
            <FormattedMessageJamezz id={action.id} />
          </Button>
        ))}
      </DialogActions>
    </>
  );
}
