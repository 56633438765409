import Box from "@mui/material/Box";

export default function Checkmark({ scale }: { scale?: number }) {
  return (
    <Box sx={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%,-50%)" }}>
      <span style={{ display: "inline-block", width: 22, height: 22, transform: `rotate(45deg) scale(${scale})` }}>
        <span style={{ position: "absolute", width: 3, height: 9, backgroundColor: "green", left: 11, top: 6 }}></span>
        <span style={{ position: "absolute", width: 3, height: 3, backgroundColor: "green", left: 8, top: 12 }}></span>
      </span>
    </Box>
  );
}
