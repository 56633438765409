import { memo } from "react";
import OrderArticle from "../../../../global/utils/models/order/OrderArticle.ts";
import { Box } from "@mui/material";
import CheckoutArticleContent from "../../../pages/CheckoutPage/CheckoutArticleContent.tsx";

function _OrderRound({
  order_items,
  count_as_round,
  header,
  id,
}: {
  order_items: OrderArticle[];
  count_as_round: boolean;
  header: JSX.Element;
  id: React.Key;
}) {
  return (
    <Box key={id}>
      <Box
        sx={(theme) => ({
          width: 1,
          display: "flex",
          alignItems: "baseline",
          justifyContent: "space-between",
          ...(count_as_round
            ? {
                borderBottom: `2px solid ${theme.palette.primary.main}`,
                color: theme.palette.primary.main,
              }
            : {
                borderBottom: `1px solid ${theme.palette.primary.light}`,
                color: theme.palette.primary.light,
              }),
          mb: 1,
        })}
      >
        {header}
      </Box>
      {order_items.map((orderArticle) => {
        return <CheckoutArticleContent orderArticle={orderArticle} key={orderArticle.article.id} />;
      })}
      <Box sx={{ mt: 4 }} />
    </Box>
  );
}

export const OrderRound = memo(_OrderRound);
