import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";
import { Button } from "@mui/material";
import { useGoToHomeKiosk } from "./ReturnPage.tsx";
import { useCountDownTimer } from "../../../global/components/CountDownTimer.tsx";

export default function StopButton() {
  const goToHome = useGoToHomeKiosk();
  const { timer } = useCountDownTimer(30, () => {
    goToHome();
  });

  return (
    <Button
      sx={{ paddingX: 8, paddingY: 4, color: "white", height: 1, width: 1 }}
      variant={"contained"}
      color={"error"}
      size={"large"}
      onClick={() => {
        goToHome();
      }}
    >
      <span>
        <FormattedMessageJamezz id={"Stop"} /> ({timer})
      </span>
    </Button>
  );
}
