import { Grid, Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import useCurrency, { CurrencyLocation } from "../../../global/utils/useCurrency";

import { AfterPayArticle } from "./AfterPayPage";

interface Props {
  orderArticle: AfterPayArticle;
}

export default function AfterPayArticleContent(props: Props) {
  const toCurrency = useCurrency({ location: CurrencyLocation.Checkout });

  const totalPrice = props.orderArticle.price;

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          margin: 1,
          position: "relative",
          borderRadius: 3,
          padding: 1,
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", flex: "1 1 0%" }}>
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Typography sx={{ fontWeight: 800, flex: "1 1 0%" }}>
              {props.orderArticle.qty}x {props.orderArticle.name}
            </Typography>

            {totalPrice !== 0 && <Typography sx={{ fontWeight: 800 }}>{toCurrency(totalPrice)}</Typography>}
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
}
