import { Link } from "@mui/material";
import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import AyceAlmostOverNotificationTimer from "./AyceAlmostOverNotificationTimer.tsx";
import AyceCleanupService from "./AyceCleanupService.tsx";
import { useEffect } from "react";
import { toast } from "react-toastify";

export function AyceReactService() {
  const enabled = useAppSelector((state) => state.global.salesarea.use_arrangements);
  const demo_mode = useAppSelector((state) => state.global.salesarea.arrangements_demo);
  const mid = useAppSelector((state) => state.global.webcode);

  useEffect(() => {
    if (enabled && demo_mode) {
      toast.info(
        <Link
          href={`/v5_2/arrangements/demo/${mid}`}
          target="_blank"
          sx={{
            color: "white",
            fontSize: "1.1em",
          }}
        >
          Arrangements demo mode!
        </Link>,
        {
          toastId: "arrangements.demo_mode",
        }
      );
    }
  }, [demo_mode, enabled]);

  return enabled ? (
    <>
      <AyceCleanupService />
      <AyceAlmostOverNotificationTimer />
    </>
  ) : null;
}
