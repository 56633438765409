import { postAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics.ts";
import React, { useMemo } from "react";
import SlideUpTransition from "../../../global/components/SlideUpTransition.tsx";
import { ReceiptLong } from "@mui/icons-material";
import { AYCERoundsDialog } from "../Arrangements/AYCERoundsDialog.tsx";
import { useDialog } from "../../../global/utils/dialog/DialogProvider.tsx";
import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { selectActiveArrangement } from "../../../global/utils/redux/arrangements/selectActiveArrangement.tsx";
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useIntl } from "react-intl";
import clsx from "clsx";

export default function useAyceOrderedRoundsButton() {
  const { openDialog } = useDialog();
  const isAyce = useAppSelector((state) => state.global.salesarea.use_arrangements);
  const arrangement = useAppSelector(selectActiveArrangement);
  const intl = useIntl();

  return useMemo(
    () =>
      isAyce && arrangement?.variant === "regular" ? (
        <ListItem className={clsx("JS-SideMenuDrawer-ListItem")} key={"Ayce"} disablePadding>
          <ListItemButton
            data-cy={"ayce-ordered-rounds-open-dialog"}
            onClick={() => {
              postAnalyticsEvent({
                category: "JamezzShowOrderButton",
                action: "AYCERoundsDialog",
              });
              openDialog({
                children: <AYCERoundsDialog />,
                fullScreen: true,
                TransitionComponent: SlideUpTransition,
              });
            }}
          >
            <ListItemIcon className={"JS-SideDrawerMenu-ItemIcon"}>
              <ReceiptLong />
            </ListItemIcon>
            <ListItemText primary={intl.formatMessage({ id: "AYCE.fab.open-rounds-dialog" })} />
          </ListItemButton>
        </ListItem>
      ) : null,
    [isAyce, arrangement?.variant, intl, openDialog]
  );
}
