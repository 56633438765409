import { useWindowSize } from "react-use";
import { Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../../../global/components/FormattedMessageJamezz.tsx";
import Confetti from "react-confetti";

export default function Birthday({ firstname }: { firstname: string }) {
  const { width, height } = useWindowSize();
  return (
    <>
      <Typography sx={{ textAlign: "center" }}>
        <FormattedMessageJamezz id={"Happy birthday {firstname}"} values={{ firstname }} />
        🥳
      </Typography>
      <Confetti
        width={width}
        height={height}
        style={{ position: "fixed" }}
        recycle={false}
        gravity={0.1}
        numberOfPieces={800}
      />
      {/*<Typography sx={{ letterSpacing: 16 }}></Typography>*/}
    </>
  );
}
