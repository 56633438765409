import { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { Alert, Button, Dialog, Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { EventsType, useIdleTimer } from "react-idle-timer";
import { useAppSelector } from "../../../global/utils/redux/store";
import { useGoToHomeKiosk } from "../../pages/ReturnPage/ReturnPage";
import { usePreventKioskTimeoutActive } from "../../../global/modules/PreventKioskTimeout/preventKioskTimeout.ts";

const activeEvents: EventsType[] = [
  "mousemove",
  "keydown",
  "touchstart",
  "touchmove",
  "MSPointerDown",
  "MSPointerMove",
];

function TimeoutDialog() {
  const [open, setOpen] = useState(false);
  const refreshTimeout = useAppSelector((state) => state.global.salesarea?.refreshTimeout);
  const liveEditEnabled = useAppSelector((state) => state.dev.liveEditModeEnabled);
  const gotoHome = useGoToHomeKiosk();
  const isBlocked = usePreventKioskTimeoutActive();
  const timeout = useMemo(() => {
    if (isBlocked) {
      return 2 ** 30;
    }

    if (import.meta.env.MODE === "development" || liveEditEnabled) {
      return Math.max(30_000, Math.min(2 ** 30, refreshTimeout * 1_000_000));
    } else {
      return Math.min(2 ** 30, Math.max(30_000, refreshTimeout * 1_000));
    }
  }, [refreshTimeout, liveEditEnabled, isBlocked]);

  // this timer opens the popup shown on the kiosk "10 seconds left until restart"
  useIdleTimer({
    events: activeEvents,
    onIdle: () => setOpen(true),
    onActive: () => setOpen(false),
    timeout,
  });

  // this timer actually restarts the kiosk
  const restartTimer = useIdleTimer({
    events: activeEvents,
    onIdle: () => gotoHome(),
    timeout: timeout + 10_000,
  });

  if (!open) {
    return null;
  }
  return (
    <Dialog
      open={open}
      sx={{
        width: 1,
        zIndex: 2_000,
      }}
    >
      <Alert
        severity={"error"}
        sx={{
          fontSize: 80,
          "& .MuiAlert-icon": { fontSize: 80 },
        }}
      >
        <Typography sx={{ fontSize: 32 }}>
          <Message idleTimer={restartTimer} setOpen={setOpen} />
        </Typography>
      </Alert>
      <Box
        sx={{
          display: "flex",
          width: 1,
          justifyContent: "center",
          paddingY: 8,
          backgroundColor: "rgb(253, 237, 237)",
        }}
      >
        <Button variant={"contained"}>
          <FormattedMessageJamezz id={"Continue ordering"} />
        </Button>
      </Box>
    </Dialog>
  );
}

function Message(props: { idleTimer: any; setOpen: any }) {
  const idleTimer = props.idleTimer;
  const [seconds, setSeconds] = useState(Math.ceil(idleTimer.getRemainingTime() / 1000));

  useEffect(() => {
    const interval = () => {
      setSeconds(Math.ceil(idleTimer.getRemainingTime() / 1000));
      window.requestAnimationFrame(interval);
    };
    window.requestAnimationFrame(interval);
  }, [idleTimer, props]);

  return (
    <FormattedMessageJamezz
      id={"Your session will logout if no activity is registered after {seconds} seconds."}
      values={{ seconds: Math.max(0, seconds) }}
    />
  );
}

export default TimeoutDialog;
