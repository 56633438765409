import { useDialog } from "../utils/dialog/DialogProvider";
import store from "../utils/redux/store";
import SlideUpTransition from "./SlideUpTransition";

import { useCustomHtmlTranslations } from "../utils/useCustomTexts";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import useVouchers from "../utils/useVouchers";
import { toast } from "react-toastify";
import FormattedMessageJamezz from "./FormattedMessageJamezz";

enum BetweenOrderPageResponse {
  CONFIRM,
  CANCEL,
  NOT_SHOWN,
}

function BetweenOrderPage({ onAnswer }: { onAnswer: (confirmed: BetweenOrderPageResponse) => void }) {
  const texts = useCustomHtmlTranslations([
    "betweenOrderOkText",
    "betweenOrderContent",
    "betweenOrderCancelText",
    "betweenOrderTitle",
  ]);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        padding: 2,
        gap: 2,
        gridTemplateAreas: `
      'title title' 
      'body body' 
      'yes no' 
    `,
      }}
    >
      <Typography variant="h2" fontSize="1.5em" sx={{ gridArea: "title" }}>
        {texts["betweenOrderTitle"] ?? "Text missing"}
      </Typography>
      <div
        style={{ gridArea: "body" }}
        dangerouslySetInnerHTML={{ __html: (texts["betweenOrderContent"] as string) ?? "Text missing" }}
      />
      <Button
        sx={{ gridArea: "yes" }}
        variant="contained"
        color="success"
        onClick={() => onAnswer(BetweenOrderPageResponse.CONFIRM)}
      >
        {texts["betweenOrderOkText"] ?? "Text missing"}
      </Button>
      <Button sx={{ gridArea: "no" }} variant="contained" onClick={() => onAnswer(BetweenOrderPageResponse.CANCEL)}>
        {texts["betweenOrderCancelText"] ?? "Text missing"}
      </Button>
    </Box>
  );
}

export function useBetweenOrderPage() {
  const { openDialog, closeDialog } = useDialog();
  const checkVoucherCode = useVouchers();
  return function () {
    const shouldShow = store.getState().global.salesarea.betweenOrderPage.enabled;
    const voucher = store.getState().global.salesarea.betweenOrderPage.voucherCode;

    if (!shouldShow) {
      return new Promise<BetweenOrderPageResponse>((resolve) => resolve(BetweenOrderPageResponse.NOT_SHOWN));
    } else {
      return new Promise<BetweenOrderPageResponse>((resolve) => {
        openDialog({
          children: (
            <BetweenOrderPage
              onAnswer={async (answer) => {
                closeDialog();
                if (answer === BetweenOrderPageResponse.CONFIRM && voucher) {
                  try {
                    await checkVoucherCode(voucher, 1);
                    toast.success(<FormattedMessageJamezz id={"Voucher.messages.added-successfully"} />);
                  } catch (e: any) {
                    if (e.result.message) {
                      toast.error(<FormattedMessageJamezz id={e.result.message} />);
                    } else {
                      toast.error(<FormattedMessageJamezz id="Unknown error" />);
                    }
                  }
                }
                resolve(answer);
              }}
            />
          ),
          disabledBackdropClick: true,
          fullScreen: false,
          TransitionComponent: SlideUpTransition,
        });
      });
    }
  };
}
