import { useCallback, useState } from "react";

export function useCountdown(countdownInMs: number) {
  const [timer, setTimer] = useState<number>(0);
  const startCountDown = useCallback(
    function startCountDown() {
      const target = +new Date() + countdownInMs;

      return new Promise<void>((resolve) => {
        const tick = () => {
          const now = +new Date();
          setTimer(target - now);
          if (target - now >= 0) {
            window.requestAnimationFrame(tick);
          } else {
            resolve();
          }
        };

        window.requestAnimationFrame(tick);
      });
    },
    [countdownInMs]
  );

  return {
    startCountDown,
    timer,
    finished: timer < 0,
  };
}
