import { PropsWithChildren } from "react";
import Box from "@mui/material/Box";

export default function TopBar(props: PropsWithChildren<any>) {
  return (
    <Box position={"sticky"} className={"JS-TopBar-AppBar"}>
      {props.children}
    </Box>
  );
}
