import OrderOptionGroup from "../../../global/utils/models/order/OrderOptionGroup";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import _ from "lodash";
import { getArticleName } from "../../../global/utils/models/menu/Article";
import { useOptionTranslations } from "../../../global/utils/translations/useOptionTranslations";
import PrintOptionOrderArticle from "./PrintOptionOrderArticle";
import { useAppSelector } from "../../../global/utils/redux/store";
import { selectAppLanguage } from "../../../kiosk/components/LanguageSelector/useLanguage";

export default function PrintOrderOptionGroup({ orderOptionGroup }: { orderOptionGroup: OrderOptionGroup }) {
  const lang = useAppSelector(selectAppLanguage);
  const { translate } = useOptionTranslations();

  return (
    <>
      {orderOptionGroup.orderArticles
        .filter((orderArticle) => orderArticle.count > 0)
        .map((orderArticle) => {
          return (
            <>
              <Box
                key={orderArticle.uuid}
                className={"JS-OrderArticleContent-Option-GridItem"}
                sx={{ display: "inline-flex" }}
              >
                {orderArticle.count !== 1 ? (
                  <Typography
                    className={"JS-OrderArticleContent-Option-TextCount"}
                    sx={{
                      marginRight: 1,
                      fontSize: "0.8rem",
                      opacity: 0.6,
                    }}
                  >
                    {orderArticle.count}x
                  </Typography>
                ) : null}
                <Typography
                  sx={{ fontSize: "0.8rem", opacity: 0.6, paddingRight: 1 }}
                  className={"JS-OrderArticleContent-Option-TextName"}
                >
                  {!_.isEmpty(orderArticle.article.translations)
                    ? getArticleName(orderArticle.article, lang)
                    : translate(orderArticle.article.name)}
                </Typography>
              </Box>
              {orderArticle.extraOrderArticles?.map((orderArticle) => (
                <PrintOptionOrderArticle
                  orderArticle={orderArticle}
                  count={orderArticle.count}
                  key={orderArticle.uuid}
                />
              ))}

              {orderArticle.orderOptionGroups.map((orderOptionGroup) => (
                <PrintOrderOptionGroup orderOptionGroup={orderOptionGroup} key={orderOptionGroup.id} />
              ))}
            </>
          );
        }, [])}
    </>
  );
}
