import { useRef, useState } from "react";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";

import ISO from "iso-639-1";
import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { selectAppLanguage, selectAvailableLanguages, setLanguage } from "../LanguageSelector/useLanguage.ts";
import { postAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics.ts";
import JTooltip from "../JamezzMUI/JTooltip.tsx";
import { languageToCountry } from "../LanguageSelector/LanguageSelector.tsx";
import clsx from "clsx";
export default function LanguageButton() {
  const languages = useAppSelector(selectAvailableLanguages);
  const selectedLanguage = useAppSelector(selectAppLanguage);

  const anchor = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Menu
        open={open}
        anchorEl={anchor.current}
        onClose={() => {
          setOpen(false);
        }}
      >
        {languages.map((language) => (
          <MenuItem
            data-cy={`language-item-${language}`}
            key={language}
            onClick={() => {
              postAnalyticsEvent({
                category: "setSelectedLanguage",
                action: language,
              });
              setLanguage(language);
              setOpen(false);
            }}
          >
            <JTooltip title={ISO.getName(language)}>
              <Box>
                <span
                  style={{ width: 72, height: 72 }}
                  className={"JS-LanguageFlag-" + language + " fi fi-" + languageToCountry(language).code.toLowerCase()}
                ></span>
              </Box>
            </JTooltip>
          </MenuItem>
        ))}
      </Menu>

      {languages.length > 1 ? (
        <JTooltip title={"Show menu in particular language"}>
          <IconButton
            data-cy="language-button"
            ref={anchor}
            onClick={(e) => {
              e.stopPropagation();
              setOpen(true);
            }}
          >
            <span
              style={{ width: 48 }}
              className={clsx(
                "JS-LanguageFlag-icon",
                "JS-LanguageFlag-" + selectedLanguage,
                "fi",
                "fi-" + languageToCountry(selectedLanguage).code.toLowerCase()
              )}
            ></span>
          </IconButton>
        </JTooltip>
      ) : null}
    </>
  );
}
