import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import { Box, ButtonBase, Card, CardContent, Typography } from "@mui/material";
import Image from "../../../global/components/Image";

import SlideUpTransition from "../../../global/components/SlideUpTransition";
import ClaimRewardDialog from "./ClaimRewardDialog";
import { useAppSelector } from "../../../global/utils/redux/store";
import { ComponentOverlay } from "../../../global/components/ComponentOverlay";
import _ from "lodash";
import LockIcon from "@mui/icons-material/Lock";
import CheckIcon from "@mui/icons-material/Check";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { MWiseAPIReward, MWiseReward, MWiseRewardVisualStatus } from "../../../types/mwise";
import isQr from "../../../global/utils/helpers/isQr";
import useIsWidescreen from "../../../global/utils/useIsWidescreen";

export default function MWiseExtraCard({ reward }: { reward: MWiseReward | MWiseAPIReward }) {
  const chosenRewardId = useAppSelector((state) => state.mwise.claimedReward?.oracleDiscountId);
  const { openDialog } = useDialog();
  const isWidescreen = useIsWidescreen();
  const isChosenReward = chosenRewardId === ("OracleProduct" in reward && reward.OracleProduct.discountId);

  const visualStatusIcon = _.cond([
    [_.matches(MWiseRewardVisualStatus.LockedAndVisible), _.constant(<LockIcon />)],
    [_.matches(MWiseRewardVisualStatus.Used), _.constant(<CheckIcon />)],
    [_.matches(MWiseRewardVisualStatus.Expired), _.constant(<ScheduleIcon />)],
  ]);

  return (
    <ComponentOverlay
      disabled={reward.VisualStatus === MWiseRewardVisualStatus.Valid}
      overlayContent={<>{visualStatusIcon(reward.VisualStatus)}</>}
    >
      <ButtonBase
        onClick={() => {
          if ("OracleProduct" in reward)
            openDialog({
              fullScreen: !(isQr() && isWidescreen),
              TransitionComponent: SlideUpTransition,
              children: <ClaimRewardDialog reward={reward} />,
            });
        }}
      >
        <Card
          className="JS-MWiseReward-Card"
          sx={{
            width: "320px",
            "& *": {
              userSelect: "none",
            },
          }}
        >
          <ComponentOverlay
            disabled={!isChosenReward}
            overlayContent={<Typography variant="h6">Je hebt deze reward gekozen</Typography>}
          >
            <CardContent sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", gap: 1 }}>
              {reward.ImageUrl ? (
                <Image
                  src={reward.ImageUrl}
                  style={{ maxHeight: 84, minHeight: 84, maxWidth: "100%", objectFit: "cover", alignSelf: "center" }}
                />
              ) : (
                <Box style={{ maxHeight: 84, minHeight: 84 }} />
              )}
              <Typography sx={{ fontWeight: 800, alignSelf: "center" }} className="JS-MWiseReward-Card-Name">
                {reward.Title}
              </Typography>
            </CardContent>
          </ComponentOverlay>
        </Card>
      </ButtonBase>
    </ComponentOverlay>
  );
}
