import { createSelector } from "@reduxjs/toolkit";

import _ from "lodash";
import { RootState } from "../../redux/store";

export const selectVouchersToBeRedeemedMap = createSelector(
  [(state: RootState) => state.vouchersV2.vouchers],
  (vouchers) => {
    return _.chain(vouchers).keyBy("voucher.code").value();
  }
);
