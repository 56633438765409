import { rtkQueryApi } from "./rtkQueryApi";
import _ from "lodash";
import { JamezzApiResponse } from "../../../../types/shared/api/common_types";
import { Packaging, PackagingType } from "../packagingsSlice";
import { OptionTranslations } from "../../models/menu/Article";

const packagingsEndpoints = rtkQueryApi.enhanceEndpoints({});

export interface PackagingResponse {
  id: string;

  name: string;
  translations: OptionTranslations;

  packaging_products: { id: number; packaging_id: number; product_id: number }[];
  menukaart_packagings: { id: number; packaging_id: number; menukaart_id: number }[];

  add_price_per_item: boolean;
  add_price_per_order: boolean;
  packaging_type_id: string;
  product_id: string;
}
export interface PackagingTypeResponse {
  id: string;

  name: string;
  translations: OptionTranslations;
  order_mode_takeaway_enabled: boolean;
  order_mode_eat_in_enabled: boolean;
  order_mode_delivery_enabled: boolean;
}
export type FetchPackagingsSettingsResponse = {
  data: {
    packagings: PackagingResponse[];
    packaging_types: PackagingTypeResponse[];
  };
} & JamezzApiResponse;

export const packagingsApi = packagingsEndpoints.injectEndpoints({
  endpoints: (builder) => ({
    fetchPackagingsSettings: builder.query<
      {
        packagings: Packaging[];
        packaging_types: PackagingType[];
      },
      void
    >({
      query: () => {
        return {
          url: `/v5_2/qr/packagings`,
          method: "GET",
        };
      },
      transformResponse: (baseQueryReturnValue: FetchPackagingsSettingsResponse, meta, arg) => {
        return {
          packagings: baseQueryReturnValue.data.packagings.map((packagingResponse) => ({
            id: packagingResponse.id,
            name: packagingResponse.name,
            packaging_type_id: packagingResponse.packaging_type_id,
            product_id: packagingResponse.product_id,
            add_price_per_item: packagingResponse.add_price_per_item,
            add_price_per_order: packagingResponse.add_price_per_order,
            productIds: _.chain(packagingResponse.packaging_products)
              .keyBy("product_id")
              .mapValues(() => true)
              .value(),
            menukaartIds: _.chain(packagingResponse.menukaart_packagings)
              .keyBy("menukaart_id")
              .mapValues(() => true)
              .value(),
            translations: packagingResponse.translations,
          })),

          packaging_types: baseQueryReturnValue.data.packaging_types.map((packagingTypeResponse) => ({
            id: packagingTypeResponse.id,
            name: packagingTypeResponse.name,
            translations: packagingTypeResponse.translations,
            order_mode_takeaway_enabled: packagingTypeResponse.order_mode_takeaway_enabled,
            order_mode_eat_in_enabled: packagingTypeResponse.order_mode_eat_in_enabled,
            order_mode_delivery_enabled: packagingTypeResponse.order_mode_delivery_enabled,
          })),
        };
      },
    }),
  }),
});

export const { useLazyFetchPackagingsSettingsQuery, useFetchPackagingsSettingsQuery } = packagingsApi;
