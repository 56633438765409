import { useEffect } from "react";
import { Button, DialogActions, DialogContent, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { selectOrderModeCustomField } from "../../../global/utils/redux/customFieldsSlice.ts";
import { selectAppLanguage } from "../LanguageSelector/useLanguage.ts";
import { useIntl } from "react-intl";
import { useCountdown } from "../../../global/utils/hooks/useCountdown.ts";
import { useDialog } from "../../../global/utils/dialog/DialogProvider.tsx";

export default function PleaseFirstMakeAChoiceDialog({ type }: { type: "NEED_TO_SELECT_ORDERMODE" | "START_ORDER" }) {
  const orderModeCustomField = useAppSelector(selectOrderModeCustomField);
  const { closeDialog } = useDialog();
  const selectedLanguage = useAppSelector(selectAppLanguage);
  const intl = useIntl();
  const { timer: closeTimer, startCountDown } = useCountdown(6000);
  useEffect(() => {
    startCountDown().then(() => {
      closeDialog();
    });
  }, [closeDialog, startCountDown]);
  return (
    <>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography variant={"subtitle1"}>
              {type == "NEED_TO_SELECT_ORDERMODE" ? (
                <>
                  <FormattedMessageJamezz
                    id={"Barcode: Please, first make a choice!"}
                    values={{
                      text: orderModeCustomField?.options
                        ?.map(
                          (orderMode) =>
                            '"' + (orderMode.translationOverwrite?.[selectedLanguage] ?? orderMode.label) + '"'
                        )
                        .join(" " + intl.formatMessage({ id: "or" }) + " "),
                    }}
                  />
                </>
              ) : (
                <FormattedMessageJamezz id={"Barcode: You must start an order before you can use the scanner."} />
              )}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          className="JS-PiggyScanDialog-LaterButton"
          variant="contained"
          onClick={() => {
            closeDialog();
          }}
          data-cy="piggy-scan-card-dialog-dismiss-btn"
          sx={{ borderRadius: "16px" }}
        >
          <FormattedMessageJamezz
            id="Piggy.ScanLoyaltyCardDialog.closingIn"
            values={{
              seconds: Math.ceil(closeTimer / 1000),
            }}
          />
        </Button>
      </DialogActions>
    </>
  );
}
