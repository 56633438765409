import { useCallback, useEffect } from "react";
import { useScroll } from "../../../../global/utils/ScrollProvider";
import Articlegroup from "../../../../global/utils/models/menu/Articlegroup";
// @ts-ignore
import scrollIntoView from "scroll-into-view";
import { throttle } from "lodash";
import { setSelectedArticlegroup } from "../../../../global/utils/redux/menuSlice";
import store from "../../../../global/utils/redux/store";

interface Props {
  articlegroups: Articlegroup[];
}

export default function OnScrollContent(props: Props) {
  const articlegroups = props.articlegroups;
  const { scrollContent, scrollTabBlocked } = useScroll();

  const onScrollArticlegroupContent = useCallback(
    throttle(() => {
      if (scrollTabBlocked.current) {
        return;
      }
      // console.log("NOT BLOCKED");
      let articlegroupIndex = null;

      articlegroups?.some((articlegroup, index) => {
        const element = document.getElementById("Articlegroup-" + articlegroup.id);
        if (element) {
          // console.log(articlegroup.name);
          // console.log(element.getBoundingClientRect().y);
          // console.log(element.getBoundingClientRect().top);
          if (element.getBoundingClientRect().y > 120) {
            if (element.getBoundingClientRect().top < window.innerHeight) {
              articlegroupIndex = index;
            } else {
              articlegroupIndex = Math.max(0, index - 1);
            }
            return true;
          }
        }
        return false;
      });
      if (articlegroupIndex == null && articlegroups?.length > 0) {
        articlegroupIndex = articlegroups.length - 1;
      }

      if (articlegroupIndex != null) {
        const articlegroup = articlegroups[articlegroupIndex];

        if (
          store.getState().global.articleSearchText === "" &&
          store.getState().menu.selectedArticlegroup?.id !== articlegroup?.id
        ) {
          store.dispatch(setSelectedArticlegroup({ articlegroup }));
        }

        const element = document.getElementById("ArticlegroupTab-" + articlegroup.id);
        if (element) {
          scrollIntoView(element, {
            time: 500,
            align: {
              left: 0.5,
              // top: 0.5,
            },
            validTarget: (target: any) => {
              return (
                target.classList?.contains("MuiTabs-scroller") ||
                target.classList?.contains("JS-HorizontalArticlegroupTabBar-Root")
              );
            },
          });
        }
      }
    }, 500),
    [scrollContent, props.articlegroups]
  );

  useEffect(() => {
    const reference = scrollContent.current;
    if (reference) {
      reference.addEventListener("scroll", onScrollArticlegroupContent);
    }
    return () => {
      if (reference) {
        reference.removeEventListener("scroll", onScrollArticlegroupContent);
      }
    };
  }, [onScrollArticlegroupContent, scrollContent]);
  return null;
}
