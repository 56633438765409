import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import PiggyRewards from "./PiggyRewards.tsx";
import { usePiggyUser } from "../../../utils/piggy/piggy.tsx";
import Slide from "@mui/material/Slide";
import "./shine.css";
import { LoyaltyCard } from "./LoyaltyCard/LoyaltyCard.tsx";
import PiggyVouchers from "../Vouchers/PiggyVouchers.tsx";

export default function PiggyLoyaltyContent() {
  const { piggyUser } = usePiggyUser();

  return (
    <Slide in={Boolean(piggyUser)} direction="down" mountOnEnter unmountOnExit>
      <Stack sx={{ width: 1, my: 4 }} alignItems={"center"} direction={"column"} gap={6}>
        <Box sx={{ width: 600 }}>
          <LoyaltyCard />
        </Box>
        <Box sx={{ width: 1 }}>
          <PiggyRewards showBalance={false} />
        </Box>
        <Box sx={{ width: 1 }}>
          <PiggyVouchers />
        </Box>
      </Stack>
    </Slide>
  );
}
