import OrderArticle, {
  findInvalidOrderOptionGroup,
  getTotalPrice,
} from "../../../global/utils/models/order/OrderArticle.ts";
import { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store.tsx";
import { useDialog } from "../../../global/utils/dialog/DialogProvider.tsx";
import useAddOrderArticleToShoppingCart from "../../../global/utils/order/useAddOrderArticleToShoppingCart.tsx";
import { useMemo } from "react";
import useCurrency, { CurrencyLocation } from "../../../global/utils/useCurrency.tsx";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { orderArticlesPushedByUser, setInvalidOptionGroup } from "../../../global/utils/redux/shoppingCartSlice.tsx";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";
// @ts-ignore
import scrollIntoView from "scroll-into-view";
import { selectSalesAreaPriceLineId } from "../../../global/utils/useSalesAreaPriceLineId.ts";

export function AddArticleToOrderButton({
  isScrollable,
  orderArticle,
  modifyingOrderArticle,
  callAfterAdding,
}: {
  isScrollable: boolean;
  orderArticle: OrderArticle;
  modifyingOrderArticle?: OrderArticle;
  callAfterAdding?: () => void;
}) {
  const dispatch = useAppDispatch();
  const { closeDialog } = useDialog();
  const addOrderArticleToShoppingCart = useAddOrderArticleToShoppingCart();
  const priceLineId = useAppSelector(selectSalesAreaPriceLineId);
  const totalPrice = useMemo(() => getTotalPrice(orderArticle, priceLineId), [orderArticle]);

  const invalidOrderOptionGroup = useMemo(() => {
    if (orderArticle) {
      return findInvalidOrderOptionGroup(orderArticle);
    } else {
      return null;
    }
  }, [orderArticle]);

  const toCurrency = useCurrency({ location: CurrencyLocation.AddToOrderButton });

  return (
    <Box
      sx={(theme) => ({
        position: "sticky",
        bottom: 0,
        background: isScrollable
          ? theme.palette.mode === "light"
            ? "linear-gradient(0deg, rgba(255,255,255,0.8) 0%, rgba(100,100,100,0.0) 100%)"
            : "linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(100,100,100,0.0) 100%)"
          : null,
        width: 1,
        textAlign: "center",
        pointerEvents: "none",
      })}
    >
      <Button
        className="JS-OrderArticleSelectorPage-AddToOrderButton"
        disabled={!orderArticle.article.canOrder}
        sx={{
          marginX: 1,
          marginBottom: 4,
          marginTop: 0,
          paddingY: 2,
          pointerEvents: "auto",
          // background: invalidOrderOptionGroup ? "lightgrey" : undefined,
        }}
        variant={"contained"}
        data-cy="page-order-content-btn-add-to-order"
        onClick={async () => {
          if (invalidOrderOptionGroup) {
            dispatch(setInvalidOptionGroup({ orderOptionGroup: invalidOrderOptionGroup, invalid: true }));
            const element = document.getElementById(invalidOrderOptionGroup.id);
            if (element) {
              scrollIntoView(
                element,
                {
                  time: 1000,
                  align: {
                    top: 0,
                    topOffset: 56, // margin
                  },
                  validTarget: (target: HTMLElement) => {
                    return target.classList?.contains("MuiPaper-root");
                  },
                },
                () => {}
              );
            }
          } else if (!modifyingOrderArticle) {
            try {
              await addOrderArticleToShoppingCart(orderArticle);
              closeDialog();
              if (orderArticle.isUpselled) {
                closeDialog();
              }
              callAfterAdding?.();
            } catch (err) {
              console.log(err);
            }
          } else {
            closeDialog();
            dispatch(
              orderArticlesPushedByUser([
                { ...modifyingOrderArticle, count: -modifyingOrderArticle.count },
                orderArticle,
              ])
            );
            callAfterAdding?.();
          }
        }}
      >
        {modifyingOrderArticle ? <FormattedMessageJamezz id="Change" /> : <FormattedMessageJamezz id="Add to order" />}
        {totalPrice != 0 ? <>&nbsp;•&nbsp;{toCurrency(totalPrice)}</> : null}
      </Button>
    </Box>
  );
}
