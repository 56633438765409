import { FloatingCloseDialogButton } from "../../../components/Dialog/CloseDialogButton.tsx";
import { Button, Collapse, Stack, Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz.tsx";
import Box from "@mui/material/Box";
import OrderArticleContent from "../../OrderPage/OrderArticleContent.tsx";
import { useDialog } from "../../../../global/utils/dialog/DialogProvider.tsx";
import store, { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import Article from "../../../../global/utils/models/menu/Article.ts";
import { groupBy } from "lodash";
import { useEffect, useMemo } from "react";
import { TransitionGroup } from "react-transition-group";

export default function QuickRemoveOrderArticleList({ article }: { article: Article }) {
  const { closeDialog } = useDialog();

  const items = useAppSelector((state) => state.shoppingCart.items);

  const initOrderArticles = useMemo(() => {
    const items = store.getState().shoppingCart.items;
    return groupBy(items, "article.id")[article.id] ?? [];
  }, [article]);

  const orderArticlesMap = useMemo(() => {
    return groupBy(groupBy(items, "article.id")[article.id] ?? [], "uuid");
  }, [items, article]);

  const orderArticles = initOrderArticles.map((orderArticle) => ({
    ...orderArticle,
    count: orderArticlesMap[orderArticle.uuid]?.[0]?.count ?? 0,
  }));

  useEffect(() => {
    if (initOrderArticles.length === 0) {
      closeDialog();
    }
  }, [initOrderArticles, closeDialog]);
  return (
    <Box data-cy="quick-orderArticle-remove-list-page" sx={{ mb: 4 }}>
      <FloatingCloseDialogButton />
      <FloatingCloseDialogButton visibility={"collapse"} float={"right"} />

      <Typography
        variant={"h5"}
        sx={{ fontStyle: "italic", fontWeight: 800, marginY: 2, width: 1, textAlign: "center" }}
      >
        <FormattedMessageJamezz id={"Change"} />
      </Typography>

      <Box
        sx={{
          overflowY: "auto",
          width: 1,
          height: 1,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingX: 4,
        }}
      >
        <Stack spacing={2} sx={{ width: 1 }} alignItems={"center"}>
          <TransitionGroup style={{ width: "100%" }} component={null}>
            {orderArticles.map((orderArticle) => (
              <Collapse sx={{ width: 1 }}>
                <OrderArticleContent
                  key={orderArticle.uuid}
                  orderArticle={orderArticle}
                  canChangeOrderArticle={false}
                />
              </Collapse>
            ))}
          </TransitionGroup>
          <Button
            sx={{ marginY: 4, marginBottom: 8, width: 1, maxWidth: 300 }}
            variant={"contained"}
            onClick={() => {
              closeDialog();
            }}
          >
            <FormattedMessageJamezz id={"Continue ordering"} />
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}
