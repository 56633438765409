import { useCallback } from "react";

import Article from "../models/menu/Article";
import { getArticleById } from "../models/menu/Menu";
import SlideUpTransition from "../../components/SlideUpTransition";
import { useDialog } from "../dialog/DialogProvider";
import useMenuFilters from "../menu/filters/useMenuFilters";
import store, { useAppSelector } from "../redux/store";
import { ArticleSuggestionPageProps } from "../../../qr/pages/ArticleSuggestionPage/ArticleSuggestionPage";
import { OrderArticleOrigin } from "../models/order/OrderArticle";
import { getTranslations } from "../api/parseApis/useParseApiArticle";
import useIsWidescreen from "../useIsWidescreen";
import { selectArticlesMap } from "../redux/selectors/selectArticlesMap";
import { selectAppLanguage } from "../../../kiosk/components/LanguageSelector/useLanguage";
import isKiosk from "../helpers/isKiosk.tsx";

export enum UpsellType {
  upsellNoFood = "upsellNoFood",
  upsellNoDrinks = "upsellNoDrinks",
}

export default function useUpsellNoFoodOrNoDrinksInShoppingCart(upsellType: UpsellType) {
  const { openDialog } = useDialog();
  const articleFilter = useMenuFilters();
  const isWidescreen = useIsWidescreen();

  const isActiveGlobal = useAppSelector((state) => state.global.salesarea?.upsellSettings?.is_active);
  const isActive = useAppSelector((state) => state.global.salesarea?.upsellSettings?.[upsellType]?.is_active);
  const title = useAppSelector((state) => state.global.salesarea?.upsellSettings?.[upsellType]?.title);
  const translations = useAppSelector((state) => state.global.salesarea?.upsellSettings?.[upsellType]?.translations);
  const lang = useAppSelector(selectAppLanguage);
  const text = useAppSelector((state) => state.global.salesarea?.upsellSettings?.[upsellType]?.text);
  const articleIds = useAppSelector((state) => state.global.salesarea?.upsellSettings?.[upsellType]?.productIds);

  return {
    isActive: isActiveGlobal && isActive,
    openUpsell: useCallback(
      (ArticleSuggestionPage: React.FC<ArticleSuggestionPageProps>) => {
        if (isActive && isActiveGlobal) {
          if (articleIds) {
            const menuItems = store
              .getState()
              .shoppingCart.items.filter((item) => item.added_origin === OrderArticleOrigin.MENU);
            if (
              menuItems.length > 0 &&
              menuItems.every(
                (item) =>
                  (upsellType === UpsellType.upsellNoFood && item.article.isDrinksArticle) ||
                  (upsellType === UpsellType.upsellNoDrinks && item.article.isFoodArticle)
              )
            ) {
              let articles: Article[] = [];
              JSON.parse(articleIds).forEach((productId: number) => {
                const article = getArticleById(selectArticlesMap(store.getState()), String(productId));
                if (article) {
                  articles.push(article);
                }
              });
              articles = articles.filter(articleFilter);
              const validArticlesCount = articles.filter((article) => {
                return !(article.isOutOfStock || article.isNotAvailable);
              }).length;

              if (validArticlesCount > 0) {
                const titleKey = upsellType === UpsellType.upsellNoFood ? "noFoodTitel" : "noDrinksTitel";
                const textKey = upsellType === UpsellType.upsellNoFood ? "noFoodText" : "noDrinksText";
                openDialog({
                  children: (
                    <ArticleSuggestionPage
                      articles={articles}
                      upsellType={UpsellType.upsellNoFood ? "UpsellNoFood" : "UpsellNoDrinks"}
                      title={getTranslations(translations ?? {}, titleKey)?.[lang] ?? title}
                      text={getTranslations(translations ?? {}, textKey)?.[lang] ?? text}
                    />
                  ),
                  fullScreen: isKiosk() ? "almostFullScreen" : !isWidescreen,
                  TransitionComponent: SlideUpTransition,
                });
              }
            }
          }
        }
      },
      [
        isActive,
        isActiveGlobal,
        isWidescreen,
        articleIds,
        upsellType,
        articleFilter,
        openDialog,
        translations,
        lang,
        title,
        text,
      ]
    ),
  };
}
