import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import VerticalArticlegroupTabBar from "./ArticlegroupTab/VerticalArticlegroupTabBar.tsx";
import Box from "@mui/material/Box";
import ArticlegroupContent from "./ArticlegroupContent";
import WideShoppingCartButton from "./ShoppingCartButtons/WideShoppingCartButton";
import Articlegroup from "../../../global/utils/models/menu/Articlegroup";
import store, { useAppSelector } from "../../../global/utils/redux/store";
import { getArticlegroupsBySupergroupId } from "../../../global/utils/models/menu/Menu";
import ArticlegroupsShortcutPage from "../../components/ArticlegroupsShortcutPage/ArticlegroupsShortcutPage";
import useArticlegroups from "../../../global/utils/hooks/useArticlegroups";
import SmallShoppingCartButton from "./ShoppingCartButtons/SmallShoppingCartButton";
import { selectArticlesSum } from "../../../global/utils/redux/shoppingCartSlice";
import { selectArticlegroupsMap } from "../../../global/utils/redux/selectors/selectArticlegroupsMap";
import { selectSupergroupsMap } from "../../../global/utils/redux/selectors/selectSupergroupsMap";
import { selectArticlegroupChildrenMap } from "../../../global/utils/redux/selectors/selectArticlegroupChildrenMap";
import { setSelectedArticlegroup } from "../../../global/utils/redux/menuSlice.ts";
import { PiggyBottomToolbarView } from "./HorizontalMenuPage.tsx";
import BackgroundMediaUrlFromSalesarea from "../../hooks/useBackgroundMediaUrl.tsx";

const defaultWidthVerticalArticlegroupTab = 250;

export default function VerticalMenuPage() {
  const scrollingRef = useRef<HTMLElement>(null);
  const hasSelectedArticlegroup = useAppSelector((state) => state.menu.selectedArticlegroup != null);
  const articlegroupsMap = useAppSelector(selectArticlegroupsMap);
  const supergroupsMap = useAppSelector(selectSupergroupsMap);
  const articlegroupChildrenMap = useAppSelector(selectArticlegroupChildrenMap);

  const articlegroupsFound = useMemo<Articlegroup[]>(() => {
    let articlegroups: Articlegroup[] = [];
    Object.values(supergroupsMap).forEach((supergroup) => {
      articlegroups = articlegroups.concat(
        getArticlegroupsBySupergroupId(articlegroupsMap, articlegroupChildrenMap, supergroup.id)
      );
    });
    return articlegroups.sort((a: Articlegroup, b: Articlegroup) => a.sortKey - b.sortKey);
  }, [supergroupsMap, articlegroupsMap, articlegroupChildrenMap]);

  // const articlegroups: Articlegroup[] = useArticlegroups(getArticlegroupsById(articlegroupsMap, topArticlegroupIds));
  const articlegroups: Articlegroup[] = useArticlegroups(articlegroupsFound);
  const articlegroupsShortcutPage = useAppSelector((state) => state.global.salesarea.articlegroups_shortcut_page);
  const typeOfShoppingCartButton = useAppSelector((state) => state.global.salesarea?.type_of_shopping_cart_button);
  const verticalArticlegroupTabRef = useRef<any>(null);

  const [widthVerticalArticlegroupTab, setWidthVerticalArticlegroupTab] = useState(defaultWidthVerticalArticlegroupTab);

  const recalculateWidthVerticalArticlegroupTab = useCallback(() => {
    setWidthVerticalArticlegroupTab(
      verticalArticlegroupTabRef.current?.getBoundingClientRect().width ?? defaultWidthVerticalArticlegroupTab
    );
  }, []);

  useEffect(() => {
    if (verticalArticlegroupTabRef.current) {
      recalculateWidthVerticalArticlegroupTab();
    }
  }, [recalculateWidthVerticalArticlegroupTab]);

  const countBiggerThanZero = useAppSelector((state) => selectArticlesSum(state) > 0);

  const pixelHeightCartButton = "200px";

  useEffect(() => {
    if (!hasSelectedArticlegroup && articlegroups[0] && !articlegroupsShortcutPage) {
      store.dispatch(setSelectedArticlegroup({ articlegroup: articlegroups[0] }));
    }
  }, [articlegroups, articlegroupsShortcutPage, hasSelectedArticlegroup]);

  return (
    <>
      <VerticalArticlegroupTabBar
        articlegroups={articlegroups}
        verticalArticlegroupTabRef={verticalArticlegroupTabRef}
        width={widthVerticalArticlegroupTab}
      />
      <BackgroundMediaUrlFromSalesarea
        keyName={"extra_settings__kioskv5_background_of_menu_page_content"}
        data-cy={"menu"}
        className={"JS-MenuArticleGroups-Root"}
        sx={{
          flex: "1 1 0%",
          height: 1,
          width: 1,
          overflowY: "auto",
          overflowX: "none",
          position: "relative",
        }}
        sxWrapper={{ flex: "1 1 0%", height: 1, width: 0 }}
        innerRef={scrollingRef}
      >
        {!hasSelectedArticlegroup && articlegroupsShortcutPage ? (
          <ArticlegroupsShortcutPage />
        ) : hasSelectedArticlegroup ? (
          <>
            <ArticlegroupContentWrapper
              pixelHeightCartButton={pixelHeightCartButton}
              scrollingRef={scrollingRef.current ? scrollingRef : undefined}
            />
          </>
        ) : null}

        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            zIndex: 30,
            width: 1,
            display: "grid",
            gridTemplateAreas: "'a b c'",
            gridTemplateColumns: "1fr 250px 1fr",
            gridTemplateRows: "auto",
          }}
        >
          {!(countBiggerThanZero && typeOfShoppingCartButton === "WIDE") ? (
            <PiggyBottomToolbarView width={370} position={"right"} />
          ) : null}
          {countBiggerThanZero ? (
            typeOfShoppingCartButton === "WIDE" ? (
              <WideShoppingCartButton pixelHeightCartButton={pixelHeightCartButton} />
            ) : typeOfShoppingCartButton === "SMALL" ? (
              <SmallShoppingCartButton pixelHeightCartButton={pixelHeightCartButton} />
            ) : null
          ) : null}
        </Box>
      </BackgroundMediaUrlFromSalesarea>
    </>
  );
}

function ArticlegroupContentWrapper({
  scrollingRef,
  pixelHeightCartButton,
}: {
  scrollingRef?: RefObject<HTMLElement>;
  pixelHeightCartButton?: string;
}) {
  const selectedArticlegroup = useAppSelector((state) => state.menu.selectedArticlegroup);
  if (selectedArticlegroup) {
    return (
      <ArticlegroupContent
        key={selectedArticlegroup.id}
        articlegroup={selectedArticlegroup}
        menuOrientation={"VERTICAL"}
        pixelHeightCartButton={pixelHeightCartButton}
        scrollingRef={scrollingRef?.current ? scrollingRef : undefined}
      />
    );
  } else {
    return null;
  }
}
