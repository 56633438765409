import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../global/utils/redux/store";
import { setSelectedArticlegroup } from "../../../../global/utils/redux/menuSlice";
import { ButtonBase, GlobalStyles, styled, Tab, TabProps, Tabs, TabsProps, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";

import TabUnstyled from "@mui/base/Tab";
import TabsListUnstyled from "@mui/base/TabsList";
import TabsUnstyled from "@mui/base/Tabs";
import Articlegroup, { getArticlegroupName } from "../../../../global/utils/models/menu/Articlegroup";
import { useScroll } from "../../../../global/utils/ScrollProvider";
import clsx from "clsx";
import { scrollArticlegroupContent } from "../../../../global/utils/menu/ScrollArticlegroupTabListener";
import {
  getArticlegroupById,
  getArticlegroupsById,
  getArticlegroupsBySupergroupId,
  getArticlesByArticlegroupId,
} from "../../../../global/utils/models/menu/Menu";
import QuickTraitAsTopArticlegroup from "./QuickTraits/QuickTraitAsTopArticlegroup";
import ArticlegroupTabContent from "../ArticlegroupTabContent";
import ActiveQuickTraitsChipBar from "./QuickTraits/ActiveQuickTraitsChipBar";
import { Close } from "@mui/icons-material";
import { selectTopArticlegroupIds } from "../../../../global/utils/redux/selectors/selectTopArticlegroupIds";
import { selectArticlesMap } from "../../../../global/utils/redux/selectors/selectArticlesMap";
import { selectArticlegroupsMap } from "../../../../global/utils/redux/selectors/selectArticlegroupsMap";
import { selectArticlegroupChildrenMap } from "../../../../global/utils/redux/selectors/selectArticlegroupChildrenMap";
import { selectArticlegroupArticlesMap } from "../../../../global/utils/redux/selectors/selectArticlegroupArticlesMap";
import { selectAppLanguage } from "../../../components/LanguageSelector/useLanguage.ts";

const StyledTabs = styled((props: TabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))(({ theme }) => ({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 200,
    width: "100%",
    backgroundColor: theme.palette.primary.dark,
  },
}));

const StyledTab = styled((props: TabProps) => <Tab {...props} />)(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: "rgba(255, 255, 255, 0.7)",
  flexGrow: 1,
  "&.Mui-selected": {
    color: "#fff",
  },
  "&.Mui-focusVisible": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export default function MultipleLayersBottomTabBar() {
  const topArticlegroupIds = useAppSelector(selectTopArticlegroupIds);
  const articlesMap = useAppSelector(selectArticlesMap);
  const articlegroupsMap = useAppSelector(selectArticlegroupsMap);
  const articlegroupsShortcutPage = useAppSelector((state) => state.global.salesarea.articlegroups_shortcut_page);
  const articlegroupChildrenMap = useAppSelector(selectArticlegroupChildrenMap);
  const articlegroupArticlesMap = useAppSelector(selectArticlegroupArticlesMap);
  const articlegroups: Articlegroup[] = useMemo(() => {
    return getArticlegroupsById(articlegroupsMap, topArticlegroupIds);
  }, [articlegroupsMap, topArticlegroupIds]);
  const selectedArticlegroup = useAppSelector((state) => state.menu.selectedArticlegroup);
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const [articlegroupStairs, setArticlegroupStairs] = useState<Articlegroup[]>([]);

  useEffect(() => {
    if (articlegroupStairs.length === 0 && articlegroups[0]) {
      // if (articlegroupsShortcutPage == null) {
      if (!selectedArticlegroup?.id) {
        setArticlegroupStairs([articlegroups[0]]);
        if (articlegroupsShortcutPage == null) {
          dispatch(setSelectedArticlegroup({ articlegroup: articlegroups[0] }));
        }
      } else {
        setArticlegroupStairs([selectedArticlegroup]);
        if (articlegroupsShortcutPage == null) {
          dispatch(setSelectedArticlegroup({ articlegroup: selectedArticlegroup }));
        }
      }
      // }
    }
  }, [articlegroupsShortcutPage, articlegroups, selectedArticlegroup, dispatch, articlegroupStairs.length]);

  const topArticlegroups = useMemo(() => {
    return getArticlegroupsById(articlegroupsMap, topArticlegroupIds);
  }, [articlegroupsMap, topArticlegroupIds]);

  const topArticlegroupsMap = useMemo(() => {
    const map: Record<string, Articlegroup> = {};
    topArticlegroups.forEach((topArticlegroup) => {
      map[topArticlegroup.id] = topArticlegroup;
    });
    return map;
  }, [topArticlegroups]);

  const getChildArticlegroups = useCallback(
    (articlegroup: Articlegroup) => {
      return getArticlegroupsBySupergroupId(articlegroupsMap, articlegroupChildrenMap, articlegroup.id).filter(
        (articlegroup) => getArticlesByArticlegroupId(articlesMap, articlegroupArticlesMap, articlegroup.id).length > 0
      );
    },
    [articlegroupArticlesMap, articlegroupChildrenMap, articlegroupsMap, articlesMap]
  );

  const childArticlegroups = useMemo(() => {
    if (selectedArticlegroup?.id) {
      let childArticlegroups = getChildArticlegroups(selectedArticlegroup);
      while (childArticlegroups.length === 1) {
        childArticlegroups = getChildArticlegroups(childArticlegroups[0]);
      }

      return childArticlegroups;
    }
    return [];
  }, [getChildArticlegroups, selectedArticlegroup]);
  // const boxContainer = useScrollShadows(Orientation.HORIZONTAL);
  return (
    <Box sx={{ position: "relative" }} className={"JS-MultipleLayersBottomTabBar-Root"}>
      <GlobalStyles styles={arrowStyle} />
      {/*<QuickTraitFilterTabBar />*/}
      <ActiveQuickTraitsChipBar />
      {/*<Box sx={{ width: 1 }}>*/}
      <TabsUnstyled
        className={"JS-MultipleLayersBottomTabBar-Top-Tabs"}
        style={{ overflowX: "auto" }}
        value={selectedArticlegroup?.id ?? ""}
        onChange={(e, newArticlegroupId) => {
          setArticlegroupStairs([topArticlegroupsMap[newArticlegroupId as string]]);
        }}
      >
        <TabsListUnstyled
          style={{ display: "flex" }}
          className={"JS-MultipleLayersBottomTabBar-Top-TabsList"}
          // ref={boxContainer}
        >
          <QuickTraitAsTopArticlegroup />
          {topArticlegroups.map((articlegroup) => {
            return (
              <TabUnstyled
                key={articlegroup.id}
                value={articlegroup.id}
                style={{ flexGrow: 1, border: "none", background: "none" }}
                className={"JS-MultipleLayersBottomTabBar-Top-Tab"}
              >
                <TopArticlegroupTab articlegroup={articlegroup} />
              </TabUnstyled>
            );
          })}
        </TabsListUnstyled>
      </TabsUnstyled>
      {/*</Box>*/}
      <Box sx={{ paddingX: 3, paddingY: 2 }}>
        {articlegroupStairs.length > 1 &&
          articlegroupStairs.slice(1, articlegroupStairs.length + 1).map((articlegroup: Articlegroup, index) => {
            return (
              <ArticlegroupStep
                key={articlegroup.id}
                index={index}
                articlegroup={articlegroup}
                onClick={() => {
                  if (index < articlegroupStairs.length - 1) {
                    setArticlegroupStairs(articlegroupStairs.slice(0, index + 1));
                    dispatch(setSelectedArticlegroup({ articlegroup: articlegroupStairs[index] }));
                  }
                }}
              />
            );
          })}
      </Box>

      <StyledTabs
        className={"JS-MultipleLayersBottomTabBar-Children-Tabs"}
        sx={{ marginY: 1 }}
        variant={"scrollable"}
        value={false}
        onChange={(e, newArticlegroupId) => {
          const newArticlegroup = getArticlegroupById(articlegroupsMap, newArticlegroupId);
          if (newArticlegroup) {
            setArticlegroupStairs([...articlegroupStairs, newArticlegroup]);
            dispatch(setSelectedArticlegroup({ articlegroup: newArticlegroup }));
          }
        }}
      >
        {childArticlegroups.map((articlegroup) => {
          return (
            <StyledTab
              key={articlegroup.id}
              sx={{
                fontSize: theme.typography.pxToRem(10),
                borderRadius: 60,
                background: "white",
                color: "black",
                marginX: 1,
              }}
              value={articlegroup.id}
              label={articlegroup.name}
              className={"JS-MultipleLayersBottomTabBar-Children-Tab"}
            />
          );
        })}
      </StyledTabs>
    </Box>
  );
}

function ArticlegroupStep(props: { articlegroup: Articlegroup; onClick: () => void; index: number }) {
  return (
    <ButtonBase onClick={props.onClick} className={"JS-ArticlegroupStep-ButtonBase"}>
      <Box
        className={"JS-ArticlegroupStep-ButtonBase-Content"}
        style={{
          position: "relative",
          height: 75,
          whiteSpace: "nowrap",
          width: 174,
          zIndex: 100 - props.index,
        }}
      >
        <Box className={"JS-ArticlegroupStep-ArrowBorder"} id={"arrowBorder"} />
        <Box className={"JS-ArticlegroupStep-Arrow"} id={"arrow"}>
          <Typography
            className={"JS-ArticlegroupStep-Text"}
            sx={{
              fontSize: "1rem",
              paddingLeft: props.index === 0 ? 0 : 6,
              textOverflow: "wrap",
              width: 1,
              color: "white",
            }}
          >
            {props.articlegroup.name}
          </Typography>
          <Close
            sx={{ background: "white", borderRadius: "50%", zoom: 0.3, position: "absolute", top: 10, right: 10 }}
          />
        </Box>
      </Box>
    </ButtonBase>
  );
}

interface TopArticlegroupTabProps {
  articlegroup: Articlegroup;
}

function TopArticlegroupTab(props: TopArticlegroupTabProps) {
  const { scrollTabBlocked } = useScroll();
  const isArticlegroupSelected = useAppSelector((state) =>
    Boolean(state.menu.selectedArticlegroupIds[props.articlegroup.id])
  );
  const dispatch = useAppDispatch();
  const articlegroup = props.articlegroup;
  const lang = useAppSelector(selectAppLanguage);
  // needs to rerender based on this selector
  useAppSelector((state) => state.menu.selectedArticlegroup);

  return (
    <Box
      key={articlegroup.id}
      id={"ArticlegroupTab-" + articlegroup.id}
      className={clsx(
        "JS-HorizontalArticlegroupTabBar-Button",
        isArticlegroupSelected && "JS-HorizontalArticlegroupTabBar-Button-Selected"
      )}
      onClick={() => {
        scrollTabBlocked.current = true;
        dispatch(setSelectedArticlegroup({ articlegroup }));
        scrollArticlegroupContent(scrollTabBlocked, articlegroup, 0);
      }}
      sx={{ height: 1 }}
    >
      <Box
        sx={{
          width: 1,
          height: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ArticlegroupTabContent
          isSelected={isArticlegroupSelected}
          text={getArticlegroupName(articlegroup, lang)}
          mediaUrl={articlegroup.images[0]}
        />
      </Box>
    </Box>
  );
}

const arrowStyle: any = {
  "#arrow": {
    position: "absolute",
    width: "174px",
    left: 5,
    backgroundColor: "#ff5555",
    textAlign: "center",
    fontSize: "1.5em",
    height: "76px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    whiteSpace: "normal",
    // zIndex: 10,
  },
  "#arrow::after": {
    // zIndex: 15,
    content: "''",
    borderWidth: "38px",
    borderStyle: "solid",
    borderColor: "transparent transparent transparent #ff5555",
    // borderTop: "37px solid transparent",
    // borderBottom: "38px solid transparent",
    // borderLeft: "50px solid red",
    position: "absolute",
    left: "174px",
  },
  "#arrowBorder": {
    // zIndex: 0,
    position: "absolute",
    top: -5,
    width: "182px",
    backgroundColor: "white",
    textAlign: "center",
    fontSize: "1.6em",
    height: "86px",
  },
  "#arrowBorder::after": {
    content: "''",
    borderWidth: "43px",
    borderStyle: "solid",
    borderColor: "transparent transparent transparent white",
    position: "absolute",
    left: "182px",
    top: 0,
  },
};
