import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import { Collapse, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { setJamezzPaymentMethod } from "../../../global/utils/redux/shoppingCartSlice";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { useController, useFormContext } from "react-hook-form";
import { useCustomTexts } from "../../../global/utils/useCustomTexts";
import { PaymentSetting } from "../../../global/utils/redux/globalSlice.tsx";

interface Props {
  doPay: boolean;
}

export default function JamezzPayment(props: Props) {
  const dispatch = useAppDispatch();
  const salesarea = useAppSelector((state) => state.global.salesarea);
  const jamezzPaymentMethod = useAppSelector((state) => state.shoppingCart.jamezzPaymentMethod);
  const { payMethod, payIssuer } = jamezzPaymentMethod;
  const paymentSettings = useAppSelector((state) => state.global.salesarea.payment_settings);
  const payByCashEnabled = useAppSelector((state) => state.global.salesarea.contantBetalen);
  const { control } = useFormContext();
  const {
    field: { value: payMethodValue, onChange: payMethodOnChange },
  } = useController({
    name: "payMethod",
    control: control,
    defaultValue: payMethod,
  });
  const customTexts = useCustomTexts(["Translate cash payment text in app", "Translate online payment text in app"]);

  const [paymentIssuerList, setPaymentIssuerList] = useState<any[] | null>(null);

  const selectedPaymentMethod = useMemo<PaymentSetting | null>(() => {
    return paymentSettings?.settings?.find((method) => method.id === payMethod) ?? null;
  }, [paymentSettings, payMethod]);

  const paymentMethods = useMemo(() => {
    const paymentMethods = (paymentSettings?.settings ?? []).filter((method) => method.active);

    if (salesarea.payProvider !== "MOLLIE") {
      paymentMethods.push({
        active: true,
        id: "ONLINE",
        image: "https://jamezz.blob.core.windows.net/public-media/v5/cashless-payment.png",
        issuers: null,
        name: customTexts["Translate online payment text in app"] ?? "Online",
      });
    }
    if (payByCashEnabled) {
      paymentMethods.push({
        active: true,
        id: "CONTANT",
        image: "https://jamezz.blob.core.windows.net/public-media/v5/pay-by-cash.png",
        issuers: null,
        name: customTexts["Translate cash payment text in app"] ?? "Cash",
      });
    }
    return paymentMethods;
  }, [payByCashEnabled, paymentSettings?.settings, customTexts, salesarea.payProvider]);

  const updatePaymentIssuerList = useCallback(() => {
    if (selectedPaymentMethod?.issuers) {
      setPaymentIssuerList(selectedPaymentMethod.issuers);
      if (selectedPaymentMethod.issuers?.[0]?.id && paymentSettings !== null) {
        dispatch(
          setJamezzPaymentMethod({
            ...jamezzPaymentMethod,
            payProvider: paymentSettings.provider,
            payIssuer: selectedPaymentMethod.issuers[0].id,
          })
        );
      }
    } else {
      setPaymentIssuerList(null);
    }
  }, [dispatch, jamezzPaymentMethod, selectedPaymentMethod, paymentSettings]);

  useEffect(() => {
    /// CHECK VALID
    if (paymentMethods && paymentMethods.length > 0) {
      if (
        payMethod === "" ||
        paymentMethods.findIndex(
          (paymentMethod: any) =>
            paymentMethod.id === payMethod && paymentMethod.paymentMethods === salesarea.payProvider
        ) === -1
      ) {
        const firstPaymentMethod = paymentMethods?.[0];
        if (firstPaymentMethod) {
          payMethodOnChange(firstPaymentMethod.id);
          dispatch(
            setJamezzPaymentMethod({
              ...jamezzPaymentMethod,
              payProvider: salesarea.payProvider,
              payMethod: firstPaymentMethod.id,
              payIssuer: "",
            })
          );
        }
      }
    } else if ((!paymentMethods || paymentMethods.length === 0) && salesarea?.payProvider) {
      payMethodOnChange("");
      dispatch(
        setJamezzPaymentMethod({
          payProvider: salesarea.payProvider,
          payMethod: "",
          payIssuer: "",
        })
      );
    }
  }, []);

  useEffect(() => {
    updatePaymentIssuerList();
  }, [payMethod]);

  // // only iff fullPSPIntergration
  // if (!salesarea.fullPSPIntergration) {
  //   return null;
  // }

  if (!props.doPay) {
    return null;
  }

  return (
    <Grid container>
      {paymentMethods && paymentMethods?.length > 0 ? (
        <FormControl sx={{ width: 1, marginY: 1 }}>
          <InputLabel>
            <FormattedMessageJamezz id={"Payment method"} />
          </InputLabel>

          <Select
            data-cy="payment-select"
            sx={{ width: 1, "& .MuiSelect-select": { display: "flex" } }}
            value={payMethodValue}
            disabled={paymentMethods.length == 1}
            label={<FormattedMessageJamezz id={"Payment method"} />}
            onChange={(e) => {
              payMethodOnChange(e.target.value);
              dispatch(
                setJamezzPaymentMethod({
                  ...jamezzPaymentMethod,
                  payProvider: salesarea.payProvider,
                  payMethod: e.target.value,
                  payIssuer: "",
                })
              );
            }}
          >
            {paymentMethods.map((method: any) => (
              <MenuItem value={method.id} key={method.id}>
                <img
                  src={method.image}
                  style={{ marginRight: "10px", width: "32px", height: "24px", objectFit: "contain" }}
                />
                <Typography>{method.name}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null}

      <Collapse in={paymentIssuerList != null} sx={{ width: 1 }}>
        <Select
          sx={{ width: 1, marginY: 1, "& .MuiSelect-select": { display: "flex" } }}
          value={payIssuer}
          onChange={(e) => {
            if (paymentSettings) {
              dispatch(
                setJamezzPaymentMethod({
                  ...jamezzPaymentMethod,
                  payProvider: paymentSettings.provider,
                  payIssuer: e.target.value,
                })
              );
            }
          }}
        >
          {paymentIssuerList?.map((issuer) => (
            <MenuItem value={issuer.id} key={issuer.id}>
              <img src={issuer.image} style={{ marginRight: "10px" }} />
              {issuer.name}
            </MenuItem>
          ))}
        </Select>
      </Collapse>
    </Grid>
  );
}
