import { JamezzApiResponse } from "./shared/api/common_types";

export type MWiseSession = {
  has_session: boolean;
  card_number: string | null;
  token: string | null;
  name: string | null;
};
export type Login = {
  card_number: string;
  password: string;
};

export interface OracleProduct {
  discountId: number;
  name: {
    [k: string]: string;
  };
  type: "percentage"; // TODO oracle,
  value: number;
  extensions: {
    MWISE_REWARD_CODE: string;
  };
}

export enum MWiseRewardVisualStatus {
  LockedAndInvisible = 1,
  LockedAndVisible = 2,
  Valid = 3,
  Used = 4,
  Expired = 5,
}

export type MWiseReward = {
  PositionId: number;
  VisualStatus: MWiseRewardVisualStatus;
  Title: string;
  Description: string;
  Conditions: string;
  ImageUrl: string | null;
  BigImageUrl: string | null;
  BackgroundImageURL: string | null;
  IsNewExtras: boolean;
  IsBirthdayExtras: boolean;
  IsStampExtras: boolean;
  ShortTitle: string;
  Label: string;
  VisitExtraNo: number;
  ValidFrom: null | string;
  ValidTo: null | string;
};

export interface MWiseAPIReward extends MWiseReward {
  OracleProduct: OracleProduct;
}

export interface MWiseUser {
  EmailAddress: string;
  CardNumber: string;
  FirstName: string;
}

export interface MWiseState {
  user: MWiseUser | null;
  claimedReward: {
    oracleDiscountId: number;
    mwiseCode: string;
  } | null;
}

export type LoginResponse = {
  data: MWiseUser;
} & JamezzApiResponse;

export type MWiseCustomerRewardsResponse = JamezzApiResponse & { data: MWiseAPIReward[] };
export type MWiseSessionResponse = { data: MWiseSession } & JamezzApiResponse;
