import Box from "@mui/material/Box";
import { Button, Collapse, Divider, Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import useCurrency, { CurrencyLocation } from "../../../global/utils/useCurrency";
import CustomField from "../../../types/shared/CustomField";
import CloseDialogButton from "../../components/Dialog/CloseDialogButton.tsx";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import JamezzPayment from "../../components/Payments/JamezzPayment";
import useSalesareaIsOpen from "../../../global/utils/useSalesareaIsOpen";
import store, { useAppSelector } from "../../../global/utils/redux/store";
import VoucherInput from "../../components/VoucherInput";
import CustomFieldContent from "../CheckoutPage/CustomFieldContent";
import AfterPayArticleContent from "./AfterPayArticleContent";
import useSendAfterPay from "../utils/useSendAfterPay";
import { TipDrawer } from "../../components/TipDrawer";
import { useTipping } from "../../../global/utils/useTipping.ts";
import { selectCustomFields } from "../../../global/utils/redux/selectors/selectCustomFields.tsx";
import { selectPaymentMethodCustomField } from "../../../global/utils/redux/selectors/selectPaymentMethodCustomField.tsx";
import BackgroundMediaUrlFromSalesarea from "../../../kiosk/hooks/useBackgroundMediaUrl.tsx";
import { postAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics.ts";
import {
  selectTransactionFeeAmount,
  userFinishedPayAfterOrder,
  userStartedPayAfterOrder,
} from "../../../global/utils/redux/accountSlice.tsx";
import { TransitionGroup } from "react-transition-group";

export interface AfterPayArticle {
  apiId: string;
  id: number;
  name: string;
  price: number;
  qty: number;
}

/**
 * State machine:
 * from app
 * 1. sees checkout page
 * 2. Can go back (to app), or press pay button
 * 3. Pay button pressed -> open tip drawer
 * 4. User can go back (to 1), or decide on a tip (no tip or a tip amount)
 * 5. Tip decided -> Tip is set, order is sent.
 * @constructor
 */
export default function AfterPayPage() {
  const afterPayData = useAppSelector((state) => state.shoppingCart.afterPayData);

  const amount = afterPayData?.payment ?? 0;
  const transactionFeeAmount = useAppSelector(selectTransactionFeeAmount);
  const totalAmount = amount + transactionFeeAmount;
  const items = afterPayData?.items ?? [];
  const { isAvailable: tippingIsAvailable } = useTipping();

  // const items = useVisibleShoppingCartItems();
  const toCurrency = useCurrency({ location: CurrencyLocation.Checkout });
  // const sendCheckout = useSendCheckout2();
  const sendCheckout = useSendAfterPay({ totalAmount, amount, items });

  const allowVouchers = useAppSelector((state) => state.global.salesarea?.allowVouchers);

  const useFormMethods = useForm({
    reValidateMode: "onChange",
    mode: "onSubmit",
    defaultValues: {},
    criteriaMode: "all",
  });
  const { trigger } = useFormMethods;

  const customFieldsExceptPayment = useAppSelector(selectCustomFields);
  const paymentCustomField = useAppSelector(selectPaymentMethodCustomField);
  const customFields = paymentCustomField
    ? [...customFieldsExceptPayment, paymentCustomField]
    : customFieldsExceptPayment;
  const isOpen = useSalesareaIsOpen();
  const [tipDrawerOpened, setTipDrawerOpened] = useState(false);
  const [userFinishedTipping, setUserFinishedTipping] = useState(false);

  useEffect(() => {
    postAnalyticsEvent({
      category: "PayAfterOrderDialog",
      action: "AfterPayPage",
    });
  }, []);

  const processTip = useCallback(() => {
    setTipDrawerOpened(false);
    setUserFinishedTipping(true);
  }, []);

  useEffect(() => {
    if (userFinishedTipping) {
      sendCheckout();
      setUserFinishedTipping(false);
    }
  }, [sendCheckout, userFinishedTipping]);

  useEffect(() => {
    store.dispatch(userStartedPayAfterOrder());
    return () => {
      store.dispatch(userFinishedPayAfterOrder());
    };
  }, []);
  return (
    <>
      <TipDrawer
        paymentAmount={amount}
        onTipIsAdded={processTip}
        open={tipDrawerOpened}
        onClose={() => {
          setTipDrawerOpened(false);
          setUserFinishedTipping(false);
        }}
      />
      <FormProvider {...useFormMethods}>
        <form
          style={{ height: "100%" }}
          onSubmit={(e) => {
            postAnalyticsEvent({
              category: "AfterPayPage",
              action: "sendCheckout",
            });
            e.preventDefault();
            trigger().then((isValid) => {
              if (isValid) {
                if (tippingIsAvailable) {
                  postAnalyticsEvent({
                    category: "CheckoutPage",
                    action: "setTipDrawerOpened",
                  });
                  setTipDrawerOpened(true);
                } else {
                  postAnalyticsEvent({
                    category: "CheckoutPage",
                    action: "sendCheckout",
                  });
                  sendCheckout();
                }
              }
            });
          }}
        >
          <BackgroundMediaUrlFromSalesarea
            keyName={"extra_settings__qrv5_background_of_checkout_page"}
            className="JS-AfterPayPage-BackgroundImage"
            sx={{
              display: "flex",
              width: 1,
              height: 1,
              justifyContent: "center",
              overflowY: "auto",
            }}
            fallbackBackgroundColor={"primary.light"}
          >
            <CloseDialogButton />
            <Box
              sx={{
                width: 1,
                height: 1,
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingY: 4,
                paddingX: 1,
                maxWidth: 600,
              }}
            >
              <Typography variant={"h5"} sx={{ fontStyle: "italic", fontWeight: 800 }}>
                <FormattedMessageJamezz id={"Checkout"} />
              </Typography>
              <Grid container>
                {items.map((orderArticle) => (
                  <AfterPayArticleContent key={orderArticle.id} orderArticle={orderArticle} />
                ))}
              </Grid>
              <Divider sx={{ height: 4, width: 1, marginY: 2 }} />
              <Grid container sx={{ paddingX: 6 }}>
                {customFields
                  .filter((customField: CustomField) => {
                    return customField.customfieldType === undefined || customField.customfieldType === "payment_only";
                  })
                  .map((customField: CustomField) => (
                    <CustomFieldContent key={customField.name} customField={customField} />
                  ))}
              </Grid>

              {totalAmount > 0 ? <JamezzPayment doPay={true} /> : null}

              <Box sx={{ width: 1 }}>
                <TransitionGroup style={{ width: "100%" }}>
                  {amount !== totalAmount ? (
                    <Collapse>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ fontStyle: "italic", opacity: 0.6 }}>
                          <FormattedMessageJamezz id={"Order value"} />
                        </Typography>

                        <Typography
                          align={"right"}
                          sx={{ fontStyle: "italic", opacity: 0.6, whiteSpace: "nowrap" }}
                          data-cy="transaction-costs"
                        >
                          {toCurrency(amount)}
                        </Typography>
                      </Box>
                    </Collapse>
                  ) : null}

                  {transactionFeeAmount > 0 ? (
                    <Collapse>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ fontStyle: "italic", opacity: 0.6 }}>
                          <FormattedMessageJamezz id={"Transaction fee"} />
                        </Typography>

                        <Typography
                          align={"right"}
                          sx={{ fontStyle: "italic", opacity: 0.6, whiteSpace: "nowrap" }}
                          data-cy="transaction-costs"
                        >
                          {toCurrency(Number(transactionFeeAmount))}
                        </Typography>
                      </Box>
                    </Collapse>
                  ) : null}
                </TransitionGroup>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>
                    <FormattedMessageJamezz id={"Outstanding amount"} />
                  </Typography>

                  <Typography
                    align={"right"}
                    sx={{ fontWeight: 800, whiteSpace: "nowrap" }}
                    data-cy={"pay-after-order-open-amount"}
                  >
                    {toCurrency(totalAmount)}
                  </Typography>
                </Box>
              </Box>
              {Boolean(allowVouchers) ? <VoucherInput /> : null}

              <Button sx={{ margin: 4, marginBottom: 8 }} variant={"contained"} type={"submit"} disabled={!isOpen}>
                <FormattedMessageJamezz id={"Pay"} />
              </Button>

              <Box sx={{ height: 10, opacity: 0 }}>_</Box>
            </Box>
          </BackgroundMediaUrlFromSalesarea>
        </form>
      </FormProvider>
    </>
  );
}
