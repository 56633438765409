import { useAppDispatch, useAppSelector } from "../../global/utils/redux/store";
import Box from "@mui/material/Box";
import { Button, DialogActions, Typography } from "@mui/material";
import FormattedMessageJamezz from "../../global/components/FormattedMessageJamezz";
import { madeDecisionAboutReceipt } from "../../global/utils/redux/userInputSlice";

import { useDialog } from "../../global/utils/dialog/DialogProvider";

function AskForReceiptDialog({ onClose }: { onClose: () => void }) {
  const dispatch = useAppDispatch();

  return (
    <Box sx={{ m: 4 }}>
      <Typography variant="h1" fontSize="2em" sx={{ mb: 2 }}>
        <FormattedMessageJamezz id="Popup.askForReceipt.title" />
      </Typography>
      <DialogActions
        sx={{
          display: "grid",
          w: 1,
          gridTemplateColumns: "1fr 1fr",
          gap: 1,
        }}
      >
        <Button
          color="success"
          onClick={() => {
            dispatch(madeDecisionAboutReceipt(true));
            onClose();
          }}
          variant="contained"
        >
          <FormattedMessageJamezz id="Popup.askForReceipt.button.yes" />
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            dispatch(madeDecisionAboutReceipt(false));
            onClose();
          }}
        >
          <FormattedMessageJamezz id="Popup.askForReceipt.button.no" />
        </Button>
      </DialogActions>
    </Box>
  );
}

export function useAskIfUserWantsReceipt() {
  const { openDialog, closeDialog } = useDialog();
  const shouldAskForReceipt = useAppSelector<boolean>((state) => state.global.salesarea.askForReceipt);

  return function askForReceipt() {
    if (!shouldAskForReceipt) {
      return new Promise<void>((resolve) => resolve());
    }
    return new Promise<void>((resolve) => {
      openDialog({
        disabledBackdropClick: true,
        children: (
          <AskForReceiptDialog
            onClose={() => {
              closeDialog();
              resolve();
            }}
          />
        ),
      });
    });
  };
}
