import store, { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import { useCallback, useMemo } from "react";
import OrderMode from "../../utils/models/OrderMode";
import { selectOrderModeCustomField, setOrderCustomField } from "../../../global/utils/redux/customFieldsSlice";
import { httpGet, httpPost } from "../../../global/utils/api/http";
import { apiRoutes, createReturnPath, routes } from "../../../global/utils/config";
import JamezzApi from "../../../global/utils/api/JamezzApi";
import usePrinterCheck from "../../utils/usePrinterCheck";
import useAppNavigate from "../../../global/utils/useAppNavigate";
import { setSessionState } from "../../../global/utils/redux/globalSlice.tsx";

function useOrderModeSelector() {
  const webcodeFromStore = useAppSelector((state) => state.global.webcode);
  const { triggerCheckPrinter } = usePrinterCheck();
  const printerCheckEnabled = useAppSelector((state) => state.global.salesarea.printer_check_state_enabled);
  const salesareaId = useAppSelector((state) => state.global.salesarea?.id);
  const propposEnabled = useAppSelector((state) => state.global.salesarea?.proppos_settings?.enabled ?? false);
  const appEnabled = useAppSelector((state) => state.global.salesarea?.systemOnline);

  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();

  const orderModeCustomField = useAppSelector(selectOrderModeCustomField);

  const orderModes = useMemo<OrderMode[]>(() => {
    return (orderModeCustomField?.options ?? []) as OrderMode[];
  }, [orderModeCustomField]);

  const onClickOrderMode = useCallback(
    (orderMode?: OrderMode) => {
      if (!appEnabled) {
        return;
      }
      const searchParams = new URLSearchParams();

      searchParams.set("vatGroup", String(orderMode?.vatGroup ?? 1));
      searchParams.set("vestiging_id", String(salesareaId));
      searchParams.set("mid", String(webcodeFromStore));
      searchParams.set("format", "json");
      searchParams.set("instoreStartScreen", "true");
      if (orderModeCustomField) {
        dispatch(setOrderCustomField({ customFieldName: orderModeCustomField.name, value: orderMode?.id ?? 1 }));
      }
      httpGet("/session/activate?" + searchParams.toString()).then(() => {
        httpPost(
          apiRoutes.kiosk.activateSession,
          JamezzApi.post.loginSession.data(webcodeFromStore, window.location.origin + createReturnPath(), "", "", ""),
          { showSpinner: false, showToastSuccess: false }
        ).then((response: any) => {
          if ("data" in response) {
            if (JSON.stringify(response.data.data) !== JSON.stringify(store.getState().global.sessionState)) {
              store.dispatch(setSessionState(response.data.data));
            }
          }
        });
      });
      if (printerCheckEnabled) {
        triggerCheckPrinter();
      }
      if (propposEnabled) {
        navigate(routes.kiosk.propposPath);
      } else {
        navigate(routes.kiosk.menuPath);
      }
    },
    [
      appEnabled,
      dispatch,
      navigate,
      orderModeCustomField,
      printerCheckEnabled,
      propposEnabled,
      salesareaId,
      triggerCheckPrinter,
      webcodeFromStore,
    ]
  );

  return { orderModes, orderModeCustomField, onClickOrderMode };
}

export default useOrderModeSelector;
