import { createSelector } from "@reduxjs/toolkit";
import store, { RootState } from "../store";
import { CustomFieldType } from "../../../../types/shared/CustomField";
import { parsePaymentMethod2, preparePaymentData } from "../../api/parseApis/parseApiCustomFields";

export const selectPaymentMethodCustomField = createSelector(
  [
    (state: RootState) => state.global.sessionState?.paymentMethods ?? [],
    (state: RootState) => state.customFields.customFieldsResponse,
  ],
  (paymentMethods, customFields) => {
    const paymentMethodData = customFields?.find((customField) => {
      return customField.actief !== false && customField.type?.toLowerCase() === CustomFieldType.PaymentMethodButtons;
    });
    if (paymentMethodData) {
      return parsePaymentMethod2(paymentMethodData, preparePaymentData(paymentMethods));
    }
    return undefined;
  }
);

if (window.selectors) {
  window.selectors.selectPaymentMethodCustomField = () => selectPaymentMethodCustomField(store.getState());
}
