import Campaign, { getPriceOfCampaign } from "../../../../global/utils/models/campaigns/Campaign.ts";
import Articlegroup from "../../../../global/utils/models/menu/Articlegroup.ts";
import { Button, ButtonBase, DialogActions, Divider, lighten, Stack, Typography, useTheme } from "@mui/material";
import Image from "../../../../global/components/Image.tsx";
import { useDialog } from "../../../../global/utils/dialog/DialogProvider.tsx";
import Box from "@mui/material/Box";
import store, { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import { selectCampaignArticlesMap } from "../../../../global/utils/redux/selectors/selectCampaignArticlesMap.ts";
import useMenuFilters from "../../../../global/utils/menu/filters/useMenuFilters.ts";
import { selectArticlesMap } from "../../../../global/utils/redux/selectors/selectArticlesMap.ts";
import { ReactNode, useCallback, useMemo, useRef, useState } from "react";
import Article, { getArticleDescription, getArticleName } from "../../../../global/utils/models/menu/Article.ts";
import { getArticlesById } from "../../../../global/utils/models/menu/Menu.ts";
import { CSSProperties } from "@mui/material/styles/createMixins";
import { selectAppLanguage } from "../../../components/LanguageSelector/useLanguage.ts";
import SlideUpTransition from "../../../../global/components/SlideUpTransition.tsx";
import OrderArticle, {
  initOrderArticle,
  orderArticlesAreEqual,
} from "../../../../global/utils/models/order/OrderArticle.ts";
import _ from "lodash";
import {
  ArrangementCheckResult,
  useArrangementChecks,
} from "../../../../global/components/ArrangementLimitations/ArrangementLimitations.tsx";
import { AgeCheckResult } from "../../../../global/utils/ageCheck/ageCheckUtils.ts";
import useAddOrderArticleToShoppingCart, {
  checkStockAndMaxCount,
} from "../../../../global/utils/order/useAddOrderArticleToShoppingCart.tsx";
import { useAgeCheck } from "../../../../global/components/AgeCheckDialog.tsx";
import useCurrency, { CurrencyLocation } from "../../../../global/utils/useCurrency.tsx";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz.tsx";
import { useIntl } from "react-intl";
import ScrollShadowsKiosk from "../../../components/ScrollShadowKiosk.tsx";

export default function CampaignMenuItem({
  campaign,
  category,
  widthPercentage,
}: {
  campaign: Campaign;
  category: Articlegroup;
  widthPercentage: CSSProperties["width"];
}) {
  const { openDialog } = useDialog();
  const articleFilter = useMenuFilters();
  const articlesMap = useAppSelector(selectArticlesMap);

  const articleIds = useAppSelector(selectCampaignArticlesMap)[campaign.id];
  const articles = useMemo<Article[]>(() => {
    return getArticlesById(articlesMap, articleIds).filter(articleFilter) ?? [];
  }, [articlesMap, articleIds, articleFilter]);
  const parseCurrency = useCurrency({ location: CurrencyLocation.CampaignMenuItem });

  const price = useMemo(() => {
    return getPriceOfCampaign(campaign);
  }, [campaign]);
  if (articles.length === 0) {
    return null;
  }
  return (
    <Box sx={{ width: widthPercentage }} className={"JS-CampaignMenuItem-Root"}>
      <ButtonBase
        className={"JS-CampaignMenuItem-Button"}
        sx={{ width: 1, height: 1 }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          openDialog({
            children: <CampaignProductsDialog campaign={campaign} />,
            fullScreen: true,
            TransitionComponent: SlideUpTransition,
          });
        }}
      >
        <Stack gap={2} justifyContent={"space-between"} className={"JS-CampaignMenuItem-Stack"}>
          <Image
            className={"JS-CampaignMenuItem-Image"}
            srcSet={campaign.mediaInCategory?.conversions?.responsive?.srcset}
            style={{ objectFit: "contain" }}
          />
          <Typography className={"JS-CampaignMenuItem-Title"}>{campaign.name}</Typography>
          {/*{price != null ? <Typography textAlign={"center"}>{parseCurrency(price)}</Typography> : null}*/}
        </Stack>
      </ButtonBase>
    </Box>
  );
}

export function useMiniShoppingCart({ maxCount }: { maxCount: number }) {
  const [orderArticles, setOrderArticles] = useState<OrderArticle[]>([]);

  const totalCount = useMemo(() => {
    return _.chain(orderArticles).sumBy("count").value();
  }, [orderArticles]);

  const checkArrangements = useArrangementChecks();
  const checkAge = useAgeCheck();

  const addOrderArticle = useCallback(
    (orderArticle: OrderArticle) => {
      return new Promise((resolve, reject) => {
        (async () => {
          const orderArticlesFromStore = store.getState().shoppingCart.items;
          if (!checkStockAndMaxCount(orderArticle, [...orderArticles, ...orderArticlesFromStore])) {
            reject("stock_denied");
            return;
          }

          if (totalCount >= maxCount && orderArticle.count > 0) {
            reject("max_count_denied");
            return;
          }

          const arrangementRulesCheck = await checkArrangements(orderArticle);
          if (arrangementRulesCheck === ArrangementCheckResult.Accepted) {
            const ageCheckResult = await checkAge(orderArticle);
            if (ageCheckResult === AgeCheckResult.Denied) {
              reject("age_denied");
              return;
            }
          } else {
            reject("arrangement_check_denied");
            return;
          }

          const state = [...orderArticles];
          const itemIndex = state.findIndex((item) => orderArticlesAreEqual(item, orderArticle));
          const item = state[itemIndex];

          if (item) {
            item.count += orderArticle.count;
            if (item.count === 0) {
              state.splice(itemIndex, 1);
            }
          } else {
            if (orderArticle.count > 0) {
              state.push(orderArticle);
            }
          }
          setOrderArticles(state);
        })();
      });
    },
    [checkAge, checkArrangements, maxCount, orderArticles, totalCount]
  );

  return { addOrderArticle, orderArticles, totalCount };
}

function CampaignProductsDialog({ campaign }: { campaign: Campaign }) {
  const { closeDialog } = useDialog();
  const addOrderArticleToShoppingCart = useAddOrderArticleToShoppingCart({ allowUpsell: false });

  const articleFilter = useMenuFilters();
  const articlesMap = useAppSelector(selectArticlesMap);

  const articleIds = useAppSelector(selectCampaignArticlesMap)[campaign.id];
  const articles = useMemo<Article[]>(() => {
    return getArticlesById(articlesMap, articleIds).filter(articleFilter) ?? [];
  }, [articlesMap, articleIds, articleFilter]);

  const { minCount, maxCount } = useMemo(() => {
    if (campaign.type == "X_FOR_Y") {
      return { maxCount: campaign.Xs?.[0] ?? 0, minCount: campaign.Xs?.[0] ?? 0 };
    } else {
      return { maxCount: 0, minCount: 0 };
    }
  }, [campaign]);

  const { orderArticles, addOrderArticle, totalCount } = useMiniShoppingCart({ maxCount: maxCount });

  const countPerArticleId = _.chain(orderArticles)
    .groupBy("article.id")
    .mapValues((group) => _.sumBy(group, "count"))
    .value();
  console.log(orderArticles);
  const parseCurrency = useCurrency({ location: CurrencyLocation.CampaignProductsDialog });

  const price = useMemo(() => {
    return getPriceOfCampaign(campaign);
  }, [campaign]);

  const scrollRef = useRef(null);
  const message = useMessagesFromMinAndMaxSelect({ minSelect: minCount, maxSelect: maxCount });
  const theme = useTheme();
  const subTextColor = lighten(theme.palette.text.primary, 0.5);
  return (
    <>
      <Box sx={{ flex: "1 1 0%", overflowY: "auto" }} ref={scrollRef}>
        <ScrollShadowsKiosk containerRef={scrollRef}>
          <Stack justifyContent={"center"} gap={2} sx={{ my: 2 }}>
            <Stack direction={"row"} gap={3}>
              {campaign.mediaInCategory?.conversions?.responsive?.srcset ? (
                <Image
                  style={{ maxHeight: 300, flex: "1 1 0%", objectFit: "contain" }}
                  srcSet={campaign.mediaInCategory?.conversions?.responsive?.srcset}
                />
              ) : null}
            </Stack>
            <Typography textAlign={"center"} sx={{ flex: "1 1 0%" }}>
              {campaign.name}
            </Typography>
            {price != null ? <Typography textAlign={"center"}>{parseCurrency(price)}</Typography> : null}
            <Divider />
            <Typography fontSize={"2rem"} fontStyle={"italic"} textAlign={"center"} color={subTextColor}>
              {message}
            </Typography>
            <Stack direction={"row"} justifyContent={"space-between"} flexWrap={"wrap"}>
              {_.chunk(articles, 3).map((articles) => (
                <CampaignMenuItemGrid numberOfArticles={articles.length}>
                  {articles.map((article, columnIndex) => (
                    <CampaignArticleItem
                      campaign={campaign}
                      article={article}
                      addOrderArticle={addOrderArticle}
                      count={countPerArticleId[article.id] ?? 0}
                      columnIndex={columnIndex}
                    />
                  ))}
                </CampaignMenuItemGrid>
              ))}
            </Stack>
          </Stack>
        </ScrollShadowsKiosk>
      </Box>
      <Divider />
      <DialogActions sx={{ justifyContent: "center", gap: 10 }}>
        <Button
          sx={{ maxWidth: 300, width: 1 }}
          onClick={() => {
            closeDialog();
          }}
        >
          <FormattedMessageJamezz id={"Go back"} />
        </Button>
        <Button
          sx={{ maxWidth: 300, width: 1 }}
          disabled={(minCount > 0 && minCount > totalCount) || (maxCount > 0 && maxCount < totalCount)}
          onClick={() => {
            orderArticles.forEach((orderArticle) => {
              addOrderArticleToShoppingCart(orderArticle);
            });
            closeDialog();
            console.log("OK");
          }}
        >
          <FormattedMessageJamezz id={"Add"} />
        </Button>
      </DialogActions>
    </>
  );
}
export function useMessagesFromMinAndMaxSelect({ minSelect, maxSelect }: { minSelect: number; maxSelect: number }) {
  const intl = useIntl();

  return useMemo<string>(() => {
    const messages = [];

    if (minSelect > 0 && maxSelect > minSelect) {
      messages.push(
        intl.formatMessage(
          { id: "QR.Pick between {min} and {max}" },
          {
            min: minSelect,
            max: maxSelect,
          }
        )
      );
    } else if (maxSelect > 0 && maxSelect != minSelect) {
      messages.push(intl.formatMessage({ id: "QR.Pick maximal {count}" }, { count: maxSelect }));
    } else if (maxSelect > 0) {
      messages.push(intl.formatMessage({ id: "QR.Pick {number} below!" }, { number: maxSelect }));
    }

    return messages.reduce((text, subText, index) => {
      if (index > 0) {
        text += " • ";
      }
      text += subText;
      return text;
    }, "");
  }, [intl, minSelect, maxSelect]);
}

function CampaignMenuItemGrid({ numberOfArticles, children }: { numberOfArticles: number; children: ReactNode }) {
  const numberOfColumns = numberOfArticles;
  const numberOfRows = 3;
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: `repeat(${numberOfColumns}, calc(33% - 20px))`,
        gridTemplateRows: `repeat(${numberOfRows}, auto)`,
        columnGap: 4,
        rowGap: 2,
        justifyItems: "center",
        minHeight: 160,
        p: 1,
        width: 1,
        justifyContent: "center",
        boxSizing: "border-box",
      }}
    >
      {children}
    </Box>
  );
}

function CampaignArticleItem({
  campaign,
  article,
  addOrderArticle,
  count,
  columnIndex,
}: {
  campaign: Campaign;
  article: Article;
  addOrderArticle: (orderArticle: OrderArticle) => void;
  count: number;
  columnIndex: number;
}) {
  const lang = useAppSelector(selectAppLanguage);
  const articleDescription = useMemo(() => {
    return (
      <Typography
        sx={{
          fontSize: 22,
          gridArea: `${3} / ${columnIndex + 1} / ${4} / ${columnIndex + 2}`,
        }}
        dangerouslySetInnerHTML={{ __html: getArticleDescription(article, lang) }}
      />
    );
  }, [article, columnIndex, lang]);
  const theme = useTheme();

  const textColor = theme.palette.getContrastText(theme.palette.primary.light);
  return (
    <>
      <ButtonBase
        sx={{
          zIndex: 0,
          backgroundColor: count > 0 ? "primary.light" : undefined,
          width: 1,
          gridArea: `${1} / ${columnIndex + 1} / ${4} / ${columnIndex + 2}`,
          boxShadow: "4px 4px 5px 0px rgba(0,0,0,0.1)",
          borderRadius: 3,
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          addOrderArticle(initOrderArticle(selectArticlesMap(store.getState()), article, 1));
        }}
      ></ButtonBase>
      {article.imageMediaUrls[0]?.conversions?.responsive?.srcset ? (
        <Image
          style={{
            width: "80%",
            gridArea: `${1} / ${columnIndex + 1} / ${2} / ${columnIndex + 2}`,
            zIndex: 1,
            pointerEvents: "none",
          }}
          srcSet={article.imageMediaUrls[0]?.conversions?.responsive?.srcset}
        />
      ) : null}
      <Typography
        textAlign={"center"}
        fontSize={"1.3rem"}
        color={count > 0 ? textColor : undefined}
        sx={{
          width: 1,
          gridArea: `${2} / ${columnIndex + 1} / ${3} / ${columnIndex + 2}`,
          px: 2,
          zIndex: 1,
          pointerEvents: "none",
        }}
      >
        {getArticleName(article, lang)}
      </Typography>
      {/*{articleDescription}*/}
      {/*<Box sx={{ gridArea: `${4} / ${columnIndex + 1} / ${5} / ${columnIndex + 2}`, px: 2, zIndex: 1 }}>*/}
      {/*  <PriceWrapper*/}
      {/*    price={article.price}*/}
      {/*    vaprice={article.vaprice}*/}
      {/*    localPrice={article.customData?.local_price > 0 ? article.customData?.local_price : null}*/}
      {/*    articleId={article.id}*/}
      {/*  />*/}
      {/*</Box>*/}

      <Stack
        direction={"row"}
        sx={{
          gridArea: `${3} / ${columnIndex + 1} / ${4} / ${columnIndex + 2}`,
          zIndex: 1,
          py: 2,
          visibility: count == 0 ? "collapse" : undefined,
        }}
        alignItems={"center"}
      >
        <PlusOrMinus
          type={"minus"}
          onClick={() => {
            addOrderArticle(initOrderArticle(selectArticlesMap(store.getState()), article, -1));
          }}
        />
        <Typography color={textColor} fontSize={"2rem"} sx={{ px: 4 }}>
          {count}
        </Typography>
        <PlusOrMinus
          type={"plus"}
          onClick={() => {
            addOrderArticle(initOrderArticle(selectArticlesMap(store.getState()), article, 1));
          }}
        />
      </Stack>
    </>
  );
}

function PlusOrMinus({ type, onClick }: { type: "plus" | "minus"; onClick: () => void }) {
  const theme = useTheme();
  const textColor = theme.palette.getContrastText(theme.palette.primary.light);
  const backgroundColor = lighten(theme.palette.primary.light, 0.2);
  return (
    <Typography
      sx={{
        backgroundColor: backgroundColor,
        borderRadius: 3,
        fontSize: "1em",
        px: 4,
        py: 1,
        boxShadow: "2px 2px 5px 0px rgba(0,0,0,0.2)",
      }}
      color={textColor}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
    >
      {type === "plus" ? "+" : "-"}
    </Typography>
  );
}
