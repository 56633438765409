import { useRef } from "react";
import CustomField from "../../../../types/shared/CustomField";
import { TextField } from "@mui/material";
import useCustomField from "./useCustomField";

interface Props {
  customField: CustomField;
}

function CustomFieldHiddenContent(props: Props) {
  const { value } = useCustomField(props.customField);
  const customField = props.customField;
  const inputRef = useRef<any>(null);

  return (
    <TextField
      inputRef={inputRef}
      name={customField.name}
      type={"hidden"}
      inputProps={{ pattern: customField.pattern, required: customField.required }}
      disabled={customField.disabled}
      value={value}
    />
  );
}

export default CustomFieldHiddenContent;
