import CustomField from "../../../../types/shared/CustomField";
import { GlobalStyles, Grid, ToggleButton, Typography } from "@mui/material";
import clsx from "clsx";
import useCustomField from "./useCustomField";
import { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import { selectAppLanguage } from "../../../../kiosk/components/LanguageSelector/useLanguage.ts";

interface Props {
  customField: CustomField;
}

function CustomFieldButtonGroupContent({ customField }: Props) {
  const { value, setCustomField } = useCustomField(customField);
  const selectedLanguage = useAppSelector(selectAppLanguage);

  return (
    <Grid
      container
      spacing={3}
      sx={{ marginY: 1 }}
      className={"JS-CustomFieldButtonGroupContent-Root"}
      data-cy="customfield-button-group"
    >
      {customField.styles ? <GlobalStyles styles={customField.styles} /> : null}
      {customField.options?.map((option: any) => (
        <Grid
          item
          xs={(customField.options?.length ?? 0) > 1 ? 6 : 12}
          container
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          key={option.id}
          className={"JS-CustomFieldButtonGroupContent-Grid"}
        >
          <ToggleButton
            data-cy={`customfield-button`}
            className={clsx(
              "JS-CustomFieldButtonGroupContent-Button",
              String(option.id) === String(value)
                ? "JS-CustomFieldButtonGroupContent-Button-Selected"
                : "JS-CustomFieldButtonGroupContent-Button-Unselected"
            )}
            onClick={() => {
              const value = String(option.id);
              setCustomField(value);
            }}
            value={String(option.id)}
            sx={{ width: 300, height: 300 }}
            selected={String(option.id) === value}
          >
            {option.customHtml ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: option.customHtml,
                }}
              ></div>
            ) : (
              (option.label?.[selectedLanguage] ?? option.label)
            )}
          </ToggleButton>
          {option.customHtml ? (
            <Typography style={{ textAlign: "center" }} className={"JS-CustomFieldButtonGroupContent-Button-Label"}>
              {option.label?.[selectedLanguage] ?? option.label}
            </Typography>
          ) : null}
        </Grid>
      ))}
    </Grid>
  );
}

export default CustomFieldButtonGroupContent;
