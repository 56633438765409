import { Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

const formatCounter = (counter: number) => {
  let hours = Math.floor(counter / 3600);
  let minutes = Math.floor((counter % 3600) / 60);
  if (hours === 0 && minutes !== 0) {
    return minutes + " min";
  } else if (hours !== 0 || minutes !== 0) {
    if (minutes < 10) {
      return hours + ":0" + minutes + " min";
    } else {
      return hours + ":" + minutes + " min";
    }
  } else {
    return (counter % 3600) % 60;
  }
};

interface Props {
  seconds: number;
}

export default function CountDownTimer(props: Props) {
  const [counter, setCounter] = useState(props.seconds);

  useEffect(() => {
    setCounter(props.seconds);
  }, [props.seconds]);

  useEffect(() => {
    const timer: ReturnType<typeof setTimeout> | undefined =
      counter > 0 ? setInterval(() => setCounter(counter - 1), 1000) : undefined;
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [counter]);

  return <Typography style={{ fontWeight: 800 }}>{counter > 0 ? formatCounter(counter) : "-"}</Typography>;
}

export function useCountDownTimer(countDownFrom: number, onZero?: () => void) {
  const [timer, setTimer] = useState(countDownFrom);

  useEffect(() => {
    let timeout: number | null = null;
    if (timer > 0) {
      timeout = window.setTimeout(() => {
        setTimer((timer) => timer - 1);
      }, 1000);
    } else {
      if (onZero) {
        onZero();
      }
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [onZero, timer]);

  return {
    timer,
    resetTimer: useCallback(() => {
      setTimer(countDownFrom);
    }, [countDownFrom]),
  };
}
