import { VoucherV2 } from "./vouchersV2Slice.tsx";
import _ from "lodash";

type ErrorMessage = "Voucher is already added" | "Voucher is not activated" | "Voucher is blocked";
export function canAddNewVoucher(
  currentVouchers: VoucherV2[],
  newVoucher: VoucherV2
): { isError: true; message: ErrorMessage } | { isError: false } {
  if (!newVoucher.voucherdef.active) {
    return { isError: true, message: "Voucher is not activated" };
  }
  if (newVoucher.voucherdef.blocked) {
    return { isError: true, message: "Voucher is blocked" };
  }

  // const mapByVoucherId = _.chain(currentVouchers).keyBy("voucher.code").value();
  //
  // if (mapByVoucherId[newVoucher.voucher.code]) {
  //   return { isError: true, message: "Voucher is already added" };
  // }
  return { isError: false };
}
