import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { routes } from "../utils/config";
import useAppNavigate from "../utils/useAppNavigate";
import isKiosk from "../utils/helpers/isKiosk";

interface Props {
  redirectToHome?: boolean;
}

export default function PageNotFound(props: Props) {
  const navigate = useAppNavigate();
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    if (props.redirectToHome) {
      timeout = setTimeout(() => {
        if (isKiosk()) {
          navigate(routes.kiosk.rootPath);
        } else {
          navigate(routes.qr.rootPath);
        }
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  return (
    <Box sx={{ p: 16 }}>
      <Typography variant={"h3"}>Error 404, page not found</Typography>
      <Typography>We are sorry but the page you are looking for does not exist.</Typography>
    </Box>
  );
}
