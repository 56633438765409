import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import IconButton from "@mui/material/IconButton";
import { ArrowBack } from "@mui/icons-material";

interface Props {
  icon?: any;
  onClose?: () => void;
}

export default function CloseDialogButtonQr(props: Props) {
  const { closeDialog } = useDialog();
  return (
    <IconButton
      data-cy="btn-close-dialog"
      className={"JS-BackDialogButton-Root"}
      onClick={() => {
        if (props.onClose) {
          props.onClose();
        } else {
          closeDialog();
        }
      }}
    >
      {props.icon ? props.icon : <ArrowBack color="primary" className={"JS-BackDialogButton-Icon"} />}
    </IconButton>
  );
}
