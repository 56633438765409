import store, { useAppDispatch } from "../../global/utils/redux/store";
import { useCallback } from "react";
import { httpPost } from "../../global/utils/api/http";
import parseShoppingCart from "../../global/utils/order/parseShoppingCart";
import { apiRoutes, createReturnPath } from "../../global/utils/config";
import { useDialog } from "../../global/utils/dialog/DialogProvider";
import { orderCreated, setHiddenIframeURL } from "../../global/utils/redux/globalSlice";
import { toast } from "react-toastify";
import FormattedMessageJamezz from "../../global/components/FormattedMessageJamezz";
import { processOrderCheckerResult } from "../../global/utils/hooks/useOrderChecker";
import useAppNavigate from "../../global/utils/useAppNavigate";
import addVariousItemsToOrder, {
  addVariousItemsToVoucherOrderItems,
} from "../../global/utils/order/addVariousItemsToOrder";
import OrderArticle from "../../global/utils/models/order/OrderArticle";
import { mockPostCheckout } from "./dev/liveInterfaceMocks";
import { KioskSendOrderFunction } from "../../types/kiosk/sendCheckout";
import { selectAutoAddedItems } from "../../global/utils/redux/shoppingCartSlice";
import { selectAllPackagingsByUserInput } from "../../global/utils/redux/packagingsSlice";
import _ from "lodash";
import { cikamTransactionStarted } from "../../global/utils/redux/cikamSlice";
import { selectCustomFields } from "../../global/utils/redux/selectors/selectCustomFields";
import { selectPaymentMethodCustomField } from "../../global/utils/redux/selectors/selectPaymentMethodCustomField";
import { selectPiggyGiftcardsWithUsingAmounts } from "../../global/utils/redux/selectors/selectPiggyGiftcardsWithUsingAmounts.tsx";
import { vouchersDeclined } from "../../global/utils/vouchersV2/vouchersV2Slice.tsx";
import { processStock } from "../../global/utils/hooks/processStock.tsx";
import { BlockUserInput } from "@jamezz/react-components";
import { selectDiscountsPerCampaign } from "../../global/utils/redux/selectors/discountsPerCampaign.ts";

const postCheckout: KioskSendOrderFunction = function realPostCheckoutRequest(checkoutParams: Record<string, any>) {
  return httpPost(apiRoutes.kiosk.checkout, checkoutParams, {
    showToastSuccess: false,
    showToastError: false,
    showSpinner: true,
  });
};

function useKioskSendCheckout2() {
  const { closeAllDialogs } = useDialog();

  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const { blockingStarted } = BlockUserInput.useBlockUserInput();

  const sendOrder = useCallback(() => {
    if (store.getState().dev.forcedOpen) {
      toast.info("Salesarea is not open!");
      return;
    }

    const dateTimeStamp = import.meta.env.__BUILD_DATE__;
    const state = store.getState();
    const customFields = _.keyBy(selectCustomFields(store.getState()), "name");
    const paymentCustomField = selectPaymentMethodCustomField(store.getState());
    let orderArticles = parseShoppingCart(state.shoppingCart.items);

    orderArticles = orderArticles.sort((itemA: OrderArticle, itemB: OrderArticle) => {
      return itemA.article.sortKey - itemB.article.sortKey;
    });

    const allergenOrderArticles = state.shoppingCart.allergenOrderArticles;
    if (Object.keys(allergenOrderArticles).length > 0) {
      orderArticles = orderArticles.concat(Object.values(allergenOrderArticles));
    }
    const autoAddedItems = selectAutoAddedItems(state);
    let packagingItems: OrderArticle[] = [];
    if (state.global.salesarea.use_packagings) {
      packagingItems = selectAllPackagingsByUserInput(state);
    }

    const copyItems = [...orderArticles, ...autoAddedItems, ...packagingItems];
    const copyVoucherOrderItems: OrderArticle[] = [];
    addVariousItemsToOrder(copyItems);
    addVariousItemsToVoucherOrderItems(copyVoucherOrderItems);

    const voucherOrderItemsParsed = parseShoppingCart(copyVoucherOrderItems);

    const sendResponse = state.dev.liveEditModeEnabled ? mockPostCheckout : postCheckout;

    sendResponse({
      piggy_giftcards: selectPiggyGiftcardsWithUsingAmounts(state),
      piggy_prepaids: store.getState().piggy.usePrepaids,
      ...(state.userInput.ageCheckEmployeePinCode
        ? { ageConfirmedByEmployee: state.userInput.ageCheckEmployeePinCode }
        : {}),
      items: copyItems,
      build: dateTimeStamp,
      campaign_discounts: selectDiscountsPerCampaign(store.getState()),
      voucherOrderItems: voucherOrderItemsParsed,
      orderCustomFields: _.chain(state.customFields.orderCustomFields)
        .mapValues((orderCustomField) => {
          if (paymentCustomField?.name === orderCustomField.customFieldName) {
            return { customField: paymentCustomField, ...orderCustomField };
          }

          const customField = customFields[orderCustomField.customFieldName];

          return { customField: customField, ...orderCustomField };
        })
        .value(),
      returnUrl: createReturnPath(),
      discountCardNr: state.shoppingCart.discountCardNr,
      wantsReceipt: state.userInput.wantsReceipt,
      flightDetails: state.userInput.flightDetails,
      vouchersV2: state.vouchersV2.vouchers,
    })
      .then((response) => {
        if ("orderLimitationsFailed" in response?.data?.data) {
          // orderLimitationsFailed is QR-only, but our types are shared
          return;
        }

        if (!("checkResults" in response.data.data) && "reason" in response.data.data) {
          if (response?.data?.data?.checkerNames && Array.isArray(response?.data?.data?.checkerNames)) {
            response?.data?.data?.checkerNames.forEach((checkerName) => {
              if (checkerName === "CheckTimeslotAvailable") {
                toast.error(<FormattedMessageJamezz id={"Timeslot not available anymore!"} />);
              }
            });
          }
          processStock(response?.data?.data);
          return;
        }

        if (response?.data?.data) {
          dispatch(orderCreated(response?.data?.data));
        }

        if (response?.data?.data?.checkResults) {
          processOrderCheckerResult(response.data.data.checkResults);
          return;
        }

        if (response.data?.data?.paymentError) {
          toast.error(<FormattedMessageJamezz id={"Payment error!"} />);
          return;
        }
        if (response.data?.data?.transactionId && response.data?.data?.PaymentAmount) {
          dispatch(
            cikamTransactionStarted({
              transactionId: response.data.data.transactionId,
              paymentAmount: response.data.data.PaymentAmount,
            })
          );
          return;
        }
        if (response.data?.data?.paymentData?.transaction?.paymentURL) {
          if (
            window.location.origin ===
            response.data?.data?.paymentData?.transaction?.paymentURL.slice(0, window.location.origin.length)
          ) {
            closeAllDialogs();
            const moveToPath = (response.data?.data?.paymentData?.transaction?.paymentURL as string).replace(
              window.location.origin,
              ""
            );
            navigate(moveToPath);
          } else {
            blockingStarted();
            // https://github.com/microsoft/TypeScript/issues/48949
            (window as Window).location = response.data.data.paymentData.transaction.paymentURL;
          }
          return;
        }
        if (response.data?.data?.iframeURL) {
          dispatch(setHiddenIframeURL(response.data.data.iframeURL));
        }
        if (response.data?.data?.orderStatus > 0 && response.data?.data?.paymentError != 1) {
          closeAllDialogs();
          navigate(createReturnPath() + "?oid=" + response.data.data.orderId);
        } else if (response.data?.data?.paymentError === 0) {
          closeAllDialogs();
        } else if (response.data?.data?.paymentError === 1) {
          toast.error(<FormattedMessageJamezz id={"Error starting payment"} />);
        } else {
          toast.error(<FormattedMessageJamezz id={"Error"} />);
        }
        return;
      })
      .catch((error) => {
        const data = error?.response?.data ?? error?.data ?? {};

        if (data?.message == "Used voucher(s) declined.") {
          dispatch(vouchersDeclined());
        }

        if (data?.message) {
          if (data.message === "Errors.ordering.missing-custom-fields") {
            toast.error(
              <FormattedMessageJamezz
                id={"Errors.ordering.missing-custom-fields"}
                values={{
                  missingFields: data.context.join(", "),
                }}
              />
            );
          } else {
            // TODO translations
            // all data?.messages here should be translated similar to Errors.ordering.missing-custom-fields
            toast.error(data?.message);
          }
        } else {
          // unknown error
          toast.error(<FormattedMessageJamezz id="Error" />);
        }
      });
  }, [dispatch, closeAllDialogs, navigate, blockingStarted]);

  return { sendOrder };
}

export default useKioskSendCheckout2;
