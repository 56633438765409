import { rtkQueryApi } from "./rtkQueryApi";
import { JamezzApiResponse } from "../../../../types/shared/api/common_types";
import { CikamStatusCode } from "../cikamSlice";

const cikamEndpoints = rtkQueryApi.enhanceEndpoints({
  addTagTypes: [],
});

export type CikamTransaction = {
  ChangeRemaining: number;
  PaymentAmount: number;
  PaymentID: string;
  InsertedAmount: number;
  DispensedAmount: number;
  TransactionStatus: "closed" | "open";
  TransactionResultDescription: "Cancel" | null;

  StatusCode: CikamStatusCode;
  StatusDescription: string;
};

export type CheckTransactionResponse = {
  data: {
    message: string;
    oid: number;
    paid: boolean;
    paymentResult: number;
    paymentreturn: string;
    success: boolean;
    transaction?: CikamTransaction;
  };
} & JamezzApiResponse;

type CancelTransactionResponse = {
  data: {};
} & JamezzApiResponse;

export const cikamApi = cikamEndpoints.injectEndpoints({
  endpoints: (builder) => ({
    checkTransaction: builder.query<CheckTransactionResponse, string>({
      query: (transactionId: string) => {
        return {
          url: `/request/sessions/checkpayment?useSecondProvider=true&transactionid=${transactionId}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 0,
    }),
    cancelTransaction: builder.mutation<CancelTransactionResponse, string>({
      query: (transactionId: string) => {
        return {
          url: `/request/sessions/cancelpayment?useSecondProvider=true&transactionid=${transactionId}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useCheckTransactionQuery, useCancelTransactionMutation } = cikamApi;
