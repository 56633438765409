import { useCallback } from "react";
import Box from "@mui/material/Box";
import useTraitFiltering from "../../../../../global/utils/articleTraits/useTraitFiltering";
import { Chip, Collapse } from "@mui/material";
import { TransitionGroup } from "react-transition-group";
import { useAppSelector } from "../../../../../global/utils/redux/store";
import { selectArticleTraitsMap } from "../../../../../global/utils/redux/selectors/selectArticleTraitsMap";

export default function ActiveQuickTraitsChipBar() {
  const stateOfArticleTraits = useAppSelector((state) => state.traitFilter.stateOfArticleTraits);
  const { articleTraitsMap } = useAppSelector(selectArticleTraitsMap);
  const { setValuesForFilter } = useTraitFiltering();

  const onDelete = useCallback(
    (traitKey: string, articleTraitValue: string) => {
      const values = [...(stateOfArticleTraits[traitKey] ?? [])];

      // remove
      const index = values.findIndex((v) => v === articleTraitValue);
      values.splice(index, 1);

      setValuesForFilter(articleTraitsMap[traitKey], values);
    },
    [articleTraitsMap, setValuesForFilter, stateOfArticleTraits]
  );

  return (
    <Box>
      <TransitionGroup style={{ display: "inline-flex", flexWrap: "wrap" }}>
        {Object.keys(stateOfArticleTraits).reduce((items: any[], traitKey: string) => {
          stateOfArticleTraits[traitKey]?.forEach((articleTraitValue: string) => {
            items.push(
              <Collapse orientation={"horizontal"} key={traitKey + ":" + articleTraitValue}>
                <Chip
                  className={"JS-ActiveQuickTraitsChipBar-Chip"}
                  sx={{ marginX: 1, padding: 3, fontWeight: 800 }}
                  label={articleTraitValue}
                  onClick={() => {
                    onDelete(traitKey, articleTraitValue);
                  }}
                  onDelete={() => {
                    onDelete(traitKey, articleTraitValue);
                  }}
                />
              </Collapse>
            );
          });

          return items;
        }, [])}
      </TransitionGroup>
    </Box>
  );
}
