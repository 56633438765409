import { useEffect, useMemo, useRef, useState } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import Box from "@mui/material/Box";
import { ClickAwayListener, Collapse, GlobalStyles } from "@mui/material";
import "./Keyboard.css";
import store, { useAppDispatch, useAppSelector } from "../../utils/redux/store";
import { isElementChildOf } from "../../utils/domHelpers/DomHelpers";
import { keyboardClosed, setKeyboardValue } from "../../utils/redux/globalSlice";

const qwerty = [
  "1 2 3 4 5 6 7 8 9 0 {bksp} {close}",
  "q w e r t y u i o p",
  "a s d f g h j k l {enter}",
  "z x c v b n m , .",
  "{shift} _ @ {space} -",
];
const shiftQwerty = [
  "1 2 3 4 5 6 7 8 9 0 {bksp} {close}",
  "Q W E R T Y U I O P",
  "A S D F G H J K L {enter}",
  "Z X C V B N M , .",
  "{shift} _ @ {space} -",
];

const azerty = [
  "1 2 3 4 5 6 7 8 9 0 {bksp} {close}",
  "a z e r t y u i o p",
  "q s d f g h j k l m {enter}",
  "w x c v b n , .",
  "{shift} _ @ {space} -",
];
const shiftAzerty = [
  "1 2 3 4 5 6 7 8 9 0 {bksp} {close}",
  "A Z E R T Y U I O P",
  "Q S D F G H J K L M {enter}",
  "W X C V B N , .",
  "{shift} _ @ {space} -",
];

function checkCloseKeyboard() {
  const clickAway = store.getState().global.keyboard.timestamp;

  window.setTimeout(() => {
    if (store.getState().global.keyboard.timestamp === clickAway && store.getState().global.keyboard.isOpen) {
      store.dispatch(keyboardClosed());
    }
  }, 50);
}

/**
 * Warning:
 * ClickAwayListener listens for onMouseUp, if you stopPropagation somewhere, the listener won't trigger

 * @constructor
 */
export default function KeyboardWrapper() {
  const isOpen = useAppSelector((state) => state.global.keyboard.isOpen);
  const value = useAppSelector((state) => state.global.keyboard.value);
  const pLayout = useAppSelector((state) => state.global.keyboard.layout);
  const keyboardPosition = useAppSelector((state) => state.global.keyboard.position);
  const keyboardLayoutSetting = useAppSelector((state) => state.global.salesarea.custom_data?.keyboard_layout);

  const [layout, setLayout] = useState(pLayout ?? "default");
  const dispatch = useAppDispatch();
  const keyboard = useRef<any | null>(null);

  const keyboardLayout = useMemo(() => {
    return keyboardLayoutSetting === "AZERTY" ? azerty : qwerty;
  }, [keyboardLayoutSetting]);

  const keyboardLayoutShift = useMemo(() => {
    return keyboardLayoutSetting === "AZERTY" ? shiftAzerty : shiftQwerty;
  }, [keyboardLayoutSetting]);

  const handleShift = () => {
    if (keyboardLayoutSetting === "AZERTY") {
      const newLayoutName = layout === "default" ? "shift" : "default";
      setLayout(newLayoutName);
    } else {
      const newLayoutName = layout === "default" ? "shift" : "default";
      setLayout(newLayoutName);
    }
  };

  const onKeyPress = (button: any) => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift();

    if (button === "{enter}" || button === "{close}") {
      const activeElement = document.activeElement as HTMLElement | null;
      if (activeElement) {
        activeElement.blur();
      }
      dispatch(keyboardClosed());
    }
    if (button === "{monkeytail}") {
    }
  };

  useEffect(() => {
    if (keyboard.current) {
      keyboard.current.setInput(value);
    }
  }, [keyboard, value]);

  useEffect(() => {
    setLayout(pLayout);
  }, [pLayout]);

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        marginLeft: "auto",
        marginRight: "auto",
        zIndex: 1400,
        width: 1,
        left: 0,
        right: 0,
        height: 0,
        color: "black",
      }}
    >
      <GlobalStyles styles={{ ".hg-button": { height: "150px !important", width: "120px !important" } }} />
      {isOpen && keyboardPosition === "push" ? (
        <GlobalStyles
          styles={{
            ".MuiDialog-root": { bottom: "780px !important" },
            ".MuiBackdrop-root": { bottom: "780px !important" },
            html: { height: "1140px !important" },
          }}
        />
      ) : null}
      <Collapse in={isOpen} unmountOnExit>
        <ClickAwayListener
          mouseEvent={false}
          onClickAway={(evt) => {
            const target = evt.target;
            const focusedElement = document.activeElement as HTMLElement;
            const clickedOutsideSelectedInputElement =
              target === null || focusedElement == null || !isElementChildOf(target as HTMLElement, focusedElement);
            if (clickedOutsideSelectedInputElement) {
              checkCloseKeyboard();
            }
          }}
        >
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              marginLeft: "auto",
              marginRight: "auto",
              zIndex: 10,
              width: 1,
              left: 0,
              right: 0,
            }}
          >
            <Keyboard
              key={layout}
              keyboardRef={(r) => (keyboard.current = r)}
              layout={{
                default: keyboardLayout,
                shift: keyboardLayoutShift,
                numpad: ["1 2 3 {bksp}", "4 5 6 {enter}", "7 8 9 +", "* 0 # ,"],
                numonly: ["1 2 3 {bksp}", "4 5 6 ", "7 8 9 ", " 0  ", "   {enter}"],
                decimal: ["1 2 3 {bksp}", "4 5 6 ", "7 8 9 ", ". 0 . ", "   {enter}"],
              }}
              display={{
                "{bksp}": "⌫",
                "{enter}": "OK",
                "{space}": "Space",
                "{close}": "✖",
              }}
              buttonAttributes={[
                { attribute: "id", value: "hg-button-monkeytail", buttons: "@" },
                { attribute: "id", value: "hg-button-dash", buttons: "-" },
                { attribute: "id", value: "hg-button-lodash", buttons: "_" },
              ]}
              mergeDisplay={true}
              layoutName={layout}
              onChange={(value: string) => {
                const input = document.activeElement;
                if (input) {
                  try {
                    Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set?.call(
                      input,
                      value
                    );

                    // This will trigger a new render for the component
                    input.dispatchEvent(new Event("change", { bubbles: true }));
                    dispatch(setKeyboardValue((input as HTMLInputElement).value));
                    keyboard.current.setInput((input as HTMLInputElement).value);
                  } catch (e) {}
                }
              }}
              onRender={(keyboard) => {
                keyboard?.setInput(value);
              }}
              preventMouseDownDefault={true}
              onKeyPress={onKeyPress}
            />
          </Box>
        </ClickAwayListener>
      </Collapse>
    </Box>
  );
}

export function useCheckActiveElement() {
  useEffect(() => {
    window.addEventListener("blur", checkCloseKeyboard, true);

    return () => {
      window.removeEventListener("blur", checkCloseKeyboard);
    };
  }, []);
}
