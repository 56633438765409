import { Button, CircularProgress, Fade } from "@mui/material";

import { useAppSelector } from "../../utils/redux/store";
import { useCancelTransactionMutation } from "../../utils/redux/api/cikamApi";
import { QueryStatus } from "@reduxjs/toolkit/query";
import FormattedMessageJamezz from "../FormattedMessageJamezz";

export default function CikamCancelButton() {
  const transactionId = useAppSelector((state) => state.cikam.transactionId);
  const [cancelTransaction, result] = useCancelTransactionMutation();

  return (
    <Button
      variant={"contained"}
      disabled={result.status === QueryStatus.pending}
      startIcon={
        <Fade in={false}>
          <CircularProgress />
        </Fade>
      }
      endIcon={
        <Fade in={result.status === QueryStatus.pending}>
          <CircularProgress />
        </Fade>
      }
      color={"error"}
      onClick={() => {
        if (transactionId) {
          cancelTransaction(transactionId).then((response) => {});
        }
      }}
    >
      <FormattedMessageJamezz id={"Cancel"} />
    </Button>
  );
}
