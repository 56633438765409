import { format } from "date-fns";
import { digestMessage } from "../../../../global/utils/crypto/digestMessage";
import Article from "../../../../global/utils/models/menu/Article";

export class PropposApi {
  private readonly baseUrl: string;
  private readonly deviceId: string;
  private readonly secretString: string;

  constructor(propposIp: string, device_id: string, secret_string: string) {
    this.deviceId = device_id;
    this.secretString = secret_string;
    let propposIpWithPort = `${propposIp}`;
    if (!propposIp.includes(":")) {
      propposIpWithPort = `${propposIpWithPort}:5001`;
    }
    this.baseUrl = `http://${propposIpWithPort}/api`;
  }

  private generateAuthHash() {
    const dateYYYYMMDD = format(new Date(), "yyyyMMdd");
    let message = `${this.deviceId}${this.secretString}${dateYYYYMMDD}`;
    return digestMessage(message);
  }

  public async get(path: string) {
    const response = await fetch(`${this.baseUrl}/${path}`, {
      headers: {
        hash: await this.generateAuthHash(),
      },
      method: "get",
    });

    return {
      response,
      data: await response.json(),
    };
  }
}

export interface PropposProduct {
  id: string;
  quantity: number;
  name: string;
}

export interface PropposApiProduct extends PropposProduct {
  score: number;
}

export interface PropposJamezzProduct extends PropposProduct {
  article: Article;
}
