export type SimpleStateMachine<StateProperties = {}> = {
  start: string;
  states: {
    [states: string]: {
      transitions?: string[];
    } & StateProperties;
  };
};

export class InvalidStateTransitionException extends Error {}

export class StateMachine {
  private readonly machine: SimpleStateMachine;
  public current_state: string;

  private readonly on_transition: (old_state: string, new_state: string) => any;

  constructor(machine: SimpleStateMachine, on_transition: (old_state: string, new_state: string) => any) {
    this.machine = machine;
    this.current_state = machine.start;
    this.on_transition = on_transition;
  }

  public next(step: string) {
    const allowed_transitions = this.machine.states[this.current_state].transitions ?? [];
    const transition_allowed = allowed_transitions.find((t) => t === step);

    if (transition_allowed) {
      const previous_state = this.current_state;
      this.current_state = step;
      this.on_transition(previous_state, step);
    } else {
      throw new InvalidStateTransitionException(`Transition from ${this.current_state} to ${step} not allowed.`);
    }
  }
}
