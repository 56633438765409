import { useAppSelector } from "../../global/utils/redux/store";
import { useEffect } from "react";

export function adjustViewportWithZoom(zoomFactor: number) {
  let viewportTag = document.querySelector('meta[name="viewport"]');

  if (!viewportTag) {
    // The viewport tag should always exist, but handle anyway
    viewportTag = document.createElement("meta");
    viewportTag.setAttribute("name", "viewport");
    document.head.appendChild(viewportTag);
  }
  viewportTag.setAttribute(
    "content",
    `width=device-width, initial-scale=${zoomFactor} maximum-scale=${zoomFactor}, user-scalable=no`
  );
}

export function useZoomFactor() {
  const zoomFactor = useAppSelector((state) => state.global.salesarea?.custom_data?.kiosk?.zoom_factor ?? 0);
  useEffect(() => {
    if (zoomFactor > 0) {
      adjustViewportWithZoom(zoomFactor);
    }
  }, [zoomFactor]);
}
