import Echo from "laravel-echo";
import { useEffect } from "react";
import { usePrinterCheckResponse } from "../../kiosk/utils/usePrinterCheck";
import { useAppSelector } from "./redux/store";
import { fetchSharedShoppingCart } from "./sharedShoppingCart/processSharedShoppingCart";
import Pusher from "pusher-js";

let initialized: boolean = false;

export default function useWebsockets2() {
  const echo = useEcho();
  const salesareaId = useAppSelector((state) => state.global.salesarea?.id);
  const checkPrinterResponse = usePrinterCheckResponse();

  const isSharedShoppingCartEnabled = useAppSelector((state) => state.shoppingCart.isSharedShoppingCartEnabled);
  const webcode = useAppSelector((state) => state.global.webcode);

  // const sessionState = useAppSelector((state) => state.global.sessionState);
  // console.log(sessionState.sessionid);
  // echo.channel("AppEvents." + sessionState.sessionid).listen(".Orders.PayStatus.changed", () => {
  // if (!this.state.paymentReady) {
  //     this.loadRemoteData();
  // }
  // });
  // const vestigingId = 1;
  //

  useEffect(() => {
    let subscribedWebcode = webcode;
    if (!initialized) {
      if (salesareaId && isSharedShoppingCartEnabled && webcode) {
        let channel = echo.channel(`sharedShoppingCart.${webcode}`);

        channel.listen(".shoppingCartUpdated", (response: any) => {
          fetchSharedShoppingCart();
        });

        channel.listen(".shoppingCartCleared", (response: any) => {});
        channel.listen(".shoppingCartUuidCreated", (response: any) => {});
        channel.listen(".shoppingCartUuidCanceled", (response: any) => {});
        channel.listen(".shoppingCartTicketUpdated", (response: any) => {});
        initialized = true;

        fetchSharedShoppingCart();
      }
    }
    return () => {
      initialized = false;

      echo.leaveChannel(`sharedShoppingCart.${subscribedWebcode}`);
    };
  }, [echo, salesareaId, webcode, isSharedShoppingCartEnabled]);

  useEffect(() => {
    if (salesareaId) {
      echo.channel(`printerStates${salesareaId}`).listen(".printerStatus", (response: any) => {
        checkPrinterResponse({
          printerId: response.printer_id,
          errorState: response.errorState,
          vestigingId: response.vestiging_id,
        });
      });

      // echo.channel(`tableSession.${salesareaId}`).listen(".MenuUpdated", () => {
      //   getMenu();
      // });
    }
    return () => {
      if (salesareaId) {
        echo.leaveChannel(`printerStates${salesareaId}`);
      }
    };
  }, [checkPrinterResponse, echo, salesareaId]);
}

window.Pusher = Pusher;

const pusher_config = {
  broadcaster: "pusher",
  key: import.meta.env.VITE_PUSHER_APP_KEY,
  cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
  forceTLS: true,
};

const ci_config = {
  broadcaster: "null",
};

const echo: Echo = new Echo({
  ...(import.meta.env.JAMEZZ_CI ? ci_config : pusher_config),
});

export function useEcho() {
  return echo;
}
