import { useEffect, useMemo } from "react";
import { useAppSelector } from "../../../global/utils/redux/store";
import { BlinkstickStatus } from "../../../global/utils/redux/blinkstickSlice";
import axios from "axios";

export default function Blinkstick() {
  const blinkstick_settings = useAppSelector((state) => state.global.salesarea.blinkstick_settings);

  if (blinkstick_settings) {
    return <BlinkstickController />;
  } else {
    return null;
  }
}

interface BlinkstickSettings {
  accessToken?: string;
  defaultStatusColor?: string;
  eancodeErrorColor?: string;
  checkAgeColor?: string;
}

function BlinkstickController() {
  const blinkstick_settings = useAppSelector((state) => state.global.salesarea.blinkstick_settings);
  const blinkstickStatus = useAppSelector((state) => state.blinkstick.status);

  const blinkstickSettings: BlinkstickSettings | null = useMemo(() => {
    if (blinkstick_settings) {
      return JSON.parse(blinkstick_settings);
    }
    return null;
  }, [blinkstick_settings]);

  const accessToken = useMemo(() => {
    return blinkstickSettings?.accessToken ?? null;
  }, [blinkstickSettings]);

  const defaultStatusColor = useMemo(() => {
    return blinkstickSettings?.defaultStatusColor ?? null;
  }, [blinkstickSettings]);

  const eancodeErrorColor = useMemo(() => {
    return blinkstickSettings?.eancodeErrorColor ?? null;
  }, [blinkstickSettings]);

  const ageCheckNotificationColor = blinkstickSettings?.checkAgeColor ?? null;

  useEffect(() => {
    if (!accessToken) {
      console.warn("Blinkstick access token not configured");
      return;
    }
    switch (blinkstickStatus) {
      case BlinkstickStatus.Default:
        // TODO: Set blinkstick color defaults
        updateBlinkstickColor(accessToken, defaultStatusColor);
        break;
      case BlinkstickStatus.EancodeError:
        // TODO: Set blinkstick color defaults
        updateBlinkstickColor(accessToken, eancodeErrorColor);
        break;
      case BlinkstickStatus.AgeCheckNotification:
        // TODO: Set blinkstick color defaults
        updateBlinkstickColor(accessToken, ageCheckNotificationColor);
    }
  }, [accessToken, ageCheckNotificationColor, blinkstickStatus, defaultStatusColor, eancodeErrorColor]);

  return null;
}

function updateBlinkstickColor(accessToken: string, color: string | null) {
  if (color) {
    let strippedColor = color.replace("#", "");
    strippedColor = strippedColor.substring(0, 6);
    const url = `https://www.blinkstick.com/d/${accessToken}/${strippedColor}.json`;
    axios.get(url);
  }
}
