import { useEffect } from "react";
import useOrderModeSelector from "./useOrderModeSelector";
import { Box, ButtonBase, Typography } from "@mui/material";
import { keyframes } from "@emotion/react";
import OrderMode from "../../utils/models/OrderMode";

import { useAppSelector } from "../../../global/utils/redux/store";
import { selectAppLanguage } from "../LanguageSelector/useLanguage";

function OrderModeSelector() {
  const { orderModes, onClickOrderMode } = useOrderModeSelector();
  const selectedLanguage = useAppSelector(selectAppLanguage);
  const customKioskButtonsEatin = useAppSelector((state) => state.global.salesarea?.customKioskButtonsEatin);
  const customKioskButtonsTakeaway = useAppSelector((state) => state.global.salesarea?.customKioskButtonsTakeaway);

  const b = keyframes`
0% {
    transform: scale(1);
    color: inherit;
   transform-origin: center;
    } 
50% {
    transform: scaleX(1.05);
    transform-origin: center;
}
100% {
    transform: scale(1);
    color: inherit;
    transform-origin: center;
}`;

  useEffect(() => {
    if (orderModes) {
      for (const orderMode of orderModes) {
        if (orderMode.default === 1) {
          setTimeout(() => {
            onClickOrderMode(orderMode);
          }, 100);
          break;
        }
      }
    }
  }, [orderModes, onClickOrderMode]);

  return (
    <Box
      className={"JS-OrderModeSelector-Root"}
      sx={{ width: 1, display: "flex", justifyContent: "center", marginTop: 120 }}
    >
      {orderModes?.map((orderMode: OrderMode) => {
        if (orderMode.customHtml) {
          orderMode = { ...orderMode };
          if (customKioskButtonsEatin && orderMode.vatGroup == 2) {
            orderMode.customHtml = customKioskButtonsEatin;
          } else if (customKioskButtonsTakeaway && orderMode.vatGroup == 1) {
            orderMode.customHtml = customKioskButtonsTakeaway;
          }
          orderMode.customHtml = orderMode.customHtml.replace(/\{label\}/, orderMode.label);

          return (
            <div
              key={orderMode.label}
              className={"JS-OrderModeSelector-ButtonBase"}
              onClick={(e) => {
                e.stopPropagation();
                onClickOrderMode(orderMode);
              }}
              dangerouslySetInnerHTML={{
                __html: orderMode.customHtml,
              }}
            />
          );
        }
        return (
          <ButtonBase
            key={orderMode.label}
            className={"JS-OrderModeSelector-ButtonBase"}
            sx={{
              margin: 4,
              backgroundColor: "primary.main",
              padding: 4,
              width: 400,
              borderRadius: 8,
              animation: `${b} 1000ms ease infinite 800ms`,
              boxShadow: "0 1px 10px 0px rgba(0,0,0,0.6)",
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (orderMode.webcode) {
                if (import.meta.env.MODE === "development") {
                  window.location.href =
                    "http://jamezzv2.test/dl/" + orderMode.webcode + "?language=" + selectedLanguage;
                } else {
                  window.location.href = "https://jamezz.app/dl/" + orderMode.webcode + "?language=" + selectedLanguage;
                }
              } else {
                onClickOrderMode(orderMode);
              }
            }}
          >
            <Typography
              className={"JS-OrderModeSelector-ButtonBase-Text"}
              sx={{ fontSize: 48, textTransform: "uppercase", fontWeight: 800 }}
            >
              {orderMode.translationOverwrite?.[selectedLanguage] ?? orderMode.label}
            </Typography>
          </ButtonBase>
        );
      })}
    </Box>
  );
}

export default OrderModeSelector;
