import store, { RootState, useAppDispatch, useAppSelector } from "./redux/store";
import { useCallback } from "react";
import { httpPost } from "./api/http";
import parseShoppingCart from "./order/parseShoppingCart";
import { apiRoutes, createReturnPath } from "./config";
import { useDialog } from "./dialog/DialogProvider";
import { orderCreated, setHiddenIframeURL, setServerTimestamp } from "./redux/globalSlice";
import { toast } from "react-toastify";
import FormattedMessageJamezz from "../components/FormattedMessageJamezz";
import { selectAppLanguage } from "../../kiosk/components/LanguageSelector/useLanguage";
import { orderSucceeded, selectAutoAddedItems, sharedShoppingCartReceived } from "./redux/shoppingCartSlice";
import useAppNavigate from "./useAppNavigate";
import isQr from "./helpers/isQr";
import addVariousItemsToOrder, { addVariousItemsToVoucherOrderItems } from "./order/addVariousItemsToOrder";
import OrderArticle from "./models/order/OrderArticle";
import { mockSendCheckout } from "../../kiosk/utils/dev/liveInterfaceMocks";
import { IQRSendCheckoutRequest, qrCheckoutParams } from "../../types/qr/sendCheckout";
import { selectAllPackagingsByUserInput } from "./redux/packagingsSlice";
import { findArrangementGroupForArticle } from "./redux/arrangements/arrangementsSlice.tsx";
import _ from "lodash";
import { selectCustomFields } from "./redux/selectors/selectCustomFields";
import { selectPaymentMethodCustomField } from "./redux/selectors/selectPaymentMethodCustomField";
import { OrderResponse } from "../../types/kiosk/sendCheckout";
import { IntlShape, useIntl } from "react-intl";
import { processOrderCheckerResult } from "./hooks/useOrderChecker.tsx";
import { selectArticleArticlegroupsMap } from "./redux/selectors/selectArticleArticlegroupsMap.ts";
import { selectActiveArrangement } from "./redux/arrangements/selectActiveArrangement.tsx";
import { selectPiggyGiftcardsWithUsingAmounts } from "./redux/selectors/selectPiggyGiftcardsWithUsingAmounts.tsx";
import { vouchersDeclined } from "./vouchersV2/vouchersV2Slice.tsx";
import { processStock } from "./hooks/processStock.tsx";
import { fetchSharedShoppingCart } from "./sharedShoppingCart/processSharedShoppingCart.ts";
import { BlockUserInput } from "@jamezz/react-components";

const sendCheckout: IQRSendCheckoutRequest = function sendCheckout(checkoutParams: qrCheckoutParams) {
  return httpPost(apiRoutes.kiosk.checkout, checkoutParams, {
    showToastSuccess: false,
    showSpinner: true,
    showToastError: false,
  });
};

function addArrangementDataToOrderItems(orderItems: OrderArticle[], state: RootState) {
  if (state.global.salesarea.use_arrangements) {
    const activeArrangement = selectActiveArrangement(state);
    if (activeArrangement != null) {
      for (const orderArticle of orderItems) {
        const articleArticleGroupsMap = selectArticleArticlegroupsMap(state);
        const arrangementGroup = findArrangementGroupForArticle(
          orderArticle.article,
          articleArticleGroupsMap,
          activeArrangement.groups
        );
        if (arrangementGroup) {
          orderArticle.arrangement_credit_cost = arrangementGroup.credit_cost;
        }
      }
    }
  }
}

function checkArrangementRulesResponse(res: OrderResponse["data"]["data"], intl: IntlShape) {
  if ("reason" in res) {
    const { reason } = res;
    if (reason === "AyceLimitation.roundTimer") {
      toast.warn(intl.formatMessage({ id: "AYCE.rules.round-timer" }));
    } else if (reason === "AyceLimitation.creditsPerCover") {
      toast.warn(intl.formatMessage({ id: "AYCE.rules.credit-limit" }));
    } else if (reason === "AyceLimitation.maxTime") {
      toast.warn(intl.formatMessage({ id: "AYCE.rules.max-time" }));
    } else if (reason === "AyceLimitation.roundCounter") {
      toast.warn(intl.formatMessage({ id: "AYCE.rules.round-counter" }));
    }
    return true;
  }

  return false;
}

export default function useSendCheckout2() {
  const intl = useIntl();
  const { closeAllDialogs } = useDialog();
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();
  const { blockingStarted } = BlockUserInput.useBlockUserInput();

  const selectedLanguage = useAppSelector(selectAppLanguage);

  const iFrameRedirect = (targetUrl: string) => {
    window.parent.location = targetUrl;
  };

  return useCallback(() => {
    if (store.getState().dev.forcedOpen) {
      toast.info("Salesarea is not open!");
      return;
    }

    const dateTimeStamp = import.meta.env.__BUILD_DATE__;
    const state = store.getState();
    const qrParams = isQr() ? state.shoppingCart.jamezzPaymentMethod : {};
    const mwiseParams: {
      oracleDiscountIds?: number[];
      mwiseCodes?: string[];
    } = {};
    if (state.global.salesarea.mwise.enabled) {
      if (state.mwise.claimedReward) {
        mwiseParams["oracleDiscountIds"] = [state.mwise.claimedReward.oracleDiscountId];
        mwiseParams["mwiseCodes"] = [state.mwise.claimedReward.mwiseCode];
      }
    }
    const customFields = _.keyBy(selectCustomFields(store.getState()), "name");
    const paymentCustomField = selectPaymentMethodCustomField(store.getState());
    let orderArticles = parseShoppingCart(state.shoppingCart.items);

    orderArticles = orderArticles.sort((itemA: OrderArticle, itemB: OrderArticle) => {
      return itemA.article.sortKey - itemB.article.sortKey;
    });
    const allergenOrderArticles = state.shoppingCart.allergenOrderArticles;
    if (Object.keys(allergenOrderArticles).length > 0) {
      orderArticles = orderArticles.concat(Object.values(allergenOrderArticles));
    }

    const autoAddedItems = selectAutoAddedItems(state);
    let packagingItems: OrderArticle[] = [];
    if (state.global.salesarea.use_packagings) {
      packagingItems = selectAllPackagingsByUserInput(state);
    }
    const excludedItems = state.shoppingCart.excludedFromOrdering;

    const copyItems = [...orderArticles, ...autoAddedItems, ...packagingItems].filter(
      (item) => !excludedItems.includes(item.article.id)
    );
    const copyVoucherOrderItems: OrderArticle[] = [];
    addVariousItemsToOrder(copyItems);
    addArrangementDataToOrderItems(copyItems, state);
    addVariousItemsToVoucherOrderItems(copyVoucherOrderItems);

    const voucherOrderItemsParsed = parseShoppingCart(copyVoucherOrderItems);

    const sendCheckoutRequest = state.dev.liveEditModeEnabled ? mockSendCheckout : sendCheckout;

    sendCheckoutRequest({
      piggy_giftcards: selectPiggyGiftcardsWithUsingAmounts(state),
      piggy_prepaids: store.getState().piggy.usePrepaids,
      items: copyItems,
      orderCustomFields: _.chain(state.customFields.orderCustomFields)
        .mapValues((orderCustomField) => {
          if (paymentCustomField?.name === orderCustomField.customFieldName) {
            return { customField: paymentCustomField, ...orderCustomField };
          }

          const customField = customFields[orderCustomField.customFieldName];

          return { customField: customField, ...orderCustomField };
        })
        .value(),
      build: dateTimeStamp,
      voucherOrderItems: voucherOrderItemsParsed,
      returnUrl: createReturnPath(),
      selectedLanguage,
      cardhash: state.global.sessionState?.rawData?.hash,
      discountCardNr: state.global.sessionState?.rawData?.cardnr,
      ...mwiseParams,
      ...qrParams,
      sharedShoppingCartUuids: state.shoppingCart.sharedShoppingCartTransactionUuidsProcessed,
      vouchersV2: state.vouchersV2.vouchers,
    })
      .then((response) => {
        if (response?.data?.data) {
          dispatch(orderCreated(response?.data?.data));
        }

        if (!("checkResults" in response.data.data) && "reason" in response.data.data) {
          checkArrangementRulesResponse(response.data.data, intl);

          if (response?.data?.data?.checkerNames && Array.isArray(response?.data?.data?.checkerNames)) {
            response?.data?.data?.checkerNames.forEach((checkerName) => {
              if (checkerName === "CheckTimeslotAvailable") {
                toast.error(<FormattedMessageJamezz id={"Timeslot not available anymore!"} />);
              }
            });
          }
          processStock(response?.data?.data);
          return;
        }

        if (response?.data?.data?.shared_basket?.oufOfSync) {
          fetchSharedShoppingCart();
          toast.warn(<FormattedMessageJamezz id={"Shopping Cart State uuids must be the same"} />);
          return;
        }

        if (!response?.data?.data?.requestPayment && response?.data?.data?.shared_basket?.readyToOrder) {
          store.dispatch(sharedShoppingCartReceived(response?.data?.data?.shared_basket));
          store.dispatch(setServerTimestamp(response?.data.timestamp));
          return;
        }

        if (response?.data?.data?.checkResults) {
          const checkResults = response.data.data.checkResults;
          processOrderCheckerResult(checkResults);
          const timeslotRules = "CheckTimeslotAvailable";
          if (checkResults?.resultNames && checkResults.resultNames.includes(timeslotRules)) {
            /**
             * Refresh order slots
             */
          }
          return;
        }

        if (response.data?.data?.paymentError) {
          toast.error(<FormattedMessageJamezz id={"Payment error!"} />);
          return;
        }

        if (response.data?.data?.paymentData?.transaction?.paymentURL) {
          if (
            window.location.origin ===
              response.data?.data?.paymentData?.transaction?.paymentURL.substr(0, window.location.origin.length) &&
            response.data?.data?.paymentData?.transaction?.paymentURLReloadDocument !== true
          ) {
            closeAllDialogs();
            const moveToPath = (response.data?.data?.paymentData?.transaction?.paymentURL as string).replace(
              window.location.origin,
              ""
            );
            navigate(moveToPath);
          } else {
            blockingStarted();
            if (response.data?.data?.paymentData?.transaction?.iFrameRedirect) {
              iFrameRedirect(response.data.data.paymentData.transaction.paymentURL);
            } else {
              // https://github.com/microsoft/TypeScript/issues/48949
              (window as Window).location = response.data.data.paymentData.transaction.paymentURL;
            }
          }
          return;
        }
        if (response.data.data.iframeURL) {
          dispatch(setHiddenIframeURL(response.data.data.iframeURL));
        }
        if (response.data.data.shared_shopping_cart_ordered) {
          toast.success(<FormattedMessageJamezz id={"Your order is placed successfully!"} />);
          dispatch(orderSucceeded());
        } else if (response.data.data.orderStatus > 0 && response.data.data?.paymentError != 1) {
          blockingStarted();
          closeAllDialogs();
          navigate(createReturnPath() + "?oid=" + response.data.data.orderId);
        } else if (response.data.data.paymentError === 0) {
          closeAllDialogs();
        } else if (response.data.data.paymentError === 1) {
          toast.error(<FormattedMessageJamezz id={"Error starting payment"} />);
        } else {
          toast.error(<FormattedMessageJamezz id={"Error"} />);
        }
        return;
      })
      .catch((error) => {
        const data = error?.response?.data ?? error?.data ?? {};

        if (data?.message == "Used voucher(s) declined.") {
          dispatch(vouchersDeclined());
        }

        if ("message" in data) {
          if (data.message === "Errors.ordering.missing-custom-fields") {
            toast.error(
              <FormattedMessageJamezz
                id="Errors.ordering.missing-custom-fields"
                values={{
                  missingFields: data.context.join(", "),
                }}
              />
            );
          } else if (data.message === "AYCE.error.no_active_arrangement") {
            toast.error(<FormattedMessageJamezz id="AYCE.error.no_active_arrangement" />);
          } else if (data.message === "Errors.ordering.ayce.product-not-in-arrangement") {
            toast.error(
              <FormattedMessageJamezz
                id="Errors.ordering.ayce.product-not-in-arrangement"
                values={{
                  name: data.context.name,
                  id: data.context.id,
                }}
              />
            );
          } else {
            toast.error(data.message);
          }
        } else {
          toast.error(<FormattedMessageJamezz id={"Error"} />);
        }
      });
  }, [selectedLanguage, dispatch, intl, closeAllDialogs, navigate, blockingStarted]);
}
