import { Box } from "@mui/material";
import { selectAppLanguage, selectAvailableLanguages } from "./useLanguage";

// @ts-ignore
import CountryLanguages from "country-language";
import store, { useAppSelector } from "../../../global/utils/redux/store";
import useOrderModeSelector from "../OrderModeSelector/useOrderModeSelector";
import TextLanguageButton from "./TextLanguageButton.tsx";
import FlagLanguageButton from "./FlagLanguageButton.tsx";

interface MapLanguageEntry {
  code: string;
  style?: {
    backgroundSize?: string;
  };
  background?: string;
}

function LanguageSelector() {
  const { orderModes, onClickOrderMode } = useOrderModeSelector();

  const selectedLanguage = useAppSelector(selectAppLanguage);
  const languages = useAppSelector(selectAvailableLanguages);

  const languagePresentation = useAppSelector(
    (state) => state.global.salesarea.custom_data?.kiosk?.how_to_present_languages
  );

  return (
    <Box
      className={"JS-LanguageSelector-Root"}
      sx={{ width: 1, justifyContent: "center", display: "flex", flexWrap: "wrap", gap: 5 }}
    >
      {languages.length > 1
        ? languages.map((language) => {
            if (languagePresentation === "text") {
              return (
                <TextLanguageButton
                  key={language}
                  language={language}
                  orderModes={orderModes}
                  onClickOrderMode={onClickOrderMode}
                  selectedLanguage={selectedLanguage}
                />
              );
            } else {
              return (
                <FlagLanguageButton
                  key={language}
                  language={language}
                  orderModes={orderModes}
                  onClickOrderMode={onClickOrderMode}
                  selectedLanguage={selectedLanguage}
                />
              );
            }
          })
        : null}
    </Box>
  );
}

export const languageToCountry = (lang: string): MapLanguageEntry => {
  const nlFlag = (store.getState().global.salesarea?.nlFlag ?? "NL").toUpperCase();

  // Defining the type of 'map' explicitly
  const map: Record<string, MapLanguageEntry> = {
    en: { code: "GB" },
    nl: { code: nlFlag },
    de: { code: "DE" },
    fr: { code: "FR", style: {} },
    zh: { code: "CN", style: { backgroundSize: "contain" }, background: "#ef1c26" },
    es: { code: "ES", style: { backgroundSize: "contain" }, background: "#aa151b" },
    da: { code: "DK" },
    sv: { code: "SE" },
    hr: { code: "HR" },
    it: { code: "IT" },
    ca: { code: "es-ct" },
    ja: { code: "JP", style: { backgroundSize: "contain" }, background: "#fff" },
  };

  if (map[lang]) {
    return map[lang];
  }

  // If not found in map, we try to get it from the CountryLanguages
  const fallbackCountryCode = CountryLanguages.getLanguageCountries(lang)?.[0]?.code_2 ?? lang;

  return {
    code: fallbackCountryCode,
  };
};

export default LanguageSelector;
