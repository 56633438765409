import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import useCurrency, { CurrencyLocation } from "../../../../global/utils/useCurrency";
import clsx from "clsx";
import { useAppSelector } from "../../../../global/utils/redux/store";
import { selectArticleCampaignsMap } from "../../../../global/utils/redux/selectors/selectArticleCampaignsMap";
import Article, { getArticlePrice } from "../../../../global/utils/models/menu/Article.ts";
import { selectSalesAreaPriceLineId } from "../../../../global/utils/useSalesAreaPriceLineId.ts";

export default function PriceWrapper({ article }: { article: Article }) {
  const { id, vaprice } = article;
  const localPrice = article.customData?.local_price > 0 ? article.customData?.local_price : null;
  const parseCurrency = useCurrency({ location: CurrencyLocation.Menu });
  const articleCampaignsMap = useAppSelector(selectArticleCampaignsMap);
  const isCampaignArticle = articleCampaignsMap[id]?.length > 0;
  const background = useAppSelector(
    (state) =>
      state.global.salesarea?.media_urls?.extra_settings__kioskv5_background_of_price_label?.[0]?.conversions?.optimized
        ?.url
  );

  const priceLineId = useAppSelector(selectSalesAreaPriceLineId);
  const price = getArticlePrice(article, { salesAreaPriceLineId: priceLineId });
  return price > 0 || vaprice > 0 ? (
    <Box
      className={"JS-ArticleContent-PriceWrapper"}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: background ? "primary.contrastText" : null,

        background: background ? `url('${background}')` : null,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
    >
      {localPrice != null ? (
        <>
          <Typography className={"JS-ArticleContent-LocalPrice"} sx={{ textDecoration: "line-through", fontSize: 18 }}>
            {parseCurrency(localPrice)}
          </Typography>
        </>
      ) : null}
      <Typography
        sx={{
          fontWeight: 800,
          whiteSpace: "nowrap",
          fontSize: 25,
          marginLeft: localPrice != null ? 1 : null,
          // color: isCampaignArticle ? "red" : null,
        }}
        className={clsx(isCampaignArticle ? "JS-ArticleContent-Price-IsCampaign" : null, "JS-ArticleContent-Price")}
      >
        {parseCurrency(vaprice > 0 ? vaprice : price)}
      </Typography>
    </Box>
  ) : null;
}
