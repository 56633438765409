import { useCallback, useEffect, useRef } from "react";
import { throttle } from "lodash";

export default function useInputListener(submitBuffer: (buffer: string) => void) {
  const keyBuffer = useRef<string>("");

  const resetBuffer = useCallback(
    () =>
      throttle(() => {
        if (keyBuffer.current) {
          keyBuffer.current = "";
        }
      }, 100),
    []
  );

  const onKeydown = useCallback(
    (e: KeyboardEvent) => {
      resetBuffer();

      if (e.key === "Enter") {
        e.preventDefault();
      }

      if (e.key?.length === 1) {
        keyBuffer.current += e.key;
      } else if (e.key === "Backspace") {
        keyBuffer.current = keyBuffer.current.substring(0, keyBuffer.current.length - 1);
      } else if (e.key === "Enter") {
        const buffer = keyBuffer.current;
        keyBuffer.current = "";
        if (buffer) {
          submitBuffer(buffer);
        } else {
          // skipped empty buffer
        }
      }
    },
    [resetBuffer, submitBuffer]
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeydown);

    return () => {
      document.removeEventListener("keydown", onKeydown);
    };
  }, [onKeydown]);

  window.useInputListener = submitBuffer;
}
