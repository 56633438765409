import store, { useAppSelector } from "./redux/store";
import { useEffect, useMemo } from "react";
import OpeningHours, { findOpenStateAndDuration, getNow, JamezzTimeExceptionTypes } from "./models/JamezzHours";
import parseApiTimeTable, { parseApiDayTimeTable } from "./api/parseApis/parseApiTimeTable";
import { add, differenceInMilliseconds } from "date-fns";
import { openStateAndDurationSet } from "./redux/globalSlice.tsx";

export default function useSalesareaIsOpen() {
  const forcedOpen = useAppSelector((state) => state.dev.forcedOpen);

  const sessionState = useAppSelector((state) => state.global.sessionState);

  const systemOnline = sessionState?.systemOnline && sessionState?.tableIsActive && sessionState?.zoneIsActive;

  const openStateAndDuration = useAppSelector((state) => state.global.openStateAndDuration);

  return useMemo(() => {
    if (forcedOpen) {
      return true;
    }

    return systemOnline && openStateAndDuration.isOpen;
  }, [forcedOpen, systemOnline, openStateAndDuration]);
}

//
export function useOpenStateAndDuration() {
  const sessionState = useAppSelector((state) => state.global.sessionState);
  const openStateAndDuration = useAppSelector((state) => state.global.openStateAndDuration);

  const openingHoursExceptions = useMemo(() => {
    const exceptions: any = {};
    sessionState?.time_odds
      ?.filter((timeOdd: any) => {
        return timeOdd.type === JamezzTimeExceptionTypes.OpeningHours;
      })
      .forEach((item: any) => {
        exceptions[item.date] = parseApiDayTimeTable(item.scheme);
      });
    return exceptions;
  }, [sessionState]);

  const openingHours = useMemo<OpeningHours | null>(() => {
    if (sessionState?.time_settings?.timeOpenScheme) {
      return parseApiTimeTable(sessionState?.time_settings?.timeOpenScheme, openingHoursExceptions) ?? null;
    } else {
      return null;
    }
  }, [openingHoursExceptions, sessionState]);

  useEffect(() => {
    if (openingHours) {
      const newState = findOpenStateAndDuration(openingHours, getNow());
      store.dispatch(openStateAndDurationSet(newState));
    }
  }, [openingHours]);

  useEffect(() => {
    let timer: number | null = null;
    if (openStateAndDuration?.duration) {
      add(getNow(), openStateAndDuration.duration);
      const timeout = differenceInMilliseconds(add(getNow(), openStateAndDuration.duration), getNow()) + 1000;

      timer = window.setTimeout(() => {
        if (openingHours) {
          const newState = findOpenStateAndDuration(openingHours, getNow());
          store.dispatch(openStateAndDurationSet(newState));
        }
      }, timeout);
    }
    return () => {
      if (timer != null) {
        clearTimeout(timer);
      }
    };
  }, [openStateAndDuration.duration, openStateAndDuration.isOpen, openingHours]);
}
