import { httpGet } from "../../global/utils/api/http";
import { useCallback } from "react";

import { setPrinterErrorState } from "../../global/utils/redux/globalSlice";
import { useAppDispatch, useAppSelector } from "../../global/utils/redux/store";

export let printerPollTimer: ReturnType<typeof setTimeout> | null = null;

export interface PrinterStatusResponse {
  printerId: number;
  vestigingId: number;
  errorState: number;
}

export function usePrinterCheckResponse() {
  const dispatch = useAppDispatch();

  return useCallback((printerStatusResponse: PrinterStatusResponse) => {
    // If response is before poll, clear poll timer
    if (printerPollTimer) {
      clearTimeout(printerPollTimer);
      printerPollTimer = null;
    }

    // Analyse response
    if (printerStatusResponse.errorState !== undefined) {
      // Error state === 0 -> OK
      dispatch(setPrinterErrorState(printerStatusResponse.errorState));
    }
  }, []);
}

export default function usePrinterCheck() {
  const salesareaId = useAppSelector((state) => state.global.salesarea.id);
  const checkPrinterResponse = usePrinterCheckResponse();

  const checkPrinter = useCallback(() => {
    return new Promise((resolve) => {
      getPrinterStatus(salesareaId)
        .then((response: any) => {
          checkPrinterResponse({
            printerId: response.data.id,
            errorState: response.data.errorState,
            vestigingId: response.data.vestiging_id,
          });
          resolve(response);
        })
        .catch((err) => {
          resolve(err);
          console.log(err);
        });
    });
  }, [checkPrinterResponse, salesareaId]);

  const triggerCheckPrinter = useCallback(() => {
    if (!printerPollTimer) {
      printerPollTimer = setTimeout(() => {
        printerPollTimer = null;
        checkPrinter();
      }, 10000);
      triggerStatusUpdate(salesareaId)
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [checkPrinter, salesareaId]);

  return { triggerCheckPrinter, checkPrinter };
}

function triggerStatusUpdate(salesareaId: number) {
  return httpGet(`/core/printing/${salesareaId}/status`);
}

function getPrinterStatus(salesareaId: number) {
  return httpGet(`/core/printing/${salesareaId}/dbstatus`);
}
