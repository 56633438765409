import { useFetchMenuDataQuery, useFetchMenuUpdatedAtQuery, useLazyFetchMenuDataQuery } from "../redux/api/menuDataApi";
import { useEffect } from "react";
import { useAppSelector } from "../redux/store";

export default function useLoadMenuData() {
  const menukaartVestigingId = useAppSelector((state) => state.global.salesarea?.menukaartVestigingId);
  const menuUpdatedAt = useAppSelector((state) => state.menuData.menu_updated_at);
  const [refetchMenu] = useLazyFetchMenuDataQuery();

  const pollingInterval =
    useAppSelector((state) => state.global.salesarea?.custom_data?.polling_interval_data_reload_v5) ?? 900;

  useFetchMenuUpdatedAtQuery(
    { menukaartVestigingId: menukaartVestigingId ?? 0 },
    { pollingInterval: 120_000, skip: !menukaartVestigingId }
  );

  const { isLoading } = useFetchMenuDataQuery(undefined, {
    pollingInterval: Math.max(900_000, pollingInterval * 1000),
    skip: !menukaartVestigingId,
  });

  useEffect(() => {
    if (menuUpdatedAt) {
      refetchMenu();
    }
  }, [menuUpdatedAt, refetchMenu]);
}
