import store, { useAppDispatch } from "../redux/store";
import { useLogoutMutation } from "../redux/api/piggyAuthApi";
import { resetToDefaults } from "../redux/piggySlice";
import { useCallback } from "react";

export default function useResetPiggy() {
  const dispatch = useAppDispatch();
  const [logout] = useLogoutMutation();

  return useCallback(() => {
    const piggyCard = store.getState().piggy.contactIdentifierScan;
    if (piggyCard) {
      logout();
    }

    dispatch(resetToDefaults());
  }, [dispatch, logout]);
}
