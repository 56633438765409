import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import _ from "lodash";
import { selectArticlesMap } from "./selectArticlesMap";
import { selectArticlegroupsMap } from "./selectArticlegroupsMap";
import { selectCampaignsMap } from "./selectCampaignsMap.ts";
import { CategoryItemResponse } from "../api/menuDataApi.ts";

export const selectCategoryItemsMap = createSelector(
  [
    (state: RootState) => state.menuData.category_items,
    selectArticlegroupsMap,
    selectArticlesMap,
    selectCampaignsMap,
    (state: RootState) => state.menuData.menukaart_products,
  ],
  (category_items, articlegroupsMap, articlesMap, campaignsMap, menukaartProducts) => {
    const categoryItemsMap: {
      [categoryId: string]: CategoryItemResponse[];
    } = {};

    category_items
      .filter((entry) => {
        if (entry.category_item_type == "product") {
          return articlesMap[entry.category_item_id] && articlegroupsMap[entry.category_id];
        } else if (entry.category_item_type == "campaign") {
          return campaignsMap[entry.category_item_id] && articlegroupsMap[entry.category_id];
        } else if (entry.category_item_type == "category") {
          return articlegroupsMap[entry.category_item_id] && articlegroupsMap[entry.category_id];
        }
      })
      .forEach((categoryItem) => {
        if (!categoryItemsMap[categoryItem.category_id]) {
          categoryItemsMap[categoryItem.category_id] = [];
        }

        categoryItemsMap[categoryItem.category_id].push(categoryItem);
      });

    menukaartProducts
      .filter((menukaartProduct) => articlegroupsMap[menukaartProduct.menukaart_id])
      .forEach((menukaartProduct) => {
        if (!categoryItemsMap[menukaartProduct.menukaart_id]) {
          categoryItemsMap[menukaartProduct.menukaart_id] = [];
        }
        categoryItemsMap[menukaartProduct.menukaart_id].push({
          category_item_type: "product",
          category_id: menukaartProduct.menukaart_id,
          category_item_id: menukaartProduct.product_id,
          sort_key: articlesMap[menukaartProduct.product_id]?.sortKey ?? 999,
        });
      });

    return _.chain(categoryItemsMap)
      .mapValues((record) => {
        return _.values(record).sort((a, b) => a.sort_key - b.sort_key);
      })
      .value();
  }
);
