import { useAppSelector } from "../../../global/utils/redux/store";
import { Button, getContrastRatio, useTheme } from "@mui/material";
import { RefObject, useCallback, useEffect, useState } from "react";
import Articlegroup, { getArticlegroupName } from "../../../global/utils/models/menu/Articlegroup";
import { useScroll } from "../../../global/utils/ScrollProvider";
import { postAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics";
import clsx from "clsx";
import { scrollArticlegroupContent } from "../../../global/utils/menu/ScrollArticlegroupTabListener";
import Box from "@mui/material/Box";
import { throttle } from "lodash";
import { selectAppLanguage } from "../LanguageSelector/useLanguage.ts";

export default function SubArticlegroupTabBar({
  articlegroups,
  contentRef,
  heightTopBar,
}: {
  articlegroups: Articlegroup[];
  contentRef?: RefObject<HTMLElement>;
  heightTopBar: number;
}) {
  const background = useAppSelector(
    (state) =>
      state.global.salesarea?.media_urls?.extra_settings__qrv5_list_of_menus_background?.[0]?.conversions?.optimized
        ?.url
  );
  const [selectedSubArticlegroup, setSelectedSubArticlegroup] = useState<Articlegroup | undefined>(
    articlegroups[0] ?? undefined
  );
  const selectedArticlegroup = useCallback(
    throttle(() => {
      const articlegroup = articlegroups.find((articlegroup, index) => {
        const element = document.getElementById("Articlegroup-" + articlegroup.id);
        if (element) {
          if (element.getBoundingClientRect().bottom > window.innerHeight || index === articlegroups.length - 1) {
            return true;
          }
        }
        return false;
      });
      setSelectedSubArticlegroup(articlegroup);
    }, 500),
    [articlegroups]
  );

  useEffect(() => {
    const ref = contentRef?.current;
    if (ref) {
      ref.addEventListener("scroll", selectedArticlegroup);
    }
    return () => {
      if (ref) {
        ref.removeEventListener("scroll", selectedArticlegroup);
      }
    };
  }, [contentRef, selectedArticlegroup]);

  return (
    <>
      <Box
        className={"JS-SubArticlegroupTabBar-Root"}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: 2,
          paddingTop: 2,
          marginBottom: 2,
          backgroundColor: background ? undefined : "background.default",
          background: background ? `url(${background})` : undefined,
        }}
      >
        {articlegroups.map((articlegroup) => {
          return (
            <ArticlegroupTab
              key={articlegroup.id}
              articlegroup={articlegroup}
              isArticlegroupSelected={selectedSubArticlegroup?.id === articlegroup.id}
              heightTopBar={heightTopBar}
            />
          );
        })}
      </Box>
    </>
  );
}

function ArticlegroupTab({
  articlegroup,
  isArticlegroupSelected,
  heightTopBar,
}: {
  articlegroup: Articlegroup;
  isArticlegroupSelected: boolean;
  heightTopBar: number;
}) {
  const theme = useTheme();
  const { scrollTabBlocked } = useScroll();
  const lang = useAppSelector(selectAppLanguage);

  return (
    <Button
      className={clsx(
        "JS-SubArticlegroupTabBar-Tab",
        isArticlegroupSelected ? "JS-SubArticlegroupTabBar-Tab-selected" : "JS-SubArticlegroupTabBar-Tab-not-selected"
      )}
      id={"SubArticlegroupTab-" + articlegroup.id}
      style={{
        backgroundColor: isArticlegroupSelected ? theme.palette.primary.main : "unset",
        color: isArticlegroupSelected
          ? getContrastRatio(theme.palette.primary.main, "#fff") > 2.5
            ? "#fff"
            : "#111"
          : undefined,
      }}
      sx={{
        fontWeight: 800,

        borderRadius: 2,
      }}
      onClick={() => {
        postAnalyticsEvent({
          category: "ScrollToArticleGroup",
          action: articlegroup.name,
        });
        scrollTabBlocked.current = true;
        scrollArticlegroupContent(scrollTabBlocked, articlegroup, heightTopBar);
      }}
    >
      {getArticlegroupName(articlegroup, lang)}
    </Button>
  );
}
