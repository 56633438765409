import { useCallback, useEffect } from "react";
import store, { useAppSelector } from "../redux/store";
import { httpGet, httpPost } from "../api/http";
import { setShoppingCart } from "../redux/shoppingCartSlice";

export default function useServerSideShoppingCart() {
  const uuid = useAppSelector((state) => state.shoppingCart.serverSideShoppingCart?.uuid);
  const shoppingCartWebcode = useAppSelector((state) => state.shoppingCart.serverSideShoppingCart?.webcode);
  const webcode = useAppSelector((state) => state.global.webcode);
  const isSharedShoppingCartEnabled = useAppSelector((state) => state.shoppingCart.isSharedShoppingCartEnabled);
  const createNewShoppingCart = useCallback(() => {
    httpPost(`/v5_2/shopping-cart`, null, { showToastSuccess: false, showSpinner: false }).then((response: any) => {
      store.dispatch(setShoppingCart(response.data.data));
    });
  }, []);

  const getShoppingCart = useCallback(() => {
    const uuid = store.getState().shoppingCart.serverSideShoppingCart?.uuid;
    httpGet(`/v5_2/shopping-cart/${uuid}`, null, { showToastError: false })
      .then((response) => {})
      .catch((err) => {
        store.dispatch(setShoppingCart(null));
        if (err.response?.status === 404) {
          createNewShoppingCart();
        }
      });
  }, [createNewShoppingCart]);

  useEffect(() => {
    if (!isSharedShoppingCartEnabled) {
      if (uuid && shoppingCartWebcode == webcode) {
        getShoppingCart();
      } else {
        createNewShoppingCart();
      }
    }
  }, [createNewShoppingCart, getShoppingCart, uuid, webcode, shoppingCartWebcode, isSharedShoppingCartEnabled]);
}
