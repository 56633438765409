import { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import { Collapse, Divider, Typography } from "@mui/material";
import SingleVoucherTableView from "./SingleVoucherTableView.tsx";
import { TransitionGroup } from "react-transition-group";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";

export default function VouchersV2CheckoutView() {
  const vouchersV2 = useAppSelector((state) => state.vouchersV2.vouchers);
  const intl = useIntl();

  return vouchersV2.length === 0 ? null : (
    <>
      <Box sx={{ display: "inline-flex", justifyContent: "space-between", width: 1 }}>
        <Typography>Vouchers</Typography>
      </Box>

      <TransitionGroup style={{ width: "100%" }}>
        {vouchersV2.map((voucher) => {
          console.log(voucher.voucher.code);
          return (
            <Collapse key={voucher.voucher.code}>
              <SingleVoucherTableView voucherV2={voucher} />
            </Collapse>
          );
        })}
      </TransitionGroup>
      <Divider sx={{ width: 1, marginY: 2 }} />
    </>
  );
}
