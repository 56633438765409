import { rtkQueryApi } from "../../utils/redux/api/rtkQueryApi";
import store from "../../utils/redux/store";

const sixPaymentstimApiEndpoints = rtkQueryApi.enhanceEndpoints({
  addTagTypes: ["sixPaymentstimApi-writeLog"],
});

interface writeLog {
  type: string;
  data: any;
  result: string;
  status: number;
}

export const sixPaymentstimApiBackend = sixPaymentstimApiEndpoints.injectEndpoints({
  endpoints: (builder) => ({
    writeTerminalLog: builder.mutation<void, writeLog>({
      query: (data) => {
        let tafel_id = store.getState().global.sessionState?.tafel_id;
        let vestiging_id = store.getState().global.salesarea?.id;
        let paymentData = store.getState().global.order;
        return {
          url: `/core/timsixpayments/log`,
          method: "POST",
          body: { ...data, vestiging_id, tafel_id, paymentData },
        };
      },
    }),
    callSixpaymentsTimCallback: builder.mutation<void, writeLog>({
      query: (data) => {
        return {
          url: `/core/timsixpayments/callback`,
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const { useWriteTerminalLogMutation, useCallSixpaymentsTimCallbackMutation } = sixPaymentstimApiBackend;
