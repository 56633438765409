import { useUpdateRequestShoppingCartConfirmationMutation } from "../redux/api/shoppingCartApi";
import { useEffect } from "react";
import store, { useAppSelector } from "../redux/store";

export default function useCancelRequest() {
  const [updateRequest] = useUpdateRequestShoppingCartConfirmationMutation();
  const uuid = useAppSelector((state) => state.shoppingCart.serverSideShoppingCart?.uuid);

  useEffect(() => {
    const oldUuid = uuid;
    return () => {
      if (oldUuid) {
        const response = store.getState().shoppingCart.shoppingCartConfirmation.response;
        if (response == null && oldUuid && store.getState().shoppingCart.shoppingCartConfirmation.requestSentForUuid) {
          updateRequest({ shoppingCartUuid: oldUuid, response: "DENIED", responseText: "Canceled from Kiosk" });
        }
      }
    };
  }, [updateRequest, uuid]);
}
