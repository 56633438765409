import store, { useAppSelector } from "./redux/store.tsx";
import Article from "./models/menu/Article.ts";
import { selectArticlesMap } from "./redux/selectors/selectArticlesMap.ts";
import { TipPercentages } from "../../types/shared/Salesarea.ts";
import useCurrency, { CurrencyLocation } from "./useCurrency.tsx";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import roundTwoDecimals from "./helpers/roundTwoDecimals.ts";
import { useCustomTexts } from "./useCustomTexts.tsx";
import { postAnalyticsEvent } from "./analytics/useAnalytics.ts";
import { userChangedTipAmount } from "./redux/accountSlice.tsx";

/**
 * Intended for currency inputs.
 * @param currency
 */
function clipCurrencyToTwoDecimals(currency: string): string | number {
  if (currency.length === 0) {
    return "";
  }
  const [wholes, decimals] = currency.split(".");
  if (currency.includes(".") && decimals?.length > 2) {
    const clippedDecimals = decimals.substring(0, 2);
    return Number.parseFloat(wholes + `.${clippedDecimals}`);
  }
  return Number.parseFloat(currency);
}

/**
 * Everything you need to use tips (fooi).
 * - Tells you if it is available
 * - Gives you the tip article you can attach the tip value to
 * - Percentages for the lower, medium and high tip as configured
 */
export function useTipping(): { isAvailable: boolean; tipArticle: Article | null; percentages: TipPercentages } {
  const tipProductId = useAppSelector((state) => state.global.salesarea.tipProductId);
  const articlesMap = useAppSelector(selectArticlesMap);
  const askForTip = useAppSelector((state) => state.global.salesarea.askForTip);
  const percentages = useAppSelector((state) => state.global.salesarea.tipPercentages);

  const tipArticle = articlesMap[String(tipProductId)] ?? null;

  return {
    isAvailable: Boolean(askForTip && tipArticle),
    tipArticle,
    percentages,
  };
}

export function useTipDrawer({
  onTipIsAdded,
  paymentAmount,
}: {
  onTipIsAdded: (tipAmount: number) => void;
  paymentAmount: number;
}) {
  const formatCurrency = useCurrency({ location: CurrencyLocation.Checkout });
  const [customSelected, setCustomIsSelected] = useState(false);
  const amount = paymentAmount;
  const tipAmountValue = useAppSelector((state) => state.account.credits.tipAmount);
  const [chosenTipAmount, setChosenTipAmount] = useState<string>(tipAmountValue > 0 ? String(tipAmountValue) : "");
  const [errorMessage, setErrorMessage] = useState("");
  const { isAvailable: tippingIsAvailable, tipArticle, percentages } = useTipping();
  const intl = useIntl();
  const amountFirstPercentage = roundTwoDecimals(amount * percentages.lower_tip_percentage * 0.01);
  const amountSecondPercentage = roundTwoDecimals(amount * percentages.medium_tip_percentage * 0.01);
  const amountThirdPercentage = roundTwoDecimals(amount * percentages.high_tip_percentage * 0.01);
  const customTexts = useCustomTexts(["add_tip_message"]);

  useEffect(() => {
    if (Number(chosenTipAmount) > 0) {
      setErrorMessage("");
    }
  }, [chosenTipAmount, intl]);

  const onChangeCustomTextField = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const input = event.target.value;
    const tip = String(clipCurrencyToTwoDecimals(input));
    // TODO: @Sander, misschien de moeite waard om hier ook mee te geven of het de "lage", "middel" of "hoge" fooi knop was
    //       die is geselecteerd.
    //       En daarbij het percentage dat daarbij staat ingesteld.
    postAnalyticsEvent({
      category: "TipDrawerBody",
      action: "setChosenTipAmount(" + tip + ")",
    });
    setChosenTipAmount(tip);
  }, []);

  const onCustomCanceled = useCallback(() => {
    postAnalyticsEvent({
      category: "TipDrawerBody",
      action: "setCustomIsSelected(false)",
    });
    setCustomIsSelected(false);
  }, []);

  const onCustomSelected = useCallback(() => {
    postAnalyticsEvent({
      category: "TipDrawerBody",
      action: "setCustomIsSelected(true)",
    });
    setCustomIsSelected(true);
  }, []);

  const onPercentageSelected = useCallback((amount: number) => {
    postAnalyticsEvent({
      category: "TipDrawerBody",
      action: "setChosenTipAmount(" + String(amount) + ")",
    });
    setChosenTipAmount(String(amount));
  }, []);

  const onConfirm = useCallback(() => {
    if (Number(chosenTipAmount) <= 0) {
      setErrorMessage(
        intl.formatMessage({
          id: "TipDrawer.error_message.no_tip_selected",
        })
      );
      return;
    }
    if (tipArticle) {
      postAnalyticsEvent({
        category: "TipDrawerBody",
        action: "createOrUpdateTipProduct(" + chosenTipAmount + ")",
      });
      store.dispatch(userChangedTipAmount(Number(chosenTipAmount)));
    }

    onTipIsAdded(Number(chosenTipAmount));
  }, [chosenTipAmount, intl, onTipIsAdded, tipArticle]);

  const onDontTip = useCallback(() => {
    setChosenTipAmount("");
    if (tipArticle) {
      postAnalyticsEvent({
        category: "TipDrawerBody",
        action: "createOrUpdateTipProduct(0)",
      });
      store.dispatch(userChangedTipAmount(0));
    }

    onTipIsAdded(0);
  }, [onTipIsAdded, tipArticle]);

  return {
    formatCurrency,
    customSelected,
    errorMessage,
    tippingIsAvailable,
    customTexts,
    firstPercentage: percentages.lower_tip_percentage,
    secondPercentage: percentages.medium_tip_percentage,
    thirdPercentage: percentages.high_tip_percentage,
    amountFirstPercentage,
    amountSecondPercentage,
    amountThirdPercentage,
    chosenTipAmount,
    onChangeCustomTextField,
    onCustomCanceled,
    onCustomSelected,
    onPercentageSelected,
    onConfirm,
    onDontTip,
  };
}
