import { BoxProps, Chip, Collapse, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo } from "react";
import OptionGroupCountable from "./OptionGroupCountable";
import OptionGroupRadio from "./OptionGroupRadio";
import OptionGroupOptional from "./OptionGroupOptional";
import OrderOptionGroup, { isOrderOptionGroupValid } from "../../../../global/utils/models/order/OrderOptionGroup";
import useOptionGroup, { Type } from "../../../../global/utils/orderSelector/useOptionGroup";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz";
import { useAppDispatch, useAppSelector } from "../../../../global/utils/redux/store";
import { TransitionGroup } from "react-transition-group";
import { setInvalidOptionGroup } from "../../../../global/utils/redux/shoppingCartSlice";
import {
  OptionTranslationType,
  useOptionTranslations,
} from "../../../../global/utils/translations/useOptionTranslations";
import useSalesareaType, { SalesareaType } from "../../../../global/utils/hooks/useSalesareaType";
import Box from "@mui/material/Box";
import OptionGroup, {
  getOptionGroupName,
  getPropertyOfOptionGroup,
} from "../../../../global/utils/models/menu/OptionGroup";
import { useIntl } from "react-intl";
import _ from "lodash";
import { selectAppLanguage } from "../../../../kiosk/components/LanguageSelector/useLanguage.ts";
import Article from "../../../../global/utils/models/menu/Article.ts";
import OrderArticle from "../../../../global/utils/models/order/OrderArticle.ts";

interface Props {
  orderOptionGroup: OrderOptionGroup;
  onChange: (orderOptionGroup: OrderOptionGroup) => void;
  isFirstOptionGroup?: boolean;
  orderArticle: OrderArticle;
}

export default function OptionGroupContent({ onChange, orderOptionGroup, orderArticle }: Props) {
  const dispatch = useAppDispatch();
  const salesareaType = useSalesareaType();

  const markAsInvalidOrderOptionGroup = useAppSelector(
    (state) => state.shoppingCart.invalidOrderOptionGroups[orderOptionGroup.id]
  );

  const { type } = useOptionGroup(orderOptionGroup, orderArticle.article);
  const { translate } = useOptionTranslations();
  const lang = useAppSelector(selectAppLanguage);

  useEffect(() => {
    if (isOrderOptionGroupValid(orderOptionGroup, orderArticle.article)) {
      dispatch(setInvalidOptionGroup({ orderOptionGroup: orderOptionGroup, invalid: false }));
    }
  }, [dispatch, orderOptionGroup, orderArticle]);
  const theme = useTheme();
  return (
    <>
      <Box
        id={orderOptionGroup.id}
        sx={{
          width: "calc(100% + 3px)",
          marginLeft: "-3px",
          borderLeft: "3px solid",
          borderColor: theme.palette.mode === "light" ? "#0001" : "#fff1",
        }}
      >
        <Box
          className={"JS-OptionGroupContent-Header"}
          sx={{
            backgroundColor: theme.palette.mode === "light" ? "#0001" : "#fff1",
            paddingX: 2,
            paddingY: 1,
          }}
        >
          <Typography className={"JS-OptionGroupContent-Name"} sx={{ fontWeight: 800 }}>
            {!_.isEmpty(orderOptionGroup.optionGroup.translations)
              ? getOptionGroupName(orderOptionGroup.optionGroup, lang)
              : translate(orderOptionGroup.optionGroup.name, OptionTranslationType.OptionGroup)}
          </Typography>
          <TransitionGroup>
            {markAsInvalidOrderOptionGroup ? (
              <Collapse>
                <Chip
                  className="JS-OptionGroupContent-PleaseMakeAChoiceFeedback"
                  sx={{
                    backgroundColor: "error.light",
                    margin: 1,
                    fontWeight: 800,
                    color: "error.contrastText",
                  }}
                  label={<FormattedMessageJamezz id={"Please, make a choice!"} />}
                />
              </Collapse>
            ) : null}
          </TransitionGroup>
          {salesareaType !== SalesareaType.CATALOG ? (
            <OptionGroupHeaderText
              className={"JS-OptionGroupContent-Explanation"}
              optionGroup={orderOptionGroup.optionGroup}
              fontSize={"0.8rem"}
              article={orderArticle.article}
            />
          ) : null}
        </Box>

        {type === Type.Radio ? (
          <OptionGroupRadio
            orderOptionGroup={orderOptionGroup}
            onChange={(orderOptionGroup) => onChange(orderOptionGroup)}
          />
        ) : null}
        {type === Type.Countable ? (
          <OptionGroupCountable
            orderOptionGroup={orderOptionGroup}
            onChange={(orderOptionGroup) => onChange(orderOptionGroup)}
          />
        ) : null}
        {type === Type.Optional ? (
          <OptionGroupOptional
            orderOptionGroup={orderOptionGroup}
            onChange={(orderOptionGroup) => onChange(orderOptionGroup)}
          />
        ) : null}
      </Box>
    </>
  );
}

export function OptionGroupHeaderText({
  optionGroup,
  fontSize,
  article,
  className,
}: {
  optionGroup: OptionGroup;
  fontSize: string;
  article: Article;
  className: BoxProps["className"];
}) {
  const intl = useIntl();

  const message = useMemo<string>(() => {
    const maxCountNonCountable = Math.min(
      getPropertyOfOptionGroup(optionGroup, article, "maxCount"),
      optionGroup.optionIds.length
    );

    const messages = [];
    if (getPropertyOfOptionGroup(optionGroup, article, "minCount") > 0) {
      messages.push(intl.formatMessage({ id: "Required" }));
    }
    if (!optionGroup.countable) {
      if (
        getPropertyOfOptionGroup(optionGroup, article, "minCount") > 0 &&
        maxCountNonCountable > getPropertyOfOptionGroup(optionGroup, article, "minCount")
      ) {
        messages.push(
          intl.formatMessage(
            { id: "QR.Pick between {min} and {max}" },
            { min: getPropertyOfOptionGroup(optionGroup, article, "minCount"), max: maxCountNonCountable }
          )
        );
      } else if (maxCountNonCountable > 0) {
        messages.push(intl.formatMessage({ id: "QR.Pick maximal {count}" }, { count: maxCountNonCountable }));
      }
    } else if (optionGroup.countable) {
      if (
        getPropertyOfOptionGroup(optionGroup, article, "minCount") > 0 &&
        getPropertyOfOptionGroup(optionGroup, article, "maxCount") >
          getPropertyOfOptionGroup(optionGroup, article, "minCount")
      ) {
        messages.push(
          intl.formatMessage(
            { id: "QR.Pick between {min} and {max}" },
            {
              min: getPropertyOfOptionGroup(optionGroup, article, "minCount"),
              max: getPropertyOfOptionGroup(optionGroup, article, "maxCount"),
            }
          )
        );
      } else if (getPropertyOfOptionGroup(optionGroup, article, "maxCount") > 0) {
        messages.push(
          intl.formatMessage(
            { id: "QR.Pick maximal {count}" },
            { count: getPropertyOfOptionGroup(optionGroup, article, "maxCount") }
          )
        );
      }
    }
    return messages.reduce((text, subText, index) => {
      if (index > 0) {
        text += " • ";
      }
      text += subText;
      return text;
    }, "");
  }, [article, intl, optionGroup]);

  return useMemo(
    () => (
      <Typography className={className} sx={{ fontSize }}>
        {message}
      </Typography>
    ),
    [fontSize, message]
  );
}
