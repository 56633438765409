import Box from "@mui/material/Box";

import { keyframes } from "@emotion/react";
import { BoxProps, useTheme } from "@mui/material";
import { ReactNode } from "react";

export default function Bouncer({
  origin = "center",
  scale = 2,
  time = "800ms",
  repeat = 2,
  delay = "300ms",
  children,
  style,
  color,
}: {
  origin?: string;
  scale?: number;
  time?: string;
  repeat?: string | number;
  delay?: string;
  children: ReactNode;
  style?: BoxProps["sx"];
  color?: string;
}) {
  const theme = useTheme();

  const animationColor = color ?? theme.palette.primary.main;

  const b = keyframes`
0% {
    transform: scale(1);
    color: inherit;
   transform-origin: ${origin};
    } 
50% {
    transform: scale(${scale});
    color: ${animationColor};
    transform-origin: ${origin};
}
100% {
    transform: scale(1);
    color: inherit;
    transform-origin: ${origin};
}`;
  return (
    <Box
      sx={{
        animation: `${b} ${time} ease ${repeat} ${delay}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...(style ?? {}),
      }}
    >
      {children}
    </Box>
  );
}
