import { useMemo } from "react";
import Article from "../models/menu/Article";
import useMenuFilters from "./filters/useMenuFilters";
import Articlegroup from "../models/menu/Articlegroup";
import { getArticlesByArticlegroupId } from "../models/menu/Menu";
import { useAppSelector } from "../redux/store";
import { selectArticlesMap } from "../redux/selectors/selectArticlesMap";
import { selectArticlegroupArticlesMap } from "../redux/selectors/selectArticlegroupArticlesMap";

function useArticlegroup(articlegroup: Articlegroup) {
  const articleFilter = useMenuFilters();
  const articlesMap = useAppSelector(selectArticlesMap);
  const articlegroupArticlesMap = useAppSelector(selectArticlegroupArticlesMap);

  const articles = useMemo<Article[]>(() => {
    return (
      getArticlesByArticlegroupId(articlesMap, articlegroupArticlesMap, articlegroup.id).filter(articleFilter) ?? []
    );
  }, [articlegroup, articleFilter, articlesMap, articlegroupArticlesMap]);

  return { articles };
}

export default useArticlegroup;
