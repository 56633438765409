import { useAppSelector } from "../../../global/utils/redux/store";
import TimeoutDialog from "./TimeoutDialog";

export default function KioskTimeout() {
  const printerErrorState = useAppSelector((state) => state.global.printerErrorState);

  if (printerErrorState) {
    return null;
  }
  return <TimeoutDialog />;
}
