import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../global/utils/redux/store";
import Box from "@mui/material/Box";
import clsx from "clsx";
import ArticleTrait from "../../../../../global/utils/models/ArticleTraits/ArticleTrait";
import TabUnstyled from "@mui/base/Tab";
import ArticlegroupTabContent from "../../ArticlegroupTabContent";
import { setIsFilterPageOpen } from "../../../../../global/utils/redux/traitFilterSlice";
import { selectArticleTraitsMap } from "../../../../../global/utils/redux/selectors/selectArticleTraitsMap";

export default function QuickTraitAsTopArticlegroup() {
  const { quickFilterOnArticleTrait, allArticleTraits } = useAppSelector(selectArticleTraitsMap);

  if (allArticleTraits?.length > 0 && quickFilterOnArticleTrait != null) {
    return <Content allArticleTraits={allArticleTraits} quickFilterOnArticleTrait={quickFilterOnArticleTrait} />;
  } else {
    return null;
  }
}

interface Props {
  allArticleTraits: ArticleTrait[];
  quickFilterOnArticleTrait: ArticleTrait;
}
function Content(props: Props) {
  const dispatch = useAppDispatch();
  const allArticleTraits = props.allArticleTraits;
  // TODO: needs to rerender based on this selector, maybe?
  useAppSelector((state) => state.menu.selectedArticlegroup);

  const articleTraitsHaveValues = useMemo(() => {
    return allArticleTraits.some((articleTrait) => {
      return Object.keys(articleTrait.values).length > 0;
    });
  }, [allArticleTraits]);
  const setDialogIsOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(setIsFilterPageOpen(isOpen));
    },
    [dispatch]
  );

  if (!articleTraitsHaveValues) {
    return null;
  }

  return (
    <TabUnstyled
      key={"ArticleTraitValues"}
      value={"ArticleTraitValues"}
      style={{ flexGrow: 1, border: "none", background: "none" }}
      className={"JS-MultipleLayersBottomTabBar-Top-Tab"}
    >
      <Box
        className={clsx(
          "JS-HorizontalArticlegroupTabBar-Button"
          // isArticlegroupSelected && "JS-HorizontalArticlegroupTabBar-Button-Selected"
        )}
        onClick={() => {
          setDialogIsOpen(true);
        }}
        sx={{ height: 1 }}
      >
        <Box
          sx={{
            width: 1,
            height: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ArticlegroupTabContent
            isSelected={false}
            text={props.quickFilterOnArticleTrait.name}
            // imageSrc={props.quickFilterOnArticleTrait.imageSrcs[0] ?? null}
          />
        </Box>
      </Box>
    </TabUnstyled>
  );
}
