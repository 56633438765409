import _ from "lodash";

export function* walkParents(node: HTMLElement | SVGElement) {
  let current: HTMLElement | SVGElement | null = node;
  while (current) {
    yield current;
    if (current.parentNode !== null) {
      current = current.parentNode as HTMLElement | SVGElement;
    } else {
      break;
    }
  }
}

export function isElementChildOf(
  childToFind: HTMLElement | SVGElement,
  elementTest: HTMLElement | SVGElement | ((node: HTMLElement | SVGElement) => boolean)
): boolean {
  for (let parentNode of walkParents(childToFind)) {
    if (_.isFunction(elementTest)) {
      if (elementTest(parentNode)) return true;
    } else if (parentNode === elementTest) {
      return true;
    }
  }
  return false;
}
