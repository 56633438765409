import { Dialog, Drawer } from "@mui/material";
import { useTipping } from "../../global/utils/useTipping.ts";
import { postAnalyticsEvent } from "../../global/utils/analytics/useAnalytics";
import isKiosk from "../../global/utils/helpers/isKiosk.tsx";
import { TipDrawerBodyQr } from "./Tipping/TipDrawerBodyQr.tsx";
import { TipDrawerBodyKiosk } from "../../kiosk/components/Tipping/TipDrawerBodyKiosk.tsx";

export function TipDrawer({
  onTipIsAdded,
  open,
  onClose,
  paymentAmount,
}: {
  onTipIsAdded: (tipAmount: number) => void;
  open: boolean;
  onClose: () => void;
  paymentAmount: number;
}) {
  const { isAvailable: tippingIsAvailable } = useTipping();

  if (isKiosk()) {
    return tippingIsAvailable ? (
      /* z-index necessary to show over a dialog. */
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        keepMounted={false}
        sx={{ zIndex: 10000 }}
        open={open}
        onClose={() => {
          postAnalyticsEvent({
            category: "TipDrawer",
            action: "closeDrawer",
          });
          onClose();
        }}
      >
        <TipDrawerBodyKiosk onTipIsAdded={onTipIsAdded} paymentAmount={paymentAmount} />
      </Dialog>
    ) : null;
  } else {
    return tippingIsAvailable ? (
      /* z-index necessary to show over a dialog. */
      <Drawer
        keepMounted={false}
        sx={{ zIndex: 10000 }}
        anchor="bottom"
        open={open}
        onClose={() => {
          postAnalyticsEvent({
            category: "TipDrawer",
            action: "closeDrawer",
          });
          onClose();
        }}
        elevation={10}
      >
        <TipDrawerBodyQr onTipIsAdded={onTipIsAdded} paymentAmount={paymentAmount} />
      </Drawer>
    ) : null;
  }
}
