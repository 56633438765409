import { MutableRefObject, useEffect, useRef, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { NavigationDot } from "./NavigationDot.tsx";

const navContainerStyles = {
  position: "fixed",
  right: "16px",
  top: "50%",
  translate: "0 -50%",
  maxHeight: "90vh",
  overflowY: "hidden",
};

export function AyceRoundNavigation({
  navigation_items,
  scrollContainer,
}: {
  navigation_items: { count_as_round: boolean; id: string | number }[];
  scrollContainer: MutableRefObject<HTMLDivElement | undefined>;
}) {
  const [selectedNavItem, setSelectedNavItem] = useState(0);
  const navContainer = useRef<HTMLDivElement>();
  const theme = useTheme();

  useEffect(() => {
    let running = true;

    function onTick() {
      if (!scrollContainer.current) {
        window.setTimeout(onTick, 1000 / 8);
        return;
      }

      if (!running) return;

      let idx;
      for (idx = 0; idx < scrollContainer.current.children.length - 1; idx++) {
        const child = scrollContainer.current.children[idx];
        // -2 and not 0 because of alignment with scrollIntoView({block: "start"}) behavior.
        if (child.getBoundingClientRect().top > -2) {
          break;
        }
      }
      if (idx !== selectedNavItem) {
        setSelectedNavItem(idx);
        if (navContainer.current) {
          navContainer.current.children[idx]?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }

      window.setTimeout(onTick, 1000 / 8);
    }

    window.setTimeout(onTick, 1000 / 8);

    return () => {
      running = false;
    };
  }, [scrollContainer, selectedNavItem]);

  return navigation_items.length > 2 ? (
    <Box ref={navContainer} sx={navContainerStyles}>
      {navigation_items.map(({ id }, idx) => {
        return (
          <NavigationDot
            key={id}
            idx={idx}
            color={theme.palette.primary.main}
            active={idx === selectedNavItem}
            scrollContainer={scrollContainer}
            navContainer={navContainer}
          />
        );
      })}
    </Box>
  ) : null;
}
