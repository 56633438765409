import { selectAppLanguage } from "../../kiosk/components/LanguageSelector/useLanguage";
import { RootState, useAppSelector } from "./redux/store";
import _ from "lodash";
import { createSelector } from "@reduxjs/toolkit";
import { selectCustomizableTexts } from "./redux/selectors/selectCustomizableTexts";
import { custom_text_keys, html_translations_keys, Language, Translations } from "../../types/shared/language";

export function useCustomTexts<T extends custom_text_keys>(keys: T[]): { [k in T]?: string } {
  const selectedLanguage = useAppSelector(selectAppLanguage);
  const customTexts = useAppSelector((state) => state.global.salesarea.customized_texts) ?? {};

  return _.pickBy(_.pick(customTexts[selectedLanguage], keys), (value: string) => value.length > 0) as {
    [k in T]?: string;
  };
}

export function useCustomHtmlTranslations<T extends html_translations_keys>(keys: T[]): { [k in T]?: string } {
  const selectedLanguage = useAppSelector(selectAppLanguage);
  const customTexts = useAppSelector((state) => state.global.salesarea.htmlTranslations) ?? {};

  return _.pickBy(_.pick(customTexts[selectedLanguage], keys), (value: string) => value.length > 0) as {
    [k in T]?: string;
  };
}

export function useCustomTextsFromModel(model: string, modelId: string, translationKey: string) {
  const selectedLanguage = useAppSelector(selectAppLanguage);

  const disableOrderingTexts = useAppSelector((state) =>
    selectCustomTextsFromModel(state, model, modelId, translationKey)
  );

  return disableOrderingTexts?.[selectedLanguage] && disableOrderingTexts[selectedLanguage] !== ""
    ? disableOrderingTexts[selectedLanguage]
    : null;
}

export const selectCustomTextsFromModel = createSelector(
  [
    (state: RootState, model: string, modelId: string, translationKey: string) => [model, modelId, translationKey],
    selectCustomizableTexts,
  ],
  ([model, modelId, translationKey], customizableTexts) => {
    return customizableTexts[model]?.[modelId]?.[translationKey] ?? undefined;
  }
);

/**
 * Select translations straight from a translations object
 * @param translations
 * @example
 *   const translations = useSelectTranslations(props.translations);
 *   translations["myTranslationKey"] ?? "default text if translation not defined";
 */
export function useSelectTranslations<T extends Translations<any>>(translations: T | undefined): Partial<T[Language]> {
  const selectedLanguage = useAppSelector(selectAppLanguage);

  // forced type with "as Partial", not sure how to get Typescript itself to return this type.
  return (translations?.[selectedLanguage] ?? {}) as Partial<T[Language]>;
}
