import { useCallback } from "react";
import OrderArticle from "../../utils/models/order/OrderArticle";
import { toast } from "react-toastify";
import { ActiveArrangement, selectArrangementCreditCost } from "../../utils/redux/arrangements/arrangementsSlice.tsx";
import store from "../../utils/redux/store";
import { DialogOption, useDialog } from "../../utils/dialog/DialogProvider.tsx";

import { checkMaxRoundsItemsCanBeOrdered } from "./groupLimitations/maxRounds/maxRoundsLimitation.tsx";
import { checkMaxPerTicket } from "./groupLimitations/maxPerTicket/maxPerTicketLimitation.tsx";
import { IntlShape, useIntl } from "react-intl";
import { CREDITS_PER_COVER_PER_ROUND_DISABLED } from "../../utils/redux/arrangements/arrangementRuleSelectors.ts";
import { selectActiveArrangement } from "../../utils/redux/arrangements/selectActiveArrangement.tsx";
import { checkMaxPerRoundPerCover } from "./groupLimitations/maxPerRoundPerCover/maxPerRoundPerCoverLimitation.tsx";

export enum ArrangementCheckResult {
  Accepted,
  Denied,
  Skipped,
}

export enum CheckResult {
  VALID,
  INVALID,
  SKIPPED,
}

export type ArrangementLimitationCheck = (
  orderArticle: OrderArticle,
  arrangement: ActiveArrangement,
  openDialog: (option: DialogOption) => string,
  closeDialog: () => void,
  intl: IntlShape
) => Promise<{ checkResult: CheckResult; messageText?: string }>;

export function useArrangementChecks() {
  const { openDialog, closeDialog } = useDialog();
  const intl = useIntl();

  return useCallback(
    async (orderArticle: OrderArticle) => {
      const state = store.getState();
      const arrangement = selectActiveArrangement(state);

      if (!state.global.salesarea.use_arrangements || arrangement?.variant === "pre-dining") {
        return ArrangementCheckResult.Accepted;
      }
      if (arrangement == null) {
        return ArrangementCheckResult.Denied;
      }

      const checks = [
        async () => await checkMaxItemsPerRoundPerCover(orderArticle, arrangement, openDialog, closeDialog, intl),
        async () => await checkMaxRoundsItemsCanBeOrdered(orderArticle, arrangement, openDialog, closeDialog, intl),
        async () => await checkMaxPerTicket(orderArticle, arrangement, openDialog, closeDialog, intl),
        async () => await checkMaxPerRoundPerCover(orderArticle, arrangement, openDialog, closeDialog, intl),
      ];

      for (const check of checks) {
        const error = await check();
        if (error.checkResult === CheckResult.INVALID) {
          toast.info(error.messageText ?? "Invalid", {
            toastId: error.messageText,
          });
          return ArrangementCheckResult.Denied;
        } else if (error.checkResult === CheckResult.SKIPPED) {
          return ArrangementCheckResult.Skipped;
        }
      }

      return ArrangementCheckResult.Accepted;
    },
    [closeDialog, openDialog, intl]
  );
}

const checkMaxItemsPerRoundPerCover: ArrangementLimitationCheck = async (
  orderArticle: OrderArticle,
  arrangement: ActiveArrangement,
  _openDialog: (option: DialogOption) => string,
  _closeDialog: () => void,
  intl: IntlShape
) => {
  const state = store.getState();
  const maxItemsPp = arrangement.max_number_of_items_per_round_per_cover;

  if (maxItemsPp === CREDITS_PER_COVER_PER_ROUND_DISABLED) {
    return { checkResult: CheckResult.VALID };
  }

  if (maxItemsPp != null && arrangement.total_covers != null) {
    const count = selectArrangementCreditCost(state, [...state.shoppingCart.items, orderArticle]);
    return maxItemsPp * arrangement.total_covers >= count
      ? { checkResult: CheckResult.VALID }
      : {
          checkResult: CheckResult.INVALID,
          messageText: intl.formatMessage(
            {
              id: "AYCE.limitation-checks.max-credits-per-round",
            },
            {
              maximum: maxItemsPp * arrangement.total_covers,
              current: count,
            }
          ),
        };
  } else {
    return { checkResult: CheckResult.VALID };
  }
};
