import { useAppSelector } from "../../../../global/utils/redux/store";
import isKiosk from "../../../../global/utils/helpers/isKiosk";
import { MutableRefObject, useEffect } from "react";

export function useFocusCustomField(
  inputRef: MutableRefObject<HTMLInputElement | null> | null,
  customFieldName: string
) {
  // kiosk-only
  const openedCustomField = useAppSelector((state) => state.customFields.currentCustomField);

  useEffect(() => {
    const element = inputRef?.current;
    if (isKiosk() && element && openedCustomField === customFieldName) {
      if (document.activeElement !== element) {
        setTimeout(() => {
          element.focus({ preventScroll: true });
        }, 1);
      }
    }
  }, [openedCustomField, customFieldName, inputRef]);
}
