import Image from "../../../global/components/Image";
import { alpha, Tab, Typography } from "@mui/material";
import { memo } from "react";
import { MediaUrl } from "../../../global/utils/redux/api/menuDataApi.ts";

interface Props {
  text: string;
  mediaUrl?: MediaUrl;
  isSelected: boolean;
}

function _ArticlegroupTabContent({ text, mediaUrl, isSelected }: Props) {
  return (
    <Tab
      className={isSelected ? "JS-HorizontalArticlegroupTabBar-Tab-Selected" : ""}
      sx={(theme) => ({
        backgroundColor: isSelected ? alpha(theme.palette.primary.main, 1) : null,
        opacity: 1,
      })}
      icon={
        mediaUrl ? (
          <Image
            className={"JS-HorizontalArticlegroupTabBar-ArticlegroupImage"}
            srcSet={mediaUrl?.conversions?.responsive?.srcset}
            style={{
              width: 170,
              padding: "0 30px",
              height: 200,
              flex: 1,
              objectFit: "contain",
            }}
          />
        ) : (
          <Typography sx={{ width: 1, flex: 1, height: 0, minHeight: 150 }} />
        )
      }
      label={
        <Typography
          className={`JS-HorizontalArticlegroupTabBar-Text${
            isSelected ? " JS-HorizontalArticlegroupTabBar-Text-selected" : ""
          }`}
          sx={{
            marginTop: "15px",
            fontSize: 24,
            color: "background.paper",
            fontWeight: 800,
            width: 1,
            overflowWrap: "break-word",
            borderRadius: 2,
            marginX: 1,
            paddingY: 1,
            marginY: 1,
          }}
        >
          {text}
        </Typography>
      }
    />
  );
}

export const ArticlegroupTabContent = memo(_ArticlegroupTabContent);
export default ArticlegroupTabContent;
