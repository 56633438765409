import { useMemo } from "react";

import { useAppSelector } from "./redux/store";
import useCurrency, { CurrencyLocation } from "./useCurrency";
import { useAccountSubTotalValue } from "./redux/accountSlice";
import { ShoppingCartErrors } from "./redux/shoppingCartSlice";

export default function useOrderLimits() {
  const orderLimitsEnabled = useAppSelector((state) => state.global.salesarea?.orderLimits);
  const minOrderValue = useAppSelector((state) => state.global.sessionState?.minOrderValue);
  const maxOrderValue = useAppSelector((state) => state.global.salesarea?.maxOrderValue);
  const toCurrency = useCurrency({ location: CurrencyLocation.Checkout });

  const amountForOrderLimits = useAccountSubTotalValue();

  const errors = useMemo(() => {
    const errors: ShoppingCartErrors = {};
    // Check orderLimits
    if (orderLimitsEnabled) {
      if (minOrderValue > 0 && minOrderValue > amountForOrderLimits) {
        errors.minOrderValueError = {
          id: "ShoppingCart.errors.minOrderValueError",
          values: { amount: toCurrency(Number(minOrderValue) ?? 0).toString() },
        };
      }
      if (maxOrderValue > 0 && maxOrderValue < amountForOrderLimits) {
        errors.maxOrderValueError = {
          id: "ShoppingCart.errors.maxOrderValueError",
          values: { amount: toCurrency(Number(maxOrderValue) ?? 0).toString() },
        };
      }
    }

    return errors;
  }, [amountForOrderLimits, maxOrderValue, minOrderValue, orderLimitsEnabled, toCurrency]);

  const isValid = useMemo(() => {
    return Object.keys(errors).length === 0;
  }, [errors]);

  return { errors, isValid };
}
