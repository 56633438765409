import { httpGet } from "../http";
import { apiRoutes } from "../../config";
import { AfterPayArticle } from "../../../../qr/pages/AfterPayPage/AfterPayPage";

export interface OutstandingBalanceResponse {
  payment: number;
  total: number;
  items: AfterPayArticle[];
  clientTimestamp: number;
}

export default function checkOutstandingBalance() {
  return new Promise<OutstandingBalanceResponse | null>((resolve, reject) => {
    httpGet<{ data: null | { data: { data: Omit<OutstandingBalanceResponse, "clientTimestamp"> } } }>(
      apiRoutes.qr.checkOutstandingBalance
    )
      .then((response) => {
        if (response.data?.data.data) {
          resolve({
            payment: response.data?.data.data.payment,
            total: response.data?.data.data.total,
            items: response.data?.data.data.items,
            clientTimestamp: new Date().getTime(),
          });
        } else {
          resolve(null);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}
