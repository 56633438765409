import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../redux/store";
import { countProductsWithAgeRequirement, findProductWithHighestAgeRequirement } from "./ageCheckUtils";

export const selectRequireAgeArticle = createSelector(
  [(state: RootState) => state.shoppingCart.items],
  (shoppingCartItems) => {
    const highestArticle =
      shoppingCartItems.length > 0 ? findProductWithHighestAgeRequirement(...shoppingCartItems) : null;

    return Boolean(highestArticle && highestArticle.requireAge != null && highestArticle.requireAge > 0);
  }
);

export const selectRequireAgeArticleCount = createSelector(
  [(state: RootState) => state.shoppingCart.items],
  (shoppingCartItems) => {
    return shoppingCartItems.length > 0 ? countProductsWithAgeRequirement(1, ...shoppingCartItems) : 0;
  }
);
