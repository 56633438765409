import { httpGet } from "./api/http";
import { apiRoutes } from "./config";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { orderSucceeded } from "./redux/shoppingCartSlice";
import store, { useAppDispatch, useAppSelector } from "./redux/store";
import { jamezzApplicationType } from "../../qr/App";
import { orderCreated } from "./redux/globalSlice";
import { CheckPaymentResponse, ISendCheckPaymentRequest, OrderState } from "../../types/shared/checkPayment";
import { mockSendCheckPaymentRequest } from "../../kiosk/utils/dev/liveInterfaceMocks";

const sendCheckPaymentRequest: ISendCheckPaymentRequest = function sendCheckPaymentRequest(oid: string) {
  return httpGet<CheckPaymentResponse>(apiRoutes.kiosk.getOrderStatus + `/${oid}` + window.location.search, null, {
    showToastError: false,
  });
};

let pendingRequest = false;
export default function useCheckPayment() {
  const order = useAppSelector<any>((state) => state.global.order);
  const wait_for_dispatch = useAppSelector<any>((state) => state.global.salesarea.wait_for_dispatch);

  const dispatch = useAppDispatch();

  const isKiosk = useMemo<boolean>(() => {
    return jamezzApplicationType.type === "KIOSK";
  }, []);

  const [orderState, setOrderState] = useState(OrderState.INIT);
  const responseData = useRef<any>({
    payDirect: undefined,
    hasPrinters: undefined,
    apiStatus: undefined,
    printed: undefined,
    payStatus: undefined,
    timestamp: undefined,
    orderMsg: undefined,
  });

  const checkPaymentRequest = store.getState().dev.liveEditModeEnabled
    ? mockSendCheckPaymentRequest
    : sendCheckPaymentRequest;

  const checkOrder = useCallback(
    (oid: string) => {
      return new Promise((resolve) => {
        checkPaymentRequest(oid)
          .then((response) => {
            const payDirect = response?.data?.data?.payDirect;
            const apiStatus = response?.data?.data?.apiStatus;
            const orderFailed = response?.data?.data?.orderFailed;
            const hasPrinters = response?.data?.data?.hasPrinters;
            const orderHash = response?.data?.data?.orderHash;
            const printed = response?.data?.data?.printed;
            const payStatus = response?.data?.data?.payStatus;
            const orderMsg = response?.data?.data?.orderMsg;

            responseData.current.payDirect = payDirect;
            responseData.current.apiStatus = apiStatus;
            responseData.current.orderFailed = orderFailed;
            responseData.current.hasPrinters = hasPrinters;
            responseData.current.orderHash = orderHash;
            responseData.current.printed = printed;
            responseData.current.payStatus = payStatus;
            responseData.current.timestamp = response?.data?.data?.ordered_at;
            responseData.current.orderMsg = orderMsg;

            resolve(true);
          })
          .catch(() => {
            resolve(true);
          });
      });
    },
    [checkPaymentRequest]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (responseData.current.payStatus !== undefined) {
        switch (orderState) {
          case OrderState.INIT:
            if (responseData.current.payDirect) {
              setOrderState(OrderState.WAITING_FOR_PAYMENT);
            } else if (responseData.current.hasPrinters && isKiosk) {
              setOrderState(OrderState.WAITING_FOR_PRINT);
            } else {
              if (wait_for_dispatch) {
                setOrderState(OrderState.WAITING_ON_DISPATCH);
              } else {
                setOrderState(OrderState.SUCCESS);
              }
            }
            break;
          case OrderState.WAITING_FOR_PAYMENT:
            switch (responseData.current.payStatus) {
              case 0:
                break;
              case 1:
                if (responseData.current.hasPrinters && isKiosk) {
                  setOrderState(OrderState.WAITING_FOR_PRINT);
                } else {
                  if (wait_for_dispatch) {
                    setOrderState(OrderState.WAITING_ON_DISPATCH);
                  } else {
                    setOrderState(OrderState.SUCCESS);
                    dispatch(orderSucceeded());
                    dispatch(orderCreated(null));
                  }
                }
                break;
              default:
                setOrderState(OrderState.PAYMENT_FAILED);
                dispatch(orderCreated({ ...order, payStatus: responseData.current.payStatus }));
                break;
            }
            break;
          case OrderState.WAITING_FOR_PRINT:
            if (responseData.current.printed) {
              if (wait_for_dispatch) {
                setOrderState(OrderState.WAITING_ON_DISPATCH);
              } else {
                setOrderState(OrderState.SUCCESS);
                dispatch(orderSucceeded());
              }
            }
            break;
          case OrderState.WAITING_ON_DISPATCH:
            if (responseData.current.orderFailed) {
              setOrderState(OrderState.ORDER_FAILED);
              dispatch(orderSucceeded());
              dispatch(orderCreated(null));
            } else if (responseData.current.apiStatus != null && responseData.current.apiStatus > 0) {
              setOrderState(OrderState.SUCCESS);
              dispatch(orderSucceeded());
              dispatch(orderCreated(null));
            }
            break;
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, isKiosk, order, orderState]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | null = null;

    const url = new URL(window.location.href);
    const oid = order?.orderId ?? url.searchParams.get("oid");

    if (
      oid &&
      orderState != OrderState.SUCCESS &&
      orderState != OrderState.PAYMENT_FAILED &&
      orderState != OrderState.PRINT_FAILED &&
      orderState != OrderState.ORDER_FAILED
    ) {
      pendingRequest = true;
      checkOrder(oid).then(() => {
        pendingRequest = false;
      });

      interval = setInterval(() => {
        if (!pendingRequest) {
          pendingRequest = true;
          checkOrder(oid).then(() => {
            pendingRequest = false;
          });
        }
      }, 3000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [checkOrder, order?.orderId, orderState]);

  return { orderState, responseData };
}
