export enum OrderState {
  INIT = "INIT",
  WAITING_FOR_PAYMENT = "WAITING_FOR_PAYMENT",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  WAITING_ON_DISPATCH = "WAITING_ON_DISPATCH",
  WAITING_FOR_PRINT = "WAITING_FOR_PRINT",
  PRINT_FAILED = "PRINT_FAILED",
  ORDER_FAILED = "ORDER_FAILED",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

export enum PaymentState {
  SUCCESSFUL = 1,
  PENDING = 2,
  ERROR = 3,
  ERROR2 = 0,
}

export type CheckPaymentResponse = {
  data: {
    data: {
      // TODO: Provide actual typings
      payDirect?: 0 | 1;
      apiStatus?: any;
      orderFailed?: any;
      hasPrinters?: any;
      printed?: any;
      payStatus?: any;
      ordered_at?: any;
      orderHash?: any;
      orderMsg?: string;
    };
  };
};
export type ISendCheckPaymentRequest = (oid: string) => Promise<CheckPaymentResponse>;
