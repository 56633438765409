import { useMemo } from "react";
import Image from "../../../../global/components/Image";
import Articlegroup, {
  getArticlegroupDescription,
  getArticlegroupName,
} from "../../../../global/utils/models/menu/Articlegroup";
import { useAppSelector } from "../../../../global/utils/redux/store";
import { Box, Collapse, Grid, Typography } from "@mui/material";
import KioskTopBar from "../../../components/KioskTopBar/KioskTopBar";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz";
import { selectAppLanguage } from "../../../components/LanguageSelector/useLanguage.ts";

interface Props {
  articlegroup: Articlegroup;
  addKioskTopBar?: boolean;
}

export default function MultipleArticlegroupBanner(props: Props) {
  const lang = useAppSelector(selectAppLanguage);

  const description = useMemo(() => {
    return getArticlegroupDescription(props.articlegroup, lang);
  }, [props.articlegroup, lang]);

  const searchValue = useAppSelector((state) => state.global.articleSearchText);

  return (
    <Grid item xs={12} className="JS-ArticlegroupContent-Banner-Wrapper">
      <Collapse in={!searchValue}>
        {props.articlegroup.imageBannerMediaUrl ? (
          <Image
            key={props.articlegroup.imageBannerMediaUrl.id}
            srcSet={props.articlegroup.imageBannerMediaUrl?.conversions?.responsive?.srcset}
            style={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
            }}
            className={"JS-ArticlegroupContent-Image"}
          />
        ) : null}
      </Collapse>
      {props.addKioskTopBar ? <KioskTopBar disableHomeButton={true} /> : null}
      <Collapse in={!searchValue} className="JS-ArticlegroupContent-Text-Collapse">
        <Box
          sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
          className="JS-ArticlegroupContent-Wrapper"
        >
          <Typography
            className={"JS-ArticlegroupContent-Name"}
            sx={{ fontWeight: 800, fontSize: 48, marginLeft: 1, color: "primary.main" }}
          >
            {getArticlegroupName(props.articlegroup, lang)}
          </Typography>

          {props.articlegroup.disableOrdering ? (
            <Typography fontSize={24} sx={{ ml: 1, paddingTop: 1, color: "primary.main" }}>
              <FormattedMessageJamezz id="Articlegroup.messages.not-available" />
            </Typography>
          ) : null}
        </Box>
        {description ? (
          <Typography
            sx={{ fontStyle: "italic", opacity: 0.6, textAlign: "center" }}
            className={"JS-ArticlegroupContent-Description"}
          >
            {description}
          </Typography>
        ) : null}
      </Collapse>
    </Grid>
  );
}
