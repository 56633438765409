import Articlegroup, { getArticlegroupName } from "../../../../global/utils/models/menu/Articlegroup.ts";
import { useScroll } from "../../../../global/utils/ScrollProvider.tsx";
import store, { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import { selectAppLanguage } from "../../../components/LanguageSelector/useLanguage.ts";
import { ButtonBase, Typography } from "@mui/material";
import clsx from "clsx";
import { setSelectedArticlegroup } from "../../../../global/utils/redux/menuSlice.ts";
import Box from "@mui/material/Box";
import Image from "../../../../global/components/Image.tsx";

export default function VerticalArticlegroupTab({ articlegroup }: { articlegroup: Articlegroup }) {
  const { scrollTabBlocked } = useScroll();
  const isArticlegroupSelected = useAppSelector((state) =>
    Boolean(state.menu.selectedArticlegroupIds[articlegroup.id])
  );
  const lang = useAppSelector(selectAppLanguage);

  return (
    <ButtonBase
      key={articlegroup.id}
      id={"ArticlegroupTab-" + articlegroup.id}
      className={clsx(
        "JS-VerticalArticlegroupTab-Button",
        isArticlegroupSelected && "JS-VerticalArticlegroupTab-Button-Selected"
      )}
      onClick={() => {
        scrollTabBlocked.current = true;
        store.dispatch(setSelectedArticlegroup({ articlegroup }));
      }}
      sx={{
        width: "100%",
        height: 350,
        borderBottom: isArticlegroupSelected ? "5px solid" : "unset",
        borderColor: "primary.main",
        transition: "all 800ms",
      }}
    >
      <Box
        sx={{
          width: 1,
          height: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: articlegroup.images[0] ? undefined : "center",
        }}
      >
        {
          articlegroup.images[0] ? (
            <Image
              srcSet={articlegroup.images[0]?.conversions?.responsive?.srcset}
              className={"JS-VerticalArticlegroupTab-Image"}
              style={{
                width: "150px",
                minHeight: 200,
                flex: 1,
                objectFit: "contain",
                margin: "0 auto",
              }}
            />
          ) : null // <Typography sx={{ width: 1, flex: 1, height: 0, minHeight: 150 }}></Typography>
        }

        <Typography
          className={clsx(
            "JS-VerticalArticlegroupTab-Text",
            isArticlegroupSelected && "JS-VerticalArticlegroupTab-Text-Selected"
          )}
          sx={{
            fontSize: 24,
            color: "background.paper",
            fontWeight: 800,
            width: 1,
            textTransform: "uppercase",
            overflowWrap: "break-word",
            transition: "all 100ms",
          }}
        >
          {getArticlegroupName(articlegroup, lang)}
        </Typography>
      </Box>
    </ButtonBase>
  );
}
