import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

interface Props {
  count: number;
}

function ShoppingCartButtonCounter(props: Props) {
  return (
    <Box sx={{ position: "absolute", top: -30, right: 0 }} className={"JS-ShoppingCartButtonCounter-Root"}>
      <Typography
        sx={{
          padding: 2,
          overflow: "hidden",
          width: 65,
          height: 65,
          borderRadius: 10,
          backgroundColor: "text.primary",
          color: "background.paper",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "0.8em",
          fontWeight: 800,
          boxShadow: "0 5px 15px 0px rgba(0,0,0,0.6)",
        }}
        className={"JS-ShoppingCartButtonCounter-Count"}
      >
        {props.count}
      </Typography>
    </Box>
  );
}

export default ShoppingCartButtonCounter;
