import { useEffect, useMemo, useRef } from "react";
import { Alert, Button, Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import CloseDialogButton from "../../../qr/components/Dialog/CloseDialogButton.tsx";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import { BlinkstickStatus, setBlinkstickStatus } from "../../../global/utils/redux/blinkstickSlice";
import EmployeePinCheckDialog from "../EmployeePinCheckDialog/EmployeePinCheckDialog";
import { setEancodeUnknownDialogIsOpen } from "../../../global/utils/redux/globalSlice";
import { apiRoutes, createReturnPath } from "../../../global/utils/config";
import { httpPost } from "../../../global/utils/api/http";
import Article from "../../../global/utils/models/menu/Article";
import { initOrderArticle } from "../../../global/utils/models/order/OrderArticle";
import _ from "lodash";
import useEmployeeCodes from "../../hooks/useEmployeeCodes";
import { selectArticlesMap } from "../../../global/utils/redux/selectors/selectArticlesMap";

interface Props {
  eancode: string;
}

export default function EancodeUnknownDialog(props: Props) {
  const { closeDialog, openDialog } = useDialog();
  const dispatch = useAppDispatch();

  const customData = useAppSelector((state) => state.global.salesarea.custom_data);

  const articlesMap = useAppSelector(selectArticlesMap);

  const requestIsActive = useRef(false);

  const notificationArticle = useMemo<Article | null>(() => {
    const articleId = customData?.notification_article ? JSON.parse(customData.notification_article) : [];

    if (articleId) {
      const article = articlesMap[String(articleId)];
      if (article) {
        return article;
      }
    }
    return null;
  }, [customData.notification_article, articlesMap]);

  const employeeCodes = useEmployeeCodes();

  useEffect(() => {
    dispatch(setBlinkstickStatus(BlinkstickStatus.EancodeError));
    dispatch(setEancodeUnknownDialogIsOpen(true));

    if (notificationArticle && !requestIsActive.current) {
      const orderArticle = _.cloneDeep(initOrderArticle(articlesMap, notificationArticle, 1));

      orderArticle.article.name = "Barcode unknown: " + props.eancode;
      requestIsActive.current = true;
      httpPost(
        apiRoutes.kiosk.checkout,
        {
          items: [orderArticle],
          voucherOrderItems: [],
          orderCustomFields: [],
          returnUrl: createReturnPath(),
          discountCardNr: null,
          isNotification: true,
          eancode: props.eancode,
        },
        { showToastSuccess: false, showToastError: false, showSpinner: false }
      );
    }

    return () => {
      dispatch(setEancodeUnknownDialogIsOpen(false));
    };
  }, [dispatch, articlesMap, notificationArticle, props.eancode]);

  return (
    <>
      <Alert
        severity={"error"}
        sx={{
          "& .MuiAlert-icon": { fontSize: 80 },
          paddingY: 8,
        }}
      >
        {Object.keys(employeeCodes).length === 0 || customData?.disable_user_input_no_ean ? (
          <CloseDialogButton
            onClose={() => {
              dispatch(setBlinkstickStatus(BlinkstickStatus.Default));
              closeDialog();
            }}
          />
        ) : null}
        <Typography sx={{ fontSize: "2em" }}>
          <FormattedMessageJamezz id={"Barcode not recognized!"} />
        </Typography>
        <Typography sx={{ fontSize: "1.5em" }}>
          <FormattedMessageJamezz id={"Please ask the staff for help!"} />
        </Typography>

        {Object.keys(employeeCodes).length > 0 && !customData?.disable_user_input_no_ean ? (
          <Button
            sx={{}}
            onClick={() => {
              closeDialog();
              openDialog({
                children: <EmployeePinCheckDialog />,
                disabledBackdropClick: Object.keys(employeeCodes).length > 0,
              });
            }}
          >
            <FormattedMessageJamezz id={"Enter manually"} />
          </Button>
        ) : null}
      </Alert>
    </>
  );
}
