import { ReactNode } from "react";
import { Box, Stack } from "@mui/material";

// TODO ClassName toevoegen voor live editor
export function MenuContentMessage({ cy, children }: { cy?: string; children: ReactNode }) {
  return (
    <Box
      data-cy={cy}
      sx={{
        position: "absolute",
        left: "50vw",
        bottom: "calc(50vh - 110px)",
        transform: "translate(-50%, 50%)",
        textAlign: "center",
        color: "gray",
      }}
    >
      <Stack gap={3} direction={"column"}>
        {children}
      </Stack>
    </Box>
  );
}
