import { useCallback, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { Button, Stack, Typography } from "@mui/material";
import useCurrency, { CurrencyLocation } from "../../../../global/utils/useCurrency";
import Bouncer from "../../../../qr/components/Bouncer";
import OrderPage from "../../OrderPage/OrderPage";
import SlideUpTransition from "../../../../global/components/SlideUpTransition";
import { useDialog } from "../../../../global/utils/dialog/DialogProvider";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz";
import { useAccountSubTotalValue } from "../../../../global/utils/redux/accountSlice";
import { useAppSelector } from "../../../../global/utils/redux/store";
import { selectArticlesSum } from "../../../../global/utils/redux/shoppingCartSlice";
import Image from "../../../../global/components/Image.tsx";
import PiggyScanLoyaltyButton from "./PiggyScanLoyaltyButton.tsx";

interface Props {
  pixelHeightCartButton: string;
}

export default function WideShoppingCartButton(props: Props) {
  const toCurrency = useCurrency({ location: CurrencyLocation.Menu });
  const count = useAppSelector(selectArticlesSum);
  const subTotalAmount = useAccountSubTotalValue();
  const loyaltyEnabled = useAppSelector((state) => state.global.salesarea.piggy.loyalty.enabled);
  const { openDialog } = useDialog();

  const [bouncerKey, setBouncerKey] = useState<string>(String(count));

  const bouncerKeyTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (!bouncerKeyTimer.current) {
      bouncerKeyTimer.current = setTimeout(() => {
        setBouncerKey(new Date().getTime() + "-" + count);
        bouncerKeyTimer.current = null;
      }, 5000);
    }
  }, [bouncerKey, count]);

  useEffect(() => {
    if (bouncerKeyTimer.current) {
      clearTimeout(bouncerKeyTimer.current);
      bouncerKeyTimer.current = null;
    }
    setBouncerKey(new Date().getTime() + "-" + count);
  }, [count]);

  const onClickViewShoppingCart = useCallback(() => {
    openDialog({
      children: <OrderPage />,
      fullScreen: true,
      TransitionComponent: SlideUpTransition,
    });
  }, [openDialog]);
  const logo = useAppSelector(
    (state) =>
      state.global.salesarea?.media_urls?.extra_settings__kioskv5_logo_of_shopping_cart_button?.[0]?.conversions
        ?.optimized?.url
  );
  return (
    <Stack
      direction={"row"}
      className={"JS-WideShoppingCartButton-Root"}
      justifyContent={"space-between"}
      sx={{
        gridArea: "1/1/1/4",
        height: props.pixelHeightCartButton,
        display: "flex",
        zIndex: 25,
        alignItems: "center",
        backgroundColor: "primary.light",
      }}
      onClick={() => {
        onClickViewShoppingCart();
      }}
    >
      <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} sx={{ flex: 1, width: 0 }}>
        <Typography
          className={"JS-WideShoppingCartButton-Price"}
          sx={{ fontSize: 32, fontWeight: 800, marginRight: 12, color: "primary.contrastText" }}
        >
          {toCurrency(subTotalAmount)}
        </Typography>

        <Bouncer
          key={bouncerKey}
          scale={count > 0 ? 1.2 : 1}
          delay={"0s"}
          time={"500ms"}
          repeat={"1"}
          origin={"bottom"}
        >
          <Button
            data-cy="btn-view-order"
            className={"JS-WideShoppingCartButton-ButtonBase"}
            variant={"contained"}
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
            onClick={(e) => {
              e.stopPropagation();
              onClickViewShoppingCart();
            }}
          >
            <Typography className={"JS-WideShoppingCartButton-ButtonText"} sx={{ fontSize: 32, fontWeight: 800 }}>
              <FormattedMessageJamezz id={"View order"} />
            </Typography>
            {logo ? (
              <Box className={"JS-WideShoppingCartButton-ImageWrapper"}>
                <Image
                  src={logo}
                  style={{ height: "100%", width: "100%", objectFit: "contain" }}
                  className={"JS-WideShoppingCartButton-Image"}
                />
              </Box>
            ) : null}
            <Typography
              className={"JS-WideShoppingCartButton-ButtonTextPrice"}
              sx={{ fontSize: 32, fontWeight: 800, display: "none" }}
            >
              {toCurrency(subTotalAmount)}
            </Typography>
          </Button>
        </Bouncer>
      </Stack>
      {loyaltyEnabled ? (
        <Box sx={{ marginRight: 1 }}>
          <PiggyScanLoyaltyButton showText={true} />
        </Box>
      ) : null}
    </Stack>
  );
}
