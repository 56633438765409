import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import OrderArticle from "../../../global/utils/models/order/OrderArticle.ts";

export function PlusMinusButtons(props: {
  count: number;
  modifyingOrderArticle?: OrderArticle;
  onClickAdd: () => void;
  onClickRemove: () => void;
}) {
  return (
    <Box sx={{ display: "flex", width: 1, justifyContent: "center", alignItems: "center" }}>
      <Button
        data-cy="order-article-selector-page-minus-btn"
        color={"primary"}
        variant={"contained"}
        size={"medium"}
        className={"JS-OrderArticleSelectorPage-PlusButton JS-OrderArticleSelectorPage-CountChangeButton"}
        disabled={!(props.count > (props.modifyingOrderArticle ? 0 : 1))}
        onClick={props.onClickRemove}
      >
        <Remove />
      </Button>
      <Typography sx={{ textAlign: "center", marginX: 3 }}>{props.count}</Typography>
      <Button
        data-cy="order-article-selector-page-plus-btn"
        // sx={{ padding: 0 }}
        color={"primary"}
        className={"JS-OrderArticleSelectorPage-MinusButton JS-OrderArticleSelectorPage-CountChangeButton"}
        variant={"contained"}
        size={"medium"}
        onClick={props.onClickAdd}
      >
        <Add />
      </Button>
    </Box>
  );
}
