import { useLayoutEffect } from "react";
import { useAppSelector } from "../../global/utils/redux/store";
// @ts-ignore
import scrollIntoView from "scroll-into-view";
import { useScroll } from "../../global/utils/ScrollProvider";

export function ScrollToArticleGroup() {
  const { scrollTabBlocked } = useScroll();
  const selectedArticlegroup = useAppSelector((state) => state.menu.selectedArticlegroup);

  useLayoutEffect(() => {
    // const selectedGroup = store.getState().menu.selectedArticlegroup;
    if (selectedArticlegroup) {
      const element = document.getElementById(`ArticlegroupTab-${selectedArticlegroup.id}`);

      if (element) {
        scrollIntoView(
          element,
          {
            align: {
              top: 0,
            },
          },
          () => {
            scrollTabBlocked.current = false;
          }
        );
        scrollTabBlocked.current = true;
      }
    }
  }, [scrollTabBlocked, selectedArticlegroup]);

  return null;
}
