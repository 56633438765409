import { PropposApi, PropposApiProduct } from "./PropposApi";
import { StateMachine } from "../../../../global/utils/simpleStateMachine";
import _ from "lodash";

export class PropposInterface {
  private api: PropposApi;

  private readonly polling_rate_in_ms: number;
  private readonly onScan: (products: PropposApiProduct[]) => void;
  private pollingIntervalId: number | undefined;

  private readonly stateMachine = new StateMachine(
    {
      start: "UNINITIALIZED",
      states: {
        UNINITIALIZED: {
          transitions: ["INITIALIZING", "SHUTTING_DOWN"],
        },
        INITIALIZING: {
          transitions: ["POLLING", "INITIALIZING", "UNUSABLE", "SHUTTING_DOWN"],
        },
        POLLING: {
          transitions: ["POLLING", "SHUTTING_DOWN"],
        },
        SHUTTING_DOWN: {},
        UNUSABLE: {
          transitions: ["SHUTTING_DOWN"],
        },
      },
    },
    this.on_state_transition.bind(this)
  );

  constructor(
    propposIp: string,
    onScan: (products: PropposApiProduct[]) => void,
    deviceId: string,
    secretString: string,
    polling_rate_in_ms: number
  ) {
    this.api = new PropposApi(propposIp, deviceId, secretString);
    this.polling_rate_in_ms = polling_rate_in_ms;
    this.onScan = onScan;
  }

  stop() {
    if (this.stateMachine.current_state !== "SHUTTING_DOWN") {
      this.stateMachine.next("SHUTTING_DOWN");
    }
  }

  private async on_state_transition(old_state: string, new_state: string) {
    console.debug(old_state, new_state);
    if (old_state === "POLLING") {
      window.clearInterval(this.pollingIntervalId);
    }
    switch (new_state) {
      case "UNINITIALIZED":
        break;
      case "INITIALIZING":
        const is_ready = await this.isReady();
        if (is_ready) {
          this.stateMachine.next("POLLING");
        } else {
          const system_is_responsive = await this.startPredictions();
          if (system_is_responsive) {
            window.setTimeout(() => this.stateMachine.next("INITIALIZING"), 1000);
          } else {
            this.stateMachine.next("UNUSABLE");
          }
        }
        break;
      case "POLLING":
        this.pollingIntervalId = window.setInterval(async () => {
          const predictions = await this.getPredictions();
          if (predictions && _.isArray(predictions?.products)) {
            this.onScan(predictions.products);
          }
        }, this.polling_rate_in_ms);
        break;
      case "SHUTTING_DOWN":
        window.clearInterval(this.pollingIntervalId);
        break;
      case "UNUSABLE":
        break;
    }
  }

  private async getPredictions(): Promise<{ products: PropposApiProduct[] }> {
    const { data } = await this.api.get(`predict`);
    return data;
  }

  public init() {
    this.stateMachine.next("INITIALIZING");
  }

  private async isReady(): Promise<boolean> {
    const { data } = await this.api.get(`predict/ready`);

    return data.ready;
  }

  private async startPredictions(): Promise<boolean> {
    const { response } = await this.api.get(`predict/start`);

    return response.status === 200;
  }
}
