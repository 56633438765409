import { ArrowBack } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { useDialog } from "../../global/utils/dialog/DialogProvider";

interface Props {
  onClose?: () => void;
}

export default function BackDialogButton(props: Props) {
  const { closeDialog } = useDialog();
  return (
    <IconButton
      className={"JS-BackDialogButton"}
      sx={{
        zIndex: 100,
        position: "absolute",
        display: "none",
        top: 0,
        left: 0,
        marginTop: 3,
        marginLeft: 3,
        opacity: 0.7,
        background: "grey",
      }}
      onClick={() => {
        if (props.onClose) {
          props.onClose();
        } else {
          closeDialog();
        }
      }}
    >
      <ArrowBack sx={{ width: 100, height: 100 }} className={"JS-BackDialogButton-Icon"} />
    </IconButton>
  );
}
