import { useCallback, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { httpGet } from "../../../global/utils/api/http";
import { apiRoutes } from "../../../global/utils/config";
import _, { debounce } from "lodash";
import useInputListener from "../../../global/utils/useInputListener";
import { useDispatch } from "react-redux";
import { scannerDisabled, scannerEnabled } from "../../../global/utils/redux/globalSlice";
import { flightDetailsSupplied } from "../../../global/utils/redux/userInputSlice";
import { ArrowBack } from "@mui/icons-material";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import IconButton from "@mui/material/IconButton";
import { toast } from "react-toastify";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import BackgroundMediaUrlFromSalesarea from "../../hooks/useBackgroundMediaUrl.tsx";

interface Props {
  onClose: () => void;
  onFinish: () => void;
}

const DOUBLE_SCAN_PREVENTION_DEBOUNCE_MS = 1250;

function BoardingPassPage(props: Props) {
  const dispatch = useDispatch();
  const [swiperRef, setSwiperRef] = useState<SwiperType | null>(null);
  const { closeDialog } = useDialog();

  const firstTimeSkipSlideTo = useRef<boolean>(true);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [scanned, setScanned] = useState<boolean>(false);
  const [destination, setDestination] = useState("");

  const backButtonRef = useRef<HTMLButtonElement | null>(null);

  const questions = [
    {
      key: "destination",
      translationKey: <FormattedMessageJamezz id="Please scan your boarding pass" />,
      type: "scan",
    },
    {
      key: "final_destination",
      translationKey: (
        <FormattedMessageJamezz id="Is {destination} your final destination" values={{ destination: destination }} />
      ),
      type: "confirm",
    },
    {
      key: "direct_flight",
      translationKey: (
        <FormattedMessageJamezz id="Are you taking a direct flight from Schiphol Airport to your final destination" />
      ),
      type: "confirm",
    },
    { translationKey: "You can now pay your order by using your credit or debitcard" },
  ];
  useEffect(() => {
    if (swiperRef) {
      if (firstTimeSkipSlideTo.current) {
        firstTimeSkipSlideTo.current = false;
      } else {
        swiperRef.slideTo(questionIndex);
      }
    }
  }, [questionIndex, swiperRef]);

  const getBoardingPassData = useCallback(
    debounce(
      (boardingPassString: string) => {
        if (scanned || questionIndex > 0) {
          return;
        }
        setScanned(true);

        httpGet(apiRoutes.schiphol.getDestination + "?boarding_pass=" + boardingPassString, null, { showSpinner: true })
          .then((response: any) => {
            const data = response?.data?.data;

            if (data.error) {
              toast.error(data.message);
            } else {
              setQuestionIndex(questionIndex + 1);

              setDestination(`${data?.city ?? ""}, ${data?.country ?? ""}`);
              setAnswers({ ...answers, ...{ destination: _.pick(data, "country", "city", "iata") } });
            }
          })
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {
            setScanned(false);
          });
      },
      DOUBLE_SCAN_PREVENTION_DEBOUNCE_MS,
      { leading: true }
    ),
    [answers, scanned, questionIndex]
  );

  // Listen for scan
  useInputListener(getBoardingPassData);

  useEffect(() => {
    if (document.activeElement) {
      // @ts-ignore
      document.activeElement.blur();
    }
  }, [questionIndex]);

  useEffect(() => {
    dispatch(scannerDisabled());
    return () => {
      dispatch(scannerEnabled());
    };
  }, [dispatch]);

  const handleQuestion = useCallback(
    (question: any) => {
      const buttonStyle = {
        margin: 4,
        marginBottom: 8,
        borderRadius: "30px",
        fontSize: 48,
        fontWeight: 800,
        padding: "20px 48px",
      };

      switch (question.type) {
        case "info":
          return (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Button
                sx={buttonStyle}
                variant="contained"
                size="large"
                onClick={() => setQuestionIndex(questionIndex + 1)}
              >
                <FormattedMessageJamezz id="Continue" />
              </Button>
            </div>
          );
        case "scan":
          return (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            ></div>
          );
        case "confirm":
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                sx={buttonStyle}
                variant="contained"
                size="large"
                onClick={() => {
                  setQuestionIndex(questionIndex + 1);
                  setAnswers({ ...answers, ...{ [question.key]: false } });
                }}
              >
                <FormattedMessageJamezz id="No" />
              </Button>
              <Button
                sx={buttonStyle}
                variant="contained"
                size="large"
                onClick={() => {
                  setQuestionIndex(questionIndex + 1);
                  setAnswers({ ...answers, ...{ [question.key]: true } });
                }}
              >
                <FormattedMessageJamezz id="Yes" />
              </Button>
            </div>
          );
      }

      dispatch(flightDetailsSupplied(answers));

      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            onClick={() => {
              closeDialog();
              props.onFinish();
            }}
          >
            <FormattedMessageJamezz id="Finish" />
          </Button>
        </div>
      );
    },
    [answers, dispatch, questionIndex]
  );

  const handleBack = useCallback(() => {
    if (questionIndex > 0) {
      setQuestionIndex(questionIndex - 1);
      if (backButtonRef.current) {
        backButtonRef.current.blur();
      }
    } else {
      props.onClose();
      closeDialog();
    }
  }, [closeDialog, questionIndex, backButtonRef]);

  return (
    <>
      <BackgroundMediaUrlFromSalesarea
        keyName={"extra_settings__kioskv5_background_of_order_page"}
        sx={{
          width: 1,
          height: 1,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 4,
        }}
        fallbackBackgroundColor={"primary.light"}
      >
        <Swiper
          onSwiper={setSwiperRef}
          tabIndex={questionIndex}
          allowTouchMove={false}
          style={{ height: "0", flex: "1 1 0%", overflow: "hidden", width: "100%" }}
          className="JS-BoardingPassPage-Swiper"
        >
          <SwiperSlide>
            <Box sx={{ width: 1, height: 1 }}>
              <IconButton className={"JS-BackBoardingPassButton"} onClick={handleBack} ref={backButtonRef}>
                <ArrowBack sx={{ width: 100, height: 100 }} className={"JS-BackDialogButton-Icon"} />
              </IconButton>

              <Box
                sx={{
                  marginTop: "20px",
                  padding: "20px",
                  backgroundColor: "primary.dark",
                  textAlign: "center",
                  fontSize: "50px",
                }}
              >
                {questions[0].translationKey ?? ""}
              </Box>
              {handleQuestion(questions[0])}
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box sx={{ width: 1, height: 1, display: "flex", flexDirection: "column" }}>
              <Box sx={{ flex: 1 }}>
                <IconButton className={"JS-BackBoardingPassButton"} onClick={handleBack} ref={backButtonRef}>
                  <ArrowBack sx={{ width: 100, height: 100 }} className={"JS-BackDialogButton-Icon"} />
                </IconButton>

                <Box
                  sx={{
                    marginTop: "20px",
                    padding: "20px",
                    backgroundColor: "primary.dark",
                    textAlign: "center",
                    fontSize: "50px",
                  }}
                >
                  {questions[1].translationKey ?? ""}
                </Box>
              </Box>
              <Box>{handleQuestion(questions[1])}</Box>
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box sx={{ width: 1, height: 1, display: "flex", flexDirection: "column" }}>
              <Box sx={{ flex: 1 }}>
                <IconButton className={"JS-BackBoardingPassButton"} onClick={handleBack} ref={backButtonRef}>
                  <ArrowBack sx={{ width: 100, height: 100 }} className={"JS-BackDialogButton-Icon"} />
                </IconButton>

                <Box
                  sx={{
                    marginTop: "20px",
                    padding: "20px",
                    backgroundColor: "primary.dark",
                    textAlign: "center",
                    fontSize: "50px",
                  }}
                >
                  {questions[2].translationKey ?? ""}
                </Box>
              </Box>

              {handleQuestion(questions[2])}
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box sx={{ width: 1, height: 1, display: "flex", flexDirection: "column" }}>
              <Box sx={{ flex: 1 }}>
                <IconButton className={"JS-BackBoardingPassButton"} onClick={handleBack} ref={backButtonRef}>
                  <ArrowBack sx={{ width: 100, height: 100 }} className={"JS-BackDialogButton-Icon"} />
                </IconButton>

                <Box
                  sx={{
                    marginTop: "20px",
                    padding: "20px",
                    backgroundColor: "primary.dark",
                    textAlign: "center",
                    fontSize: "50px",
                  }}
                >
                  {questions[3].translationKey ?? ""}
                </Box>
              </Box>

              {handleQuestion(questions[3])}
            </Box>
          </SwiperSlide>
        </Swiper>
      </BackgroundMediaUrlFromSalesarea>
    </>
  );
}

export default BoardingPassPage;
