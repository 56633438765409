import { PiggyVoucher } from "../../../types/shared/piggy.ts";
import { useMemo } from "react";
import { differenceInDays } from "date-fns";

export default function usePiggyVoucher(piggyVoucher: PiggyVoucher) {
  const expirationDate: Date | null = useMemo(() => {
    return piggyVoucher.expiration_date ? new Date(piggyVoucher.expiration_date) : null;
  }, [piggyVoucher.expiration_date]);
  const activationDate: Date | null = useMemo(() => {
    return piggyVoucher.activation_date ? new Date(piggyVoucher.activation_date) : null;
  }, [piggyVoucher.activation_date]);
  const validDate: Date | null = useMemo(() => {
    if (expirationDate && activationDate) {
      if (differenceInDays(expirationDate, activationDate) <= 1) {
        return activationDate;
      }
    }
    return null;
  }, [expirationDate, activationDate]);

  return { expirationDate, activationDate, validDate };
}
