import CustomField from "../../../../types/shared/CustomField";
import { useCustomfieldTranslatableText } from "../../../../global/utils/languages.ts";
import { Typography } from "@mui/material";

type Props = {
  customField: CustomField;
};

export default function Explanation(props: Props) {
  const customFieldText = useCustomfieldTranslatableText();
  const text = customFieldText(props.customField, "explanation");

  return text ? (
    <Typography
      component="span"
      fontSize="1.2em"
      className="JS-CustomFields-Explanation"
      data-cy="customfield-explanation"
    >
      {text}
    </Typography>
  ) : null;
}
