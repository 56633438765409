import { MutableRefObject, useCallback, useEffect, useRef } from "react";
// @ts-ignore
import scrollIntoView from "scroll-into-view";
import { useScroll } from "../ScrollProvider";
import Articlegroup from "../models/menu/Articlegroup";

interface Props {
  articlegroups: Articlegroup[];
}

function ScrollArticlegroupTabListener(props: Props) {
  const articlegroups = props.articlegroups;
  const { scrollContent, scrollTabBlocked } = useScroll();
  const timer = useRef<ReturnType<typeof setTimeout>>();

  const onScrollArticlegroupContent = useCallback(() => {
    if (timer.current) {
      return;
    }
    if (scrollTabBlocked.current) {
      return;
    }

    let articlegroupIndex = null;

    articlegroups?.some((articlegroup, index) => {
      const element = document.getElementById("Articlegroup-" + articlegroup.id);
      if (element) {
        if (element.getBoundingClientRect().y > 120) {
          if (element.getBoundingClientRect().top < window.innerHeight) {
            articlegroupIndex = index;
          } else {
            articlegroupIndex = Math.max(0, index - 1);
          }
          return true;
        }
      }
      return false;
    });

    if (articlegroupIndex != null) {
      let articlegroup = articlegroups[articlegroupIndex];

      const element = document.getElementById("ArticlegroupTab-" + articlegroup.id);
      if (element) {
        timer.current = setTimeout(() => {
          scrollIntoView(element, {
            time: 500,
            align: {
              left: 0.5,
              // top: 0.5,
            },
            validTarget: (target: any) => {
              return (
                target.classList?.contains("MuiTabs-scroller") ||
                target.classList?.contains("JS-HorizontalArticlegroupTabBar-Root")
              );
            },
          });
          timer.current = undefined;
        }, 100);
      }
    }
  }, [scrollContent]);

  useEffect(() => {
    if (scrollContent.current) {
      scrollContent.current.addEventListener("scroll", onScrollArticlegroupContent);
    }
    return () => {
      if (scrollContent.current) {
        scrollContent.current.removeEventListener("scroll", onScrollArticlegroupContent);
      }
    };
  }, [onScrollArticlegroupContent, scrollContent]);
  return null;
}

export function scrollArticlegroupContent(
  scrollTabBlocked: MutableRefObject<boolean>,
  articlegroup: Articlegroup,
  topOffset: number
) {
  const element = document.getElementById("Articlegroup-" + articlegroup.id);
  if (element) {
    scrollIntoView(
      element,
      {
        time: 1000,
        align: {
          top: 0,
          topOffset: topOffset,
        },
        validTarget: () => true,
      },
      () => {
        scrollTabBlocked.current = false;
      }
    );
  } else {
    //// If not yet rendered because of lazy rendering
    const alternative = document.getElementById("menu-content");
    if (alternative) {
      scrollIntoView(
        alternative,
        {
          time: 1000,
          align: {
            top: 1000000,
            topOffset: 0,
          },
          validTarget: () => true,
        },
        () => {
          scrollTabBlocked.current = false;
        }
      );
    } else {
    }
  }
}

export default ScrollArticlegroupTabListener;
