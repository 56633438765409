import { Tooltip, TooltipProps } from "@mui/material";

export default function JTooltip({ children, ...props }: TooltipProps) {
  return (
    // eslint-disable-next-line jamezz/prefer-jamezz-component
    <Tooltip disableInteractive enterTouchDelay={0} enterDelay={0} {...props}>
      {children}
    </Tooltip>
  );
}
