import { memo, MutableRefObject, useCallback } from "react";
import { ButtonBase } from "@mui/material";

function _NavigationDot({
  color,
  active,
  idx,
  scrollContainer,
  navContainer,
}: {
  color: string;
  active: boolean;
  idx: number;
  scrollContainer: MutableRefObject<undefined | HTMLDivElement>;
  navContainer: MutableRefObject<undefined | HTMLDivElement>;
}) {
  const onClick = useCallback(() => {
    if (scrollContainer.current) {
      scrollContainer.current.children[idx].scrollIntoView({
        behavior: "smooth",
      });
    }
    if (navContainer.current) {
      navContainer.current.children[idx].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, []);

  return (
    <ButtonBase
      sx={{
        transition: "background-color 120ms ease-in",
        width: "24px",
        height: "24px",
        border: `4px solid ${color}`,
        borderRadius: "50%",
        marginBottom: "8px",
        backgroundColor: active ? color : "transparent",
        display: "block",
      }}
      onClick={onClick}
    />
  );
}

export const NavigationDot = memo(_NavigationDot);
