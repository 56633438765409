import { useEffect, useState } from "react";
import {
  piggyAuthApi,
  useGetLoggedinUserQuery,
  useLazyContactByIdentifierQuery,
} from "../../../global/utils/redux/api/piggyAuthApi";
import { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";

const EVERY_FIFTEEN_SECONDS = 15 * 1000;
export default function PiggySessionWatcher() {
  const [wasLoggedInBefore, setWasLoggedInBefore] = useState<boolean>(false);
  const piggyEnabled = useAppSelector((state) => state.global.salesarea.piggy.loyalty.enabled);
  const { data: loggedInSessionResponse } = useGetLoggedinUserQuery(undefined, {
    pollingInterval: EVERY_FIFTEEN_SECONDS,
    skip: !piggyEnabled,
  });
  const piggyEmail = useAppSelector((state) => state.piggy.userWrittenPiggyEmail);
  const [login] = useLazyContactByIdentifierQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (loggedInSessionResponse) {
      const hasSession = loggedInSessionResponse.data.has_session;
      if (wasLoggedInBefore && !hasSession && piggyEmail) {
        login({ identifier: piggyEmail, timestamp: +new Date(), create_if_not_exists: false }).then(() => {
          dispatch(piggyAuthApi.util.invalidateTags(["piggyAuth"]));
        });
      }
      if (hasSession) {
        setWasLoggedInBefore(true);
      } else {
        setWasLoggedInBefore(false);
      }
    }
  }, [dispatch, loggedInSessionResponse, login, piggyEmail, wasLoggedInBefore]);

  return <></>;
}
