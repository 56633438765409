import React, { useMemo } from "react";
import { PaidRounded } from "@mui/icons-material";
import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { postAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics.ts";
import useOpenPayAfterOrderDialog from "../../pages/utils/useOpenPayAfterOrderDialog.tsx";
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useIntl } from "react-intl";
import clsx from "clsx";

export default function useAfterPayListItem(): React.JSX.Element | null {
  const afterPay = useAppSelector((state) => state.global.sessionState?.payAfter);
  const openPayAfterOrderDialog = useOpenPayAfterOrderDialog();
  const intl = useIntl();

  return useMemo(
    () =>
      afterPay ? (
        <ListItem
          className={clsx("JS-SideMenuDrawer-ListItem", "JS-SideMenuDrawer-Afterpay")}
          key={"Afterpay"}
          disablePadding
        >
          <ListItemButton
            onClick={() => {
              postAnalyticsEvent({
                category: "JamezzShowOrderButton",
                action: "openPayAfterOrderDialog",
              });
              openPayAfterOrderDialog();
            }}
          >
            <ListItemIcon className={"JS-SideDrawerMenu-ItemIcon"}>
              <PaidRounded />
            </ListItemIcon>
            <ListItemText primary={intl.formatMessage({ id: "Pay bill" })} />
          </ListItemButton>
        </ListItem>
      ) : null,
    [afterPay, intl, openPayAfterOrderDialog]
  );
}
