import { useEffect, useMemo, useRef } from "react";
import Articlegroup from "../../../global/utils/models/menu/Articlegroup";
import HorizontalArticlegroupTabBar from "./ArticlegroupTab/HorizontalArticlegroupTabBar";
import Box from "@mui/material/Box";
import ArticlegroupContent from "./ArticlegroupContent";
import WideShoppingCartButton from "./ShoppingCartButtons/WideShoppingCartButton";
import { useScroll } from "../../../global/utils/ScrollProvider";
import SmallShoppingCartButton from "./ShoppingCartButtons/SmallShoppingCartButton";
import { useAppSelector } from "../../../global/utils/redux/store";
import SearchContent from "./SearchContent";
import { getArticlegroupsBySupergroupId } from "../../../global/utils/models/menu/Menu";
import ArticlegroupsShortcutPage from "../../components/ArticlegroupsShortcutPage/ArticlegroupsShortcutPage";
import useArticlegroups from "../../../global/utils/hooks/useArticlegroups";
import { Skeleton } from "@mui/material";
import OnScrollContent from "../../../qr/pages/MenuPage/EventListeners/OnScrollContent";
import { usePiggyUser } from "../../utils/piggy/piggy";
import { selectArticlesSum } from "../../../global/utils/redux/shoppingCartSlice";
import { selectSupergroupsMap } from "../../../global/utils/redux/selectors/selectSupergroupsMap";
import { selectArticlegroupsMap } from "../../../global/utils/redux/selectors/selectArticlegroupsMap";
import { selectArticlegroupChildrenMap } from "../../../global/utils/redux/selectors/selectArticlegroupChildrenMap";
import BackgroundMediaUrlFromSalesarea from "../../hooks/useBackgroundMediaUrl.tsx";
import PiggyLoyaltyContent from "../../components/piggy/Loyalty/PiggyLoyaltyContent.tsx";
import PiggyScanLoyaltyButton from "./ShoppingCartButtons/PiggyScanLoyaltyButton.tsx";
import ScrollShadowsKiosk from "../../components/ScrollShadowKiosk.tsx";

export default function HorizontalMenuPage() {
  const selectedArticlegroupIsNull = useAppSelector((state) => state.menu.selectedArticlegroup == null);
  // const [scrollContainerMounted, setScrollContainerMounted] = useState<boolean>(false);
  const searchValue = useAppSelector((state) => state.global.articleSearchText);

  const supergroupsMap = useAppSelector(selectSupergroupsMap);
  const articlegroupsMap = useAppSelector(selectArticlegroupsMap);
  const articlegroupChildrenMap = useAppSelector(selectArticlegroupChildrenMap);

  const articlegroupsShortcutPage = useAppSelector((state) => state.global.salesarea.articlegroups_shortcut_page);

  const countBiggerThanZero = useAppSelector((state) => selectArticlesSum(state) > 0);
  const { scrollContent } = useScroll();
  const orderContainerRef = useRef<HTMLElement>(null);
  const typeOfShoppingCartButton = useAppSelector((state) => state.global.salesarea?.type_of_shopping_cart_button);

  const { piggyUserScannedCard, isLoggedIn, isLoading } = usePiggyUser();

  const articlegroupsFound = useMemo<Articlegroup[]>(() => {
    let articlegroups: Articlegroup[] = [];
    Object.values(supergroupsMap).forEach((supergroup) => {
      articlegroups = articlegroups.concat(
        getArticlegroupsBySupergroupId(articlegroupsMap, articlegroupChildrenMap, supergroup.id)
      );
    });
    return articlegroups.sort((a: Articlegroup, b: Articlegroup) => a.sortKey - b.sortKey);
  }, [supergroupsMap, articlegroupsMap, articlegroupChildrenMap]);

  const articlegroups: Articlegroup[] = useArticlegroups(articlegroupsFound);

  const pixelHeightCartButton = "200px";
  const renderUptoArticlegroupIndex = articlegroups.length;

  useEffect(() => {
    if (orderContainerRef.current) {
      scrollContent.current = orderContainerRef.current;
    }
  }, [orderContainerRef, scrollContent]);

  const menuLoaded = useAppSelector((state) => state.menuData.menuLoaded);

  return (
    <>
      <HorizontalArticlegroupTabBar articlegroups={articlegroups} />
      <BackgroundMediaUrlFromSalesarea
        keyName={"extra_settings__kioskv5_background_of_menu_page_content"}
        data-cy={"menu"}
        className={"JS-HorizontalMenuPage-Root"}
        sx={{
          height: "100%",
          width: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
        sxWrapper={{ flex: "1 1 0%", height: 0 }}
      >
        {!menuLoaded ? (
          <>
            <Box sx={{ px: 2, display: "flex", justifyContent: "center", flexDirection: "column" }}>
              <Skeleton sx={{ width: 1, height: 350 }} />
              <Skeleton sx={{ width: 1, height: 350 }} />
              <Skeleton sx={{ width: 1, height: 350 }} />
              <Skeleton sx={{ width: 1, height: 350 }} />
              <Skeleton sx={{ width: 1, height: 350 }} />
            </Box>
          </>
        ) : null}

        <Box
          sx={{
            display: "flex",
            // flexWrap: "wrap",
            flex: "1 1 0%",
            alignContent: "flex-start",
            overflowY: "auto",
            position: "relative",
            flexDirection: "column",
            justifyContent: "space-between",
            pb: typeOfShoppingCartButton === "SMALL" || !countBiggerThanZero ? 25 : null,
          }}
          ref={orderContainerRef}
        >
          <ScrollShadowsKiosk
            containerRef={orderContainerRef}
            styleBottomShadow={{
              gridArea: "1 / 1 / 1 / 4",
              alignSelf: "end",
              transform: typeOfShoppingCartButton === "SMALL" || !countBiggerThanZero ? "translateY(200px)" : null,
            }}
          >
            {scrollContent.current ? <OnScrollContent articlegroups={articlegroups} /> : null}
            {!searchValue ? (
              articlegroupsShortcutPage && selectedArticlegroupIsNull ? (
                <ArticlegroupsShortcutPage />
              ) : (
                <>
                  {piggyUserScannedCard ? isLoading ? <Skeleton /> : isLoggedIn ? <PiggyLoyaltyContent /> : null : null}
                  {articlegroups
                    .filter((articlegroup) => articlegroup.showInCategoryMenu)
                    .slice(0, renderUptoArticlegroupIndex + 1)
                    .map((articlegroup: Articlegroup) => {
                      return (
                        <ArticlegroupContent
                          articlegroup={articlegroup}
                          key={articlegroup.id}
                          menuOrientation={"HORIZONTAL"}
                          scrollingRef={orderContainerRef.current ? orderContainerRef : undefined}
                        />
                      );
                    })}
                </>
              )
            ) : null}
            {searchValue ? <SearchContent pixelHeightCartButton={pixelHeightCartButton} /> : null}
          </ScrollShadowsKiosk>
        </Box>
        <Box sx={{ height: typeOfShoppingCartButton === "SMALL" || !countBiggerThanZero ? 0 : null, zIndex: 30 }}>
          <Box
            sx={{
              transform: typeOfShoppingCartButton === "SMALL" || !countBiggerThanZero ? "translateY(-100%)" : null,
              overflow: "visible",
              width: 1,
              display: "grid",
              gridTemplateAreas: "'a b c'",
              gridTemplateColumns: "1fr 250px 1fr",
              gridTemplateRows: "auto",
            }}
          >
            {!(countBiggerThanZero && typeOfShoppingCartButton === "WIDE") ? (
              <PiggyBottomToolbarView position={"left"} width={370} />
            ) : null}
            {countBiggerThanZero ? (
              typeOfShoppingCartButton === "SMALL" ? (
                <SmallShoppingCartButton pixelHeightCartButton={pixelHeightCartButton} />
              ) : (
                <WideShoppingCartButton pixelHeightCartButton={pixelHeightCartButton} />
              )
            ) : null}
          </Box>
        </Box>
      </BackgroundMediaUrlFromSalesarea>
    </>
  );
}

export function PiggyBottomToolbarView({ position, width }: { position: "left" | "right"; width?: number }) {
  const showLoyalty = useAppSelector(
    (state) => state.global.salesarea.piggy.loyalty.enabled && state.global.salesarea.piggy.loyalty.show_loyalty_in_app
  );
  const prepaidEnabled = useAppSelector((state) => state.global.salesarea.piggy.prepaid.enabled);
  const enabled = showLoyalty || prepaidEnabled;

  return enabled ? (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        alignSelf: "end",
        justifySelf: position == "left" ? "start" : "end",
        gridArea: position == "left" ? "a" : "c",
        zIndex: 110,
        width,
        px: 2,
        backgroundColor: "primary.main",
        height: 200,
        borderTopRightRadius: position == "left" ? 24 : undefined,
        borderTopLeftRadius: position == "left" ? undefined : 24,
        boxShadow: "0 1px 10px 0px rgba(0,0,0,0.6)",
      }}
      className={"JS-PiggyScanLoyalty-Button"}
    >
      <PiggyScanLoyaltyButton showText={true} />
    </Box>
  ) : null;
}
