import CustomField, { CustomFieldType, OrderCustomField } from "../../types/shared/CustomField";
import { RootState, useAppDispatch, useAppSelector } from "../../global/utils/redux/store";
import { customFieldNameChanged } from "../../global/utils/redux/customFieldsSlice";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import PiggyGiftcardCheckout from "../../qr/components/Piggy/Giftcards/PiggyGiftcardCheckout";
import { useCustomTexts } from "../../global/utils/useCustomTexts";
import { selectPaymentMethodCustomField } from "../../global/utils/redux/selectors/selectPaymentMethodCustomField";
import { selectCustomFields } from "../../global/utils/redux/selectors/selectCustomFields";
import { useCustomfieldTranslatableText } from "../../global/utils/languages.ts";
import { ReviewOrderPage } from "../pages/CheckoutPage/ReviewOrderPage.tsx";
import { createSelector } from "@reduxjs/toolkit";
import { useAccountTotalValue } from "../../global/utils/redux/accountSlice.tsx";

const pickCustomFieldByName = (customFields: CustomField[], fieldName: string) =>
  _.find(customFields, { name: fieldName });

const nameMap: Record<string, string> = {
  paymentMethod: "Payment method",
};

type Page = {
  name: string;
  customField?: CustomField;
  Content?: React.FunctionComponent<{ isValid?: boolean; onShouldSlideNext: () => void }>;
  required: boolean;
  isFilledCorrectly: boolean;
};

export function useCustomerOrderInputPages() {
  const intl = useIntl();
  const useFormMethods = useForm({
    reValidateMode: "onChange",
    mode: "all",
    criteriaMode: "all",
  });
  const {
    formState: { isValid, errors },
  } = useFormMethods;
  const [pageIndex, setPageIndex] = useState(0);
  const dispatch = useAppDispatch();
  const totalAmount = useAccountTotalValue();

  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const payDirect = useAppSelector((state) => state.global.salesarea.payDirect);
  const orderCustomFields = useAppSelector((state) => state.customFields.orderCustomFields);
  const customFieldsForPages = useAppSelector(selectCustomFieldsForPages);

  const piggyGiftcardsEnabled = useAppSelector((state) => state.global.salesarea.piggy.giftcards.enabled);
  const piggyGiftcardsShowAsStep =
    useAppSelector((state) => state.global.salesarea.custom_data?.kiosk?.piggy?.show_giftcards_as_step) ?? true;

  const customFieldText = useCustomfieldTranslatableText();

  const ct = useCustomTexts(["loyalty gift card - stepper name"]);

  const pages: Page[] = useMemo(() => {
    const pages: Page[] = customFieldsForPages
      .filter((customField) => customField.type !== "paymentmethodbuttons" || totalAmount > 0)
      .map((customField) => {
        const currentName = customField.name;
        const required = pickCustomFieldByName(customFieldsForPages, currentName)?.required ?? false;
        const customFieldValue = (orderCustomFields[currentName] ?? null)?.value;
        const hasValue = customFieldValue != null;
        const hasError = errors[currentName] != null;
        const isFilledCorrectly = !required || (required && !hasError && hasValue);

        const displayName =
          customFieldText(customField, "stepperLabel") ?? nameMap[customField.name] ?? customField.name;

        return {
          name: displayName,
          customField,
          required,
          isFilledCorrectly,
        };
      });

    if (piggyGiftcardsEnabled && payDirect && piggyGiftcardsShowAsStep && totalAmount > 0) {
      pages.push({
        required: true,
        isFilledCorrectly: true,
        name: ct["loyalty gift card - stepper name"] ?? intl.formatMessage({ id: "Piggy Giftcards" }),
        Content: PiggyGiftcardCheckout,
      });
    }

    pages.push({
      required: false,
      isFilledCorrectly: true,
      name: intl.formatMessage({ id: "CustomFields.review-your-order-page-name" }),
      Content: ReviewOrderPage,
    });

    return pages;
  }, [
    customFieldsForPages,
    piggyGiftcardsEnabled,
    payDirect,
    piggyGiftcardsShowAsStep,
    intl,
    orderCustomFields,
    errors,
    customFieldText,
    ct,
    totalAmount,
  ]);

  if (!isInitialized && pages?.[0]) {
    dispatch(customFieldNameChanged(pages[0].customField?.name ?? pages[0].name));
    setIsInitialized(true);
  }

  const pageStatus = useCallback(
    (idx: number) => {
      const currentPage = pages[idx];

      if (currentPage) {
        const isFilledCorrectly = currentPage.isFilledCorrectly;
        const isReviewPage = currentPage.customField == null;
        const hasKeyboardInput = _.find(
          [
            CustomFieldType.Text,
            CustomFieldType.Email,
            CustomFieldType.Mobilenr,
            CustomFieldType.Number,
            CustomFieldType.PagerNr,
            CustomFieldType.Pager,
          ],
          (type) => {
            return currentPage.customField?.type === type;
          }
        );

        return {
          canGoToPage: isFilledCorrectly && !isReviewPage,
          isFilledCorrectly,
          currentPage,
          hasKeyboardInput,
        };
      } else {
        return {
          canGoToPage: false,
          isFilledCorrectly: false,
          currentPage: null,
          hasKeyboardInput: false,
        };
      }
    },
    [pages]
  );

  const { isFilledCorrectly } = pageStatus(pageIndex);

  const goNext = useCallback(() => {
    const newIndex = Math.min(pages.length - 1, pageIndex + 1);
    setPageIndex(newIndex);
    const page = pages[newIndex];
    dispatch(customFieldNameChanged(page.customField?.name ?? page.name));
  }, [dispatch, pageIndex, pages]);
  const goBack = useCallback(() => {
    const newIndex = Math.max(0, pageIndex - 1);
    setPageIndex(newIndex);
    const page = pages[newIndex];
    dispatch(customFieldNameChanged(page.customField?.name ?? page.name));
  }, [dispatch, pageIndex, pages]);
  const goToPage = useCallback(
    (idx: number) => {
      setPageIndex(idx);
      const page = pages[idx];
      dispatch(customFieldNameChanged(page.customField?.name ?? page.name));
    },
    [dispatch, pages]
  );

  const hasPrev = pageIndex > 0 && pages.length > 0;
  const hasNext = pages.length - 1 > pageIndex;
  const canGoNext = hasNext && isFilledCorrectly;
  const canGoPrev = hasPrev;
  const isOnReviewPage = !hasNext;
  useEffect(() => {
    if (pages[pageIndex] == null) {
      setPageIndex(0);
    }
  }, [pages, pageIndex]);
  return {
    pages,
    pageIndex,
    goNext,
    goBack,
    canGoNext,
    canGoPrev,
    pageStatus,
    goToPage,
    hasPrev,
    hasNext,
    isFilledCorrectly,
    useFormMethods,
    isOnReviewPage,
    isValid,
  };
}

export const selectCustomFieldsForPages = createSelector(
  [selectCustomFields, selectPaymentMethodCustomField, (state: RootState) => state.customFields.orderCustomFields],
  (customFields, paymentMethodCustomField, orderCustomFields) => {
    return (paymentMethodCustomField ? [...customFields, paymentMethodCustomField] : customFields)
      .filter((a) => a)
      .reduce((customFields: CustomField[], field) => {
        return customFields.concat(findRecursiveCustomFields(orderCustomFields, field));
      }, []);
  }
);

function findRecursiveCustomFields(
  orderCustomFields: Record<string, OrderCustomField>,
  customField: CustomField
): CustomField[] {
  let customFields: CustomField[] = [];

  const currentName = customField.name;
  const customFieldValue = (orderCustomFields[currentName] ?? null)?.value;
  const hasValue = customFieldValue != null;

  let shouldBeHidden = true;
  if (customField.type === CustomFieldType.PaymentMethodButtons) {
    if (customField.options !== undefined && customField.options.length > 1) {
      shouldBeHidden = false;
    }
  } else if (customField.type === CustomFieldType.OrderMode && !customField.hide) {
    if ((customField?.options ?? []).length >= 2) {
      shouldBeHidden = false;
    }
  } else if (!customField.hide && customField.type !== CustomFieldType.Hidden) {
    shouldBeHidden = false;
  }

  if (!shouldBeHidden) {
    customFields.push(customField);
  }

  if (hasValue) {
    const option = customField.options?.find((option) => option.id == customFieldValue);
    if (option && option.customFields) {
      option.customFields.forEach((subCustomField) => {
        customFields = customFields.concat(findRecursiveCustomFields(orderCustomFields, subCustomField));
      });
    }
  }
  return customFields;
}

// Doesn't support recursive customFields
// export function customFieldShouldBeInStepper(customField: CustomField) {
//   if (customField.type === "hidden" || customField.hide) {
//     return false;
//   }
//   if (customField.type === CustomFieldType.OrderMode) {
//     return (customField?.options ?? []).length >= 2;
//   }
//   return true;
// }

export const selectStepperStepcount = createSelector(
  [
    selectCustomFieldsForPages,
    selectPaymentMethodCustomField,
    (state: RootState) => state.global.salesarea.piggy.loyalty.enabled,
    (state: RootState) => state.global.salesarea.piggy.giftcards.enabled,
    (state: RootState) => state.global.salesarea.custom_data?.kiosk?.piggy?.show_giftcards_as_step ?? true,
  ],
  (customFields, piggyLoyaltyEnabled, piggyGiftcardsEnabled, piggyGiftcardsShowAsStep) => {
    return customFields.length + (piggyGiftcardsEnabled && piggyGiftcardsShowAsStep ? 1 : 0);
  }
);
