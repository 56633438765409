import { useCallback, useMemo } from "react";
import { alpha, Badge, ButtonBase, IconButton, InputBase, Stack, styled, useTheme } from "@mui/material";
import store, { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import { onFocusKeyboard, setArticleSearchText } from "../../../global/utils/redux/globalSlice";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import { useIntl } from "react-intl";
import { Close, FilterAlt } from "@mui/icons-material";
import clsx from "clsx";
// @ts-ignore
import JamezzLogo from "../../../assets/images/JamezzLogo.svg";
import Image from "../../../global/components/Image";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { createPath, routes } from "../../../global/utils/config";
import { useLocation } from "react-router";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import CurrencySelector from "../../../global/components/CurrencySelector/CurrencySelector";
import { setSelectedArticlegroup } from "../../../global/utils/redux/menuSlice";
import useMenuTagFilters from "../../../global/utils/menu/filters/useMenuTagFilters";
import { postAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics";
import SlideUpTransition from "../../../global/components/SlideUpTransition";
import isKiosk from "../../../global/utils/helpers/isKiosk";
import { useGoToHomeKiosk } from "../../pages/ReturnPage/ReturnPage";
import MenuFilterDialogV2 from "../../pages/MenuTagFilterPage/MenuFilterDialogV2";
import LanguageButton from "./LanguageButton";
import { AreYouSureStartOverDialog } from "../AreYouSureStartOverDialog.tsx";

const Search = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "max-content 1fr max-content",
  position: "relative",
  borderRadius: "30px",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  color: theme.palette.primary.contrastText,
  marginLeft: 0,
  width: "100%",
  maxHeight: 60,
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "60%",
  position: "absolute",
  top: 0,
  bottom: 0,
  margin: "auto 0",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.text.primary,
  "& .MuiInputBase-input": {
    fontSize: "25px",
    marginLeft: "10px",
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    // [theme.breakpoints.up("sm")]: {
    //   width: "150px",
    //   "&:focus": {
    //     width: "400px",
    //   },
    // },
  },
}));

type Props = { disableSearch?: boolean; disableHomeButton?: boolean };

export default function KioskTopBar({ disableSearch, disableHomeButton }: Props) {
  const searchValue = useAppSelector((state) => state.global.articleSearchText);
  const menuLoaded = useAppSelector((state) => state.menuData.menuLoaded);
  const { pathname } = useLocation();
  const menuTags = useMenuTagFilters();
  const isOnMenuPage = useMemo(() => {
    return pathname === createPath(routes.kiosk.menuPath);
  }, [pathname]);
  const menuTagPreferences = useAppSelector((state) => state.menuState.menuTagPreferences);

  const numberOfPreferencesActive = useMemo(() => {
    let numberOfPreferencesActive = 0;

    Object.values(menuTagPreferences).forEach((preference) => {
      if (preference.checked) {
        numberOfPreferencesActive += 1;
      }
    });
    return numberOfPreferencesActive;
  }, [menuTagPreferences]);
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const { openDialog } = useDialog();
  const searchText = intl.formatMessage({
    id: "Search",
    defaultMessage: "Search",
    description: "placeholder text",
  });
  const goToHome = useGoToHomeKiosk();

  const theme = useTheme();

  const articlegroupsShortcutPage = useAppSelector((state) => state.global.salesarea?.articlegroups_shortcut_page);
  const logoImage = useAppSelector(
    (state) => state.global.salesarea?.media_urls?.logoImage?.[0]?.conversions?.optimized?.url
  );

  const languagePresentation = useAppSelector(
    (state) => state.global.salesarea.custom_data?.kiosk?.how_to_present_languages
  );
  const onClickHome = useCallback(() => {
    const selectedArticlegroup = store.getState().menu.selectedArticlegroup;
    if (articlegroupsShortcutPage && selectedArticlegroup) {
      dispatch(setSelectedArticlegroup({ articlegroup: null }));
      return;
    }
    openDialog({
      children: (
        <AreYouSureStartOverDialog
          message={<FormattedMessageJamezz id={"Are you sure you want to start over?"} />}
          onOk={() => {
            goToHome();
          }}
          CancelText={<FormattedMessageJamezz id={"Cancel order"} />}
          OkText={
            <>
              <FormattedMessageJamezz id={"Continue ordering"} />
              ...
            </>
          }
        />
      ),
      sx: {
        ".MuiDialog-paper": {
          borderRadius: 6,
        },
      },
    });
  }, [articlegroupsShortcutPage, dispatch, goToHome, openDialog]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        pl: 8,
        pr: 6,
        width: 1,
      }}
      className={"JS-KioskTopBar-Root"}
    >
      <Stack alignItems={"center"} direction={"row"}>
        {disableHomeButton ? null : (
          <ButtonBase
            data-cy="reset-kiosk-btn"
            className={clsx("JS-HorizontalArticlegroupTabBar-Home")}
            onClick={onClickHome}
            sx={{ height: 1, minWidth: 200 }}
          >
            <Box
              sx={{
                width: 1,
                height: 100,
                display: "flex",
                flexDirection: "row",
              }}
              className={"JS-HorizontalArticlegroupTabBar-Logo"}
            >
              <Box sx={{ height: 1, width: 1, flex: "1", textAlign: "center", padding: 1 }}>
                {!logoImage ? (
                  <img src={JamezzLogo} style={{ height: "100%", width: "80%", objectFit: "contain" }} />
                ) : null}
                {logoImage ? (
                  <Image src={logoImage} style={{ height: "100%", width: "100%", objectFit: "contain" }} />
                ) : null}
              </Box>
            </Box>
          </ButtonBase>
        )}
      </Stack>
      <Stack sx={{ my: 1 }} direction={"row"} alignItems={"center"} gap={3} className={"JS-KioskTopBar-SearchWrapper"}>
        {disableSearch || !menuLoaded ? null : (
          <Search
            className={"JS-Search-Root"}
            style={{
              backgroundColor: alpha(theme.palette.primary.light, 1),
              color: theme.palette.primary.contrastText,
              boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.2)",
            }}
          >
            <SearchIconWrapper>
              <SearchIcon sx={{ width: 1, height: 1 }} />
            </SearchIconWrapper>
            <StyledInputBase
              data-cy="search-input"
              placeholder={searchText}
              inputProps={{
                "aria-label": "search",
                className: "JS-Search-Input",
                inputMode: isKiosk() ? "none" : undefined,
              }}
              className={"JS-Search-InputBase"}
              style={{
                maxWidth: 400,
                width: "100%",
                color: theme.palette.primary.contrastText,
              }}
              value={searchValue}
              onChange={(e) => {
                dispatch(setArticleSearchText(e.target.value));
              }}
              onFocus={() => {
                if (isKiosk()) {
                  dispatch(onFocusKeyboard({ value: searchValue, layout: "default" }));
                }
              }}
            />

            <IconButton
              className={"JS-Search-IconButton"}
              sx={{
                color: theme.palette.primary.contrastText,
                transition: theme.transitions.create("opacity"),
                visibility: searchValue === "" ? "hidden" : "unset",
              }}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setArticleSearchText(""));
              }}
            >
              <Close className={"JS-Search-Icon"} sx={{ width: "0.8em", height: "0.8em" }} />
            </IconButton>
          </Search>
        )}

        {isOnMenuPage && menuTags.length > 0 ? (
          <IconButton
            data-cy="goto-filter-btn"
            sx={{ color: theme.palette.primary.light }}
            size={"large"}
            edge={"end"}
            className={"JS-KioskTopBar-Filter-IconButton"}
            onClick={() => {
              postAnalyticsEvent({
                category: "TopBar",
                action: "MenuFilterDialog",
              });
              openDialog({
                children: <MenuFilterDialogV2 />,
                fullScreen: "almostFullScreen",
                TransitionComponent: SlideUpTransition,
              });
            }}
          >
            <Badge
              badgeContent={numberOfPreferencesActive}
              color={"primary"}
              sx={{ "& .MuiBadge-badge": { paddingY: 1.5 } }}
            >
              <FilterAlt className={"JS-TopBar-AllergyIcon"} />
            </Badge>
          </IconButton>
        ) : null}
        {languagePresentation !== "text" ? <LanguageButton /> : null}
      </Stack>
      <CurrencySelector />
    </Box>
  );
}
