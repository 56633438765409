import { RootState, useAppSelector } from "../redux/store";
import { createSelector } from "@reduxjs/toolkit";

export enum SalesareaType {
  CATALOG = "CATALOG",
  KIOSK = "KIOSK",
  QR = "QR",
  TABLET = "TABLET",
  PICKUP_DELIVERY = "PICKUP_DELIVERY",
}

export default function useSalesareaType() {
  const salesarea_type = useAppSelector((state) => state.global.salesarea?.salesarea_type);
  const tableIsOpenInPos = useAppSelector(selectTableIsOpenInPos);

  if (tableIsOpenInPos === false) {
    return SalesareaType.CATALOG;
  }
  switch (salesarea_type) {
    case "CATALOG":
      return SalesareaType["CATALOG"];
    case "KIOSK":
      return SalesareaType["KIOSK"];
    case "QR":
      return SalesareaType["QR"];
    case "TABLET":
      return SalesareaType["TABLET"];
    case "PICKUP_DELIVERY":
      return SalesareaType["PICKUP_DELIVERY"];
    default:
      return undefined;
  }
}

export const selectTableIsOpenInPos = createSelector(
  [
    (state: RootState) => state.global.salesarea.checkTableIsOpen.enabled,
    (state: RootState) => state.global.tableIsOpenInPos,
  ],
  (enabled, tableIsOpenInPos) => {
    if (enabled) {
      return tableIsOpenInPos;
    } else {
      return undefined;
    }
  }
);
