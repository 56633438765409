import Article from "../models/menu/Article";
import useMenuFilters from "./filters/useMenuFilters";
import Articlegroup from "../models/menu/Articlegroup";
import { useAppSelector } from "../redux/store";
import { selectArticlesMap } from "../redux/selectors/selectArticlesMap";
import { selectCategoryItemsMap } from "../redux/selectors/selectCategoryItemsMap.ts";
import Campaign from "../models/campaigns/Campaign.ts";
import { selectCampaignsMap } from "../redux/selectors/selectCampaignsMap.ts";
import { selectArticlegroupsMap } from "../redux/selectors/selectArticlegroupsMap.ts";
import { useArticleTraitFiltering } from "./filters/useArticleTraitFiltering.ts";
import useArticleSearchFiltering from "./filters/useArticleSearchFiltering.ts";

export default function useCategoryItems(articlegroup: Articlegroup): {
  categoryItems: (
    | { item_type: "campaign"; item: Campaign }
    | { item_type: "product"; item: Article }
    | { item_type: "category"; item: Articlegroup }
    | null
  )[];
} {
  const articleFilter = useMenuFilters();
  const articlesMap = useAppSelector(selectArticlesMap);
  const articlegroupsMap = useAppSelector(selectArticlegroupsMap);
  const campaignsMap = useAppSelector(selectCampaignsMap);
  const categoryItemsMap = useAppSelector(selectCategoryItemsMap);
  const { articleTraitFilter } = useArticleTraitFiltering();
  const searchArticles = useArticleSearchFiltering();

  return {
    categoryItems:
      categoryItemsMap[articlegroup.id]
        ?.map((categoryItem) => {
          if (categoryItem.category_item_type == "campaign") {
            const campaign = campaignsMap[categoryItem.category_item_id];
            if (campaign) {
              const unit = { item_type: "campaign", item: campaign };
              if (isCampaignUnit(unit)) {
                return unit;
              }
            }
          } else if (categoryItem.category_item_type == "product") {
            const article = articlesMap[categoryItem.category_item_id];
            if (article && searchArticles([article].filter(articleFilter).filter(articleTraitFilter)).length == 1) {
              const unit = { item_type: "product", item: article };
              if (isProductUnit(unit)) {
                return unit;
              }
            }
          } else if (categoryItem.category_item_type == "category") {
            const category = articlegroupsMap[categoryItem.category_item_id];
            if (category) {
              const unit = { item_type: "category", item: category };
              if (isCategoryUnit(unit)) {
                return unit;
              }
            }
          }
          return null;
        })
        .filter((a) => a) ?? [],
  };
}

type Unit = { item_type: string; item: Campaign | Article | Articlegroup };
type CampaignUnit = { item_type: "campaign"; item: Campaign };
type ProductUnit = { item_type: "product"; item: Article };
type CategoryUnit = { item_type: "category"; item: Articlegroup };
function isCampaignUnit(unit: Unit): unit is CampaignUnit {
  return unit.item_type == "campaign";
}
function isProductUnit(unit: Unit): unit is ProductUnit {
  return unit.item_type == "product";
}
function isCategoryUnit(unit: Unit): unit is CategoryUnit {
  return unit.item_type == "category";
}
