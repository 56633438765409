import { useDialog } from "../../global/utils/dialog/DialogProvider";
import { AgeCheckResult, findProductWithHighestAgeRequirement } from "../../global/utils/ageCheck/ageCheckUtils";
import store, { useAppDispatch, useAppSelector } from "../../global/utils/redux/store";
import useEmployeeCodes from "../hooks/useEmployeeCodes";
import { useEffect, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import FormattedMessageJamezz from "../../global/components/FormattedMessageJamezz";
import { onFocusKeyboard } from "../../global/utils/redux/globalSlice";
import { BlinkstickStatus, setBlinkstickStatus } from "../../global/utils/redux/blinkstickSlice";
import CloseDialogButton from "./CloseDialogButton";
import { employeeConfirmedCustomerIsOfAge } from "../../global/utils/redux/userInputSlice";
import isKiosk from "../../global/utils/helpers/isKiosk";

function ConfirmAgeByEmployeeDialog({ onConfirm }: { onConfirm: (codeVerified: boolean) => void }) {
  const employeeCodes = useEmployeeCodes();
  const dispatch = useAppDispatch();
  const [pincode, setPincode] = useState<string>("");

  if (employeeCodes.hasOwnProperty(pincode)) {
    onConfirm(true);
    dispatch(employeeConfirmedCustomerIsOfAge(pincode));
  }

  useEffect(() => {
    dispatch(setBlinkstickStatus(BlinkstickStatus.AgeCheckNotification));

    return () => {
      dispatch(setBlinkstickStatus(BlinkstickStatus.Default));
    };
  });

  return (
    <Box sx={{ m: 8 }}>
      <CloseDialogButton
        onClose={() => {
          onConfirm(false);
        }}
      />
      <Typography variant="h1" fontSize="1.5em" sx={{ mb: 2 }}>
        <FormattedMessageJamezz id="Age check" />
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        <FormattedMessageJamezz id="Popup.ageCheckByEmployee.description" />
      </Typography>
      <Typography variant="body2">
        <FormattedMessageJamezz id="AgeCheckByEmployee.details" />
      </Typography>
      <Box sx={{ marginTop: 2 }}>
        <TextField
          autoFocus
          inputProps={{
            inputMode: isKiosk() ? "none" : undefined,
          }}
          sx={{ width: "100%" }}
          placeholder="password"
          onChange={(e) => setPincode(e.target.value)}
          onFocus={() => {
            dispatch(
              onFocusKeyboard({
                value: pincode,
                layout: "numonly",
              })
            );
          }}
        />
      </Box>
    </Box>
  );
}

export function useAgeCheckByEmployee() {
  const { openDialog, closeDialog } = useDialog();
  const useAgeCheck = useAppSelector((state) => state.global.salesarea.confirm_age_by_employee);

  return function EmployeeAgeCheck() {
    const order = store.getState().shoppingCart.items;
    const productToAgeVerify = findProductWithHighestAgeRequirement(...order);

    // 1) No products with an age requirement in the shopping cart
    //    or age checking is not enabled
    if (productToAgeVerify.requireAge === 0 || productToAgeVerify.requireAge === undefined || !useAgeCheck) {
      return new Promise<AgeCheckResult>((resolve) => resolve(AgeCheckResult.Accepted));
    }

    // 2) There's a product with an age requirement in the shopping cart
    return new Promise<AgeCheckResult>((resolve) => {
      openDialog({
        onClose: () => resolve(AgeCheckResult.Postponed),
        disabledBackdropClick: true,
        children: (
          <ConfirmAgeByEmployeeDialog
            onConfirm={(codeVerified) => {
              if (codeVerified) {
                resolve(AgeCheckResult.Accepted);
              } else {
                // there is no Denied result here by design.
                resolve(AgeCheckResult.Postponed);
              }
              closeDialog();
            }}
          />
        ),
      });
    });
  };
}
