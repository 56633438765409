import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ArticleContent from "./ArticleContent";
import { Button, Collapse, Fade, Paper, Skeleton, Typography } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import Articlegroup, {
  getArticlegroupDescription,
  getArticlegroupName,
} from "../../../global/utils/models/menu/Articlegroup";
import useArticlegroup from "../../../global/utils/menu/useArticlegroup";
import Image from "../../../global/components/Image";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import Box from "@mui/material/Box";
import { useAppSelector } from "../../../global/utils/redux/store";
import { useCustomTextsFromModel } from "../../../global/utils/useCustomTexts";
import useIsWidescreen from "../../../global/utils/useIsWidescreen";
import { selectArticlegroupsMap } from "../../../global/utils/redux/selectors/selectArticlegroupsMap";
import { selectArticlegroupChildrenMap } from "../../../global/utils/redux/selectors/selectArticlegroupChildrenMap";
import { JSClassAnnotatedWithIds } from "../../../kiosk/utils/Helpers";
import Article, { getArticlePrice } from "../../../global/utils/models/menu/Article";
import { DeferredChunkRenderer } from "../../../global/components/DeferredChunkRenderer.tsx";
import { selectAppLanguage } from "../../../kiosk/components/LanguageSelector/useLanguage.ts";
import { postAnalyticsEvent, postEcomAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics.ts";
import _ from "lodash";
import { selectSalesAreaPriceLineId } from "../../../global/utils/useSalesAreaPriceLineId.ts";

interface Props {
  articlegroup: Articlegroup;
}

export default function ArticlegroupContent(props: Props) {
  const { articles } = useArticlegroup(props.articlegroup);
  const useArticlegroupsHierarchically = useAppSelector(
    (state) => state.global.salesarea.custom_data?.appv5_use_articlegroups_hierarchically?.enabled
  );
  const articlegroupsMap = useAppSelector(selectArticlegroupsMap);
  const minWidth = props.articlegroup.minWidthPerArticle;
  const [numberOfColumns, setNumberOfColumns] = useState(1);

  const showMaxNumberOfItems = useMemo(() => {
    if (props.articlegroup.showMaxNumberOfItems == null) {
      return articles.length;
    }
    return Math.max(numberOfColumns, props.articlegroup.showMaxNumberOfItems ?? 0);
  }, [numberOfColumns, articles.length, props.articlegroup.showMaxNumberOfItems]);

  const lang = useAppSelector(selectAppLanguage);
  const description = useMemo(() => {
    return getArticlegroupDescription(props.articlegroup, lang);
  }, [props.articlegroup, lang]);

  const [expand, setExpand] = useState(showMaxNumberOfItems === 0);

  const isColumnOrientation = useMemo(() => {
    if (props.articlegroup.settings) {
      try {
        const settings = JSON.parse(props.articlegroup.settings);
        if (settings.orientation) {
          return settings.orientation === "column";
        }
      } catch (e) {
        console.log(e);
      }
    }
    return false;
  }, [props.articlegroup]);

  const articlegroupChildrenMap = useAppSelector(selectArticlegroupChildrenMap);
  const childArticlegroupIds = useMemo(() => {
    return articlegroupChildrenMap[props.articlegroup.id] ?? [];
  }, [articlegroupChildrenMap, props.articlegroup]);

  const childArticlegroups = useMemo(() => {
    return childArticlegroupIds?.map((articlegroupId) => articlegroupsMap[articlegroupId]).filter((a) => a) ?? [];
  }, [childArticlegroupIds, articlegroupsMap]);

  const reasonDisabledOrderingText = useCustomTextsFromModel(
    "menukaart",
    props.articlegroup.id,
    "reason_disabled_ordering"
  );
  const isWidescreen = useIsWidescreen();
  const priceLineId = useAppSelector(selectSalesAreaPriceLineId);

  useEffect(() => {
    /**
     * Anayltics ecom data
     */
    const ecomAnalyticsItems: object[] = [];
    articles.forEach((article: Article) => {
      ecomAnalyticsItems.push({
        item_id: article.id,
        item_name: article.name,
        price: getArticlePrice(article, { salesAreaPriceLineId: priceLineId }),
        quantity: 1,
      });
    });
    postEcomAnalyticsEvent("view_item_list", {
      item_list_id: props.articlegroup.id,
      item_list_name: props.articlegroup.name,
      items: ecomAnalyticsItems,
    });
  }, [articles, props.articlegroup]);

  const rootRef = useRef<HTMLDivElement>();

  const computeNumberOfColumns = useCallback(
    _.throttle(() => {
      if (rootRef.current) {
        setNumberOfColumns(Math.floor(rootRef.current.getBoundingClientRect().width / minWidth));
      }
    }, 1000),
    [minWidth]
  );

  useEffect(() => {
    const element = rootRef.current;
    let observer: MutationObserver | null = null;
    if (element) {
      computeNumberOfColumns();

      element.addEventListener("scroll", computeNumberOfColumns);
      element.addEventListener("resize", computeNumberOfColumns);
      observer = new MutationObserver(computeNumberOfColumns);
      observer.observe(element, { subtree: true, childList: true });
    }
    return () => {
      element?.removeEventListener("resize", computeNumberOfColumns);
      element?.removeEventListener("scroll", computeNumberOfColumns);
      observer?.disconnect();
    };
  }, [computeNumberOfColumns]);

  return (
    <Box
      ref={rootRef}
      id={"Articlegroup-" + props.articlegroup.id}
      data-cy="menu"
      className={JSClassAnnotatedWithIds("ArticlegroupContent-Root", undefined, props.articlegroup)}
      sx={{ display: "flex", flexWrap: "wrap", width: 1 }}
    >
      {articles.length > 0 ? (
        <Box
          className={JSClassAnnotatedWithIds("JS-ArticlegroupContent-HeaderWrapper", undefined, props.articlegroup)}
          sx={{ width: 1 }}
        >
          <Box
            className={JSClassAnnotatedWithIds(
              "JS-ArticlegroupContent-ImageAndNameWrapper",
              undefined,
              props.articlegroup
            )}
          >
            {props.articlegroup.imageBannerMediaUrl ? (
              <Image
                key={props.articlegroup.imageBannerMediaUrl.id}
                srcSet={props.articlegroup.imageBannerMediaUrl?.conversions?.responsive?.srcset}
                style={{ width: "100%", maxHeight: 400, objectFit: "contain" }}
                className={"JS-ArticlegroupContent-Image"}
              />
            ) : null}
            <Box
              sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: 1 }}
              className={JSClassAnnotatedWithIds(
                "JS-ArticlegroupContent-Name-Container",
                undefined,
                props.articlegroup
              )}
            >
              <Typography
                className={JSClassAnnotatedWithIds("JS-ArticlegroupContent-Name", undefined, props.articlegroup)}
                sx={{
                  fontWeight: 800,
                  fontSize: isWidescreen ? "2rem" : "1.1rem",
                  marginLeft: 1,
                  wordBreak: "break-word",
                }}
              >
                {getArticlegroupName(props.articlegroup, lang)}
              </Typography>
            </Box>
          </Box>
          {props.articlegroup.disableOrdering ? (
            <Typography fontSize={11} sx={{ fontStyle: "italic", ml: 1, textAlign: "center" }}>
              {reasonDisabledOrderingText ?? <FormattedMessageJamezz id="Articlegroup.messages.not-available" />}
            </Typography>
          ) : null}
          {description ? (
            <Typography sx={{ opacity: 0.6, textAlign: "center" }} className={"JS-ArticlegroupContent-Description"}>
              {description}
            </Typography>
          ) : null}
        </Box>
      ) : null}

      {
        <>
          <DeferredChunkRenderer
            placeholder={<Skeleton key={"skeleton"} sx={{ width: 1, height: 84 }} />}
            prerenderSize={4}
          >
            {articles.slice(0, showMaxNumberOfItems).map((article) => (
              <Box
                key={article.id}
                className={JSClassAnnotatedWithIds("JS-ArticleContent-Root", article)}
                sx={{ display: "flex", flexDirection: "column", minWidth, flex: "1 1 0%" }}
              >
                <ArticleContent
                  articlegroup={props.articlegroup}
                  article={article}
                  isColumnOrientation={isColumnOrientation}
                />
              </Box>
            ))}
          </DeferredChunkRenderer>

          {articles.slice(showMaxNumberOfItems, articles.length).map((article) => (
            <Fade unmountOnExit in={expand} key={article.id}>
              <Box
                className={JSClassAnnotatedWithIds("JS-ArticleContent-Root", article)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth,
                  flex: "1 1 0%",
                }}
              >
                <ArticleContent
                  articlegroup={props.articlegroup}
                  article={article}
                  isColumnOrientation={isColumnOrientation}
                />
              </Box>
            </Fade>
          ))}

          {_.times(numberOfColumns - 1).map(() => (
            <Box sx={{ minWidth, flex: "1 1 0%" }}></Box>
          ))}
        </>
      }
      {showMaxNumberOfItems < articles.length ? (
        <Collapse in={!expand} unmountOnExit sx={{ width: 1, textAlign: "center" }}>
          <Button
            component={Paper}
            startIcon={<Refresh />}
            sx={{
              marginY: 1,
              padding: 1,
              paddingX: 3,
              whiteSpace: "nowrap",
              backgroundColor: "background.paper",
              borderRadius: 3,
            }}
            color={"inherit"}
            onClick={() => {
              postAnalyticsEvent({
                category: "ArticlegroupContent",
                action: "Load more...",
              });
              setExpand(true);
            }}
          >
            <FormattedMessageJamezz id={"Load more..."} />
          </Button>
        </Collapse>
      ) : null}
      {useArticlegroupsHierarchically
        ? childArticlegroups?.map((articlegroup) => {
            return <ArticlegroupContent key={articlegroup.id} articlegroup={articlegroup} />;
          })
        : null}
    </Box>
  );
}
