import OrderArticle from "../../../global/utils/models/order/OrderArticle.ts";
import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { selectArticlesMap } from "../../../global/utils/redux/selectors/selectArticlesMap.ts";
import useMenuFilters from "../../../global/utils/menu/filters/useMenuFilters.ts";
import { useMemo } from "react";
import Rule, { ArticleAddedToShoppingCartRule } from "../../../global/utils/automation/Rule.ts";
import Article from "../../../global/utils/models/menu/Article.ts";
import { getArticleById } from "../../../global/utils/models/menu/Menu.ts";
import ArticleSuggestionPage from "../ArticleSuggestionPage/ArticleSuggestionPage.tsx";
import { selectAppLanguage } from "../../../kiosk/components/LanguageSelector/useLanguage.ts";
import { selectArticleTriggers } from "../../../global/utils/redux/selectors/upsells/selectArticleTriggers.ts";

export function UpsellWhenOrderingDisabled({ orderArticle }: { orderArticle: OrderArticle }) {
  const articleAddedToShoppingCartRules = useAppSelector(selectArticleTriggers);

  const lang = useAppSelector(selectAppLanguage);
  const articlesMap = useAppSelector(selectArticlesMap);

  const articleFilter = useMenuFilters();

  const rule = useMemo<ArticleAddedToShoppingCartRule | null>(() => {
    return (
      articleAddedToShoppingCartRules[orderArticle.article.id]?.find((rule: Rule) => {
        if (rule.action?.discriminator === "ShowArticleDialogAction") {
          return true;
        }
      }) ?? null
    );
  }, [orderArticle, articleAddedToShoppingCartRules]);

  const upsellArticles = useMemo(() => {
    let articles: Article[] = [];
    if (rule) {
      rule.action.articleIds.forEach((articleId) => {
        const article = getArticleById(articlesMap, articleId);
        if (article) {
          articles.push(article);
        }
      });
      articles = articles.filter(articleFilter);
      return articles;
    }
    return [];
  }, [rule, articleFilter, articlesMap]);

  const validArticlesCount = (upsellArticles ?? []).filter((article) => {
    return !(article.isOutOfStock || article.isNotAvailable);
  }).length;

  return rule && validArticlesCount > 0 && upsellArticles ? (
    <ArticleSuggestionPage
      articles={upsellArticles ?? []}
      title={rule.action.titleTranslations?.[lang] ?? rule.action.title}
      text={rule.action.descriptionTranslations?.[lang] ?? rule.action.description}
      upsellType={"UpsellOrderingDisabled"}
      useAsDialog={false}
    />
  ) : null;
}
