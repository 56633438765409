import { useAppSelector } from "../../../global/utils/redux/store";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import { Button, Typography } from "@mui/material";
import { ComponentOverlay } from "../../../global/components/ComponentOverlay";
import LoginDialog from "./LoginDialog";
import SlideUpTransition from "../../../global/components/SlideUpTransition";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import _ from "lodash";
import { useEffect } from "react";
import MWiseExtraCard from "./RewardCard";
import { useLazyFetchCustomerRewardsQuery, useSessionQuery } from "../../../global/utils/redux/api/MWiseApi";
import { fakeApiData } from "./FakeApiData";
import { LoadingOverlay } from "../../../global/components/LoadingOverlay";
import { MWiseReward, MWiseRewardVisualStatus } from "../../../types/mwise";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import isQr from "../../../global/utils/helpers/isQr";
import useIsWidescreen from "../../../global/utils/useIsWidescreen";

// set to true if you want to use faked api data
const devmode = false;

export function MWiseRewards() {
  const user = useAppSelector((state) => state.mwise.user);
  const { openDialog } = useDialog();
  const isWidescreen = useIsWidescreen();
  const checkCalculateSettings = useAppSelector((state) => state.global.salesarea.check_calculate_settings); // Is needed to add discounts in basket
  const [requestCustomerRewards, { data: apiRewards, isLoading, isSuccess }] = useLazyFetchCustomerRewardsQuery();

  useSessionQuery(undefined, {
    pollingInterval: 120 * 1000,
  });

  const rewards = [...(apiRewards ?? [])].sort((a: MWiseReward, b) => {
    if (a.VisualStatus === MWiseRewardVisualStatus.Valid && b.VisualStatus !== MWiseRewardVisualStatus.Valid) {
      return -1;
    } else if (a.VisualStatus !== MWiseRewardVisualStatus.Valid && b.VisualStatus === MWiseRewardVisualStatus.Valid) {
      return 1;
    } else {
      return 0;
    }
  });

  useEffect(() => {
    if (user && checkCalculateSettings.enabled && checkCalculateSettings.product_id) {
      requestCustomerRewards();
    }
  }, [user, requestCustomerRewards, checkCalculateSettings]);

  return (
    <div style={{ width: "100%" }} data-cy="MWise-rewards-container">
      {!checkCalculateSettings.enabled || checkCalculateSettings.product_id == null ? (
        <Typography sx={{ background: "red", color: "white", padding: 2, fontSize: "1rem" }}>
          Discount product not set in backoffice -&gt; Oracle settings. Or check calculate settings is not enabled
        </Typography>
      ) : (
        <>
          <Typography
            variant="h2"
            fontSize="32px"
            className={"JS-ArticlegroupContent-Name JS-MWiseContent-Name"}
            sx={{
              fontWeight: 800,
              fontStyle: "italic",
              fontSize: "1.1rem",
              marginLeft: 1,
              wordBreak: "break-word",
            }}
          >
            Extraatjes{" "}
            {user ? <FormattedMessageJamezz id="MWise.extraatjes.forUser" values={{ user: user.FirstName }} /> : null}
          </Typography>
          <LoadingOverlay isLoading={isLoading}>
            <ComponentOverlay
              backdropColor="rgba(255, 255, 255, 0.85)"
              disabled={(user !== null && isSuccess) || isLoading}
              overlayContent={
                user == null ? (
                  <Button
                    data-cy="MWise-login-btn"
                    sx={{
                      fontWeight: "bolder",
                      fontSize: "1.2em",
                    }}
                    className="JS-MWiseRewards-CallToAction-Login"
                    onClick={() => {
                      openDialog({
                        fullScreen: !(isQr() && isWidescreen),
                        children: <LoginDialog />,
                        TransitionComponent: SlideUpTransition,
                      });
                    }}
                  >
                    <FormattedMessageJamezz id={"Log in with your loyalty card"} />
                  </Button>
                ) : !isSuccess && !isLoading ? (
                  <Button
                    data-cy="MWise-get-extras"
                    variant="text"
                    className="JS-MWiseRewards-CallToAction-Login"
                    onClick={() => {
                      requestCustomerRewards();
                    }}
                  >
                    <FormattedMessageJamezz id={"Get Extras"} />
                  </Button>
                ) : (
                  <></>
                )
              }
            >
              <Swiper
                style={{
                  paddingTop: "16px",
                  paddingBottom: "40px",
                  paddingLeft: "8px",
                  display: "block",
                  width: "100%",
                }}
                slidesPerView="auto"
                pagination={{
                  clickable: true,
                }}
                spaceBetween={30}
                autoplay={{
                  delay: 3500,
                  disableOnInteraction: true,
                }}
                modules={[Pagination, ...(user ? [Autoplay] : [])]}
                onSwiper={(swiper) => {
                  _.defer(() => {
                    swiper.update();
                  });
                }}
              >
                {devmode
                  ? fakeApiData.map((fakeReward) => (
                      <SwiperSlide key={fakeReward.VisitExtraNo} style={{ width: "320px" }}>
                        <MWiseExtraCard reward={fakeReward} />
                      </SwiperSlide>
                    ))
                  : null}
                {apiRewards
                  ? apiRewards.length
                    ? rewards
                        .filter((r) => r.VisualStatus !== MWiseRewardVisualStatus.LockedAndInvisible)
                        .map((reward) => (
                          <SwiperSlide key={reward.PositionId} style={{ width: "320px" }}>
                            <MWiseExtraCard reward={reward} />
                          </SwiperSlide>
                        ))
                    : "Helaas: Er zijn op dit moment geen extraatjes voor je beschikbaar."
                  : fakeApiData.map((fakeReward) => (
                      <SwiperSlide key={fakeReward.VisitExtraNo} style={{ width: "320px" }}>
                        <MWiseExtraCard reward={fakeReward} />
                      </SwiperSlide>
                    ))}
              </Swiper>
            </ComponentOverlay>
          </LoadingOverlay>
        </>
      )}
    </div>
  );
}
