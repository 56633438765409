import React, { useMemo } from "react";
import { useDialog } from "../../../global/utils/dialog/DialogProvider.tsx";
import { ListItem, ListItemButton, ListItemIcon, ListItemText, SvgIcon } from "@mui/material";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { VoucherDialog } from "../VoucherInput.tsx";
import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import PercentageDiscount from "../../../assets/images/PercentageDiscount.svg?react";

export default function useAddVoucherListItem() {
  const { openDialog } = useDialog();
  const allowVouchers = useAppSelector((state) => state.global.salesarea?.allowVouchers);
  const intl = useIntl();

  return useMemo(
    () =>
      allowVouchers ? (
        <ListItem
          className={clsx("JS-SideMenuDrawer-ListItem", "JS-SideMenuDrawer-Add-Voucher")}
          key={"JS-SideMenuDrawer-Add-Voucher"}
          disablePadding
        >
          <ListItemButton
            onClick={() => {
              openDialog({ children: <VoucherDialog /> });
            }}
          >
            <ListItemIcon className={"JS-SideDrawerMenu-ItemIcon"}>
              <SvgIcon component={PercentageDiscount} inheritViewBox />
            </ListItemIcon>
            <ListItemText primary={intl.formatMessage({ id: "Add voucher" })} />
          </ListItemButton>
        </ListItem>
      ) : null,
    [allowVouchers, intl, openDialog]
  );
}
