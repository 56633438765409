import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store.tsx";
import { selectAccountTotalValue } from "../accountSlice.tsx";

export const selectPiggyGiftcardsWithUsingAmounts = createSelector(
  [(state: RootState) => state.piggy.giftcards, selectAccountTotalValue],
  (piggyGiftcards, totalAmount) => {
    let amountUsed = 0;

    return [...piggyGiftcards]
      .sort((a, b) => {
        return a.amount_in_cents - b.amount_in_cents;
      })
      .map((giftcard) => {
        const usingAmount = Math.round(Math.min(totalAmount * 100 - amountUsed, giftcard.amount_in_cents));
        amountUsed += usingAmount;
        return { giftcard, using_amount_in_cents: usingAmount };
      });
  }
);
