import { dayIndexToDay, getNow, setDateToJamezzTime, TimeSpan } from "../../models/JamezzHours";
import { addDays, closestTo, differenceInMilliseconds, format, isBefore } from "date-fns";
import { setMenuState } from "../../redux/menuStateSlice";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import OrderHours from "../../models/menu/OrderHours";
import Article from "../../models/menu/Article";
import Articlegroup from "../../models/menu/Articlegroup";
import { selectArticlegroupsMap } from "../../redux/selectors/selectArticlegroupsMap";
import { selectArticlesMap } from "../../redux/selectors/selectArticlesMap";

export interface ItemOrderHours {
  jamezzHours: TimeSpan;
}

export default function MenuArticleOrderHours() {
  const dispatch = useAppDispatch();
  const [updateArticleOrderHours, setUpdateArticleOrderHours] = useState(new Date().toTimeString());
  const [updateArticlegroupOrderHours, setUpdateArticlegroupOrderHours] = useState(new Date().toTimeString());
  const timer = useRef(null);
  const timer2 = useRef(null);
  const articlegroupsMap = useAppSelector(selectArticlegroupsMap);
  const articlesMap = useAppSelector(selectArticlesMap);

  const articles = useMemo<Article[]>(() => Object.values(articlesMap), [articlesMap]);
  const articlegroups = useMemo<Articlegroup[]>(() => Object.values(articlegroupsMap), [articlegroupsMap]);

  const generateOrderHours = (items: any, setState: any, timer: any) => {
    const now = getNow();
    const dayIndex = now.getDay();
    const day = dayIndexToDay(dayIndex);

    const orderHours: OrderHours = {};
    if (items.length == 0) {
      return {};
    }
    let nearestUpdateDate = getNow();
    nearestUpdateDate = addDays(nearestUpdateDate, 1);
    items.forEach((item: any) => {
      if (item.orderTimes) {
        // @ts-ignore
        let jamezzHours: TimeSpan[] = item.orderTimes[day.valueOf()];
        const dateKey = format(getNow(), "yyyy-MM-dd");
        if (item.orderTimes.exceptions[dateKey]) {
          jamezzHours = item.orderTimes.exceptions[dateKey];
        }

        const currentJamezzHour = jamezzHours.find((jamezzHour) => {
          const startDate = setDateToJamezzTime(jamezzHour.startTime);
          const endDate = setDateToJamezzTime(jamezzHour.endTime);

          if (!isBefore(now, endDate)) {
            return false;
          }
          if (!isBefore(startDate, now)) {
            nearestUpdateDate = closestTo(now, [nearestUpdateDate, startDate]) ?? nearestUpdateDate;
            return false;
          }
          nearestUpdateDate = closestTo(now, [nearestUpdateDate, endDate]) ?? nearestUpdateDate;
          return true;
        });

        if (currentJamezzHour) {
          orderHours[item.id] = { jamezzHours: currentJamezzHour };
        } else {
          orderHours[item.id] = false;
        }
      }
    });

    if (nearestUpdateDate) {
      // console.log(nearestUpdateDate);
      if (timer.current) {
        clearTimeout(timer.current);
      }
      const timeout = differenceInMilliseconds(nearestUpdateDate, now);

      // console.log("Next update: " + timeout / 3600000);
      // console.log("Next update: " + timeout / 1000);
      timer.current = setTimeout(() => {
        setState(new Date().toTimeString());
        timer.current = null;
      }, timeout + 1000);
    }
    return orderHours;
  };

  useEffect(() => {
    if (!articles) {
      return;
    }

    const articleOrderHours = generateOrderHours(articles, setUpdateArticleOrderHours, timer);

    dispatch(setMenuState({ articleOrderHours }));
  }, [articles, updateArticleOrderHours, dispatch]);

  useEffect(() => {
    if (!articlegroups) {
      return;
    }

    const articlegroupOrderHours = generateOrderHours(articlegroups, setUpdateArticlegroupOrderHours, timer2);

    dispatch(setMenuState({ articlegroupOrderHours }));
  }, [updateArticlegroupOrderHours, articlegroups, dispatch]);

  return null;
}
