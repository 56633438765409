import { useLayoutEffect, useState } from "react";
import store, { useAppSelector } from "../../../global/utils/redux/store.tsx";
import {
  ARRANGEMENT_FIRST_ROUND,
  orderTimerElapsed,
  resetOrderTimer,
  selectCurrentOrderRound,
  selectTimeSinceLastOrderInSeconds,
} from "../../../global/utils/redux/arrangements/arrangementsSlice.tsx";
import { selectActiveArrangement } from "../../../global/utils/redux/arrangements/selectActiveArrangement.tsx";

export const secondsPerMinute = 60;

export function getOrderTimerAsSecondsLeft() {
  const state = store.getState();
  const activeArrangement = selectActiveArrangement(state);
  const timeSinceLastOrder = selectTimeSinceLastOrderInSeconds(state, Math.random());
  const orderRound = selectCurrentOrderRound(state);

  if (activeArrangement?.variant !== "regular" || orderRound === ARRANGEMENT_FIRST_ROUND) {
    return 0;
  }
  const minutesBetweenEveryRound = activeArrangement?.minutes_between_every_round ?? 0;
  const secondsBetweenRound = minutesBetweenEveryRound * secondsPerMinute;
  return Math.max(0, secondsBetweenRound - (timeSinceLastOrder ?? 0));
}

export function useOrderTimer() {
  const [timer, setTimer] = useState<number>(0);
  const orderRound = useAppSelector(selectCurrentOrderRound);
  const activeArrangement = useAppSelector(selectActiveArrangement);

  const timeSinceLastOrder = useAppSelector((state) => selectTimeSinceLastOrderInSeconds(state, timer));
  const minutesBetweenEveryRound =
    activeArrangement?.variant === "regular" ? activeArrangement.minutes_between_every_round : 0;
  const secondsBetweenRound = minutesBetweenEveryRound * secondsPerMinute;
  const timeUntilNextOrder = Math.max(0, secondsBetweenRound - (timeSinceLastOrder ?? 0));

  useLayoutEffect(() => {
    let done: boolean | null = null;

    function tick() {
      setTimer(Math.random());
      if (timeSinceLastOrder === null) return;
      const newDone = timeUntilNextOrder === 0;
      if (done !== newDone) {
        if (newDone) {
          store.dispatch(orderTimerElapsed());
        } else {
          store.dispatch(resetOrderTimer());
        }
        done = newDone;
      }
    }

    // Only change this number if you understand nyquist-shannon sampling theorem!!!
    const intervalId = window.setInterval(tick, 490);

    return () => {
      window.clearInterval(intervalId);
    };
  }, [setTimer, timeUntilNextOrder, timeSinceLastOrder]);

  return orderRound === 1 ? 0 : timeUntilNextOrder;
}
