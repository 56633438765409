import { useAppSelector } from "../redux/store";
import { useMemo } from "react";

export default function useAlcoholConfirmationEnabled() {
  const enabledAlcoholConfirmation = useAppSelector(
    (state) => state.global.salesarea?.custom_data?.order_confirmations?.alcohol_confirmation?.enabled
  );
  const answers = useAppSelector(
    (state) => state.global.salesarea?.custom_data?.order_confirmations?.alcohol_confirmation?.answers
  );
  const question = useAppSelector(
    (state) => state.global.salesarea?.custom_data?.order_confirmations?.alcohol_confirmation?.question?.en
  );

  const answersContainAtLeastOneApprovedAnswer = useMemo<boolean>(() => {
    return (
      answers?.some((answer) => {
        return answer.type === "APPROVED";
      }) ?? false
    );
  }, [answers]);

  const answersContainAtLeastOneDeniedAnswer = useMemo<boolean>(() => {
    return (
      answers?.some((answer) => {
        return answer.type === "DENIED";
      }) ?? false
    );
  }, [answers]);

  return (
    enabledAlcoholConfirmation &&
    answersContainAtLeastOneDeniedAnswer &&
    answersContainAtLeastOneApprovedAnswer &&
    question
  );
}
