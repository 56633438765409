import { Chip, CircularProgress, Fade } from "@mui/material";

import { CikamTransaction } from "../../utils/redux/api/cikamApi";
import { CikamStatusCode } from "../../utils/redux/cikamSlice";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";

export default function CikamStatusView({ transaction }: { transaction: CikamTransaction }) {
  const intl = useIntl();
  switch (transaction.StatusCode) {
    case CikamStatusCode.Counting:
      return (
        <>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Chip
              label={intl.formatMessage({
                id: transaction.StatusDescription,
                defaultMessage: transaction.StatusDescription,
              })}
              sx={{ backgroundColor: "info.main", color: "white", height: 72, my: 2, fontSize: 48 }}
            />
            <CircularProgress size={72} />
          </Box>
        </>
      );

    default:
      return (
        <Fade key={transaction.StatusCode} in={true}>
          <Chip
            label={transaction.StatusDescription}
            sx={{ backgroundColor: "info.main", color: "white", height: 72, my: 2, fontSize: 48 }}
          />
        </Fade>
      );
  }
}
