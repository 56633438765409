import { NavigateOptions, To, useNavigate } from "react-router";
import { useCallback } from "react";
import store from "./redux/store";

export default function useAppNavigate() {
  const navigate = useNavigate();

  return useCallback(
    (to: To, options?: NavigateOptions) => {
      let navigateTo = to as string;

      navigateTo = navigateTo.replace(":webcode", store.getState().global.webcode);
      return navigate(navigateTo, options);
    },
    [navigate]
  );
}
