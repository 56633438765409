import useSalesareaType, { SalesareaType } from "./useSalesareaType.tsx";
import Articlegroup from "../models/menu/Articlegroup.ts";
import { AyceRuleResult, useCheckAyceRules } from "../../../qr/utils/arrangements/useCheckAyceRules.ts";
import useSalesareaIsOpen from "../useSalesareaIsOpen.ts";
import Article from "../models/menu/Article.ts";

export type OrderingStatus =
  | "ALLOWED"
  | "SALESAREA_DISABLED"
  | "ARTICLEGROUP_DISABLED"
  | "ARTICLE_DISABLED"
  | "ARRANGEMENT_ENDED";

export default function useIsOrderingAllowed(articlegroup?: Articlegroup, article?: Article): OrderingStatus {
  const salesareaType = useSalesareaType();
  const isOpen = useSalesareaIsOpen();
  const arrangementRuleResult = useCheckAyceRules();

  if (salesareaType === SalesareaType.CATALOG || !isOpen) {
    return "SALESAREA_DISABLED";
  }
  if (articlegroup?.disableOrdering) {
    return "ARTICLEGROUP_DISABLED";
  }

  if (article?.canOrder === false) {
    return "ARTICLE_DISABLED";
  }

  if (
    arrangementRuleResult == AyceRuleResult.EATING_TIME_OVER ||
    arrangementRuleResult == AyceRuleResult.ROUND_COUNTER_EXCEEDED
  ) {
    return "ARRANGEMENT_ENDED";
  }

  return "ALLOWED";
}
