import Box from "@mui/material/Box";

interface Props {
  selected: boolean;
  sx: object;
  children: any;
  className?: string;
}

export default function OptionItemSwitch(props: Props) {
  return (
    <Box
      sx={{ borderRadius: 8, backgroundColor: props.selected ? "primary.main" : null, ...props.sx }}
      className={props.className}
    >
      {props.children}
    </Box>
  );
}
