import { useAppSelector } from "../../global/utils/redux/store";
import useTraitFiltering from "../../global/utils/articleTraits/useTraitFiltering";
import { Button, Collapse, List, ListItem, Typography } from "@mui/material";
import ArticleTrait from "../../global/utils/models/ArticleTraits/ArticleTrait";
import useArticleTraitValues from "../../global/utils/articleTraits/useArticleTraitValues";
import { CheckCircle } from "@mui/icons-material";
import { selectArticleTraitsMap } from "../../global/utils/redux/selectors/selectArticleTraitsMap";

interface Props {
  articleTrait: ArticleTrait;
  nestedLevel: number;
}

export default function ArticleTraitView(props: Props) {
  const { articleTrait, nestedLevel } = props;
  const stateOfArticleTraits: any = useAppSelector((state) => state.traitFilter.stateOfArticleTraits);
  const currentValuesArray = stateOfArticleTraits[articleTrait.apiId];

  const { setValuesForFilter } = useTraitFiltering();
  const { currentValuesObject, articleTraitValues } = useArticleTraitValues(articleTrait);
  const { articleTraitsMap, traitChildrensMap } = useAppSelector(selectArticleTraitsMap);
  if (articleTraitValues.length <= 0 && nestedLevel > 0) {
    return null;
  }

  return (
    <List sx={{ overflowY: "auto", flexGrow: 1, marginLeft: nestedLevel * 8 }}>
      {articleTraitValues.map((articleTraitValue) => {
        return (
          <>
            <ListItem
              className={`${articleTrait.apiId}-QuickTraitValue-Letter-${nestedLevel}-` + articleTraitValue.charAt(0)}
            >
              <Button
                startIcon={
                  currentValuesObject[articleTraitValue] ? (
                    <CheckCircle
                      sx={{ color: "primary.main", fontSize: 36, "&:nth-of-type(1)": { fontSize: "unset" } }}
                    />
                  ) : undefined
                }
                sx={{
                  textAlign: "left",
                  paddingX: 3,
                  paddingY: 1,
                  color: "text.primary",
                  // borderBottom: currentValuesObject[articleTraitValue] ? "3px solid black" : "unset",
                  // marginBottom: currentValuesObject[articleTraitValue] ? "-3px" : 0,
                }}
                onClick={() => {
                  const values = [...(currentValuesArray ?? [])];
                  if (currentValuesObject[articleTraitValue]) {
                    // remove
                    const index = values.findIndex((v) => v === articleTraitValue);
                    values.splice(index, 1);
                  } else {
                    values.push(articleTraitValue);
                  }
                  setValuesForFilter(articleTrait, values);
                }}
              >
                <Typography sx={{ "::first-letter": { fontWeight: 800 } }}>{articleTraitValue}</Typography>
              </Button>
            </ListItem>
            <Collapse
              in={currentValuesObject[articleTraitValue] && !!traitChildrensMap[articleTraitValue]}
              timeout="auto"
              unmountOnExit
            >
              <ArticleTraitView
                articleTrait={articleTraitsMap[traitChildrensMap[articleTraitValue]]}
                nestedLevel={nestedLevel + 1}
              />
            </Collapse>
          </>
        );
      })}
    </List>
  );
}
