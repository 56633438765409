import { useFetchPointsBalanceQuery } from "../../../../../global/utils/redux/api/piggyApi.ts";
import { CircularProgress, Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../../../global/components/FormattedMessageJamezz.tsx";

export default function Points({ isCompactView }: { isCompactView?: boolean }) {
  const { data: balance, isLoading: balanceLoading } = useFetchPointsBalanceQuery();
  return (
    <>
      {balanceLoading ? (
        <CircularProgress sx={{ color: "white" }} />
      ) : (
        <Typography
          sx={{ fontSize: isCompactView ? "1rem" : "2rem", backgroundColor: "primary.light", px: 2, borderRadius: 3 }}
          data-cy={"piggy-points-balance"}
        >
          <FormattedMessageJamezz id={"{balance} points"} values={{ balance: balance }} />
        </Typography>
      )}
    </>
  );
}
