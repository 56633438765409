import Article from "../../../global/utils/models/menu/Article";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";

interface Props {
  article: Article;
}
export default function Stock(props: Props) {
  if (props.article.stock === 0) {
    return null;
  }

  if (props.article.stock > 5) {
    return (
      <Box sx={{ marginX: 2 }}>
        <Typography sx={{ whiteSpace: "nowrap" }}>
          <FormattedMessageJamezz id={"In stock ({stock})"} values={{ stock: props.article.stock }} />
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ marginX: 2 }}>
      <Typography sx={{ whiteSpace: "nowrap" }}>
        <FormattedMessageJamezz id={"Limited stock ({stock})"} values={{ stock: props.article.stock }} />
      </Typography>
    </Box>
  );
}
