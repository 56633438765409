import { useDialog } from "../dialog/DialogProvider";
import store, { useAppSelector } from "../redux/store";
import { useGetShoppingCartConfirmationQuery } from "../redux/api/shoppingCartApi";
import { useEffect } from "react";
import { shoppingCartConfirmationResponseReceived } from "../redux/shoppingCartSlice";
import { CircularProgress, DialogContent, DialogTitle } from "@mui/material";
import Box from "@mui/material/Box";
import { ShoppingCartConfirmationDenied } from "./ShoppingCartConfirmationDenied";
import { usePreventKioskTimeout } from "../../modules/PreventKioskTimeout/preventKioskTimeout.ts";

export default function WaitingForOrderConfirmationDialog() {
  const { openDialog } = useDialog();
  const uuid = useAppSelector((state) => state.shoppingCart.serverSideShoppingCart?.uuid);
  const { data } = useGetShoppingCartConfirmationQuery(uuid ?? "", {
    pollingInterval: 4000,
    skip: !uuid,
  });
  usePreventKioskTimeout(true);
  // const resolve = useAppSelector((state) => state.shoppingCart.shoppingCartConfirmation.waitingForRequestDialogIsOpen);

  useEffect(() => {
    if (data?.data && data.data.response !== "NO_RESPONSE_YET") {
      store.dispatch(shoppingCartConfirmationResponseReceived(data.data));
    }
  }, [data]);

  useEffect(() => {
    if (data?.data?.response === "DENIED") {
      openDialog({ children: <ShoppingCartConfirmationDenied />, disabledBackdropClick: true });
    }
  }, []);
  return (
    <>
      <DialogTitle>Please wait until an employee approves your order.</DialogTitle>
      <DialogContent>
        <Box sx={{ width: 1, padding: 2, display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      </DialogContent>
    </>
  );
}
