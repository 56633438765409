import { Close } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { useDialog } from "../../global/utils/dialog/DialogProvider";
import { CSSProperties } from "@mui/material/styles/createMixins";

interface Props {
  onClose?: () => void;
  position?: CSSProperties["position"];
}

export default function CloseDialogButton(props: Props) {
  const { closeDialog } = useDialog();
  return (
    <IconButton
      data-cy="dialog-close-btn"
      className={"JS-CloseDialogButton-Root"}
      sx={{
        zIndex: 100,
        position: props.position ?? "absolute",
        top: 0,
        right: 0,
        marginTop: 3,
        marginRight: 3,
        opacity: 0.7,
        background: "grey",
      }}
      onClick={() => {
        if (props.onClose) {
          props.onClose();
        } else {
          closeDialog();
        }
      }}
    >
      <Close sx={{ width: 100, height: 100 }} className={"JS-CloseDialogButton-Icon"} />
    </IconButton>
  );
}
