import Box from "@mui/material/Box";
import { useEffect } from "react";
import { Button, SxProps, Typography } from "@mui/material";
import store, { useAppSelector } from "../../../global/utils/redux/store";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import {
  PackagingType,
  selectAllPackagingsTypesFromShoppingCartItems,
  userSelectedPackagingTypeId,
} from "../../../global/utils/redux/packagingsSlice";
import useAskForWhichPackagingTypeToUse from "../../../qr/components/Packagings/useAskForWhichPackagingTypeToUse";
import {
  OptionTranslationsContext,
  OptionTranslationType,
  useOptionTranslations,
} from "../../../global/utils/translations/useOptionTranslations";
import { PackagingFee } from "../../../qr/components/Packagings/PackagingCheckout";
import { Theme } from "@mui/material/styles";

export default function PackagingKiosk({ sx }: { sx?: SxProps<Theme> }) {
  const packagingsPerType = useAppSelector(selectAllPackagingsTypesFromShoppingCartItems);

  const userSelectedPackagingType = useAppSelector((state) =>
    state.packagings.userSelectedPackagingTypeId
      ? state.packagings.packagingTypes[state.packagings.userSelectedPackagingTypeId]
      : undefined
  );
  const askForPackagingType = useAskForWhichPackagingTypeToUse();

  useEffect(() => {
    if (userSelectedPackagingType == null && Object.keys(packagingsPerType).length > 1) {
      askForPackagingType();
    } else if (userSelectedPackagingType == null && Object.keys(packagingsPerType).length === 1) {
      store.dispatch(userSelectedPackagingTypeId(Object.keys(packagingsPerType)[0]));
    }
  }, [askForPackagingType, packagingsPerType, userSelectedPackagingType]);

  return (
    <>
      {Object.keys(packagingsPerType).length > 1 && userSelectedPackagingType ? (
        <OptionTranslationsContext.Provider value={userSelectedPackagingType.translations}>
          <SelectedPackaging packagingType={userSelectedPackagingType} sx={sx} />
        </OptionTranslationsContext.Provider>
      ) : null}
      {Object.keys(packagingsPerType).length === 1 && userSelectedPackagingType ? (
        <PackagingFee showDivider={true} />
      ) : null}
    </>
  );
}

export function SelectedPackaging({ packagingType, sx }: { packagingType: PackagingType; sx?: SxProps<Theme> }) {
  const { translate } = useOptionTranslations();
  const askForPackagingType = useAskForWhichPackagingTypeToUse();
  return (
    <>
      <Box sx={{ mb: 2, borderRadius: 3, px: 2, ...sx }}>
        <Box sx={{ display: "inline-flex", justifyContent: "space-between", width: 1 }}>
          <Typography className={"JS-SelectedPackaging-Title"}>
            <FormattedMessageJamezz id={"Packaging"} />
          </Typography>
        </Box>
        <Box sx={{ my: 2 }}>
          <Box sx={{ display: "inline-flex", justifyContent: "space-between", alignItems: "center", width: 1 }}>
            <Typography sx={{ textAlign: "center" }} className={"JS-SelectedPackaging-Option"}>
              {translate(packagingType.name, OptionTranslationType.OptionGroup)}
            </Typography>
            <Button
              variant={"contained"}
              onClick={() => {
                askForPackagingType();
              }}
            >
              <FormattedMessageJamezz id={"Change packaging?"} />
            </Button>
          </Box>
        </Box>

        <PackagingFee showDivider={true} />
      </Box>
    </>
  );
}
