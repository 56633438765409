import Article from "../models/menu/Article";

export enum VoucherType {
  AMOUNT_OFF_TOTAL = "AMOUNT_OFF_TOTAL",
  PERCENTAGE_OFF_TOTAL = "PERCENTAGE_OFF_TOTAL",
  AMOUNT_OFF_PRODUCT = "AMOUNT_OFF_PRODUCT",
  PERCENTAGE_OFF_PRODUCT = "PERCENTAGE_OFF_PRODUCT",
  ADD_DISCOUNTED_PRODUCT = "ADD_DISCOUNTED_PRODUCT",
}

export type VoucherDef = {
  id?: number;
  maxChooseItems?: number;
  includedProducts_JSON?: string;
  discountType: VoucherType;
  priceDiscountAmount: string;
  priceDiscountPercentage: string;
  freeProducts_JSON: string;
};

export default interface Voucher {
  voucherType: VoucherType;
  amount: number;
  articles: Article[];
  id: string;
  useRequestList: string;
  vouchercode: string;
  voucherDef: VoucherDef;
}

/**
 * In the backoffice a voucher looks like "abc123", but if you print it
 * the same voucher will look like "QRD|{salesareaID}|abc123"
 * For checking vouchers, we only need the "abc123" part.
 */
export function voucherCodeFromScannedCode(scannedCode: string) {
  if (scannedCode.includes("|") && scannedCode.startsWith("QRD")) {
    return scannedCode.split("|")[2];
  }
  return scannedCode;
}
