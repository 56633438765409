import { memo, useMemo } from "react";
import CustomField, { CustomFieldType } from "../../../types/shared/CustomField";
import CustomFieldTextContent from "./CustomFields/CustomFieldTextContent";
import CustomFieldEmailContent from "./CustomFields/CustomFieldEmailContent";
import CustomFieldTelephoneContent from "./CustomFields/CustomFieldTelephoneContent";

import CustomFieldSelectContent from "./CustomFields/CustomFieldSelectContent";
import CustomFieldDateTimeContent from "./CustomFields/CustomFieldDateTimeContent";
import CustomFieldNumpadContent from "./CustomFields/CustomFieldNumpadContent";
import CustomFieldButtonGroupContent from "./CustomFields/CustomFieldButtonGroupContent";
import CustomFieldHiddenContent from "./CustomFields/CustomFieldHiddenContent";
import isKiosk from "../../../global/utils/helpers/isKiosk";

interface Props {
  customField: CustomField;
  // setCustomField: any;
}

function _CustomFieldContent(props: Props) {
  const customField = useMemo(() => props.customField, []);

  switch (customField.type) {
    case CustomFieldType.Text:
      return <CustomFieldTextContent customField={customField} />;
    case CustomFieldType.Hidden:
      return <CustomFieldHiddenContent customField={customField} />;
    case CustomFieldType.Email:
      return <CustomFieldEmailContent customField={customField} />;
    case CustomFieldType.Mobilenr:
      return <CustomFieldTelephoneContent customField={customField} />;
    case CustomFieldType.Select:
      return <CustomFieldSelectContent customField={customField} />;
    case CustomFieldType.PaymentMethodButtons:
      return <CustomFieldButtonGroupContent customField={customField} />;
    case CustomFieldType.OrderMode:
    case CustomFieldType.SelectButtons:
      return <CustomFieldButtonGroupContent customField={customField} />;
    case CustomFieldType.PaymentMethodDropDown:
      return <CustomFieldSelectContent customField={customField} />;
    case CustomFieldType.DateTime:
      return <CustomFieldDateTimeContent customField={customField} />;
    case CustomFieldType.Pager:
      if (isKiosk()) {
        return <CustomFieldNumpadContent customField={customField} />;
      } else {
        return <CustomFieldTextContent customField={customField} />;
      }
    default:
      return <div>No implementation available</div>;
  }
}

export default memo(_CustomFieldContent);
