import { useMemo, useRef, useState } from "react";
import { rewardCanceled, rewardClaimed } from "../../../global/utils/redux/MWiseSlice";
import Box from "@mui/material/Box";
import { Button, Collapse, Typography } from "@mui/material";
import Image from "../../../global/components/Image";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import { MWiseAPIReward } from "../../../types/mwise";
import { useIntl } from "react-intl";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import CloseDialogButtonQr from "../CloseButtons/CloseDialogButtonQr";
import { debounce } from "lodash";
import JTooltip from "../../../kiosk/components/JamezzMUI/JTooltip";

export default function ClaimRewardDialog({ reward }: { reward: MWiseAPIReward }) {
  const { closeDialog } = useDialog();
  const dispatch = useAppDispatch();
  const claimedReward = useAppSelector((state) => state.mwise.claimedReward);
  const userHasSelectedAReward = useAppSelector((state) => state.mwise.claimedReward !== null);
  const intl = useIntl();
  const [background, setBackground] = useState<string | undefined>(undefined);
  const mwiseDialog = useRef<any>(null);
  const onScroll = useMemo(
    () =>
      debounce(
        () => {
          if (mwiseDialog.current.scrollTop > 10) {
            setBackground("background.paper");
          } else {
            setBackground(undefined);
          }
        },
        100,
        { leading: true }
      ),
    []
  );
  return (
    <Box
      data-cy="MWise-reward-dialog"
      ref={mwiseDialog}
      sx={{
        background: `url(${reward.BackgroundImageURL})`,
        width: 1,
        maxWidth: 1080,
        flexGrow: 1,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowY: "auto",
        alignSelf: "center",
        paddingBottom: 4,
      }}
      onScroll={onScroll}
    >
      <Box
        className={"JS-OrderArticleSelectorPageContent-HeaderWrapper"}
        sx={{
          position: "sticky",
          top: 0,
          width: 1,
          display: "flex",
          alignItems: "center",
          backgroundColor: background,
          zIndex: 1,
          paddingY: 1,
        }}
      >
        <CloseDialogButtonQr />
        <Typography
          variant={"h1"}
          sx={{ fontWeight: 800, fontSize: "1.1rem" }}
          className={"JS-MWiseReward-Dialog-Name"}
        >
          {reward.Title}
        </Typography>
      </Box>
      <Box
        sx={{
          flex: "1 1 0%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: 1,
          paddingX: 2,
        }}
      >
        <Image
          className="JS-MWiseReward-Dialog-Image"
          src={reward.ImageUrl ?? ""}
          style={{
            maxHeight: 300,
            width: "100%",
            objectFit: "contain",
            marginTop: "16px",
            marginBottom: "16px",
          }}
        />
        <Box>
          <Typography className="JS-MWiseReward-Dialog-Description" variant="body1" sx={{ fontSize: "14px" }}>
            {reward.Description}
          </Typography>
          {reward.Conditions?.length > 0 ? (
            <>
              <Typography
                className="JS-MWiseReward-Dialog-ConditionsHeader"
                variant="h3"
                sx={{ fontSize: "26px", marginY: 1 }}
              >
                <FormattedMessageJamezz id="MWise.dialog.heading.conditions" />
              </Typography>
              <Typography className="JS-MWiseReward-Dialog-ConditionsBody" variant="body2" sx={{ fontSize: "12px" }}>
                {reward.Conditions}
              </Typography>
            </>
          ) : null}
        </Box>
      </Box>
      <Collapse in={userHasSelectedAReward && claimedReward?.oracleDiscountId == reward.OracleProduct.discountId}>
        <Button
          sx={{ marginTop: 2 }}
          data-cy="MWise-cancel-reward-btn"
          className="JS-MWiseReward-Dialog-CancelButton"
          variant="contained"
          onClick={() => {
            dispatch(rewardCanceled());
          }}
        >
          <FormattedMessageJamezz id="Cancel" />
        </Button>
      </Collapse>
      <JTooltip
        title={intl.formatMessage({ id: "MWise.messages.alreadyClaimedReward" })}
        arrow
        open={userHasSelectedAReward}
        onClose={() => {}}
        onOpen={() => {}}
      >
        <Button
          sx={{ marginTop: 2 }}
          data-cy="MWise-claim-reward-btn"
          className="JS-MWiseReward-Dialog-ClaimButton"
          variant="contained"
          disabled={userHasSelectedAReward}
          onClick={() => {
            dispatch(
              rewardClaimed({ oracleDiscountId: reward.OracleProduct.discountId, mwiseCode: reward.ShortTitle })
            );
            closeDialog();
          }}
        >
          <FormattedMessageJamezz id="MWise.dialog.callToAction.claim" />
        </Button>
      </JTooltip>
    </Box>
  );
}
