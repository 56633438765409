import { useCallback } from "react";
import Article from "../../models/menu/Article";
import { useAppSelector } from "../../redux/store";
import { ArticleTraitType } from "../../models/ArticleTraits/ArticleTrait";
import { selectArticleTraitsMap } from "../../redux/selectors/selectArticleTraitsMap";

export function useArticleTraitFiltering() {
  const { articleTraitsMap } = useAppSelector(selectArticleTraitsMap);
  const stateOfArticleTraits = useAppSelector((state) => state.traitFilter.stateOfArticleTraits);

  const articleTraitFilter = useCallback(
    (article: Article) => {
      if (stateOfArticleTraits) {
        for (const traitTitle in stateOfArticleTraits) {
          const values = stateOfArticleTraits[traitTitle];
          const articleTrait = articleTraitsMap[traitTitle];

          if (values) {
            if (values.length > 0) {
              if (articleTrait.type === ArticleTraitType.MULTIPLE_VALUES) {
                const articleTrait = article.articleTraits?.[traitTitle];
                if (!articleTrait) {
                  return false;
                }

                const foundAtLeastOne = values.some((value: string) => {
                  return articleTrait.values.includes(value);
                });

                if (!foundAtLeastOne) {
                  return false;
                }
              } else if (articleTrait.type === ArticleTraitType.SINGLE_VALUE) {
                const articleTrait = article.articleTraits?.[traitTitle];
                if (!articleTrait) {
                  return false;
                }

                const foundAtLeastOne = values.some((value: string) => {
                  return articleTrait.values.includes(value);
                });

                if (!foundAtLeastOne) {
                  return false;
                }
              } else if (articleTrait.type === ArticleTraitType.NUMBER_RANGE) {
                // @ts-ignore
                if (articleTrait.articleField && article[articleTrait.articleField] != null) {
                  if (
                    // @ts-ignore
                    article[articleTrait.articleField] < articleTrait.values[0] ||
                    // @ts-ignore
                    article[articleTrait.articleField] > articleTrait.values[1]
                  ) {
                    return false;
                  }
                }
              }
            }
          }
        }
      }

      return true;
    },
    [articleTraitsMap, stateOfArticleTraits]
  );

  return { articleTraitFilter };
}
