import { usePiggyUser } from "../../../../utils/piggy/piggy.tsx";
import { useAppSelector } from "../../../../../global/utils/redux/store.tsx";

export type LoyaltyTier = { color?: string; name: string; description: string } | null;

export default function useLoyaltyType(): LoyaltyTier | null {
  const { piggyUser } = usePiggyUser();
  const tiers = useAppSelector((state) => state.global.salesarea.custom_data?.piggy?.tiers);

  if (tiers && piggyUser?.data?.contact?.tierV2) {
    return {
      name: piggyUser?.data?.contact?.tierV2.name,
      description: piggyUser?.data?.contact?.tierV2.description,
      color: tiers[piggyUser?.data?.contact?.tierV2.uuid]?.color,
    };
  }
  return null;
}
