import { useAppSelector } from "../../utils/redux/store.tsx";
import { useEffect, useRef } from "react";
import { TerminalState } from "./SixPaymentsMain.tsx";

export default function useStartTransaction(
  connectionStatus: TerminalState | undefined,
  ecrPayment: any,
  writeLog: any
) {
  const order = useAppSelector<any>((state) => state.global.order);
  const valuta = useAppSelector((state) => state.global.salesarea?.valuta);
  const orderId = useRef<number | undefined>();

  useEffect(() => {
    if (
      order &&
      ecrPayment &&
      orderId.current !== order.oid &&
      connectionStatus === TerminalState.loggedIn &&
      order.payStatus !== 2
    ) {
      orderId.current = order.id;

      // @ts-ignore
      let setAmount = new timapi.Amount(order.paymentData.amount * 100, eval("timapi.constants.Currency." + valuta));
      // @ts-ignore
      ecrPayment.terminal.transactionAsync(timapi.constants.TransactionType.purchase, setAmount);
      if (writeLog) {
        writeLog({ type: "payment", data: "Starting Payment", result: "Start Payment", status: 1 });
      }
    }
  }, [ecrPayment, order, orderId, connectionStatus, valuta, writeLog]);
}
