import { forwardRef } from "react";
import Slide from "@mui/material/Slide";
import { SlideProps } from "@mui/material";

const SlideUpTransition = forwardRef<unknown, SlideProps>((props, ref) => {
  // const [isTouching, setIsTouching] = useState(false);
  return (
    <Slide
      direction={"up"}
      ref={ref}
      {...props}
      // onTouchStart={(e) => {
      //   console.log(e);
      //   setIsTouching(true);
      // }}
      // onTouchEnd={(e) => setIsTouching(false)}
      // in={isTouching || props.in}
    />
  );
});
export default SlideUpTransition;
