import CircularProgress from "@mui/material/CircularProgress";
import { ReactElement } from "react";
import { ComponentOverlay } from "./ComponentOverlay";
import { BoxProps } from "@mui/material";

export function LoadingOverlay({
  children,
  isLoading,
  ...props
}: { children: ReactElement; isLoading: boolean } & BoxProps) {
  return (
    <ComponentOverlay
      disabled={!isLoading}
      backdropColor="rgba(0, 0, 0, 0.4)"
      overlayContent={<CircularProgress color={"primary"} thickness={6} size={50} />}
      {...props}
    >
      {children}
    </ComponentOverlay>
  );
}
