import { ReactNode } from "react";
import Box from "@mui/material/Box";
import CloseDialogButtonQr from "../CloseButtons/CloseDialogButtonQr.tsx";
import { Typography } from "@mui/material";

export default function DialogHeader({
  canClose,
  children,
  jsClass,
}: {
  canClose: boolean;
  children: ReactNode;
  jsClass: string;
}) {
  return (
    <Box
      className={jsClass}
      sx={{
        position: "sticky",
        top: 0,
        width: 1,
        display: "flex",
        alignItems: "center",
        backgroundColor: "background.paper",
        zIndex: 1,
        paddingY: 1,
        paddingX: 2,
      }}
    >
      {canClose ? <CloseDialogButtonQr /> : null}
      <Typography
        variant="body1"
        sx={{
          fontWeight: 800,
          marginLeft: "20px",
          textAlign: "center",
          width: 1,
          marginRight: canClose ? 5.5 : undefined,
        }}
        className="JS-ArticleDialog-Name"
      >
        {children}
      </Typography>
    </Box>
  );
}
