import { PropsScrollShadows, ScrollShadows } from "@jamezz/react-components";

export default function ScrollShadowsKiosk(props: PropsScrollShadows) {
  const { styleBottomShadow, styleTopShadow, ...otherProps } = props;
  const extraStyle =
    props.direction == "row"
      ? { minWidth: 10, width: 10, marginLeft: "-10px" }
      : { minHeight: 10, height: 10, marginTop: "-10px" };
  return (
    <ScrollShadows
      styleBottomShadow={{ ...extraStyle, ...styleBottomShadow }}
      styleTopShadow={{ ...extraStyle, ...styleTopShadow }}
      {...otherProps}
    >
      {props.children}
    </ScrollShadows>
  );
}
