import { Component, PropsWithChildren } from "react";
import { CircularProgress, Typography } from "@mui/material";
import store from "../utils/redux/store";
import { refreshApp } from "../utils/redux/globalSlice";
import _ from "lodash";
import Box from "@mui/material/Box";

interface Props {}

interface MyState {
  hasError: boolean;
  error: any;
}
export default class ErrorBoundary extends Component<PropsWithChildren<Props>, MyState> {
  state: MyState = { hasError: false, error: null };

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  refreshPage() {
    refreshPageDebounced();
  }

  render() {
    if (this.state.hasError) {
      this.refreshPage();
      return (
        <Box>
          {/*<Typography sx={{ backgroundColor: "red", color: "white" }}>Please refresh app</Typography>*/}
          <CircularProgress />
        </Box>
      );
    }

    if (this.state.hasError && import.meta.env.MODE == "local") {
      console.table(this.state.error);
      // You can render any custom fallback UI
      return (
        <div style={{ position: "fixed", zIndex: 100000 }}>
          <h3>Something went wrong.</h3>
          <Typography>
            {this.state.error?.message}
            <br />

            {this.state.error?.stack}
          </Typography>
        </div>
      );
    }

    return this.props.children;
  }
}

const refreshPageDebounced = _.debounce(() => {
  setTimeout(() => {
    store.dispatch(refreshApp());
  }, 5000);
}, 5000);
