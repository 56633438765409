import { useAppSelector } from "../utils/redux/store";
import { ReactNode, useMemo } from "react";
import { LiveHelp } from "@mui/icons-material";
import { getArticleName } from "../utils/models/menu/Article";
import { selectServiceRequestArticles } from "../utils/redux/selectors/selectServiceRequestArticles";
import { selectArticlesMap } from "../utils/redux/selectors/selectArticlesMap";
import { selectAppLanguage } from "../../kiosk/components/LanguageSelector/useLanguage.ts";

export default function useServiceArticles() {
  const serviceRequestArticles = useAppSelector(selectServiceRequestArticles);
  const articlesMap = useAppSelector(selectArticlesMap);
  const lang = useAppSelector(selectAppLanguage);

  return useMemo(() => {
    const serviceArticles: { icon: ReactNode; name: string; articleId: string; sortKey: number }[] = [];

    Object.keys(serviceRequestArticles).forEach((articleId) => {
      const article = articlesMap[articleId];
      if (article) {
        serviceArticles.push({
          icon: <LiveHelp />,
          name: getArticleName(article, lang),
          articleId: articleId,
          sortKey: article.sortKey,
        });
      }
    });

    return serviceArticles.sort((a, b) => {
      return b.sortKey - a.sortKey;
    });
  }, [serviceRequestArticles, articlesMap, lang]);
}
