import OptionItemCountable from "../OptionItems/OptionItemCountable";
import OrderOptionGroup from "../../../../global/utils/models/order/OrderOptionGroup";
import Box from "@mui/material/Box";
import _ from "lodash";

interface OptionGroupCountableProps {
  orderOptionGroup: OrderOptionGroup;
  onChange: (orderOptionGroup: OrderOptionGroup) => void;
}

function OptionGroupCountable({ onChange, orderOptionGroup }: OptionGroupCountableProps) {
  const numberOfItemsPerRowOfOrderArticles = 4;

  return (
    <>
      {_.chunk(orderOptionGroup.orderArticles, numberOfItemsPerRowOfOrderArticles).map((orderArticles, rowIndex) => (
        <Box
          key={orderArticles.map((o) => o.article.id).join(",")}
          sx={optionGroupGridStyles(orderArticles.length, 5, numberOfItemsPerRowOfOrderArticles)}
        >
          {orderArticles.map((orderArticle, index) => {
            return (
              <OptionItemCountable
                orderArticle={orderArticle}
                orderOptionGroup={orderOptionGroup}
                key={orderArticle.article.id}
                columnIndex={index % orderArticles.length}
                onChange={(orderArticle) => {
                  const copy = JSON.parse(JSON.stringify(orderOptionGroup)) as OrderOptionGroup;
                  copy.orderArticles[rowIndex * numberOfItemsPerRowOfOrderArticles + index] = orderArticle;
                  onChange(copy);
                }}
              />
            );
          })}
        </Box>
      ))}
    </>
  );
}

export function optionGroupGridStyles(numberOfColumns: number, numberOfRows: number, maxNumberOfColumns: number) {
  const widthPercentage = Math.floor((1 / maxNumberOfColumns) * 100);
  return {
    display: "grid",
    gridTemplateColumns: `repeat(${numberOfColumns}, calc(${widthPercentage}% - 20px))`,
    gridTemplateRows: `repeat(${numberOfRows}, auto) 15px`,
    columnGap: "26px",
    minHeight: 160,
    paddingX: 2.5,
    marginTop: "26px",
    width: 1,
    justifyContent: "center",
    boxSizing: "border-box",
  };
}

export function optionItemMenuTagsStyles() {
  return { display: "flex", marginY: 2, width: 1, flexWrap: "wrap", justifyContent: "center", zIndex: 1 };
}

export default OptionGroupCountable;
