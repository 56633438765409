import store, { RootState, useAppSelector } from "../redux/store";
import { useCallback, useEffect, useRef } from "react";
import { ShoppingCartConfirmation } from "../redux/api/shoppingCartApi";
import { selectRequireAgeArticle } from "./selectRequireAgeArticle";
import { waitingForRequestDialogIsOpened } from "../redux/shoppingCartSlice";
import useAlcoholConfirmationEnabled from "./useAlcoholConfirmationEnabled";

export default function useShoppingCartConfirmationCheck() {
  // const { openDialog } = useDialog();
  const alcoholConfirmation = useAlcoholConfirmationEnabled();

  const data = useAppSelector((state: RootState) => state.shoppingCart.shoppingCartConfirmation.response);
  const resolveRef = useRef<null | ((data: ShoppingCartConfirmation) => void)>(null);
  useEffect(() => {
    if (data && data.response === "APPROVED" && resolveRef.current) {
      resolveRef.current(data);
    }
  }, [data]);
  return useCallback(() => {
    return new Promise<ShoppingCartConfirmation | true>((resolve) => {
      if (alcoholConfirmation) {
        const article = selectRequireAgeArticle(store.getState());
        if (article) {
          if (store.getState().shoppingCart.shoppingCartConfirmation.response?.response === "APPROVED") {
            resolve(true);
          } else {
            store.dispatch(waitingForRequestDialogIsOpened());
            resolveRef.current = resolve;
          }
          // openDialog({
          //   children: (
          //     <WaitingForOrderConfirmationDialog
          //       onClose={(data) => {
          //         resolve(data);
          //
          //         if (data.response === "DENIED") {
          //           console.log("Denied WaitingForOrderConfirmationDialog");
          //           openDialog({ children: <ShoppingCartConfirmationDenied />, disabledBackdropClick: true });
          //         }
          //       }}
          //     />
          //   ),
          //   disabledBackdropClick: true,
          // });

          return;
        }
      }
      resolve(true);
    });
  }, [alcoholConfirmation]);
}
