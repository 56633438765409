import { useMemo } from "react";
import { Box, Collapse, MenuItem, Select, Stack, Typography } from "@mui/material";
import Articlegroup, {
  getArticlegroupDescription,
  getArticlegroupName,
} from "../../../global/utils/models/menu/Articlegroup";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import KioskTopBar from "../../components/KioskTopBar/KioskTopBar.tsx";
import { useAppSelector } from "../../../global/utils/redux/store";
import {
  selectAppLanguage,
  selectAvailableLanguages,
  setLanguage,
} from "../../components/LanguageSelector/useLanguage";
import clsx from "clsx";
import { Language } from "../../../types/shared/language";
import { languageTranslations } from "../../../global/utils/languages";
import { languageToCountry } from "../../components/LanguageSelector/LanguageSelector";
import { Translate } from "@mui/icons-material";

interface Props {
  articlegroup: Articlegroup;
  addKioskTopBar?: boolean;
}
export default function ArticlegroupBanner(props: Props) {
  const lang = useAppSelector(selectAppLanguage);

  const description = useMemo(() => {
    return getArticlegroupDescription(props.articlegroup, lang);
  }, [props.articlegroup, lang]);

  const searchValue = useAppSelector((state) => state.global.articleSearchText);

  const languagePresentation = useAppSelector(
    (state) => state.global.salesarea.custom_data?.kiosk?.how_to_present_languages
  );

  return (
    <Box className="JS-ArticlegroupContent-Banner-Wrapper">
      {props.addKioskTopBar ? <KioskTopBar disableHomeButton={true} /> : null}
      <Collapse in={!searchValue} className="JS-ArticlegroupContent-Text-Collapse">
        <Box
          sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
          className="JS-ArticlegroupContent-Wrapper"
        >
          <Typography
            className={"JS-ArticlegroupContent-Name"}
            sx={{ fontWeight: 800, fontSize: 48, marginLeft: 1, color: "primary.main" }}
          >
            {getArticlegroupName(props.articlegroup, lang)}
          </Typography>

          {props.articlegroup.disableOrdering ? (
            <Typography fontSize={24} sx={{ ml: 1, paddingTop: 1, color: "primary.main" }}>
              <FormattedMessageJamezz id="Articlegroup.messages.not-available" />
            </Typography>
          ) : null}
          {languagePresentation === "text" && props.addKioskTopBar ? <LanguageSelect /> : null}
        </Box>
        {description ? (
          <Typography
            sx={{ fontStyle: "italic", opacity: 0.6, textAlign: "center" }}
            className={"JS-ArticlegroupContent-Description"}
          >
            {description}
          </Typography>
        ) : null}
      </Collapse>
    </Box>
  );
}

function LanguageSelect() {
  const languages = useAppSelector(selectAvailableLanguages);
  const selectedLanguage = useAppSelector(selectAppLanguage);

  return languages.length > 1 ? (
    <Stack direction={"row"} gap={3} alignItems={"center"}>
      <Translate />
      <Select
        className={clsx("JS-LanguageSelect-Select")}
        value={selectedLanguage}
        size={"small"}
        onChange={(e) => {
          e.stopPropagation();
          setLanguage(e.target.value as Language);
        }}
        sx={{
          my: 0,
          minWidth: 120,
          marginRight: 2,
          pr: 3,
        }}
      >
        {languages.map((language) => {
          const obj = languageToCountry(language);
          return (
            <MenuItem key={language} data-language-code={language} data-country-code={obj.code} value={language}>
              {languageTranslations[language]}
            </MenuItem>
          );
        })}
      </Select>
    </Stack>
  ) : null;
}
