import { useEffect, useState } from "react";

export enum LoadingFileState {
  idle = "idle",
  loading = "loading",
  ready = "ready",
  error = "error",
}

export default function useLoadExternalFile(url: string) {
  let [state, setState] = useState<LoadingFileState>(url ? LoadingFileState.loading : LoadingFileState.idle);

  useEffect(() => {
    if (!url) {
      setState(LoadingFileState.idle);
      return;
    }

    let script: any = document.querySelector(`script[src="${url}"]`);

    const handleScript = (e: any) => {
      setState(e.type === "load" ? LoadingFileState.ready : LoadingFileState.error);
    };

    if (!script) {
      script = document.createElement("script");
      script.type = "application/javascript";
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
      script.addEventListener("load", handleScript);
      script.addEventListener("error", handleScript);
    }

    script.addEventListener("load", handleScript);
    script.addEventListener("error", handleScript);

    return () => {
      script.removeEventListener("load", handleScript);
      script.removeEventListener("error", handleScript);
    };
  }, [url]);

  return state;
}
