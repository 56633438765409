import Box from "@mui/material/Box";
import OrderOptionGroup from "../../../../global/utils/models/order/OrderOptionGroup";
import useOptionGroup, { Type } from "../../../../global/utils/orderSelector/useOptionGroup";
import { Divider, Typography } from "@mui/material";
import OptionGroupRadio from "./OptionGroupRadio";
import OptionGroupOptional from "./OptionGroupOptional";
import OptionGroupCountable from "./OptionGroupCountable";
import clsx from "clsx";
import {
  OptionTranslationType,
  useOptionTranslations,
} from "../../../../global/utils/translations/useOptionTranslations";
import { OptionGroupHeaderText } from "../../../../qr/pages/OrderArticleSelectorPage/OptionGroups/OptionGroupContent";
import OrderArticle from "../../../../global/utils/models/order/OrderArticle.ts";
import { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import { useMemo, useRef } from "react";
import ScrollShadowsKiosk from "../../../components/ScrollShadowKiosk.tsx";

export default function OptionGroupContent({
  orderArticle,
  onChange,
  orderOptionGroup,
  index,
}: {
  orderArticle: OrderArticle;
  orderOptionGroup: OrderOptionGroup;
  onChange: (orderOptionGroup: OrderOptionGroup) => void;
  index: number;
}) {
  const optionGroup = orderOptionGroup.optionGroup;

  const { type } = useOptionGroup(orderOptionGroup, orderArticle.article);
  const containerRef = useRef<HTMLDivElement>(null);
  const { translate } = useOptionTranslations();

  const orderArticleSelectorPageStyle =
    useAppSelector((state) => state.global.salesarea?.custom_data?.kiosk?.order_article_page_selector_style) ??
    "swiper";

  const boxRootSx = useMemo(() => {
    if (orderArticleSelectorPageStyle == "swiper") {
      return { width: 1, height: 1, overflow: "hidden" };
    } else if (orderArticleSelectorPageStyle == "vertical_scrolling") {
      return { width: 1, mb: 40 };
    }
  }, [orderArticleSelectorPageStyle]);

  const boxContentSx = useMemo(() => {
    if (orderArticleSelectorPageStyle == "swiper") {
      return { width: 1, height: 1, overflowY: "auto" };
    } else if (orderArticleSelectorPageStyle == "vertical_scrolling") {
      return { width: 1 };
    }
  }, [orderArticleSelectorPageStyle]);

  if (orderOptionGroup.orderArticles.length === 0) {
    return null;
  }
  return (
    <Box className={"JS-OptionGroupContent-Root"} sx={boxRootSx} id={"OrderOptionGroup:" + index}>
      <Box sx={boxContentSx} className={clsx("ScrollBar-wide", "JamezzScroll")} ref={containerRef}>
        <ScrollShadowsKiosk containerRef={containerRef}>
          <Divider className={"JS-OptionGroupContent-Divider"} />
          <Box sx={{ display: "inline-flex" }}>
            <Typography className={"JS-OptionGroupContent-Description"} sx={{ paddingX: 4 }} fontSize={"1.4em"}>
              {translate(optionGroup.name, OptionTranslationType.OptionGroup)}
            </Typography>
          </Box>
          <OptionGroupHeaderText
            className={"JS-OptionGroupContent-Explanation"}
            optionGroup={optionGroup}
            fontSize={"0.95em"}
            article={orderArticle.article}
          />
          <Box sx={{ display: "flex", flexWrap: "wrap", width: 1, mb: 2, justifyContent: "center" }}>
            {type === Type.Radio ? (
              <OptionGroupRadio
                orderOptionGroup={orderOptionGroup}
                onChange={(orderOptionGroup) => onChange(orderOptionGroup)}
              />
            ) : null}
            {type === Type.Optional ? (
              <OptionGroupOptional
                orderOptionGroup={orderOptionGroup}
                onChange={(orderOptionGroup) => onChange(orderOptionGroup)}
              />
            ) : null}
            {type === Type.Countable ? (
              <OptionGroupCountable
                orderOptionGroup={orderOptionGroup}
                onChange={(orderOptionGroup) => onChange(orderOptionGroup)}
              />
            ) : null}
          </Box>
        </ScrollShadowsKiosk>
      </Box>
    </Box>
  );
}
