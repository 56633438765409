import { Box, ButtonBase, useTheme } from "@mui/material";
import { Language } from "../../../types/shared/language.ts";
import OrderMode from "../../utils/models/OrderMode.ts";
import { languageToCountry } from "./LanguageSelector.tsx";
import clsx from "clsx";
import { setLanguage } from "./useLanguage.ts";

export default function FlagLanguageButton({
  language,
  orderModes,
  onClickOrderMode,
  selectedLanguage,
}: {
  language: Language;
  orderModes: OrderMode[];
  onClickOrderMode: (orderMode?: OrderMode | undefined) => void;
  selectedLanguage: Language;
}) {
  const padding = 11;
  const borderWidth = 5;
  const size = 164;
  const theme = useTheme();
  const obj = languageToCountry(language);
  return (
    <ButtonBase
      data-language-code={language}
      data-country-code={obj.code}
      className={clsx(
        "JS-LanguageSelector-ButtonBase",
        selectedLanguage === language && "JS-LanguageSelector-Selected"
      )}
      key={language}
      onClick={(e) => {
        e.stopPropagation();
        setLanguage(language as Language);
        if (!orderModes || orderModes.length <= 1) {
          onClickOrderMode(orderModes?.[0]);
        }
      }}
      sx={{
        height: size,
        width: size,
      }}
    >
      <Box
        sx={{
          padding: selectedLanguage === language ? padding + "px" : borderWidth + padding + "px",
          border: selectedLanguage === language ? borderWidth + "px solid " + theme.palette.primary.main : null,

          height: size,
          width: size,
          borderRadius: "50%",
        }}
      >
        <Box
          sx={{
            background: obj.background,
            width: 1,
            height: 1,
            borderRadius: "50%",
            overflow: "hidden",
            boxShadow: "0 1px 10px 0px rgba(0,0,0,0.6)",
          }}
        >
          <span
            style={{
              width: "100%",
              height: "100%",
              backgroundSize: "cover",

              borderRadius: "50%",
              overflow: "hidden",
              ...(obj.style ?? {}),
            }}
            className={("JS-LanguageFlag-" + language + " fi fi-" + obj.code).toLowerCase()}
          ></span>
        </Box>
      </Box>
    </ButtonBase>
  );
}
