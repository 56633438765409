import useCurrency, { CurrencyLocation } from "../../../global/utils/useCurrency.tsx";
import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { selectSumOfDiscountsPerCampaign } from "../../../global/utils/redux/selectors/discountsPerCampaign.ts";
import { useAccountTotalValue } from "../../../global/utils/redux/accountSlice.tsx";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";

export function InfoTotal() {
  const toCurrency = useCurrency({ location: CurrencyLocation.Checkout });
  const totalDiscountAmountOfCampaigns = useAppSelector(selectSumOfDiscountsPerCampaign);
  const totalAmount = useAccountTotalValue();

  return (
    <Box>
      {totalDiscountAmountOfCampaigns > 0 ? (
        <Box sx={{ display: "inline-flex", alignItems: "baseline" }}>
          <Typography>
            <FormattedMessageJamezz id={"Discount: "} />
          </Typography>
          <Typography
            className={"JS-OrderPage-DiscountAmount"}
            align={"right"}
            sx={{ fontWeight: 800, fontSize: 48, flex: 1, marginLeft: 2 }}
          >
            {toCurrency(totalDiscountAmountOfCampaigns)}
          </Typography>
        </Box>
      ) : null}
      <Typography
        className={"JS-OrderPage-TotalAmount"}
        align={"right"}
        sx={{ fontWeight: 800, fontSize: 48, flex: 1 }}
      >
        {toCurrency(totalAmount)}
      </Typography>
    </Box>
  );
}
