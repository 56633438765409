import { AyceRuleResult } from "../../utils/arrangements/useCheckAyceRules.ts";
import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import {
  selectArrangementMaxRounds,
  selectArrangementMaxTime,
} from "../../../global/utils/redux/arrangements/arrangementRuleSelectors.ts";
import { Box, Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";

export function ArrangementOverText({
  rule,
}: {
  rule: AyceRuleResult.EATING_TIME_OVER | AyceRuleResult.ROUND_COUNTER_EXCEEDED;
}) {
  const maxTime = useAppSelector(selectArrangementMaxTime);
  const maxRounds = useAppSelector(selectArrangementMaxRounds);
  return (
    <Box sx={{ gridColumn: "span 2" }} data-cy="arrangement-ordering-over">
      <Typography fontWeight="bold">
        <FormattedMessageJamezz id="AYCE.ordering-over" />
      </Typography>
      <Typography>
        {rule === AyceRuleResult.EATING_TIME_OVER ? (
          <FormattedMessageJamezz
            id="AYCE.eating-time-over"
            values={{
              time: maxTime,
            }}
          />
        ) : rule === AyceRuleResult.ROUND_COUNTER_EXCEEDED ? (
          <FormattedMessageJamezz
            id="AYCE.round-counter-exceeded"
            values={{
              rounds: maxRounds,
            }}
          />
        ) : null}
      </Typography>
    </Box>
  );
}
