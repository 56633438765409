import store, { useAppSelector } from "../../../global/utils/redux/store";
import { useEffect } from "react";
import { Fade, IconButton, Typography } from "@mui/material";
import { setWheelchairFriendly } from "../../../global/utils/redux/kioskSlice";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import useSalesareaIsOpen from "../../../global/utils/useSalesareaIsOpen.ts";

export default function WheelchairFriendlyButton() {
  const showWheelchairFriendlyButton = useAppSelector(
    (state) => state.global.salesarea.custom_data?.kiosk?.show_wheelchair_friendly_button
  );

  const isOpen = useSalesareaIsOpen();

  const wheelchairFriendlyEnabled = useAppSelector((state) => state.kiosk.wheelchairFriendly);
  useEffect(() => {
    document.body.style.transition = "all 800ms";
    if (wheelchairFriendlyEnabled) {
      document.body.style.paddingTop = "800px";
    } else {
      document.body.style.paddingTop = "0";
    }
  }, [wheelchairFriendlyEnabled]);
  if (!showWheelchairFriendlyButton) {
    return null;
  }
  return (
    <>
      {isOpen ? (
        <>
          <Fade in={wheelchairFriendlyEnabled} unmountOnExit>
            <Typography
              onClick={() => {
                store.dispatch(setWheelchairFriendly(false));
              }}
              className={"JS-WheelchairFriendly-Text"}
              sx={{
                width: 0.8,
                borderRadius: 8,
                p: 2,
                position: "fixed",
                left: "50%",
                transform: "translate(-50%,0)",
                top: 16,
                fontSize: "48px",
                textAlign: "center",
                backgroundColor: "background.default",
              }}
            >
              <FormattedMessageJamezz id={"Wheelchair-friendly mode enabled, press here to disable!"} />
            </Typography>
          </Fade>

          <IconButton
            className={"JS-WheelchairFriendly-Button"}
            sx={{
              zIndex: 20,
              position: "fixed",
              aspectRatio: 1,
              bottom: 0,
              left: 0,
              height: 250,
              p: 0,
            }}
            onClick={(e) => {
              e.stopPropagation();
              store.dispatch(setWheelchairFriendly(!store.getState().kiosk.wheelchairFriendly));
            }}
          >
            <img
              src={"https://jamezz.blob.core.windows.net/jamezz/appv5/assets/images/WheelchairMode_small.webp"}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </IconButton>
        </>
      ) : null}
    </>
  );
}
