import {
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";
import React from "react";
import {
  selectAppLanguage,
  selectAvailableLanguages,
  setLanguage,
} from "../../../kiosk/components/LanguageSelector/useLanguage.ts";
import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { languageToCountry } from "../../../kiosk/components/LanguageSelector/LanguageSelector.tsx";
import { postAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics.ts";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MenuFilterDialogV2 from "../../pages/MenuTagFilterPage/MenuFilterDialogV2.tsx";
import isQr from "../../../global/utils/helpers/isQr.tsx";
import SlideUpTransition from "../../../global/components/SlideUpTransition.tsx";
import { useDialog } from "../../../global/utils/dialog/DialogProvider.tsx";
import useIsWidescreen from "../../../global/utils/useIsWidescreen.ts";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";
import MenuOpenRoundedIcon from "@mui/icons-material/MenuOpenRounded";
import { useIntl } from "react-intl";
import useAfterPayListItem from "./useAfterPayListItem.tsx";
import usePiggyLoyaltyListItem from "./usePiggyLoyaltyListItem.tsx";
import useServiceArticleListItem from "./useServiceArticleListItem.tsx";
import useAyceOrderedRoundsListItem from "./useAyceOrderedRoundsListItem.tsx";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { languageTranslations } from "../../../global/utils/languages.ts";
import useAddVoucherListItem from "./useAddVoucherListItem.tsx";

type SideMenuDrawerProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const SideMenuDrawer: React.FC<SideMenuDrawerProps> = ({ open, setOpen }) => {
  const [languageCollapse, setLanguageCollapse] = React.useState(false);
  const [serviceCollapse, setServiceCollapse] = React.useState(false);

  const languages = useAppSelector(selectAvailableLanguages);
  const selectedLanguage = useAppSelector(selectAppLanguage);
  const { openDialog } = useDialog();
  const isWidescreen = useIsWidescreen();
  const intl = useIntl();

  const piggyLoyaltyListItem = usePiggyLoyaltyListItem();
  const addVoucherListItem = useAddVoucherListItem();
  const afterPayListItem = useAfterPayListItem();
  const ayceOrderedRoundsListItem = useAyceOrderedRoundsListItem();
  const serviceArticleListItems = useServiceArticleListItem("JS-SideMenuDrawer-ListItem");

  const DrawerList = (
    <Box sx={{ width: isWidescreen ? 300 : "100%" }} role="presentation">
      <List>
        <ListItem className={"JS-SideMenuDrawer-Header"} disablePadding>
          <ListItemButton data-cy={"side-menu-drawer-close-button"} onClick={() => setOpen(false)}>
            <ListItemIcon
              className={"JS-SideMenuDrawer-Header-Content"}
              sx={{ padding: "10px", width: "100%", display: "flex", justifyContent: "space-between" }}
            >
              <FormattedMessageJamezz id={"Menu"} />
              <MenuOpenRoundedIcon />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <Divider />

        {/*{usePiggyVouchersListItem()}*/}
        {piggyLoyaltyListItem}
        {addVoucherListItem}
        {afterPayListItem}
        {ayceOrderedRoundsListItem}

        <ListItem className={"JS-SideMenuDrawer-ListItem"} key={"Filters"} disablePadding>
          <ListItemButton
            onClick={() => {
              postAnalyticsEvent({
                category: "TopBar",
                action: "MenuFilterDialog",
              });
              openDialog({
                children: <MenuFilterDialogV2 />,
                fullScreen: !(isQr() && isWidescreen),
                TransitionComponent: SlideUpTransition,
              });
            }}
          >
            <ListItemIcon className={"JS-SideDrawerMenu-ItemIcon"}>
              <FilterAltIcon />
            </ListItemIcon>
            <ListItemText primary={intl.formatMessage({ id: "Filters" })} />
          </ListItemButton>
        </ListItem>

        {serviceArticleListItems.length ? (
          <>
            <ListItem className={"JS-SideMenuDrawer-ListItem"} key={"ServiceItems"} disablePadding>
              <ListItemButton
                onClick={() => {
                  setServiceCollapse(!serviceCollapse);
                }}
              >
                <ListItemIcon className={"JS-SideDrawerMenu-ItemIcon"}>
                  <EmojiPeopleIcon />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: "Service request" })} />
              </ListItemButton>
            </ListItem>
          </>
        ) : null}
        <Collapse in={serviceCollapse}>{useServiceArticleListItem()}</Collapse>

        <ListItem className={"JS-SideMenuDrawer-ListItem"} key={"Language"} disablePadding>
          <ListItemButton
            data-cy={"language-button"}
            onClick={() => {
              setLanguageCollapse(!languageCollapse);
            }}
          >
            <ListItemIcon className={"JS-SideDrawerMenu-ItemIcon"}>
              {languageCollapse ? (
                <TranslateIcon />
              ) : (
                <span
                  style={{ width: 24 }}
                  className={
                    "JS-LanguageFlag-" +
                    selectedLanguage +
                    " fi fi-" +
                    languageToCountry(selectedLanguage).code.toLowerCase()
                  }
                ></span>
              )}
            </ListItemIcon>
            <ListItemText primary={intl.formatMessage({ id: "Language" })} />
          </ListItemButton>
        </ListItem>
        <Collapse in={languageCollapse}>
          <ListItem key={selectedLanguage} disablePadding></ListItem>
          {languages.map((language) => (
            <ListItem className={"JS-SideMenuDrawer-ListItem"} key={language} disablePadding>
              <ListItemButton
                data-cy={"language-item-" + language}
                sx={{ pl: 5 }}
                onClick={() => {
                  postAnalyticsEvent({
                    category: "setSelectedLanguage",
                    action: language,
                  });
                  setLanguage(language);
                  setLanguageCollapse(false);
                }}
              >
                <ListItemText>{languageTranslations[language] ?? language}</ListItemText>
                {selectedLanguage === language ? (
                  <ListItemIcon>
                    <CheckRoundedIcon />
                  </ListItemIcon>
                ) : null}
              </ListItemButton>
            </ListItem>
          ))}
        </Collapse>
      </List>
    </Box>
  );

  return (
    <SwipeableDrawer
      classes={{ paper: "JS-SideMenuDrawer-Root" }}
      onOpen={() => {}}
      PaperProps={{ square: false }}
      anchor={"left"}
      open={open}
      disableSwipeToOpen={true}
      onClose={() => setOpen(false)}
    >
      {DrawerList}
    </SwipeableDrawer>
  );
};

export default SideMenuDrawer;
