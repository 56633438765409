import { useAppSelector } from "../../global/utils/redux/store";
import { useMemo } from "react";

export default function usePriceKeysWithOnlyCodes() {
  const account_to_pricekey = useAppSelector((state) => state.global.salesarea?.account_to_pricekey);

  return useMemo(() => {
    let pricekeys: any = [];
    if (account_to_pricekey && typeof account_to_pricekey === "string") {
      pricekeys = JSON.parse(account_to_pricekey);
    }
    if (pricekeys?.[0]?.code) {
      return pricekeys;
    } else {
      return null;
    }
  }, [account_to_pricekey]);
}
