import IconButton from "@mui/material/IconButton";
import Bouncer from "./Bouncer.tsx";
import { ArrowBackIos } from "@mui/icons-material";
// @ts-ignore
import scrollIntoView from "scroll-into-view";
import { MutableRefObject } from "react";

export function ScrollToTargetButton({ target }: { target: MutableRefObject<HTMLElement | null> }) {
  return (
    <IconButton
      style={{
        zIndex: 100,
        transform: "rotateZ(-90deg)",
        position: "sticky",
        bottom: 0,
        right: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
      }}
      aria-label="close"
      onClick={() => {
        scrollIntoView(
          target.current,
          {
            time: 1000,
            align: {
              top: 0,
              topOffset: 0, // margin
            },
            validTarget: () => true,
          },
          () => {}
        );
      }}
    >
      <Bouncer>
        <ArrowBackIos />
      </Bouncer>
    </IconButton>
  );
}
