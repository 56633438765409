import SlideUpTransition from "../../../global/components/SlideUpTransition";
import { useCallback } from "react";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import PayAfterOrderDialog from "../../components/PayAfterOrderDialog";
import useIsWidescreen from "../../../global/utils/useIsWidescreen";
import isQr from "../../../global/utils/helpers/isQr";

export default function useOpenPayAfterOrderDialog() {
  const { openDialog } = useDialog();
  const isWidescreen = useIsWidescreen();
  return useCallback(() => {
    openDialog({
      children: <PayAfterOrderDialog />,
      fullScreen: !(isQr() && isWidescreen),
      TransitionComponent: SlideUpTransition,
    });
  }, [openDialog, isWidescreen]);
}

// function PayAfterOrderDialog() {
//   const [amount, setAmount] = useState(null);
//   useEffect( () => {
//     api call
//   },[])
//
//   return <AreYouSureDialog
//       message={
//         <>
//           <h1>Welcome terug!</h1>
//           <p>
//             Er staat nu <b>€ 33,10</b> open op deze tafel, wil je deze betalen of wil je verdergaan met bestellen?
//           </p>
//         </>
//       }
//       onOk={() => {
//         openDialog({
//           children: <CheckoutPage />,
//           fullScreen: true,
//           TransitionComponent: SlideUpTransition,
//         });
//       }}
//   />
// }
