import { useCallback } from "react";
import Box from "@mui/material/Box";
import { Typography, useTheme } from "@mui/material";
import useCurrency, { CurrencyLocation } from "../../../../global/utils/useCurrency";
import OrderPage from "../../OrderPage/OrderPage";
import SlideUpTransition from "../../../../global/components/SlideUpTransition";
import { useDialog } from "../../../../global/utils/dialog/DialogProvider";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz";
import ShoppingCartButtonCounter from "./ShoppingCartButtonCounter";
import { keyframes } from "@emotion/react";

import Image from "../../../../global/components/Image";
import { useAppSelector } from "../../../../global/utils/redux/store";
import { useAccountSubTotalValue } from "../../../../global/utils/redux/accountSlice";
import { selectArticlesSum } from "../../../../global/utils/redux/shoppingCartSlice";
import BackgroundMediaUrlFromSalesarea from "../../../hooks/useBackgroundMediaUrl.tsx";

interface Props {
  pixelHeightCartButton?: string;
}

export default function SmallShoppingCartButton({ pixelHeightCartButton = "200px" }: Props) {
  const toCurrency = useCurrency({ location: CurrencyLocation.Menu });
  const subTotalAmount = useAccountSubTotalValue();
  const count = useAppSelector(selectArticlesSum);

  const { openDialog } = useDialog();

  const onClickViewShoppingCart = useCallback(() => {
    openDialog({
      children: <OrderPage />,
      fullScreen: true,
      TransitionComponent: SlideUpTransition,
    });
  }, [openDialog]);

  const logo = useAppSelector(
    (state) => state.global.salesarea?.media_urls?.extra_settings__kioskv5_logo_of_shopping_cart_button?.[0]
  );

  return (
    <Box
      sx={{
        gridArea: "b",
        marginX: "auto",
        display: "flex",
        alignItems: "flex-end",
        width: 250,
      }}
    >
      <Box
        className={"JS-SmallShoppingCartButton-Root"}
        sx={{
          width: 250,
          height: pixelHeightCartButton,
          display: "flex",
          justifyContent: "center",
          position: "sticky",
          bottom: 0,
          marginX: "auto",
          alignItems: "center",
        }}
        onClick={(e) => {
          e.stopPropagation();
          onClickViewShoppingCart();
        }}
      >
        <Bouncer
          scale={count > 0 ? 1.2 : 1}
          delay={"0s"}
          time={"5000ms"}
          repeat={"infinite"}
          origin={"bottom"}
          color={"inherit"}
          style={{ height: "100%", width: "100%" }}
        >
          <BackgroundMediaUrlFromSalesarea
            keyName={"extra_settings__kioskv5_background_of_shopping_cart_button"}
            className={"JS-SmallShoppingCartButton-InnerWrapper"}
            sx={{
              height: 1,
              width: 1,
              paddingX: 4,
              paddingY: 2,
              borderTopLeftRadius: 24,
              borderTopRightRadius: 24,
              display: "flex",
              flexDirection: "column",
              boxShadow: "0 1px 10px 0px rgba(0,0,0,0.6)",
            }}
            sxWrapper={{ borderTopLeftRadius: 24, borderTopRightRadius: 24, backgroundColor: "primary.main" }}
          >
            <ShoppingCartButtonCounter count={count} />
            <Box
              className={"JS-SmallShoppingCartButton-TopBox"}
              sx={{
                flex: 1,
                height: 0,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
              }}
            >
              {logo ? (
                <Image
                  src={logo?.conversions?.optimized?.url}
                  style={{ height: "100%", width: "100%", objectFit: "contain" }}
                />
              ) : null}
            </Box>
            <Typography
              className={"JS-SmallShoppingCartButton-Price"}
              sx={{
                fontSize: 22,
                fontWeight: 800,
                width: 1,
                textAlign: "center",
                color: "primary.contrastText",
              }}
            >
              <FormattedMessageJamezz id={"Total amount"} />
            </Typography>
            <Typography
              className={"JS-SmallShoppingCartButton-Price"}
              sx={{
                fontSize: 32,
                fontWeight: 800,
                width: 1,
                textAlign: "center",
                color: "primary.contrastText",
              }}
            >
              {toCurrency(subTotalAmount)}
            </Typography>
            <Box className={"JS-SmallShoppingCartButton-BottomBox"} />
          </BackgroundMediaUrlFromSalesarea>
        </Bouncer>
      </Box>
    </Box>
  );
}

interface BouncerProps {
  origin: string;
  scale: number;
  time: string;
  repeat: string;
  delay: string;
  children: any;
  style?: any;
  color?: string;
}

function Bouncer(props: BouncerProps) {
  const theme = useTheme();

  const color = props.color ?? theme.palette.primary.main;

  const b = keyframes`

      0% {
        transform: scale(1);
        color: inherit;
        transform-origin: ${props.origin};
      }
      91% {
        transform: scale(1);
        color: inherit;
        transform-origin: ${props.origin};
      }
      95.5% {
        transform: scale(${props.scale});
        color: ${color};
        transform-origin: ${props.origin};
      }
      100% {
        transform: scale(1);
        color: inherit;
        transform-origin: ${props.origin};
      }`;
  return (
    <Box
      sx={{
        animation: `${b} ${props.time} ease ${props.repeat} ${props.delay}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...(props.style ?? {}),
      }}
    >
      {props.children}
    </Box>
  );
}
