import { useAppSelector } from "../redux/store";
import { useEffect, useMemo, useState } from "react";
import Article from "../models/menu/Article";
import { getSecondsLeftForOrderHours } from "../models/menu/OrderHours";
import useActiveMenuTags from "./useActiveMenuTags";

export default function useArticle(article: Article) {
  const collapsedState = useAppSelector((state) => state.menuState.collapsedArticles);
  const articleOrderHoursState = useAppSelector((state) => state.menuState.articleOrderHours);

  const activeMenuTags = useActiveMenuTags(article);
  const [collapsed, setCollapsed] = useState(collapsedState[article.id] ?? false);

  const secondsUntilEndTime = useMemo(() => {
    if (articleOrderHoursState) {
      return getSecondsLeftForOrderHours(articleOrderHoursState, article.id);
    }
    return null;
  }, [articleOrderHoursState, article]);

  useEffect(() => {
    if (secondsUntilEndTime && secondsUntilEndTime > 0) {
      setTimeout(() => {
        setCollapsed(true);
      }, secondsUntilEndTime * 1000);
    } else if (secondsUntilEndTime && secondsUntilEndTime < 0) {
      setCollapsed(true);
    }
  }, [secondsUntilEndTime]);

  useEffect(() => {
    if (collapsedState[article.id] != null && collapsed !== collapsedState[article.id]) {
      setCollapsed(collapsedState[article.id]);
    }
  }, [article.id, collapsed, collapsedState]);

  return { secondsUntilEndTime, setCollapsed, collapsed, activeMenuTags };
}
