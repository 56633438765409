import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../utils/redux/store";
import { MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import { currencies } from "../../utils/models/currencies/Currency";
import { setCurrency } from "../../utils/redux/menuSlice";
import useCurrency, { CurrencyLocation } from "../../utils/useCurrency";

interface Props {
  value?: number;
}

export default function CurrencySelector(props: Props) {
  const currency = useAppSelector((state) => state.menu.currency);
  const currencyRates = useAppSelector((state) => state.menu.currencyRates);
  const dispatch = useAppDispatch();

  const toCurrency = useCurrency({ location: CurrencyLocation.ShowAlways });

  const menuItems = useMemo(() => {
    return Object.keys(currencyRates)
      .sort()
      .map((currencyKey) => {
        return (
          <MenuItem value={currencyRates[currencyKey].currency.id} key={currencyRates[currencyKey].currency.id}>
            {currencyRates[currencyKey].currency.id}{" "}
            {props.value != null
              ? toCurrency(props.value, {
                  digits: 2,
                  useCurrencyRate: true,
                  currency: currencyRates[currencyKey].currency,
                })
              : null}
          </MenuItem>
        );
      });
  }, [currencyRates, props.value, toCurrency]);

  if (menuItems.length === 0) {
    return null;
  }

  return (
    <Box sx={{ width: "auto", display: menuItems.length === 0 ? "none" : "unset" }}>
      <Select
        sx={{ paddingRight: 4 }}
        value={currency.id}
        onChange={(e) => {
          dispatch(
            setCurrency({
              id: e.target.value,
              symbol: currencies[e.target.value]?.symbol ?? "?",
              name: e.target.value,
            })
          );
        }}
      >
        {menuItems}
      </Select>
    </Box>
  );
}
