import OptionItemRadio from "../OptionItems/OptionItemRadio";
import { useMemo } from "react";
import OrderOptionGroup from "../../../../global/utils/models/order/OrderOptionGroup";
import OrderArticle from "../../../../global/utils/models/order/OrderArticle";
import _ from "lodash";
import useMenuFilters from "../../../../global/utils/menu/filters/useMenuFilters.ts";

interface Props {
  orderOptionGroup: OrderOptionGroup;
  onChange: (orderOptionGroup: OrderOptionGroup) => void;
}

function OptionGroupRadio(props: Props) {
  const value = useMemo(
    () => props.orderOptionGroup.orderArticles.find((orderArticle) => orderArticle.count === 1)?.article.id ?? "",
    [props.orderOptionGroup]
  );

  const articleFilter = useMenuFilters({ useArrangementsFilter: false, useArticlegroupsFilter: false });

  return (
    <>
      {props.orderOptionGroup.orderArticles
        .filter((orderArticle) => articleFilter(orderArticle.article))
        .map((orderArticle, index) => (
          <OptionItemRadio
            isLastOptionInGroup={props.orderOptionGroup.orderArticles.length === index + 1}
            isFirstOptionInGroup={index === 0}
            key={orderArticle.article.id}
            index={index}
            orderArticle={orderArticle}
            orderOptionGroup={props.orderOptionGroup}
            value={value}
            onChange={(value: string) => {
              const copy = _.cloneDeep(props.orderOptionGroup);
              copy.orderArticles.forEach((orderArticle: OrderArticle) => {
                if (orderArticle.article.id === value) {
                  orderArticle.count = 1;
                } else {
                  orderArticle.count = 0;
                }
              });
              props.onChange(copy);
            }}
            onChangeOrderArticle={(orderArticle) => {
              const copy = _.cloneDeep(props.orderOptionGroup);
              copy.orderArticles[index] = orderArticle;
              props.onChange(copy);
            }}
          />
        ))}
    </>
  );
}

export default OptionGroupRadio;
