import Article from "../../../../utils/models/menu/Article.ts";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import FormattedMessageJamezz from "../../../FormattedMessageJamezz.tsx";

export default function ConfirmMaxPerTicketPopup({
  article,
  onCancel,
  onConfirm,
  maxPerTicket,
  totalItemCount,
}: {
  article: Article;
  onCancel: () => void;
  onConfirm: () => void;
  maxPerTicket: number;
  totalItemCount: number;
}) {
  return (
    <Dialog open={true}>
      <DialogTitle>
        <FormattedMessageJamezz id="AYCE.popups.heading.limited-product" />
      </DialogTitle>
      <DialogContent>
        <span style={{ textDecoration: "underline", fontWeight: "bold" }}>{article.name}</span>
        <FormattedMessageJamezz
          id="AYCE.popups.max-per-ticket.rule"
          values={{
            maxPerTicket,
          }}
        />
        <p>
          {totalItemCount === 0 ? (
            <FormattedMessageJamezz id="AYCE.popups.current-zero" />
          ) : (
            <FormattedMessageJamezz
              id="AYCE.popups.current-nonzero"
              values={{
                count: totalItemCount,
              }}
            />
          )}
        </p>
        <p>
          <FormattedMessageJamezz id="AYCE.popups.are-you-sure" />
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="contained">
          <FormattedMessageJamezz id="Cancel" />
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          color="success"
          data-cy="arrangement-rule-notice-confirmation-btn"
        >
          <FormattedMessageJamezz id="Ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
