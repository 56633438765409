import { ReactNode } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import FormattedMessageJamezz from "../../global/components/FormattedMessageJamezz";
import { useDialog } from "../../global/utils/dialog/DialogProvider";
import { OrderPageAction } from "../pages/OrderPage/OrderPageAction.tsx";

export default function AreYouSureDialog(props: {
  message: ReactNode;
  onOk: () => void;
  CancelText?: ReactNode;
  OkText?: ReactNode;
}) {
  const { closeDialog } = useDialog();
  return (
    <Box sx={{ padding: 8 }}>
      <Typography className={"JS-AreYouSureDialog-Text"} sx={{ textAlign: "center" }}>
        {props.message}
      </Typography>

      <Box sx={{ width: "100%", mt: 4 }}>
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 4 }}>
          <OrderPageAction
            onClick={() => {
              closeDialog();
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                width: 1,
                fontSize: "28px",
              }}
            >
              {props.CancelText ?? <FormattedMessageJamezz id="Cancel" />}
            </Box>
          </OrderPageAction>
          <OrderPageAction
            onClick={async () => {
              props.onOk();
              closeDialog();
            }}
          >
            <Box
              sx={{
                width: 1,
                display: "grid",
                fontSize: "28px",
              }}
            >
              {props.OkText ?? <FormattedMessageJamezz id={"Ok"} />}
            </Box>
          </OrderPageAction>
        </Box>
      </Box>
    </Box>
  );
}
