import { useState } from "react";
import Article from "../utils/models/menu/Article";
import FormattedMessageJamezz from "./FormattedMessageJamezz";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  DialogActions,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../utils/redux/store";
import { useDialog } from "../utils/dialog/DialogProvider";
import OrderArticle from "../utils/models/order/OrderArticle";
import { useIntl } from "react-intl";
import { AgeCheckResult, findProductWithHighestAgeRequirement } from "../utils/ageCheck/ageCheckUtils";
import { ageConfirmationReceived } from "../utils/redux/userInputSlice.ts";
import { useCustomHtmlTranslations, useCustomTexts } from "../utils/useCustomTexts.tsx";
import isKiosk from "../utils/helpers/isKiosk.tsx";

export type AgeCheckDialogProps = {
  product: Article;
  onConfirm: (confirmed: boolean) => void;
};

/**
 * @example
 * const checkAge = useAgeCheck();
 * <product onAddToCart={async (product) => {
 *   const ageAccepted = await checkAge(product);
 * }} />
 *
 * @description
 * When age hasn't been filled in, shows pop-up and lets user input age
 * If age has already been set, it will immediately return without showing the input popup
 */
export function useAgeCheck() {
  const { openDialog, closeDialog } = useDialog();
  const userAgeAtLeast = useAppSelector((state) => state.userInput.userConfirmedAgeBeingAtLeast);

  return function checkAge(order: OrderArticle) {
    const productToAgeVerify = findProductWithHighestAgeRequirement(order);

    // 1) product has no age requirement
    if (productToAgeVerify.requireAge === 0 || productToAgeVerify.requireAge === undefined) {
      return new Promise<AgeCheckResult>((resolve) => resolve(AgeCheckResult.Accepted));
    }

    /**
     * 2) product has age requirement, but user either
     *   a) has not given age yet
     *   or
     *   b) user has given age for product with required age of for example 18
     *      but the current product requires for example 21
     */
    if (userAgeAtLeast === undefined || userAgeAtLeast < (productToAgeVerify.requireAge ?? 0)) {
      return new Promise<AgeCheckResult>((resolve) => {
        openDialog({
          onClose: () => resolve(AgeCheckResult.Postponed),
          children: (
            <AgeCheckDialog
              product={productToAgeVerify}
              onConfirm={(minimumAgeConfirmed: boolean) => {
                resolve(minimumAgeConfirmed ? AgeCheckResult.Accepted : AgeCheckResult.Denied);
                closeDialog();
              }}
            />
          ),
        });
      });
    }

    // 3) User has confirmed age before, and age is >= product.requireAge
    return new Promise<AgeCheckResult>((resolve) => {
      resolve(AgeCheckResult.Accepted);
    });
  };
}

export function AgeCheckDialog({ product, onConfirm }: AgeCheckDialogProps) {
  const dispatch = useAppDispatch();
  const [ageConfirmed, setAgeConfirmed] = useState<boolean>(false);
  const [pleaseTickBox, setPleaseTickBox] = useState<boolean>(false);
  const intl = useIntl();
  const texts1 = useCustomHtmlTranslations(["custom_age_verify_notification_message"]);
  const texts2 = useCustomTexts(["custom_age_verify_notification_checkbox_line"]);

  return (
    <Box sx={{ m: 4 }} data-cy="age-check-by-user-dialog">
      <Typography variant="h1" fontSize="1.5em" sx={{ mb: 2 }}>
        <FormattedMessageJamezz id="Age check" />
      </Typography>
      {texts1.custom_age_verify_notification_message != null ? (
        <Typography dangerouslySetInnerHTML={{ __html: texts1.custom_age_verify_notification_message }} />
      ) : (
        <>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <FormattedMessageJamezz
              id="Popup.ageCheck.description"
              values={{
                name: product.name,
                age: product.requireAge,
              }}
            />
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            <FormattedMessageJamezz id="AgeCheck.popup.youMayBeCheckedWarning" />
          </Typography>
        </>
      )}
      <Box sx={{ mb: 2 }}>
        <Collapse in={pleaseTickBox}>
          <Typography sx={{ color: "red" }} data-cy="age-check-dialog-check-to-continue-warning">
            <FormattedMessageJamezz id={"Please check this box if you want to proceed"} />
          </Typography>
        </Collapse>
        <FormGroup>
          <FormControlLabel
            label={
              texts2.custom_age_verify_notification_checkbox_line ??
              intl.formatMessage(
                { id: "AgeCheck.popup.confirmAge.label" },
                {
                  age: product.requireAge ?? 0,
                }
              )
            }
            control={<Checkbox checked={ageConfirmed} onChange={() => setAgeConfirmed(!ageConfirmed)} />}
          />
        </FormGroup>
      </Box>
      <DialogActions>
        <Stack direction={"row"} gap={isKiosk() ? 6 : 2}>
          <Button
            type={"submit"}
            data-cy="confirm-age-check-dialog-cancel-btn"
            onClick={() => {
              onConfirm(false);
            }}
            variant="contained"
          >
            <FormattedMessageJamezz id="Cancel" />
          </Button>
          <Button
            type={"submit"}
            data-cy="confirm-age-check-dialog-read-btn"
            onClick={() => {
              if (ageConfirmed) {
                dispatch(ageConfirmationReceived(product.requireAge ?? 0));
                onConfirm(ageConfirmed);
              } else {
                setPleaseTickBox(true);
              }
            }}
            variant="contained"
          >
            <FormattedMessageJamezz id="Confirm age" />
          </Button>
        </Stack>
      </DialogActions>
    </Box>
  );
}
