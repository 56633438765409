import { useState } from "react";
import { Button, DialogContent, TextField, Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import CloseDialogButton from "../../../qr/components/Dialog/CloseDialogButton.tsx";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import { useAppDispatch } from "../../../global/utils/redux/store";
import { onFocusKeyboard } from "../../../global/utils/redux/globalSlice";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import ManuallyAddArticleDialog from "../ManuallyAddArticleDialog/ManuallyAddArticleDialog";
import useEmployeeCodes from "../../hooks/useEmployeeCodes";
import isKiosk from "../../../global/utils/helpers/isKiosk";
import { BlinkstickStatus, setBlinkstickStatus } from "../../../global/utils/redux/blinkstickSlice.tsx";

export default function EmployeePinCheckDialog() {
  const { closeDialog, openDialog } = useDialog();
  const dispatch = useAppDispatch();
  const employeeCodes = useEmployeeCodes();
  const [pincode, setPincode] = useState("");

  return (
    <>
      <DialogContent
        sx={{
          paddingY: 8,
          paddingX: 8,
        }}
      >
        {Object.keys(employeeCodes).length === 0 && <CloseDialogButton onClose={closeDialog} />}
        <Typography sx={{ fontSize: "1.5em" }}>
          <FormattedMessageJamezz id={"Enter pincode"} />
        </Typography>
        <Box display={"flex"} alignItems={"center"}>
          <TextField
            label={"Pincode"}
            value={pincode}
            type={"password"}
            inputProps={{
              inputMode: isKiosk() ? "none" : undefined,
            }}
            onChange={(e) => {
              setPincode(e.target.value);
            }}
            onFocus={() => {
              if (isKiosk()) {
                dispatch(
                  onFocusKeyboard({
                    value: pincode,
                    layout: "numpad",
                  })
                );
              }
            }}
          />
          <Button
            sx={{ marginLeft: 4 }}
            variant={"contained"}
            onClick={() => {
              if (employeeCodes[pincode]) {
                dispatch(setBlinkstickStatus(BlinkstickStatus.Default));
                closeDialog();
                openDialog({ children: <ManuallyAddArticleDialog /> });
              } else {
                toast.error(<FormattedMessageJamezz id={"Unknown pincode"} />);
              }
            }}
          >
            <FormattedMessageJamezz id={"Login"} />
          </Button>
        </Box>
      </DialogContent>
    </>
  );
}
