import { useCallback } from "react";
import { useArticleTraitFiltering } from "../../global/utils/menu/filters/useArticleTraitFiltering";
import useArticleSearchFiltering from "../../global/utils/menu/filters/useArticleSearchFiltering";
import QrArticleSuggestionPage from "../../qr/pages/ArticleSuggestionPage/ArticleSuggestionPage";
import KioskArticleSuggestionPage from "../../kiosk/pages/ArticleSuggestionPage/ArticleSuggestionPage";
import SlideUpTransition from "../../global/components/SlideUpTransition";
import { useDialog } from "../../global/utils/dialog/DialogProvider";
import OrderArticle from "../../global/utils/models/order/OrderArticle";
import { getSecondsLeftForOrderHours } from "../../global/utils/models/menu/OrderHours";
import { useAppSelector } from "../../global/utils/redux/store";
import { getArticlesByArticlegroupId } from "../../global/utils/models/menu/Menu";
import useMenuFilters from "../../global/utils/menu/filters/useMenuFilters";
import { selectArticlesMap } from "../../global/utils/redux/selectors/selectArticlesMap";
import { selectArticlegroupArticlesMap } from "../../global/utils/redux/selectors/selectArticlegroupArticlesMap";
import isKiosk from "../../global/utils/helpers/isKiosk.tsx";
import useIsWidescreen from "../../global/utils/useIsWidescreen.ts";

export default function useUpsellMenuTrigger() {
  const { openDialog } = useDialog();
  const articleFilter = useMenuFilters();
  const isWidescreen = useIsWidescreen();
  const { articleTraitFilter } = useArticleTraitFiltering();
  const searchArticles = useArticleSearchFiltering();
  const articleOrderHoursState = useAppSelector((state) => state.menuState.articleOrderHours);
  const articlesMap = useAppSelector(selectArticlesMap);
  const articlegroupArticlesMap = useAppSelector(selectArticlegroupArticlesMap);

  const trigger = useCallback(
    (orderArticle: OrderArticle) => {
      if (orderArticle.article.upsellMenu) {
        const articles = searchArticles(
          getArticlesByArticlegroupId(articlesMap, articlegroupArticlesMap, orderArticle.article.upsellMenu)
            .filter(articleFilter)
            .filter(articleTraitFilter)

            .filter((article) => {
              if (article.isOutOfStock) {
                return false;
              }
              const seconds = getSecondsLeftForOrderHours(articleOrderHoursState, article.id);
              return !(articleOrderHoursState != null && seconds != null && seconds < 0);
            })
        );
        if (articles.length > 0) {
          const ArticleSuggestionPage = isKiosk() ? KioskArticleSuggestionPage : QrArticleSuggestionPage;
          setTimeout(() => {
            openDialog({
              children: (
                <ArticleSuggestionPage
                  upsellType={"MenuUpsell"}
                  upsellSource={orderArticle.article.id}
                  articles={articles}
                />
              ),
              fullScreen: isKiosk() ? "almostFullScreen" : !isWidescreen,
              TransitionComponent: SlideUpTransition,
            });
          }, 200);
        }
      }
    },
    [
      articleOrderHoursState,
      searchArticles,
      articleTraitFilter,
      openDialog,
      articleFilter,
      articlesMap,
      articlegroupArticlesMap,
      isWidescreen,
    ]
  );

  return { triggerUpsellMenu: trigger };
}
