import useOrderLimits from "../../../global/utils/useOrderLimits.ts";
import { OrderPageAction } from "./OrderPageAction.tsx";
import { ReactNode } from "react";

export function CheckoutButton({ onClick, children }: { onClick: () => void; children: ReactNode }) {
  const { isValid } = useOrderLimits();

  return (
    <OrderPageAction
      onClick={onClick}
      disabled={!isValid}
      data-cy="btn-goto-checkout"
      className="JS-OrderPage-GoToCheckoutButton"
    >
      {children}
    </OrderPageAction>
  );
}
