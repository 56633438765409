import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import store, { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import _ from "../../../global/utils/lodash-mixins";
import useDeepCompareEffect from "use-deep-compare-effect";
import { Badge, Box, Button, Typography } from "@mui/material";
import { PropposApiProduct, PropposJamezzProduct } from "./logic/PropposApi";
import { PropposInterface } from "./logic/PropposInterface";
import { isDev } from "../../../global/utils/devHelpers/isDev";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import SmallShoppingCartButton from "../MenuPage/ShoppingCartButtons/SmallShoppingCartButton";
import HorizontalArticlegroupTabBar from "../MenuPage/ArticlegroupTab/HorizontalArticlegroupTabBar";
import ArticleContent from "../MenuPage/ArticleContent/ArticleContent";
import { initOrderArticle, OrderArticleOrigin } from "../../../global/utils/models/order/OrderArticle";
import { TrayScanned } from "../../../global/utils/redux/propposSlice";
import { propposScanReceived } from "../../../global/utils/redux/shoppingCartSlice";
import { selectArticlesMap } from "../../../global/utils/redux/selectors/selectArticlesMap";
import BackgroundMediaUrlFromSalesarea from "../../hooks/useBackgroundMediaUrl.tsx";

const DEFAULT_SCORE_THRESHOLD = 20;

function useProppos() {
  const propposEnabled = useAppSelector((state) => state.global.salesarea?.proppos_settings?.enabled ?? false);
  const propposIp = useAppSelector((state) => state.global.salesarea?.proppos_settings?.ip_address);
  const deviceId = useAppSelector((state) => state.global.salesarea?.proppos_settings?.device_id);
  const secretString = useAppSelector((state) => state.global.salesarea?.proppos_settings?.secret_string);
  const polling_rate_in_ms = useAppSelector(
    (state) => state.global.salesarea?.proppos_settings?.polling_rate_in_milliseconds
  );
  const scoreThreshold =
    useAppSelector(
      (state) => state.global.salesarea?.proppos_settings?.scoreThresholdInPercentage ?? DEFAULT_SCORE_THRESHOLD
    ) / 100;

  return {
    propposEnabled,
    propposIp,
    deviceId,
    secretString,
    scoreThreshold,
    polling_rate_in_ms,
  };
}

export function PropposPage() {
  const articlesMap = useAppSelector(selectArticlesMap);

  const { propposEnabled, propposIp, deviceId, secretString, scoreThreshold, polling_rate_in_ms } = useProppos();
  const api = useRef<PropposInterface | undefined>(undefined);
  const [snapshotJustTaken, setSnapshotJustTaken] = useState<boolean>(false);
  const [predictions, setPredictions] = useState<PropposJamezzProduct[]>([]);
  const [lowQualityPredictions, setLowQualityPredictions] = useState<boolean>(false);

  const trays = useAppSelector((state) => state.proppos);
  const dispatch = useAppDispatch();

  function addTray() {
    const orderArticles = predictions.map(({ article, quantity }) => {
      const orderArticle = initOrderArticle(articlesMap, article, quantity);
      orderArticle.editable = false;
      orderArticle.added_origin = OrderArticleOrigin.PROPPOS;
      return orderArticle;
    });
    dispatch(TrayScanned(orderArticles.map((o) => o.uuid)));
    dispatch(propposScanReceived({ propposProducts: orderArticles }));
    setSnapshotJustTaken(true);
  }

  const orderOrNext = () => (
    <Button variant="contained" disableRipple disableElevation className="JS-PropposPage-ScanButton">
      <FormattedMessageJamezz id="Proppos.help-text.scanned-one-tray" />
    </Button>
  );
  const mainButton = _.cond([
    [
      _.matchPattern({
        trayCount: 0,
        predictionItemCount: 0,
        snapshotJustTaken: false,
      }),
      () => (
        <Button variant="contained" disableRipple disableElevation>
          <FormattedMessageJamezz id="Proppos.help-text.place-tray" />
        </Button>
      ),
    ],
    [
      _.matchPattern({
        predictionItemCount: (v: number) => v > 0,
        lowQualityPredictions: false,
        snapshotJustTaken: false,
      }),
      () => (
        <Button variant="contained" onClick={addTray} color="success">
          <FormattedMessageJamezz id="Proppos.help-text.add-tray" />
        </Button>
      ),
    ],
    [_.matchPattern({ trayCount: (v: number) => v > 0, snapshotJustTaken: true }), orderOrNext],
    [_.matchPattern({ trayCount: (v: number) => v > 0, predictionItemCount: 0 }), orderOrNext],
    [
      _.matchPattern({
        lowQualityPredictions: true,
        predictionItemCount: (v: number) => v > 0,
        snapshotJustTaken: false,
      }),
      () => (
        <Button variant="contained" color="error" disableElevation disableTouchRipple>
          <FormattedMessageJamezz id="Proppos.help-text.scanner-certainty-low" />
        </Button>
      ),
    ],
    [
      _.stubTrue,
      () => {
        console.warn("Proppos: No match");
        return <Button>Error</Button>;
      },
    ],
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setProducts = useCallback(
    _.throttle((products: PropposApiProduct[]) => {
      const propposPredictions = products
        .map((p) => ({
          id: p.id,
          name: p.name,
          quantity: p.quantity,
          article: _.find(selectArticlesMap(store.getState()), (article) => article.propposId?.toString() === p.id),
        }))
        .filter((v) => v.article) as PropposJamezzProduct[];

      setLowQualityPredictions(_.some(products, (product) => product.score <= scoreThreshold));
      setPredictions(propposPredictions);
    }),
    [setPredictions, scoreThreshold]
  );

  const onScan = useCallback(
    function onScan(products: PropposApiProduct[]) {
      setProducts(products);
    },
    [setProducts]
  );

  useDeepCompareEffect(() => {
    if (predictions.length > 0) {
      if (isDev()) {
        toast.info(`Your cart changed`);
      }
      if (snapshotJustTaken) {
        setSnapshotJustTaken(false);
      }
    }
  }, [predictions]);

  useEffect(() => {
    if (propposEnabled && propposIp && api.current === undefined) {
      if (isDev()) {
        toast.warn("Proppos reloaded");
      }
      api.current = new PropposInterface(propposIp, onScan, deviceId, secretString, polling_rate_in_ms);
      api.current.init();
    }

    return () => {
      if (api.current) {
        api.current.stop();
        api.current = undefined;
      }
    };
  }, [deviceId, onScan, polling_rate_in_ms, propposEnabled, propposIp, secretString]);

  const newVar = {
    trayCount: Object.keys(trays).length,
    predictionItemCount: predictions.length ?? 0,
    lowQualityPredictions,
    snapshotJustTaken,
  };
  return (
    <BackgroundMediaUrlFromSalesarea
      keyName={"extra_settings__kioskv5_background_of_menu_page_content"}
      sx={{
        height: "100%",
      }}
      className="JS-PropposPage-Page"
      fallbackBackgroundColor={"white"}
    >
      <HorizontalArticlegroupTabBar articlegroups={[]} disableSearch disableHomeButton={false} />
      <Box
        sx={{
          padding: "32px",
          display: "flex",
          flexDirection: "column",
          maxHeight: "70vh",
        }}
      >
        <Typography
          variant="h1"
          fontSize="2em"
          sx={{ textAlign: "center", mb: 8, mt: 4 }}
          className="JS-PropposPage-MainTitle"
        >
          <FormattedMessageJamezz id="Proppos.page.heading" />
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 4,
            height: "70vh",
          }}
        >
          {snapshotJustTaken
            ? null
            : predictions.map(({ article, id, quantity }) => {
                return (
                  <Box>
                    <Badge
                      badgeContent={quantity}
                      color="primary"
                      sx={{
                        "& .MuiBadge-badge": { left: "32px", top: "24px", padding: "16px" },
                        width: "100%",
                      }}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <ArticleContent article={article} key={id} noShowCount disableEditing />
                    </Badge>
                  </Box>
                );
              })}
        </Box>
        {/*<PropposScanner*/}
        {/*  snapshotJustTaken={snapshotJustTaken}*/}
        {/*  setSnapshotJustTaken={setSnapshotJustTaken}*/}
        {/*  predictions={predictions}*/}
        {/*  lowQualityPredictions={lowQualityPredictions}*/}
        {/*/>*/}
        {/*<PropposShoppingCart*/}
        {/*  afterTrayRemoved={() => {*/}
        {/*    setSnapshotJustTaken(false);*/}
        {/*  }}*/}
        {/*/>*/}
        {mainButton(newVar)}
      </Box>
      <SmallShoppingCartButton />
    </BackgroundMediaUrlFromSalesarea>
  );
}
